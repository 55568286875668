import {MSG} from '../constants/socketEventNames';

export default {
  UserPaymentProcessedSubscription: {
    incomingEvents: [`${MSG}userPaymentProcessed`],
    fromServer: (response) => {
      const {orderId, paymentStatus, redirectUrl, via, declineScreenTemplate} =
        response;

      return {
        data: {
          paymentProcessed: {
            orderId,
            paymentStatus,
            declineScreenTemplate,
            redirectUrl,
            via,
          },
        },
      };
    },
  },
};
