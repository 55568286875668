import type {PaymentDataProps} from '../../types/paymentAnswerProps';
import getBrowserDetails from '../../utils/getBrowserDetails';

const addBrowserDetails = (
  paymentData: Omit<PaymentDataProps, 'browserDetails'>,
): PaymentDataProps => ({
  ...paymentData,
  browserDetails: getBrowserDetails(),
});

export default addBrowserDetails;
