import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';

import resetPhotoElements from '@core/gallery/utils/resetPhotoElements';
import resetVideoElements from '@core/gallery/utils/resetVideoElements';
import PopupLoadTrackingContext from '@core/popup/containers/PopupLoadTrackingContext';

const VIDEO_TAG_NAME = 'VIDEO';

const getInitialIndex = (items, initialId) =>
  items.findIndex(({id}) => id === initialId);

const resetItems = (galleryElement) => {
  resetPhotoElements(galleryElement);
  resetVideoElements(galleryElement);
};

const useStartIndex = (items, initialMediaId) => {
  const [index, setIndex] = useState(() =>
    getInitialIndex(items, initialMediaId),
  );

  /**
   * For cases when the index is managed by parent, need update state
   */
  useEffect(() => {
    setIndex(getInitialIndex(items, initialMediaId));
  }, [initialMediaId, items]);

  /**
   * updateCurrentMedia is used after removing items from the gallery
   * is used only for cases when last item is removed,
   * in other cases library handles that by itself
   */
  if (index === items.length) {
    setIndex(items.length - 1);
  }

  return index;
};

const GalleryDisplayer = ({
  userId,
  initialMediaId,
  items,
  className,
  my,
  site,
  showNav,
  paidPhoto,
  showCounter,
  counterOverContent,
  onSlide,
  onNavClick,
  bottomRenderer,
  photoRenderer,
  videoRenderer,
  navigationButtonComponent: NavigationButton,
  galleryPopupLayoutComponents: GalleryPopupLayout,
  direction: DIRECTION,
  needGTMTrack,
  ...props
}) => {
  const startIndex = useStartIndex(items, initialMediaId);
  const [fullScreenVideo, setFullScreenVideo] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const galleryRef = useRef(null);

  const trackLoadComplete = useContext(PopupLoadTrackingContext);

  const handleOnSlide = useCallback(
    (activeIndex) => {
      if (onSlide) {
        onSlide(activeIndex, site);
      }

      setActiveItem(activeIndex);

      if (fullScreenVideo) return;

      resetItems(galleryRef.current);
    },
    [site, fullScreenVideo, onSlide],
  );

  const handleFullscreenChange = useCallback(() => {
    const element = document.fullscreenElement;

    if (element && element.tagName === VIDEO_TAG_NAME) {
      setFullScreenVideo(true);
      return;
    }
    setFullScreenVideo(false);
  }, []);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [handleFullscreenChange]);

  const isNavigationButtonWithOverlay = useMemo(() => {
    return !paidPhoto || (paidPhoto && Boolean(items[activeItem].video));
  }, [activeItem, items, paidPhoto]);

  /**
   * Render Navigation button for gallery
   * Demand of gallery library
   * @see GalleryLayout.js
   */
  const renderNav = useCallback(
    (direction) => (onClick) => {
      const handleClick = onNavClick
        ? (navProps) => {
            onNavClick(direction);
            onClick(navProps);
          }
        : onClick;

      return (
        <NavigationButton
          inverse
          direction={direction}
          onClick={handleClick}
          overlay={isNavigationButtonWithOverlay}
        />
      );
    },
    [onNavClick, isNavigationButtonWithOverlay, NavigationButton],
  );

  // @see GalleryLayout.js
  const renderRightNav = useMemo(
    () => renderNav(DIRECTION.RIGHT),
    [DIRECTION, renderNav],
  );

  // @see GalleryLayout.js
  const renderLeftNav = useMemo(
    () => renderNav(DIRECTION.LEFT),
    [DIRECTION, renderNav],
  );

  const renderItem = (item, isActive) => {
    if (item.photo) {
      return photoRenderer({
        ...item,
        userId,
        my,
        site,
        onZoomUpdate: (zoomed) => {
          setIsZoomed(zoomed);
        },
        onLoad: isActive
          ? () => {
              trackLoadComplete();
            }
          : null,
        ...props,
      });
    }

    if (item.video) {
      return videoRenderer({
        ...item,
        userId,
        my,
        onCanPlay: isActive
          ? () => {
              trackLoadComplete();
            }
          : null,
        isActive,
        ...props,
      });
    }

    return null;
  };

  // Prevent render empty gallery
  if (!items.length) return null;

  return (
    <GalleryPopupLayout
      startIndex={startIndex}
      ref={galleryRef}
      onSlide={handleOnSlide}
      renderItem={renderItem}
      renderLeftNav={renderLeftNav}
      renderRightNav={renderRightNav}
      needGTMTrack={needGTMTrack}
      className={className}
      showCounter={showCounter}
      counterOverContent={counterOverContent}
      items={items}
      showNav={showNav}
      renderBottom={bottomRenderer}
      disableSwipe={fullScreenVideo}
      isZoomed={isZoomed}
    />
  );
};

GalleryDisplayer.propTypes /* remove-proptypes */ = {
  userId: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      video: PropTypes.objectOf(PropTypes.any),
    }),
  ),
  initialMediaId: PropTypes.string.isRequired,
  className: PropTypes.string,
  my: PropTypes.bool,
  showNav: PropTypes.bool,
  showCounter: PropTypes.bool,
  counterOverContent: PropTypes.bool,
  onSlide: PropTypes.func,
  /**
   * For cases if need add logic after navigation click
   * ex. add gui tracking after click
   */
  onNavClick: PropTypes.func,
  /**
   * For customize the component of photo or video item in gallery, need to use render function
   */
  photoRenderer: PropTypes.func,
  videoRenderer: PropTypes.func,
  /**
   * Render function for additional gallery content which is situated in the bottom place
   * ex. @see GalleryPopup.js
   */
  bottomRenderer: PropTypes.func,
  site: PropTypes.shape({
    cdnDomain: PropTypes.string.isRequired,
    imageFormat: PropTypes.string.isRequired,
    photoUrlExtension: PropTypes.string,
  }).isRequired,
  navigationButtonComponent: PropTypes.func.isRequired,
  /**
   * Since GalleryPopupLayout wrapped by forwardRef witch returns object
   */
  galleryPopupLayoutComponents: PropTypes.oneOfType([
    PropTypes.shape({render: PropTypes.func.isRequired}),
  ]).isRequired,
  direction: PropTypes.shape({
    RIGHT: PropTypes.string.isRequired,
    LEFT: PropTypes.string.isRequired,
  }),
  needGTMTrack: PropTypes.bool,
  paidPhoto: PropTypes.bool,
};

GalleryDisplayer.defaultProps = {
  my: false,
  showNav: false,
  showCounter: false,
  counterOverContent: false,
  needGTMTrack: false,
};

export default GalleryDisplayer;
