import {setProcessing} from '../../../common/utils/setPaymentProcessingStatus';
import type {PaymentDataProps} from '../../types/paymentAnswerProps';

/**
 * Send track and set processing status that indicate start of payment process
 */
const reportStartPayment = (
  paymentData: PaymentDataProps,
): PaymentDataProps => {
  setProcessing();
  return paymentData;
};

export default reportStartPayment;
