import {
  setReady,
  setFailed,
  setSuccess,
} from '../../../common/utils/setPaymentProcessingStatus';
import type {PaymentAnswer} from '../../types/paymentAnswerProps';

/**
 * Set processing status that indicate start of payment process
 */
const reportEndPayment = (paymentAnswer: PaymentAnswer): PaymentAnswer => {
  const {isAllowedRedirect, replaceRedirectToPostMessage, status} =
    paymentAnswer;

  if (status && replaceRedirectToPostMessage) {
    return paymentAnswer;
  }

  if (isAllowedRedirect || replaceRedirectToPostMessage) {
    setReady();
    return paymentAnswer;
  }

  if (status) {
    setSuccess();
  } else {
    setFailed();
  }

  return paymentAnswer;
};

export default reportEndPayment;
