import getHistory from '@core/application/utils/getHistory';

import type {
  PaymentAnswer,
  PaymentAnswerAbortable,
} from '../../types/paymentAnswerProps';
import {PAYMENT_FLOW_ABORT} from '../../utils/getProcessPaymentFlow';

/**
 * Redirect to external 3DS page
 */
const externalThreeDSRedirect = (
  paymentAnswer: PaymentAnswer,
): PaymentAnswerAbortable => {
  const redirectUrl = paymentAnswer['3dsRedirectUrl'];

  if (redirectUrl) {
    getHistory().push(redirectUrl);
    return PAYMENT_FLOW_ABORT;
  }

  return paymentAnswer;
};

export default externalThreeDSRedirect;
