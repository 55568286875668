import type {ComponentType} from 'react';

import type {
  PaymentAnswer,
  PaymentAnswerAbortable,
} from '@core/payment/payProcess/types/paymentAnswerProps';

import ThreeDSecureProcessor from '../../utils/ThreeDSecureProcessor';
import type ThreeDSecureAbortPopupProps from '../../../widgets/threeDSecure/types/threeDSecureAbortPopupProps';

type ProcessThreeDSecureAnswerProps = {
  PaymentPendingPopupLayout: ComponentType;
  ThreeDSecureAbortPopup: ComponentType<ThreeDSecureAbortPopupProps>;
};

const baseProcessThreeDSecureAnswer =
  ({
    PaymentPendingPopupLayout,
    ThreeDSecureAbortPopup,
  }: ProcessThreeDSecureAnswerProps) =>
  (paymentAnswer: PaymentAnswer): Promise<PaymentAnswerAbortable> => {
    const {acsUrl, via, orderId, iframeUrl} = paymentAnswer;

    if (acsUrl) {
      return new ThreeDSecureProcessor(
        via,
        orderId,
        PaymentPendingPopupLayout,
        ThreeDSecureAbortPopup,
      )
        .openThreeDSecurePopup(iframeUrl)
        .getPromise();
    }

    return Promise.resolve(paymentAnswer);
  };

export default baseProcessThreeDSecureAnswer;
