import {getClientInstance} from '@core/graphql/client';

import PAY_ANSWER_QUERY from '../../graphql/queries/payAnswer.gql';
import type {PayAnswerQuery} from '../../graphql/queries/payAnswer';
import type {PaymentAnswer} from '../../types/paymentAnswerProps';

/**
 * Get processing status that indicate start of payment process
 */
const processPaymentAnswer = (paymentAnswer: PaymentAnswer): PaymentAnswer => {
  const client = getClientInstance();

  const data = client.readQuery<PayAnswerQuery>({query: PAY_ANSWER_QUERY});

  client.writeQuery<PayAnswerQuery>({
    query: PAY_ANSWER_QUERY,
    data: {
      ...data,
      payment: {
        ...data.payment,
        payAnswer: {
          ...data.payment.payAnswer,
          status: paymentAnswer.status,
          isEmptyFormAfterDecline: paymentAnswer.isEmptyFormAfterDecline,
          failReason: paymentAnswer.reason || paymentAnswer.failPageReason,
          declineMessageType:
            paymentAnswer?.templateSettings?.declineMessageType || null,
          declineScreenTemplate: paymentAnswer.declineScreenTemplate,
          orderId: paymentAnswer.orderId,
          __typename: 'PayAnswer',
        },
        __typename: 'Payment',
      },
    },
  });

  return paymentAnswer;
};

export default processPaymentAnswer;
