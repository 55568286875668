import omit from 'lodash/omit';

import {getClientInstance} from '@core/graphql/client';
import CSRF_TOKEN_QUERY from '@core/graphql/graphql/queries/csrfToken.gql';
import type {CsrfTokenQuery} from '@core/graphql/graphql/queries/csrfToken';

import type {PaymentAnswer} from '../../types/paymentAnswerProps';

const updateCSRFToken = (
  paymentAnswer: PaymentAnswer,
): Omit<PaymentAnswer, 'csrfToken'> => {
  const csrfToken = paymentAnswer?.csrfToken?.value;

  if (csrfToken) {
    getClientInstance().writeQuery<CsrfTokenQuery>({
      query: CSRF_TOKEN_QUERY,
      data: {
        site: {
          csrfToken,
          __typename: 'Site',
        },
      },
    });

    return omit(paymentAnswer, 'csrfToken');
  }

  return paymentAnswer;
};

export default updateCSRFToken;
