import isObject from 'lodash/isObject';

import URI from '@core/utils/url';
import getHistory from '@core/application/utils/getHistory';
import clearUrl from '@core/utils/url/clearUrl';

import {PAYMENT_FLOW_ABORT} from '../../utils/getProcessPaymentFlow';

const goToNextStepOnDecline = (data) => {
  const {failPageLogic, redirectUrl, urlParams} = data;

  if (failPageLogic && redirectUrl) {
    let url = redirectUrl;

    if (isObject(urlParams)) {
      const uri = URI(url);

      url = uri.addSearch(urlParams).toString();
    }

    getHistory().push(clearUrl(url));

    return PAYMENT_FLOW_ABORT;
  }

  return data;
};

export default goToNextStepOnDecline;
