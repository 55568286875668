import cachedPurchasedPackage from '../../../common/utils/cachedPurchasedPackage';
import type {PaymentDataProps} from '../../types/paymentAnswerProps';

/**
 * Caching active package for the possibility of obtaining purchased package data after payment
 */
const cachePurchasedPackage = (
  paymentData: PaymentDataProps,
): PaymentDataProps => {
  cachedPurchasedPackage.set(paymentData.activePackage);
  return paymentData;
};

export default cachePurchasedPackage;
