import {MSG} from '../constants/socketEventNames';

export default {
  InteractionOrderProcessedSubscription: {
    incomingEvents: `${MSG}orderProcessed`,

    fromServer: ({paymentStatus}) => ({
      data: {
        payment: {
          paymentStatus,
        },
      },
    }),
  },
};
