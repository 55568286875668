import type {FC} from 'react';
import React, {Suspense, lazy} from 'react';

import type {MapProps} from './types';

const MapComponent = lazy(
  () => import(/* webpackChunkName: 'leafletMapComponent' */ './Map'),
);

const LazyMap: FC<MapProps> = (props) => (
  <Suspense fallback={null}>
    <MapComponent {...props} />
  </Suspense>
);

export default LazyMap;
