import {getClientInstance} from '@core/graphql/client';
import logger from '@core/logger';

import PAY_ANSWER_QUERY from '../../graphql/queries/payAnswer.gql';

/**
 * Update cache fail page logic
 * @param {value: String|Null, cachedData: Object}
 */
export const updateFailPageLogic = ({value, cachedData}) => {
  const client = getClientInstance();
  const data = cachedData || client.readQuery({query: PAY_ANSWER_QUERY});

  client.writeQuery({
    query: PAY_ANSWER_QUERY,
    data: {
      ...data,
      payment: {
        ...data.payment,
        payAnswer: {
          ...data.payment.payAnswer,
          failPageLogic: value,
        },
      },
    },
  });
};

/**
 * Reload packages after declined payment attempt
 * Packages will be reloaded only with decline via(failPageLogic)
 * @param {Object} paymentAnswer - payment flow data
 * @returns {Object}
 */
const reloadPackagesAfterDecline = (paymentAnswer) => {
  // Read data from cache for easier cache update, without parsing paymentAnswer
  const data = getClientInstance().readQuery({query: PAY_ANSWER_QUERY});

  const {failPageLogic, status} = paymentAnswer;

  if (status === false) {
    if (!failPageLogic) {
      logger.sendWarning(
        '[reloadPackagesAfterDecline] empty failPageLogic after decline',
      );
      return paymentAnswer;
    }

    // Set failPageLogic payAnswer param that will be picked up in usePaymentPackages and packages will be reloaded
    updateFailPageLogic({value: failPageLogic, data});
  } else if (!failPageLogic && data.payment.payAnswer.failPageLogic) {
    // Reset failPageLogic if isn't exist
    updateFailPageLogic({value: null, data});
  }

  return paymentAnswer;
};

export default reloadPackagesAfterDecline;
