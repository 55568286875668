import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';

import useRedirectToDefaultPage from '@core/utils/routing/useRedirectToDefaultPage';

import LOGO_SIZE from '../constants/logoSize';
import LOGO_QUERY from '../graphql/queries/logo.gql';

/**
 * Common component for displaying site logo.
 * Nothing of interesting. Leave it as it is. :)
 */
const Logo = ({
  layout: LogoLayout,
  placeholder: LogoPlaceholder,
  errorBoundary: ErrorBoundary,
  className,
  clickable,
  inverse,
  align,
  size,
  onLoad,
}) => {
  const redirectToDefaultPage = useRedirectToDefaultPage();
  const props = clickable ? {onClick: redirectToDefaultPage} : {};
  const {data, loading, error} = useQuery(LOGO_QUERY, {
    variables: {
      default: !inverse,
      inverse,
    },
  });

  if (loading) {
    return <LogoPlaceholder className={className} />;
  }

  if (error) {
    return <ErrorBoundary errorSize={ErrorBoundary.ERROR_SIZE.SMALL} />;
  }

  const {name, logo} = data.site;
  const src = inverse ? logo.inverse : logo.default;

  /**
   * Unstable behavior when Apollo client updates query based on "inverse" prop
   * but continues to render layout. Possible "loading" state update issue.
   */
  if (!src) return null;

  return (
    <LogoLayout
      className={className}
      clickable={clickable}
      align={align}
      alt={name}
      size={size}
      onLoad={onLoad}
      src={src}
      {...props}
    />
  );
};

Logo.propTypes /* remove-proptypes */ = {
  clickable: PropTypes.bool,
  inverse: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(LOGO_SIZE)),
  className: PropTypes.string,
  onLoad: PropTypes.func,
  align: PropTypes.string,
  layout: PropTypes.func,
  placeholder: PropTypes.func,
  errorBoundary: PropTypes.func,
};

Logo.defaultProps = {
  clickable: true,
  size: LOGO_SIZE.NORMAL,
  inverse: false,
};

export default Logo;
