import getActiveSplitGroup from '@core/utils/split/getActiveSplitGroup';
import {SPLITS} from '@core/utils/split';

import type {
  PaymentAnswer,
  PaymentDataProps,
} from '../../types/paymentAnswerProps';
import payByMethod from '../../utils/payByMethod';
import getFormName from '../../utils/getFormName';
import backbonePay from './backbonePay';

const makePay = async (
  paymentData: PaymentDataProps,
): Promise<PaymentAnswer> => {
  const isFetchApi = await getActiveSplitGroup(
    SPLITS.PAYMENT_PROCESSING_FETCH_API.ID,
    SPLITS.PAYMENT_PROCESSING_FETCH_API.GROUP.ACTIVE,
  );

  return isFetchApi
    ? payByMethod(paymentData)
    : backbonePay({
        ...paymentData,
        formName: getFormName(
          // @ts-expect-error --> Ignore this legacy code
          paymentData,
        ),
      });
};

export default makePay;
