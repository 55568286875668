import {getClientInstance} from '@core/graphql/client';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import type {PaymentAnswer} from '@core/payment/payProcess/types/paymentAnswerProps';

const updateMicroFeaturesAfterPayment = (
  paymentAnswer: PaymentAnswer,
): PaymentAnswer => {
  invalidateCacheByTypename(getClientInstance(), 'MicroFeatures');

  return paymentAnswer;
};

export default updateMicroFeaturesAfterPayment;
