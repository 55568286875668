import getPaymentDescriptorText from '../../../widgets/descriptor/utils/getPaymentDescriptorText';
import trackDescriptor from '../../../widgets/descriptor/utils/trackPaymentDescriptor';
import getPaymentDescriptorTrackData from '../../../widgets/descriptor/utils/getPaymentDescriptorTrackData';
import type {PaymentAnswer} from '../../types/paymentAnswerProps';
import isCardMethods from '../../utils/isCardMethods';

/**
 * Send payment descriptor info track data to server
 */
const trackPaymentDescriptor = (
  paymentAnswer: PaymentAnswer,
): PaymentAnswer => {
  const {descriptor, companyName, hideDescriptor} =
    getPaymentDescriptorTrackData();

  const {orderId, via, method} = paymentAnswer;

  if (!hideDescriptor && isCardMethods(method)) {
    trackDescriptor({
      action: via,
      descriptor,
      descriptorText: getPaymentDescriptorText({descriptor, companyName}),
      orderId,
    });
  }

  return paymentAnswer;
};

export default trackPaymentDescriptor;
