import logger from '@core/logger';
import isCardOneClickAllowed from '@core/payment/forms/card/utils/isCardOneClickAllowed';
import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import PAYMENT_SOURCES from '@core/payment/common/constants/paymentSources';
import getMethodScenario from '@core/payment/payProcess/utils/getMethodScenario';
import tryPay from '@core/payment/payProcess/utils/tryPay';
import type {
  PaymentAnswer,
  PaymentDataProps,
  Status,
} from '@core/payment/payProcess/types/paymentAnswerProps';

import isCardMethods from './isCardMethods';

const payByMethod = ({
  action,
  hidePaymentForm = 0,
  method,
  oneClickFlow,
  prevVia,
  payFromPopup = false,
  ...props
}: PaymentDataProps): Promise<PaymentAnswer | Status> => {
  let result: PaymentAnswer | Status;

  const pay = async (scenario: PaymentScenario): Promise<void> => {
    try {
      result = await tryPay({
        action,
        hidePaymentForm,
        method,
        oneClickFlow,
        prevVia,
        scenario,
        ...props,
      });
    } catch (error) {
      logger.sendError(`[payByMethod] pay error ${error}`);
      result = {status: false};
    }
  };

  const payWithCard = async (): Promise<PaymentAnswer | Status> => {
    // For card payments from popup need check oneClick availability and redirect to pp after decline
    if (payFromPopup) {
      if (
        await isCardOneClickAllowed({
          source: PAYMENT_SOURCES.POPUP,
          via: prevVia,
        })
      ) {
        await pay(PaymentScenario.ONECLICK);
      }

      return result;
    }

    const scenario =
      hidePaymentForm || oneClickFlow
        ? PaymentScenario.ONECLICK
        : PaymentScenario.INITIAL;

    await pay(scenario);

    return result;
  };

  /**
   * Get selected method and process payment with method scenario
   */
  const payWithAltMethod = async (): Promise<PaymentAnswer | Status> => {
    const scenario = await getMethodScenario(action, prevVia, method);
    await pay(scenario);
    return result;
  };

  return isCardMethods(method) ? payWithCard() : payWithAltMethod();
};

export default payByMethod;
