import showFailFormVar from '@core/graphql/vars/showFailFormVar';

import type {PaymentAnswer} from '../../types/paymentAnswerProps';

export default function addFailFormData(
  paymentAnswer: PaymentAnswer,
): PaymentAnswer {
  const {status, replaceRedirectToPostMessage, withPendingPopup} =
    paymentAnswer;

  // Don`t show decline message on iframe PP
  if (replaceRedirectToPostMessage) {
    return paymentAnswer;
  }

  if (status) {
    showFailFormVar(false);
  } else if (!withPendingPopup) {
    showFailFormVar(true);
  }

  return paymentAnswer;
}
