if (typeof window !== 'undefined' && 'scrollRestoration' in window.history) {
  /**
   * Turn off native scroll restoration logic to avoid conflicts with custom logic.
   * We also need this to fix a Safari bug when it shows an empty area instead of the page content
   * when returning(with a reload) to a page that has been scrolled down. Reproduced on iOS 13, 14.
   * @type {string}
   */
  window.history.scrollRestoration = 'manual';
}

type ApplyOptions = {
  /** Element for which we want to apply scroll position */
  element: Element | Document | Window | null;
  /** For saving scroll position in store. We need it, if we want save several scroll position for the same 'element'.
   (On different pages for example) */
  alias: string;
};

type SaveOptions = ApplyOptions & {
  /** Position of scroll page */
  position?: number;
};

/**
 * ScrollSaver interface for the public use
 *
 * @class ScrollSaver
 */

export class ScrollSaver {
  /**
   * {Object} key - alias for save scroll position, value - scroll position value
   */
  mapScrollPosition: Record<string, number> = {};

  /**
   * @public
   */
  rememberPosition({element, alias, position}: SaveOptions): void {
    // Since element can be 'null' in case if DOM is not yet mounted
    if (!element) return;
    this.mapScrollPosition[alias] =
      position ||
      (element instanceof Window
        ? element.scrollY
        : (element as HTMLElement).scrollTop);
  }

  applyPosition({element, alias}: ApplyOptions): void {
    const position = this.mapScrollPosition[alias];
    // Since element can be 'null' in case if DOM is not yet mounted
    if (!element || !(position || position === 0)) return;

    if ('scrollBehavior' in document.documentElement.style) {
      (element as Window).scroll({
        top: position,
      });
    } else if (element === window) {
      if ('scrollTo' in element) {
        element.scrollTo(0, position);
      } else {
        document.body.scrollTop = position;
      }
    } else {
      (element as HTMLElement).scrollTop = position;
    }
  }
}

export default new ScrollSaver();
