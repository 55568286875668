import logger from '@core/logger';

import {setReady} from '../../common/utils/setPaymentProcessingStatus';

export const PAYMENT_FLOW_ABORT = 'paymentFlowAbort';

/**
 * Return function that execute payment flow methods one by one
 * @param {Array} paymentFlow - payment methods(@see payProcess/paymentFlow/flows)
 * @returns {Function}
 */
const getProcessPaymentFlow = (paymentFlow) => {
  if (!paymentFlow.length) {
    logger.sendError('[getProcessPaymentFlow]: No process payment data flow');
  }

  /**
   * Run payment method
   * All methods receive paymentData from previous method and return it to next
   * @param {Object} data - payment data
   * @returns {Promise<Object>}
   */
  const processPaymentFlow = async (data) => {
    const currentStep = paymentFlow.shift();

    if (!currentStep) {
      return data;
    }

    try {
      const result = await currentStep(data);

      // Skip next payment flows
      if (result === PAYMENT_FLOW_ABORT) {
        setReady();
        return result;
      }

      return processPaymentFlow(result);
    } catch (error) {
      logger.sendError(
        `[processPaymentFlow]: Process payment flow error ${error}`,
      );
      throw error;
    }
  };

  return processPaymentFlow;
};

export default getProcessPaymentFlow;
