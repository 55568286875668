import trackPaymentClick from '../../../common/utils/trackPaymentClick';
import getVariablesForPayTrack from '../../../common/utils/getVariablesForPayTrack';
import type PAY_BUTTON_TRACKING from '../../../widgets/buttons/constants/payButtonTracking';
import type {PaymentAnswer} from '../../types/paymentAnswerProps';

const trackPayClick = async (
  paymentAnswer: PaymentAnswer,
  payButton: PAY_BUTTON_TRACKING,
): Promise<PaymentAnswer> => {
  trackPaymentClick(
    await getVariablesForPayTrack({...paymentAnswer, payButton}),
  );

  return paymentAnswer;
};

export default trackPayClick;
