import getCountryISO2 from 'country-iso-3-to-2';

import logger from '@core/logger';
import createApplePaySession from '@core/payment/payProcess/utils/createApplePaySession';
import handleApplePayBillingData from '@core/payment/payProcess/utils/handleApplePayBillingData';
import {getLatestApplePayVersionSupported} from '@core/payment/payProcess/utils/applePay';
import {ApplePaySDKVersion} from '@core/payment/payProcess/constants/ApplePaySDKVersion';

import PaymentPageApplePayMerchantValidationModel from '@legacy/application/components/paymentPage/models/PaymentPageApplePayMerchantValidationModel';

import PAYMENT_METHODS from '../../../common/constants/paymentMethods';
import getFormName from '../../utils/getFormName';
import {PAYMENT_FLOW_ABORT} from '../../utils/getProcessPaymentFlow';
import getPayMethodWithBackboneModel from '../../utils/getPayMethodWithBackboneModel';

/**
 * Pay with Backbone model and return pay answer
 * @deprecated Use applePay.ts
 * @param {object} paymentData PaymentFlow data
 * @returns {Promise<*|*>}
 */
const applePayLegacy = async (paymentData) => {
  const {
    action,
    via,
    activePackage: {
      stockId,
      packageId,
      tokenPrice: {amount, currencyCode},
    },
    country,
    siteName,
    settings: {
      supportedNetworks,
      merchantCapabilities,
      cardWalletAdditionalFields = [],
    },
    urlParams,
    formName,
    cancelRemarketingSubscription,
    replaceRedirectToPostMessage,
    method = PAYMENT_METHODS.APPLE_PAY,
    ...props
  } = paymentData;

  const pay = getPayMethodWithBackboneModel({
    method,
    action,
    formName: formName || getFormName({method}),
  });

  return new Promise((resolve) => {
    const validationModel = new PaymentPageApplePayMerchantValidationModel();

    /**
     * Apple Pay session
     * @see https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession
     */
    const session = createApplePaySession({
      request: {
        currencyCode,
        countryCode: getCountryISO2(country),
        total: {
          label: siteName,
          amount,
        },
        supportedNetworks,
        merchantCapabilities,
      },
      params: {
        cardWalletAdditionalFields,
      },
    });

    /**
     * Merchant session validation.
     * @see https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778021-onvalidatemerchant
     */
    session.onvalidatemerchant = ({validationURL}) => {
      validationModel.set({validationURL});
      validationModel.fetch({
        success: (model, data) => session.completeMerchantValidation(data),
        error: () => {
          // We have issue that some devices can not support abort  method
          try {
            session.abort();
          } catch (e) {
            logger.sendWarning(`Apple Pay: no method session.abort() ${e}`);
          }
          resolve(PAYMENT_FLOW_ABORT);
        },
      });
    };

    /**
     * Payment completion callback
     * @see https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778020-onpaymentauthorized
     */
    session.onpaymentauthorized = async (response) => {
      let result = {};

      if (response) {
        try {
          await handleApplePayBillingData(response);
        } catch (error) {
          logger.log('[applePay]: Shipping contact processing failed');
        }

        const {
          payment: {token},
        } = response;

        try {
          result = await pay({
            stockId,
            packageId,
            country,
            currency_code: currencyCode,
            domain: siteName,
            prevVia: via,
            hidePaymentForm: 0,
            walletToken: JSON.stringify(token),
            ...props,
          });
        } catch (error) {
          result = error;
        }
      }

      const status = result.status
        ? window.ApplePaySession.STATUS_SUCCESS
        : window.ApplePaySession.STATUS_FAILURE;

      session.completePayment(
        /**
         * Since version 3 this method accept object
         * @see https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history/apple_pay_on_the_web_version_3_release_notes
         * */
        getLatestApplePayVersionSupported() >= ApplePaySDKVersion.VERSION_3
          ? {
              status,
            }
          : status,
      );

      resolve({
        urlParams,
        cancelRemarketingSubscription,
        replaceRedirectToPostMessage,
        method,
        ...result,
      });
    };

    session.oncancel = () => {
      validationModel.abortSync();
      resolve(PAYMENT_FLOW_ABORT);
    };

    session.begin();
  });
};

export default applePayLegacy;
