import showFailFormVar from '@core/graphql/vars/showFailFormVar';

import type {PaymentAnswer} from '../../types/paymentAnswerProps';

/**
 * This method fixes bug in FE-39703
 * Fail form changes placement and closes itself by useEffect logic
 *   useEffect(() => {
 *     return () => {
 *       showFailFormVar(false);
 *   };
 *  }, []);
 * @TODO: pass declineScreenTemplate from pay/pay response on RM popup to url.
 * Fail form placement should not be changed.
 */
const closeFailForm = (paymentAnswer: PaymentAnswer): PaymentAnswer => {
  showFailFormVar(false);
  return paymentAnswer;
};

export default closeFailForm;
