import logger from '@core/logger';

import PaymentScenario from '../../common/constants/paymentScenario';
import type {
  PaymentAnswer,
  PaymentDataProps,
} from '../types/paymentAnswerProps';
import makePay from '../paymentFlow/methods/makePay';

const payByMethodFromFreeTrialSuccessPopup = async (
  paymentData: PaymentDataProps,
): Promise<PaymentAnswer> => {
  let result: PaymentAnswer;

  const paymentDataForPay = {
    ...paymentData,
    scenario: PaymentScenario.ONECLICK,
  };

  try {
    result = await makePay(paymentDataForPay);
  } catch (error) {
    logger.sendError(
      `[payByMethodFromFreeTrialSuccessPopup] pay error ${error}`,
    );
    result = {...paymentDataForPay, status: false};
  }

  return result;
};

export default payByMethodFromFreeTrialSuccessPopup;
