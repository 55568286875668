import snakeCase from 'lodash/snakeCase';
import get from 'lodash/get';

import {Direction} from '@core/types/graphql';
import {
  DEBT_CREDITS_USING,
  SKIP_GO_TO_PP,
} from '@core/messenger/common/constants/reasons';
import checkSkippedFirstGoToPP from '@core/messenger/common/utils/checkSkippedFirstGoToPP';
import getPhotoUrlParams from '@core/user/photo/utils/getPhotoUrlParams';
import {CensoredPreset} from '@core/user/photo/constants/PhotoPreset';
import REASONS from '@core/user/profile/constants/PhotoRestrictionReason';
import CommunicationRestriction from '@core/messenger/senderForm/constants/CommunicationRestriction';
import URI from '@core/utils/url';

import {MSG} from '../constants/socketEventNames';
import trackMessengerRequest from '../utils/trackMessengerRequest';

/**
 * Converts interaction message photo data to GraphQL format.
 * Also {@see createMessage} if you want to change the data here
 * @param {object} photo
 * @returns {object}
 */
const formatMessagePhoto = (photo) => ({
  id: photo.photoId || photo.id,
  level: parseInt(photo.attributes.level, 10),
  isApprovedFromMessenger: parseInt(
    photo.attributes.isApprovedFromMessenger,
    10,
  ),
  censored: Boolean(
    (photo.avatar || photo.fullSize) &&
      Object.values(CensoredPreset).includes(
        getPhotoUrlParams(photo.avatar || photo.fullSize).formatName,
      ),
  ),
  updatedOn: photo.updatedOn,
  __typename: 'MessagePhoto',
});

/**
 * Add all fields of reason extra data for correct work of sendMessage mutation
 * @param reasonExtraData
 * @return {{timeout: number|null, freeMessagesTimeout: number|null}|null}
 */
const formReasonExtraData = (reasonExtraData) => {
  if (!reasonExtraData) return null;

  return {
    timeout: reasonExtraData.timeout || null,
    freeMessagesTimeout: reasonExtraData.freeMessagesTimeout || null,
  };
};

/**
 * Extracts message photo data from interaction response
 * @param {object} response
 * @returns {object|null}
 */
const extractMessagePhoto = (response) => {
  const photo =
    get(response, 'resources.imbImage[0]', null) ||
    get(response, 'resources.pmaPhotoReply[0]', null);
  return photo && formatMessagePhoto(photo);
};

/**
 * Converts interaction message sticker data to GraphQL format.
 * @param {object}
 * @returns {object}
 */
const formatMessageSticker = ({url, contentLevel}) => ({
  url,
  contentLevel,
  __typename: 'MessageSticker',
});

/**
 * Extracts sticker data from interaction response
 * @param {object} response
 * @returns {object|null}
 */
const extractMessageSticker = (response) => {
  const sticker = get(response, 'resources.sticker[0]', null);
  return sticker && formatMessageSticker(sticker);
};

/**
 * Converts interaction message video data to GraphQL format.
 * Also {@see createMessage} if you want to change the data here
 * @param {object} video
 * @returns {object}
 */
const formatMessageVideo = (video) => ({
  id: video.videoId,
  /**
   * TODO: For some reason, the data on WS comes in the opposite way, this is how it happened historically:
   * previewUrl: video.videoPreviewUrl
   * videoPreviewUrl: video.previewUrl
   */
  previewUrl: video.videoPreviewUrl[video.videoId],
  videoPreviewUrl: video.previewUrl?.[video.videoId] || null,
  url: video.videoUrl[video.videoId],
  isConverted: video.isConverted === '1',
  level: get(video, 'attributes.level.value', 0) * 1,
  isApprovedFromMessenger:
    get(video, 'attributes.isApprovedFromMessenger.value', 0) * 1,
  __typename: 'MessageVideo',
});

/**
 * Extracts message photo data from interaction response
 * @param {object} response
 * @returns {object|null}
 */
const extractMessageVideo = (response) => {
  const video = get(response, 'resources.imbVideo[0]', null);
  return video && formatMessageVideo(video);
};

/**
 * Parse via from upgrade url
 * @param upgradeUrl
 * @returns {string}
 */
const getUpgradeSendVia = (upgradeUrl = '') => {
  const upgradeUrlWrapper = URI(upgradeUrl);
  return upgradeUrlWrapper.search(true).via || '';
};

const prepareOutgoingMessage = (response, error) => {
  // Track time between request and answer
  trackMessengerRequest.track();

  if (error && !response) {
    // Some error happened while sending message. Example: "timeout"
    return {
      data: {
        sendMessage: null,
        error,
        upgradeSendVia: null,
        canSendNext: null,
        reason: '',
        reasonExtraData: null,
      },
    };
  }

  const skipGoToPP = response.error === SKIP_GO_TO_PP;
  if (response.error && !skipGoToPP) {
    /**
     * For photo restriction errors we create ACL error to manage its in Apollo style
     * @see src/packages/core/graphql/client.ts
     * @see src/packages/dating/application/constants/accessErrorCodeMap.js
     */
    if (Object.values(REASONS).includes(response.reason)) {
      const message = snakeCase(response.reason).toUpperCase();
      return {
        data: {},
        errors: [
          {
            extensions: {
              category: 'ACL',
            },
            message,
            errorMessage: message,
          },
        ],
      };
    }

    // Send forbidden. Example: {error: "communication restriction", reason: "showAntiscamBlockAlert"}
    return {
      data: {
        sendMessage: null,
        error: response.reason || response.error,
        upgradeSendVia: null,
        canSendNext: null,
        reason: '',
        reasonExtraData: formReasonExtraData(response.reasonExtraData),
      },
    };
  }

  if (response?.data?.blockedUser) {
    // Current user is blocked by recipient, communication is restricted and we need to show cap
    return {
      data: {
        sendMessage: null,
        error: CommunicationRestriction.BLOCKED_USER,
        upgradeSendVia: null,
        canSendNext: null,
        reason: '',
        reasonExtraData: null,
      },
    };
  }

  const upgradeSendVia = getUpgradeSendVia(response.upgrade_type);

  /**
   * If we don't have a message id and we've got an "upgrade_type" it means that user must be redirected to a pay url
   * and sendMessage must be null
   */
  const sendMessage =
    !response.id && upgradeSendVia
      ? null
      : {
          id: response.id,
          // TODO: Interaction outgoingMessage does not return now
          timestamp: response.timestamp || Math.floor(Date.now() / 1000),
          type: response.msgType,
          needSendFirst: response.needSendFirst,
          countFreeMessages: {
            ...response.countFreeMessages,
            __typename: 'CountFreeMessages',
          },
          freeAssistantMessages: {
            ...response.freeAssistantMessages,
            __typename: 'FreeAssistantMessages',
          },
          photo: extractMessagePhoto(response),
          video: extractMessageVideo(response),
          isUnsent: response.isUnsent,
          __typename: 'SendMessageMutationResult',
        };

  /**
   * Define error
   * @type {string|null}
   */
  let errorMessage = !sendMessage
    ? CommunicationRestriction.FREE_COMMUNICATION_EXHAUSTED
    : null;

  if (
    (response.isUnsent && !skipGoToPP) ||
    (skipGoToPP && checkSkippedFirstGoToPP(skipGoToPP))
  ) {
    errorMessage = DEBT_CREDITS_USING;
  }

  return {
    data: {
      sendMessage,
      upgradeSendVia,
      recipientId: response.recipientId || '',
      text: response.text || '',
      canSendNext: response.canSendNext || false,
      /**
       * Reason is for canSendNext = false
       */
      reason: response.reason || '',
      error: errorMessage,
      reasonExtraData: formReasonExtraData(response.reasonExtraData),
    },
  };
};

export default {
  SendMessageMutation: {
    toServer: ({method, ...params}) => {
      // Set current request time
      trackMessengerRequest.setStartRequestTime();

      return {method, params};
    },

    fromServer: prepareOutgoingMessage,
  },

  InteractionMessageSubscription: {
    incomingEvents: [`${MSG}mail`, `${MSG}mailbox`],

    fromServer: (response) => ({
      data: {
        message: {
          id: response.messageId,
          chatLegend: response.chatLegend,
          type: response.msgType || response.type,
          subject: response.subject || '',
          needBlur: response.needBlur,
          direction: Direction.incoming,
          senderId: response.fromId,
          text: response.text,
          photo: extractMessagePhoto(response), // for photo message only
          video: extractMessageVideo(response), // for video message only
          sticker: extractMessageSticker(response),
          timestamp: response.timestamp,
          upgradeReadVia: getUpgradeSendVia(response.upgrade_read),
          isPaidForView: response.isPaidForView || false,
          isRead: false,
          isUnsent: false,
          canSendNext: response.canSendNext,
          needSendFirst: response.needSendFirst || false,
          reason: response.reason || '',
          __typename: 'Message',
        },
      },
    }),
  },

  DeleteMessageSubscription: {
    incomingEvents: [`${MSG}messageDeleted`],

    fromServer: ({userId, messageId}) => ({data: {userId, messageId}}),
  },

  InteractionReadMessageSubscription: {
    incomingEvents: [`${MSG}readmessages`],

    fromServer: ({fromUserId, messageIds}) => ({
      data: {
        fromUserId,
        messageIds,
        __typename: 'MarkConversationAsReadMutationPayload',
      },
    }),
  },

  InteractionApprovePhotoInMessengerSubscription: {
    incomingEvents: [`${MSG}approvePhotoInMessenger`],

    fromServer: ({photo}) => ({data: formatMessagePhoto(photo)}),
  },

  InteractionGalleryUnseenPhotosCountSubscription: {
    incomingEvents: [`${MSG}messengerGalleryUnseenPhotosCount`],

    fromServer: (data) => ({data}),
  },

  InteractionMediaFilteredByContentLevelSubscription: {
    incomingEvents: [`${MSG}mediaFilteredByContentLevel`],

    fromServer: (data) => ({data}),
  },

  InteractionHideMessageByDeleteSubscription: {
    incomingEvents: [`${MSG}onHideMessageByDelete`],

    fromServer: (data) => ({data}),
  },

  InteractionApproveVideoInMessengerSubscription: {
    incomingEvents: [`${MSG}approveVideoInMessenger`],

    fromServer: ({videoId, attributes, isApproved}) => ({
      data: {
        id: videoId,
        level: (attributes?.level || 0) * 1,
        // covert boolean isApproved to GraphQL isApprovedFromMessenger format: 1 - approved, 2 - declined.
        isApprovedFromMessenger: !isApproved + 1,
        __typename: 'MessageVideo',
      },
    }),
  },

  InteractionUserVideoConvertedNoticeSubscription: {
    incomingEvents: [`${MSG}UserVideoConvertedNotice`],

    fromServer: ({videoId, duplicateVideoId, isVideoConverted, message}) => ({
      data: {
        id: videoId,
        duplicateVideoId,
        isConverted: isVideoConverted,
        error: isVideoConverted ? null : message,
        __typename: 'MessageVideo',
      },
    }),
  },
};
