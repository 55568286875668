import type {
  FC,
  FocusEventHandler,
  HTMLAttributes,
  MouseEventHandler,
  MutableRefObject,
  ReactNode,
} from 'react';
import React, {useRef} from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import isDeviceWithTouchScreen from '@core/utils/device/isDeviceWithTouchScreen';

import type {CSSModule} from '../../types';
import {Icon} from '../icon';
import type {RippleElement} from '../ripple/Ripple';
import Ripple from '../ripple/Ripple';
import ButtonType from '../../constants/ButtonType';
import IconPosition from '../../constants/IconPosition';
import baseCss from './Button.css';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  className?: string;
  trackingName?: string;
  /**
   * For some themes we should change appearance when button is active (e.g. 'liked' or 'favorited' button)
   */
  active?: boolean;
  form?: string;
  inverse?: boolean;
  muted?: boolean;
  withRipple?: boolean;
  disabled?: boolean;
  dangerAsActiveColor?: boolean;
  /**
   * Allows to handle click on disabled button.
   */
  disabledInteraction?: boolean;
  loading?: boolean;
  /**
   * For change button with only icon - set round: true, narrow: false
   */
  round?: boolean;
  narrow?: boolean;
  big?: boolean;
  small?: boolean;
  /**
   * Used together with "form"
   */
  submit?: boolean;
  fixedHeight?: boolean;
  fullWidth?: boolean;
  /**
   * Used together with "fullWidth" property. Implements logic, when on mobile
   * environment button is full width, and on web - it should be min. 200px wide.
   * Also, can be used for other "adaptation" things
   */
  adaptive?: boolean;
  inline?: boolean;
  tabIndex?: number;
  type?: ButtonType;
  icon?: ReactNode;
  iconPosition?: IconPosition;
  /**
   * Ref is required for usage inside tooltip or popover, since it goes set in shadow.
   */
  innerRef?:
    | MutableRefObject<HTMLButtonElement>
    | ((el: HTMLElement | null) => void);
  /**
   * Need for design purposes add three dots if text very long (...)
   */
  withOverflow?: boolean;
  /**
   * Need for design purposes make action static as badge
   */
  hoverable?: boolean;
  // Used for wrapping icon inside additional proxy component e.g. for animations.
  iconWrapComponent?: FC<{
    active: boolean;
    className: string;
    children: ReactNode;
  }>;
  shadowLevel?: 0 | 1;
  /**
   * Used for disabling css transition on button
   * By default it's true, that mean transition is enabled
   * Now used only for split 45+ experiment
   * Used in two places TARGET_USER_INFO_GALLERY_STICKED and TARGET_USER_INFO_GALLERY
   * @see UserActions.js
   */
  transition?: boolean;
}

/**
 * Just an universal button component.
 */
const Button: FC<
  // `ButtonProps` without `css` inside to make it more suitable for `@phoenix/ui`.
  ButtonProps & {
    /**
     * Styles are passed as props and mixed with own styles.
     */
    css: CSSModule;
  }
> = ({
  css,
  children,
  className,
  onBlur,
  onFocus,
  onClick,
  trackingName,
  onMouseDown,
  form,
  icon,
  iconWrapComponent: Wrapper,
  active,
  dangerAsActiveColor = false,
  inverse = false,
  muted = false,
  withRipple = true,
  disabled = false,
  loading = false,
  round = false,
  adaptive = false,
  narrow = false,
  big = false,
  small = false,
  submit = false,
  fixedHeight = false,
  fullWidth = false,
  disabledInteraction = false,
  inline = false,
  type = ButtonType.DEFAULT,
  iconPosition = IconPosition.LEFT,
  withOverflow = false,
  shadowLevel = 1,
  transition = true,
  hoverable = true,
  tabIndex,
  innerRef,
  ...props
}) => {
  const self = useRef({props: {}, ripple: null}).current;
  self.props = {onClick, onMouseDown};
  const rippleRef = useRef<RippleElement>();

  const handleBlur: FocusEventHandler<HTMLButtonElement> = (event) => {
    if (disabled) return;
    if (onBlur) onBlur(event);
  };

  const handleFocus: FocusEventHandler<HTMLButtonElement> = (event) => {
    if (disabled) return;
    if (onFocus) onFocus(event);
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (disabled && !disabledInteraction) return;
    if (onClick && !event.defaultPrevented) {
      rippleRef.current?.animate(event);
      onClick(event);
    }
  };

  const handleMouseDown: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (disabled) return;
    if (onMouseDown && !event.defaultPrevented) {
      rippleRef.current?.animate(event);
      onMouseDown(event);
    }
  };

  const renderIcon = () => {
    const content =
      typeof icon === 'string' ? (
        <Icon
          type={icon}
          inverse={!dangerAsActiveColor && inverse}
          muted={muted}
          inherit
        />
      ) : (
        icon
      );

    return (
      <div className={cn(baseCss.icon, css.icon)}>
        {Wrapper ? (
          <Wrapper active={active} className={baseCss.iconWrapper}>
            {content}
          </Wrapper>
        ) : (
          content
        )}
      </div>
    );
  };

  const classNames = cn(
    baseCss.button,
    css.button,
    transition && baseCss.transition,
    hoverable && !isDeviceWithTouchScreen && baseCss.hoverable,
    hoverable && !isDeviceWithTouchScreen && css.hoverable,
    !hoverable && baseCss.static,
    type && baseCss[type],
    type && css[type],
    active && css.active,
    dangerAsActiveColor && css.dangerAsActiveColor,
    inverse && baseCss.inverse,
    inverse && css.inverse,
    disabled && baseCss.disabled,
    disabledInteraction && baseCss.disabledInteraction,
    loading && baseCss.loading,
    big && baseCss.big,
    small && baseCss.small,
    (fixedHeight || fullWidth) && baseCss.fixedHeight,
    fullWidth && baseCss.fullWidth,
    adaptive && baseCss.adaptive,
    inline && baseCss.inline,
    round && baseCss.round,
    round && css.round,
    narrow && baseCss.narrow,
    narrow && css.narrow,
    withOverflow && baseCss.withOverflow,
    css[`shadow${shadowLevel}`],
    className,
  );

  return (
    <AddBabciaUBTracking trackingName={trackingName && `${trackingName}Btn`}>
      <button
        {...props}
        className={classNames}
        tabIndex={tabIndex}
        onBlur={handleBlur}
        onFocus={handleFocus}
        form={form}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        ref={innerRef}
        type={submit ? 'submit' : 'button'}
      >
        {withRipple && (
          <Ripple
            inverse={
              inverse &&
              ![ButtonType.FLAT, ButtonType.FLAT_COVER].includes(type)
            }
            ref={rippleRef}
          />
        )}
        <div className={baseCss.wrap}>
          {icon && iconPosition === IconPosition.LEFT && renderIcon()}
          {children && (
            <div className={cn(baseCss.text, css.text)}>{children}</div>
          )}
          {icon && iconPosition === IconPosition.RIGHT && renderIcon()}
        </div>
      </button>
    </AddBabciaUBTracking>
  );
};

export default Button;
