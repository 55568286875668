import PAY_BUTTON_TRACKING from '../../../widgets/buttons/constants/payButtonTracking';
import type {PaymentAnswer} from '../../types/paymentAnswerProps';
import trackPayClick from './trackPayClick';

const trackApplePayClick = (paymentAnswer: PaymentAnswer): PaymentAnswer => {
  trackPayClick(paymentAnswer, PAY_BUTTON_TRACKING.APPLE_PAY);
  return paymentAnswer;
};

export default trackApplePayClick;
