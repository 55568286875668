import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

import URI from '@core/utils/url';
import logger from '@core/logger';
import clearUrl from '@core/utils/url/clearUrl';
import parsePathname from '@core/utils/url/parsePathname';
import getHistory from '@core/application/utils/getHistory';
import isPaymentUrl from '@core/utils/url/isPayUrl';
import ROUTES from '@core/application/constants/routes';

import PAYMENT_ACTIONS from '../../../common/constants/paymentActions';
import generatePayUrl from '../../../common/utils/generatePayUrl';
import {UNKNOWN_VIA} from '../../../common/constants/vias';
import extractAction from '../../utils/extractAction';

/**
 * @const {Array}
 */
const CUSTOM_ROUTES = [ROUTES.GET_THE_APP];

/**
 * DISCLAIMER: all methods below used to pay in react app with Backbone models, its temporary solution
 * This code must be removed after implementation payment method with GraphQl mutation
 */

/**
 * Return redirect url only if it leads from payment page
 * It's specific card payment logic
 * @param {String} redirectUrl
 * @returns {String}
 */
const getSiteRedirectUrl = (redirectUrl) => {
  const url = clearUrl(redirectUrl);
  return isPaymentUrl(url) ? '' : url;
};

/**
 * Get action and via from provided redirectUrl
 * @param {Object|String} redirectUrl
 * @returns {{action: String, via: String, viaMethod: String}}
 */
const getNextStepData = (redirectUrl) => {
  let via = '';
  let action = '';
  let viaMethod = '';
  let orderId = null;
  let url = redirectUrl;

  // Don't mind its specific answer format (:
  if (isArray(redirectUrl)) {
    [url] = redirectUrl;
    const uri = URI(url);

    action = extractAction(url);

    ({orderId, via, viaMethod} = uri.search(true));
  } else if (isObject(redirectUrl)) {
    url = redirectUrl['0'];
    action = extractAction(url);

    ({orderId, via, viaMethod} = redirectUrl);
  } else if (isString(redirectUrl)) {
    // Get action and via from redirectUrl
    const uri = URI(redirectUrl);
    action = extractAction(uri.pathname());

    ({orderId, via, viaMethod} = uri.search(true));
  }

  if (isPaymentUrl(url) && !via) {
    logger.sendError(
      `[goToNextStep] there is no data for next step: url="${url}", via="${via}"`,
    );
    via = UNKNOWN_VIA; // prevent swarm errors when we dont have via in url
  }

  return {
    via,
    action,
    orderId,
    viaMethod,
  };
};

/**
 * @param {String} url
 * @param {Boolean} replacement
 */
export const goTo = (url, replacement = false) => {
  const {push, replace} = getHistory();

  if (replacement) {
    replace(url);
  } else {
    push(url);
  }
};

/**
 * Parse pay answer from Backbone model and set pay answer data to Apollo cache
 * @param {Object} paymentAnswer
 * @returns {Object}
 */
const goToNextStep = (paymentAnswer) => {
  const {redirectUrl, urlParams, status, replaceRedirectToPostMessage} =
    paymentAnswer;

  if (replaceRedirectToPostMessage) {
    return paymentAnswer;
  }

  if (status) {
    let nextStepUrl;
    const {via, action, orderId, viaMethod} = getNextStepData(redirectUrl);
    const location = clearUrl(window.location.toString());

    const {
      options: {returnPath = null, viaProfileId, successReturnPath} = {},
      action: currentPaymentAction,
    } = isPaymentUrl(location) ? parsePathname(location) : {};

    if (CUSTOM_ROUTES.includes(`/${action}`)) {
      nextStepUrl = `/${action}`;
    } else if (!action) {
      nextStepUrl = clearUrl(redirectUrl);
    } else {
      nextStepUrl = generatePayUrl({
        stage: action,
        urlParams: {
          via: currentPaymentAction || via,
          viaMethod,
          orderId,
          returnPath,
          successReturnPath,
          viaProfileId,
          ...urlParams,
        },
      });
    }

    goTo(
      nextStepUrl,
      currentPaymentAction === PAYMENT_ACTIONS.REMARKETING_OFFER,
    );
  } else if (redirectUrl) {
    const siteRedirectUrl = getSiteRedirectUrl(redirectUrl);
    siteRedirectUrl && goTo(siteRedirectUrl);
  }

  return paymentAnswer;
};

export default goToNextStep;
