import PaymentPageSuccessOrders from '../../utils/PaymentPageSuccessOrders';
import type {PaymentAnswer} from '../../types/paymentAnswerProps';
import activateFeaturesAfterSuccessByVia from './activateFeaturesAfterSuccessByVia';

const addSuccessOrder = (paymentAnswer: PaymentAnswer): PaymentAnswer => {
  const {status, orderId, via, replaceRedirectToPostMessage} = paymentAnswer;

  if (orderId && !replaceRedirectToPostMessage && status) {
    activateFeaturesAfterSuccessByVia();
    PaymentPageSuccessOrders.add(orderId, via);
  }

  return paymentAnswer;
};

export default addSuccessOrder;
