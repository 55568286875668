import selectedAdditionalPackagesVar, {
  DEFAULT_VALUE,
} from '../../../widgets/package/graphql/vars/selectedAdditionalPackagesVar';

/**
 * Restore selected additional packages to default value after success send payment data
 * @param {Object} paymentAnswer - payment flow data
 * @returns {Object}
 */
const resetSelectedAdditionalPackages = (paymentAnswer) => {
  if (paymentAnswer.replaceRedirectToPostMessage) {
    return paymentAnswer;
  }

  if (paymentAnswer.status) {
    selectedAdditionalPackagesVar(DEFAULT_VALUE);
  }

  return paymentAnswer;
};

export default resetSelectedAdditionalPackages;
