import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import PAYMENT_METHODS from '../../common/constants/paymentMethods';
import PAYMENT_ACTIONS from '../../common/constants/paymentActions';
import PaymentScenario from '../../common/constants/paymentScenario';
import {getSettingsByMethod} from '../../common/utils/useAltMethodsSettings';
import {
  CARD_FORM_NAME,
  CARD_WALLET_FORM_NAME,
  IBAN_ONE_CLICK_FORM_NAME,
} from '../constants/formNames';
import type {
  PaymentDataWithoutPackageId,
  GetResourceForPayRequestResult,
} from '../types/paymentAnswerProps';
import getFormNameByMethod from './getFormNameByMethod';
import isCardMethods from './isCardMethods';

const isAltOneClick = (method: string, scenario: string) => {
  return scenario === PaymentScenario.ONECLICK && !isCardMethods(method);
};

/**
 * Get all data for further fetching
 */
const getResourceForPayRequest = ({
  action,
  altMethodsSettings,
  method,
  popupType,
  stockId,
  scenario,
  via,
  prevVia,
  ...props
}: PaymentDataWithoutPackageId): GetResourceForPayRequestResult => {
  if (action === PAYMENT_ACTIONS.EXCHANGE_CREDIT) {
    return {
      url: '/api/v1/pay/exchangeCredit/isJsonMode/true',
      body: {packageId: stockId},
    };
  }

  const generalUrl = `/api/v1/pay/pay/isJsonMode/true?via=${via}&prevVia=${prevVia}`;
  const alternativeUrl = `/api/v1/pay/alternative/isAjaxMode/true?via=${via}&prevVia=${prevVia}&processor=${method}`;
  let formName = getFormNameByMethod(method);

  const generalProps = omitBy(
    {
      action,
      method,
      prevVia,
      scenario,
      via,
      ...props,
    },
    isNil,
  );

  const forceRedirect = [
    PAYMENT_METHODS.CCBILL,
    PAYMENT_METHODS.PAY_PAL,
    PAYMENT_METHODS.PAY_PAL_V2,
    PAYMENT_METHODS.SOFORT,
    // @ts-expect-error -- TODO[TS] TS2345: Problem in shchema.gql, sometimes method is string, sometimes is enum
  ].includes(method)
    ? 0
    : 1;

  switch (method) {
    case PAYMENT_METHODS.CARD:
      return {
        url: generalUrl,
        body: {
          [CARD_FORM_NAME]: {
            ...generalProps,
            formName: CARD_FORM_NAME,
          },
        },
      };
    case PAYMENT_METHODS.MIDAS:
      return {
        url: generalUrl,
        body: {
          [formName]: {
            ...generalProps,
            formName,
          },
        },
      };
    case PAYMENT_METHODS.APPLE_PAY:
    case PAYMENT_METHODS.GOOGLE_PAY:
      return {
        url: generalUrl,
        body: {
          [CARD_WALLET_FORM_NAME]: {
            ...generalProps,
            formName: CARD_WALLET_FORM_NAME,
            cardWalletType: method,
          },
        },
      };
    case PAYMENT_METHODS.TRUST_PAY_SEPA:
    case PAYMENT_METHODS.MICRO_PAYMENT_SEPA:
    case PAYMENT_METHODS.COMM_DOO_SEPA:
    case PAYMENT_METHODS.NOVAL_NET_SEPA:
    case PAYMENT_METHODS.VENDO_SEPA:
    case PAYMENT_METHODS.SALT_EDGE_AIS:
    case PAYMENT_METHODS.TRUST_PAY_IDEAL:
    case PAYMENT_METHODS.NUVEI_IDEAL:
    case PAYMENT_METHODS.NUVEI_MY_BANK:
    case PAYMENT_METHODS.SOFORT: {
      formName = isAltOneClick(method, scenario)
        ? IBAN_ONE_CLICK_FORM_NAME
        : getFormNameByMethod(method);

      return {
        url: isAltOneClick(method, scenario)
          ? `/api/v1/pay/ibanOneClick?via=${via}&prevVia=${prevVia}&processor=${method}${isNil(popupType) ? '' : `&popupType=${popupType}`}`
          : alternativeUrl,
        body: {
          [formName]: {
            ...generalProps,
            // TODO[BB_removed]: Decide use only 'popupType' in body or in url
            ...(popupType ? {popupType} : null),
            ...(scenario === PaymentScenario.ONECLICK &&
            Boolean(
              getSettingsByMethod(altMethodsSettings, method)?.maskedFields,
            )
              ? {isMaskedForm: true}
              : null),
            formName: getFormNameByMethod(method),
          },
          forceRedirect,
          processor: method,
        },
      };
    }
    case PAYMENT_METHODS.KONBINI:
    case PAYMENT_METHODS.PAY_PAL:
    case PAYMENT_METHODS.PAY_PAL_V2:
    case PAYMENT_METHODS.CCBILL:
    default:
      return {
        url: alternativeUrl,
        body: {
          [formName]: {
            ...generalProps,
            formName,
          },
          forceRedirect,
          processor: method,
        },
      };
  }
};

export default getResourceForPayRequest;
