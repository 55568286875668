import common from './common';
import aiResolver from './ai';
import messageAssistant from './messageAssistant';
import primaryPhotoResolver from './primaryPhoto';
import activityResolver from './activity';
import trackInventory from './trackInventory';
import trackCTR from './trackCTR';
import trackRmPopup from './trackRmPopup';
import trackMediaUploadError from './trackMediaUploadError';
import trackPaymentPageDescriptor from './trackPaymentPageDescriptor';
import trackPaymentPendingPageStep from './trackPaymentPendingPageStep';
import photoDisapprove from './photoDisapprove';
import videoDisapprove from './videoDisapprove';
import stories from './stories';
import amountPartGroupVideo from './amountPartGroupVideo';
import declinedGroupVideo from './declinedGroupVideo';
import statusApprove from './statusApprove';
import screennameApprove from './screennameApprove';
import trustedStatus from './trustedStatus';
import videoChat from './videoChat';
import videoConvert from './videoConvert';
import messenger from './messenger';
import chatRooms from './chatRooms';
import payment from './payment';
import personalIdentity from './personalIdentity';
import deleteConversation from './deleteConversation';
import extraEvents from './extraEvents';
import supportRequestProcessed from './supportRequestProcessed';
import moderator from './moderator';
import blockUser from './blockUser';
import videoMute from './videoMute';
import photoUpload from './photoUpload';
import streaming from './streaming';
import orderProcessed from './orderProcessed';
import webCamRegistration from './webCamRegistration';
import popunderTargetUrl from './popunderTargetUrl';
import credits from './credits';
import freeView from './freeView';
import changeDebtBalance from './changeDebtBalance';
import remarketingPopupSettings from './remarketingPopupSettings';
import remarketingReload from './remarketingReload';
import resentMessages from './resentMessages';
import spdMatch from './spdMatch';
import getBackOfferTargetUrl from './getBackOfferTargetUrl';
import globalFreeMessageCount from './globalFreeMessageCount';
import globalSearchTrialEndedNotification from './globalSearchTrialEndedNotification';
import freeMessageNotification from './freeMessageNotification';
import lifetimeOffer from './lifetimeOffer';
import microfeatureAssigned from './microfeatureAssigned';
import coins from './coins';
import webPush from './webPush';
import upsell from './upsell';
import recipientMatches from './recipientMatches';

export default {
  ...common,
  ...aiResolver,
  ...messageAssistant,
  ...primaryPhotoResolver,
  ...activityResolver,
  ...trackInventory,
  ...trackCTR,
  ...trackPaymentPageDescriptor,
  ...trackRmPopup,
  ...trackMediaUploadError,
  ...trackPaymentPendingPageStep,
  ...videoChat,
  ...photoDisapprove,
  ...videoDisapprove,
  ...stories,
  ...declinedGroupVideo,
  ...amountPartGroupVideo,
  ...videoConvert,
  ...webCamRegistration,
  ...popunderTargetUrl,
  ...statusApprove,
  ...videoMute,
  ...chatRooms,
  ...orderProcessed,
  ...screennameApprove,
  ...trustedStatus,
  ...messenger,
  ...payment,
  ...personalIdentity,
  ...deleteConversation,
  ...extraEvents,
  ...supportRequestProcessed,
  ...moderator,
  ...streaming,
  ...blockUser,
  ...photoUpload,
  ...credits,
  ...freeView,
  ...remarketingPopupSettings,
  ...remarketingReload,
  ...resentMessages,
  ...changeDebtBalance,
  ...spdMatch,
  ...getBackOfferTargetUrl,
  ...globalFreeMessageCount,
  ...globalSearchTrialEndedNotification,
  ...freeMessageNotification,
  ...lifetimeOffer,
  ...microfeatureAssigned,
  ...coins,
  ...webPush,
  ...upsell,
  ...recipientMatches,
};
