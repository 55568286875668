import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';

import PopupService from '@core/popup/utils/PopupService';

import deliveryMessage from '../utils/deliveryMessage';
import deleteMessage from '../utils/deleteMessage';

const STUB_FUNCTION = () => {};

const InappropriateContentSendPopup = ({messageId, userId, layout: Layout}) => {
  const [disabled, setDisabled] = useState(false);
  const handleCancelMessageClick = useCallback(() => {
    setDisabled(true);
    deleteMessage(messageId, userId)
      .then(
        ({
          data: {
            messenger: {
              deleteMessage: {result},
            },
          },
        }) => {
          setDisabled(false);
          result && PopupService.closePopup();
        },
      )
      .catch(STUB_FUNCTION); // To prevent show uncaught promise in sentry use STUB_FUNCTION on catch
  }, [messageId, userId]);

  const handleSendMessageClick = useCallback(() => {
    setDisabled(true);
    deliveryMessage(messageId)
      .then(
        ({
          data: {
            messenger: {
              deliveryMessage: {result},
            },
          },
        }) => {
          setDisabled(false);
          result && PopupService.closePopup();
        },
      )
      .catch(STUB_FUNCTION); // To prevent show uncaught promise in sentry use STUB_FUNCTION on catch
  }, [messageId]);

  return (
    <Layout
      handleCancelMessageClick={handleCancelMessageClick}
      handleSendMessageClick={handleSendMessageClick}
      disabled={disabled}
    />
  );
};

InappropriateContentSendPopup.propTypes /* remove-proptypes */ = {
  layout: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.func,
  ]).isRequired,
  messageId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default InappropriateContentSendPopup;
