import type {
  PaymentAnswer,
  PaymentDataProps,
} from '../../types/paymentAnswerProps';
import makePay from './makePay';

const payFromPopup = (
  paymentAnswer: PaymentDataProps,
): Promise<PaymentAnswer> => {
  return makePay({
    ...paymentAnswer,
    payFromPopup: true,
  });
};

export default payFromPopup;
