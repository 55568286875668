import logger from '@core/logger';

import PaymentScenario from '../../common/constants/paymentScenario';
import type {
  PaymentAnswer,
  PaymentDataProps,
  Status,
} from '../types/paymentAnswerProps';
import tryPay from './tryPay';

const payByMethodFromRemarketingPopup = async (
  paymentData: PaymentDataProps,
): Promise<PaymentAnswer | Status> => {
  let result: PaymentAnswer | Status;

  const paymentDataForPay = {
    ...paymentData,
    scenario: PaymentScenario.ONECLICK,
  };

  try {
    result = await tryPay(paymentDataForPay);
  } catch (error) {
    logger.sendError(`[payByMethodFromRemarketingPopup] pay error ${error}`);
    result = {...paymentDataForPay, status: false};
  }

  return result;
};

export default payByMethodFromRemarketingPopup;
