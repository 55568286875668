import {useEffect, useContext} from 'react';
import mapValues from 'lodash/mapValues';
import isArray from 'lodash/isArray';

import {ONLY_NEW_QUERY_TYPES} from '@core/activity/constants/activitiesQueryTypes';

import ActivityContext from '../containers/ActivityContext';

/**
 * Function, for removing already readed activities form different categories of therm.
 * @example
 *
 * filterActivities({
 *   a: [{id: 1, isNew: true}, {id: 2, isNew: false}],
 *   b: [],
 *   c: [{id: 1, isNew: true}],
 *   d: [{id: 1, isNew: false}],
 *   e: 'foo',
 * }, true)
 *
 * // Result will be:
 * // {
 * //   a: [{id: 1, isNew: true}],
 * //   b: [],
 * //   c: [{id: 1, isNew: true}],
 * //   d: [],
 * //   e: 'foo',
 * // }
 *
 * @todo Soon, if you have performance problems - try to memoize this function.
 * @note Exported only for testing purposes.
 * @param {Object} activitiesByCategories
 * @param {boolean} onlyNew
 * @param {string} separatedType
 * @returns {Object} filtered inputed object of activities
 */
const filterActivities = (activitiesByCategories, onlyNew) => {
  if (!onlyNew) {
    return activitiesByCategories;
  }

  return mapValues(activitiesByCategories, (activities) => {
    if (!isArray(activities)) return activities;
    return activities.filter((activity) => activity.isNew);
  });
};

/**
 * Enhanced consumer that allows to retrieve sorted, and filtered
 * activities by 'unread' param.
 */
const useActivityConsumer = (queryType, isSeperatedActivity) => {
  const {fetchActivitiesByQueryType, requestAllowed, dateFormat, ...props} =
    useContext(ActivityContext);

  useEffect(() => {
    if (requestAllowed) {
      fetchActivitiesByQueryType(queryType, isSeperatedActivity);
    }
  }, [
    queryType,
    requestAllowed,
    fetchActivitiesByQueryType,
    isSeperatedActivity,
  ]);

  return {
    /**
     * Don't wrap in 'useMemo' since we should track changes not only activities lists in terms of array length,
     * but changes in array content too (it contains activities objects). So memo makes more troubles since
     * it makes equality checks before calculation.
     */
    ...filterActivities(props, ONLY_NEW_QUERY_TYPES.includes(queryType)),
    dateFormat,
    requestAllowed,
  };
};

export default useActivityConsumer;
