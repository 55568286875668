import type {PackageDataFragment} from '@core/payment/widgets/package/graphql/fragments/package';

export type PurchasedPackage = PackageDataFragment | null;

/**
 * Cache payment package
 * Used for cache purchased package and get data after payment.
 */
let purchasedPackage: PurchasedPackage = null;

const cachedPurchasedPackage = {
  /**
   * Get saved purchased package.
   */
  get(): PurchasedPackage {
    return purchasedPackage;
  },

  /**
   * Set saved purchased package.
   */
  set(paymentPackage: PurchasedPackage) {
    purchasedPackage = paymentPackage;
  },

  clear() {
    this.set(null);
  },
};

export default cachedPurchasedPackage;
