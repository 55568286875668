import omit from 'lodash/omit';
import isString from 'lodash/isString';

import googlePayMaskedCardVar from '@core/graphql/vars/googlePayMaskedCardVar';

import type {WALLET_METHODS} from '../../../common/constants/paymentMethods';
import {openGooglePayCvvForm} from '../../../forms/googlePay/utils/useIsGooglePayCvvForm';
import {TEST_CARD} from '../../constants/testPayLogic';
import type {
  PaymentAnswer,
  PaymentAnswerAbortable,
} from '../../types/paymentAnswerProps';
import type {
  PaymentDataPropsWithApplePaySettings,
  PaymentDataPropsWithGooglePaySettings,
} from '../../types/walletPaySettings';
import checkTestWalletPayLogic from '../../utils/checkTestWalletPayLogic';
import getPayMethodWithBackboneModel from '../../utils/getPayMethodWithBackboneModel';
import {PAYMENT_FLOW_ABORT} from '../../utils/getProcessPaymentFlow';
import getFormName from '../../utils/getFormName';
import payByMethod from '../../utils/payByMethod';
import addPaymentData from './addPaymentData';

const TEST_TOKEN = 'testWalletToken';

export type RealPay = (
  paymentData:
    | PaymentDataPropsWithApplePaySettings
    | PaymentDataPropsWithGooglePaySettings,
) => Promise<PaymentAnswer>;

/**
 * Test payment for wallet methods
 * @deprecated Use testWalletPay instead
 */
const testWalletPayLegacy =
  (method: string) =>
  async (
    paymentData:
      | PaymentDataPropsWithApplePaySettings
      | PaymentDataPropsWithGooglePaySettings,
  ) => {
    if (checkTestWalletPayLogic(method, true) && !googlePayMaskedCardVar()) {
      openGooglePayCvvForm(TEST_CARD);
      return PAYMENT_FLOW_ABORT;
    }

    const {
      action,
      via,
      prevVia,
      activePackage: {stockId, packageId, tokenPrice: {currencyCode} = {}},
      country,
      siteName,
      cancelRemarketingSubscription,
      replaceRedirectToPostMessage,
      urlParams,
      ...props
    } = paymentData;

    const pay = getPayMethodWithBackboneModel({
      method,
      action,
      // @ts-expect-error --> Ignore this legacy code
      formName: getFormName({method}),
    });

    let result;

    const previousVia = prevVia || via;

    try {
      result = await pay({
        stockId,
        packageId,
        country,
        currency_code: currencyCode,
        domain: siteName,
        prevVia: previousVia,
        hidePaymentForm: 0,
        walletToken: TEST_TOKEN,
        ...omit(props, 'settings'),
      });
    } catch (error) {
      return error;
    }

    return {
      prevVia: previousVia,
      urlParams,
      cancelRemarketingSubscription,
      replaceRedirectToPostMessage,
      method,
      ...result,
    };
  };

/**
 * Test payment for wallet methods
 */
const testWalletPay =
  (method: WALLET_METHODS) =>
  async (
    paymentData: PaymentDataPropsWithApplePaySettings,
  ): Promise<PaymentAnswerAbortable> => {
    if (checkTestWalletPayLogic(method, true) && !googlePayMaskedCardVar()) {
      openGooglePayCvvForm(TEST_CARD);
      return PAYMENT_FLOW_ABORT;
    }

    const {
      action,
      via,
      prevVia,
      activePackage,
      siteName,
      cancelRemarketingSubscription,
      replaceRedirectToPostMessage,
      ...props
    } = paymentData;

    let result: string | PaymentAnswer;

    /**
     * @TODO: Think about add 'addPaymentData' method to 'getGooglePayFlow' util,
     *        after removing Backbone.
     */
    const data = await addPaymentData({
      action,
      activePackage,
      altMethodsSettings: props.settings,
      method,
      prevVia,
      via,
    });

    try {
      result = await payByMethod({
        ...data,
        ...omit(props, 'settings'),
        // TODO[BB_removed]: Think about move it in 'addPaymentData' util, if need for all payment methods
        currencyCode: activePackage.tokenPrice?.currencyCode,
        domain: siteName,
        hidePaymentForm: 0,
        method,
        walletToken: TEST_TOKEN,
      });
    } catch (error) {
      return error;
    }

    return {
      method,
      cancelRemarketingSubscription,
      replaceRedirectToPostMessage,
      ...result,
    };
  };

/**
 * Enhance payment result with some paymentData credentials (via, etc...)
 */
const modifyPaymentResult =
  (realPay: RealPay) =>
  async (
    paymentData: PaymentDataPropsWithApplePaySettings,
  ): Promise<PaymentAnswer> => {
    const {prevVia, via} = paymentData;

    const result = await realPay(paymentData);

    /**
     * Skip result modification if flow message returned as result
     */
    if (isString(result)) {
      return result;
    }

    return {
      ...result,
      /**
       * Actually in wallet methods we have only via before this moment(no prevVia exists in payment flow for wallet methods)
       * but choose between prevVia and via is added here in case if in future methods like addPaymentData could be
       * applied in payment flow for via modification in wallet payment flow to prevent breaking something
       *
       * FIXME: delete after refactoring or in case if addPaymentData will be applied in payment flows before
       */
      prevVia: prevVia || via,
    };
  };

/**
 * Return pay function for wallet method
 * @see getApplePayFlow
 */
const getWalletPay = (
  walletMethod: WALLET_METHODS,
  realPay: RealPay,
  isFetchApi: boolean,
) => {
  if (checkTestWalletPayLogic(walletMethod)) {
    return isFetchApi
      ? testWalletPay(walletMethod)
      : testWalletPayLegacy(walletMethod);
  }

  return modifyPaymentResult(realPay);
};

export default getWalletPay;
