import {getClientInstance} from '@core/graphql/client';

import {DEBT_LIMIT_REACHED} from '../constants/reasons';
import TARGET_USER_SEND_FORM_QUERY from '../graphql/queries/targetUserSendForm.gql';
import DELETE_MESSAGE_MUTATION from '../graphql/mutations/deleteMessage.gql';
import updateRecipientCache from './updateRecipientCache';
import updateRecipientMessagePermissions from './updateRecipientMessagePermissions';
import updateRecipientsMessagePermissions from './updateRecipientsMessagePermissions';

/**
 * When recipient get adult photo on traditional sites he could remove this photo forever.
 * @param {string} messageId
 * @param {string} userId
 * @returns {Promise<<any>>}
 */
const deleteMessage = (messageId, userId) =>
  getClientInstance().mutate({
    mutation: DELETE_MESSAGE_MUTATION,
    variables: {
      messageId,
    },
    update: async (
      cache,
      {
        data: {
          messenger: {
            deleteMessage: {result, errors},
          },
        },
      },
    ) => {
      if ((errors && errors.general) || !result) return;
      let needUpdatePermissions = false;
      const client = getClientInstance();
      const {
        data: {
          user: {
            messagePermissions: {canSend, reason},
          },
        },
      } = await client.query({
        query: TARGET_USER_SEND_FORM_QUERY,
        variables: {userId},
      });

      if (reason === DEBT_LIMIT_REACHED && !canSend) {
        /*
         Before delete message should have actual data in cache.
         If after delete we try quickly write new message shouldn't show popup buy coins.
         */
        await updateRecipientMessagePermissions({
          client,
          userId,
          canSendNext: true,
          // update permissions current recipient only in cache, without fetching
          onlyInCache: true,
        });

        // If delete one message form one recipient when debt limit,
        // should remove this restrictions for other recipients too.
        // Shouldn't show popup buy coins for other recipients.
        needUpdatePermissions = true;
      }

      updateRecipientCache(
        userId,
        ({lastMessage, messages, hasOutgoingMessage, unsentMessageCount}) => {
          // After delete all unsent messages of user.
          // Because after this one limits wont reached.
          if (!(unsentMessageCount - 1)) {
            needUpdatePermissions = true;
          }
          const filteredMessages = messages.filter(
            (message) => message.id !== messageId,
          );
          const filteredMessagesLength = filteredMessages.length;
          const recipientHasMessages = Boolean(filteredMessagesLength);
          const newLastMessage = recipientHasMessages
            ? filteredMessages[filteredMessagesLength - 1]
            : null;

          return {
            unsentMessageCount: unsentMessageCount ? unsentMessageCount - 1 : 0,
            messages: filteredMessages,
            lastMessage: newLastMessage
              ? {
                  ...lastMessage,
                  id: newLastMessage.id,
                  timestamp: newLastMessage.timestamp,
                  type: newLastMessage.type,
                  text: newLastMessage.text,
                  isSending: false,
                  __typename: 'LastMessage',
                }
              : null,
            hasOutgoingMessage: hasOutgoingMessage && recipientHasMessages,
          };
        },
      );

      // Need update permissions for all recipient,
      // if the user doesn't have any unsent messages or debt limit reached
      if (needUpdatePermissions) {
        updateRecipientsMessagePermissions((recipient) => {
          // Not update permissions current recipient because already updated above
          // when call direct updateRecipientMessagePermissions, see higher in code.
          if (recipient.id === userId) {
            return null;
          }
          return true;
        }, true);
      }
    },
  });

export default deleteMessage;
