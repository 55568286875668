export const DISAPPROVE_PHOTO = 'disapprovePhoto';
export const DISAPPROVE_VIDEO = 'disapproveVideo';
export const DISAPPROVE_SCREENNAME = 'disapproveScreenname';
export const DISAPPROVE_TEXT_STATUS = 'disapproveStatus';
export const UPGRADE_FOR_FULL_SIZE_PHOTO = 'upgrateToViewFullSizePhoto';
export const NO_SCREENNAME = 'noScreenname';
export const NO_TEXT_STATUS = 'accountStatus';
export const NO_PHOTO = 'noPhoto';
export const NO_VIDEO = 'noVideo';
export const EXTRA_TOOLS = 'extraTools';
export const UPGRADE_TO_CALL = 'upgradeToCall';
export const MICROFEATURE = 'microfeatures';
export const HOLIDAY_UPGRADE_1 = 'holidayUpgrade1';
export const HOLIDAY_UPGRADE_2 = 'holidayUpgrade2';
export const HOLIDAY_UPGRADE_3 = 'holidayUpgrade3';
export const FLIRTCAST = 'flirtcast';
export const TOP_SEARCH_MICROFEATURE = 'topSearchMicrofeatures';
export const EXTRA_COINS = 'extraCoins';
export const DO_REWARD_CENTER_TASKS = 'doRewardCenterTasks';
export const UPGRADE_TO_PRIORITY_MAN = 'upgradeToPriorityMan';
export const UPDATE_SIMILAR_USERS = 'updateSimilarUsers';
export const SPECIAL_OFFER_WEEK_SPEAK = 'specialOfferSpeakWeek';
export const FREE_MESSAGES = 'freeMessages';
export const WORLD_WIDE = 'worldWide';
