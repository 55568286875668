import type {Subscription} from 'rxjs';
import {Subject, race} from 'rxjs';
import {bufferTime, bufferCount} from 'rxjs/operators';
import type {ApolloClient} from '@apollo/client';
import type {DocumentNode} from 'graphql';

import {getClientInstance} from '@core/graphql/client';
import getCookie from '@core/utils/cookie/getCookie';

import IS_ALLOWED_TRACKING_QUERY from './graphql/queries/isAllowedTracking.gql';
import type {
  IsAllowedTrackingQuery,
  IsAllowedTrackingQueryVariables,
} from './graphql/queries/isAllowedTracking';

/**
 * Amount of ID's that can be sent in one pack
 */
export const MAX_QUEUE_SIZE = 100;

/**
 * Amount of time how much we should collect ID's for making one track instead
 * of sending separated tacks for each ID
 */
export const TRACKING_PERIOD = 60000; // e.g. 1 minute

// Instant time period to send data to server (0 because need to send immediately before reload page)
export const INSTANT_TRACKING_PERIOD = 0;

type DataFormatter<TVariables, TrackingData> = (
  data: TrackingData[],
  client: ApolloClient<any>,
) => Promise<TVariables>;

/**
 * The basic class of frontend tracks, which includes logic for collecting and sending data
 */
class BaseTracker<TVariables, TrackingData> {
  // Callback function for setting the sending formats
  private dataFormatter: DataFormatter<TVariables, TrackingData>;

  // Mutation of sending a track
  private mutation: DocumentNode;

  // The name of the track for debugging as well as for requesting track availability
  private trackingName: string;

  /**
   * We can track only one time needed ID during session.
   * So it's the place where they are stored.
   */
  private trackedIds: Set<string>;

  /**
   * Data source for collecting data and sending it batched to server,
   * that is piped for collecting data during some interval
   */
  private subject: Subject<TrackingData>;

  // Cached observable for batching
  private tracks$: Subscription | null;

  private client: ApolloClient<any>;

  constructor(
    mutation: DocumentNode,
    dataFormatter: DataFormatter<TVariables, TrackingData>,
    trackingName: string = '',
  ) {
    this.dataFormatter = dataFormatter;
    this.mutation = mutation;
    this.trackingName = trackingName;
    this.trackedIds = new Set();
    this.subject = new Subject();
    this.tracks$ = null;
    this.client = getClientInstance();
  }

  /**
   * Track without allowed check
   * @public
   * @param trackData - data for track
   * @param instantTrack - flag whether the track can be sent immediately
   * @param storeId - tracking will be filtered by this id (see trackCTR)
   */
  track(
    trackData: TrackingData,
    instantTrack = false,
    storeId: string | null = null,
  ): void {
    // If we have an ID, then we remembered the track
    if (storeId && trackData[storeId]) {
      if (this.trackedIds.has(trackData[storeId] as string)) {
        return;
      }
    }

    // If data doesn't exists or we are already unsubscribed from it
    if (!this.tracks$ || this.tracks$.closed) {
      /**
       * Emit first completed value. E.g. if we reach max queue size before period ends
       * subscription will receive this array, if wait period ends and max queue size isn't reached
       * we take array from time
       */
      this.tracks$ = race(
        this.subject.pipe(bufferCount(MAX_QUEUE_SIZE)),
        this.subject.pipe(
          bufferTime(instantTrack ? INSTANT_TRACKING_PERIOD : TRACKING_PERIOD),
        ),
      ).subscribe((dataList) => {
        // Format the data and send the track
        this.dataFormatter(dataList, this.client).then(
          (variables: TVariables) => {
            this.client
              .mutate<never, TVariables>({
                mutation: this.mutation,
                variables,
              })
              .then(() => {
                if (getCookie(`${this.trackingName}Debug`)) {
                  console.log(
                    `[${this.trackingName}] Data was sent:`,
                    variables,
                  );
                }
              });
          },
        );

        /**
         * After successful data send - unsubscribe. Since there is a case when anything is already
         * tracked. So we don't need a subscription.
         * Also, don't wait finish of mutation because we can loose some tracks between start and finish of mutation.
         */
        this.tracks$.unsubscribe();
      });
    }

    if (storeId && trackData[storeId]) {
      this.trackedIds.add(trackData[storeId] as string);
    }

    this.subject.next(trackData);
  }

  /**
   * Check if allowed tracking and track
   * @public
   * @param trackData - data for track
   * @param instantTrack - flag whether the track can be sent immediately
   * @param storeId - tracking will be filtered by this id (see trackCTR)
   */
  checkAndTrack(
    trackData: TrackingData,
    instantTrack = false,
    storeId: string | null = null,
  ): void {
    // Check if tracking is available
    this.client
      .query<IsAllowedTrackingQuery, IsAllowedTrackingQueryVariables>({
        query: IS_ALLOWED_TRACKING_QUERY,
        variables: {trackingName: this.trackingName},
      })
      .then(({data}) => {
        if (data?.userFeatures?.frontendTrackingParams?.isTrackingAllowed) {
          this.track(trackData, instantTrack, storeId);
        }
      });
  }
}

export default BaseTracker;
