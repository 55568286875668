/** Don`t modify this file, it is generated by graphql-codegen script in order to update it`s content run yarn gql:codegen  */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
};

export type AboutUs = {
  __typename?: 'AboutUs';
  hosts: Array<Maybe<HostUser>>;
  video: Video;
};

export type AccountStatus = {
  __typename?: 'AccountStatus';
  isPaid: Scalars['Boolean']['output'];
  notBoughtAccountFeatures?: Maybe<Array<Scalars['String']['output']>>;
};

export type Act = {
  __typename?: 'Act';
  availableSpinCount?: Maybe<Scalars['Int']['output']>;
  extraPaymentBanner?: Maybe<ExtraPaymentBannerPayload>;
  nextGameTimeout?: Maybe<Scalars['Int']['output']>;
  via?: Maybe<Scalars['String']['output']>;
};

export type ActMutationResult = {
  __typename?: 'ActMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<Act>;
};

export enum Action {
  additionalPackageInitialState = 'additionalPackageInitialState',
  ageVerification = 'ageVerification',
  ageVerificationPage = 'ageVerificationPage',
  alternative = 'alternative',
  appleSession = 'appleSession',
  auth3d = 'auth3d',
  autoFanclub = 'autoFanclub',
  autoOneClick = 'autoOneClick',
  beforePay = 'beforePay',
  bongaCams = 'bongaCams',
  bundle = 'bundle',
  buyCredits = 'buyCredits',
  buyCreditsFromChat = 'buyCreditsFromChat',
  buyMinutes = 'buyMinutes',
  callMeBack = 'callMeBack',
  callback = 'callback',
  cancel = 'cancel',
  cardAuthorization = 'cardAuthorization',
  cardFrame = 'cardFrame',
  cardNumberLength = 'cardNumberLength',
  cardWallet = 'cardWallet',
  commdoo = 'commdoo',
  credit = 'credit',
  credoraxDeviceFingerprint = 'credoraxDeviceFingerprint',
  crossSales = 'crossSales',
  datingCourse = 'datingCourse',
  debtInvoice = 'debtInvoice',
  devents = 'devents',
  deviceFingerprint = 'deviceFingerprint',
  dimocco = 'dimocco',
  dimoco = 'dimoco',
  exchangeCredit = 'exchangeCredit',
  external = 'external',
  externalPackage = 'externalPackage',
  fanclub = 'fanclub',
  fanclubPostTrans = 'fanclubPostTrans',
  fanclubPromoCredit = 'fanclubPromoCredit',
  features = 'features',
  fixIt = 'fixIt',
  freeMembership = 'freeMembership',
  freePermission = 'freePermission',
  gate2Shop = 'gate2Shop',
  gift = 'gift',
  graphql = 'graphql',
  holidayOffer = 'holidayOffer',
  hookupPlan = 'hookupPlan',
  ibanOneClick = 'ibanOneClick',
  ideal = 'ideal',
  idealPackage = 'idealPackage',
  iframePP = 'iframePP',
  index = 'index',
  infobip = 'infobip',
  instantRepeat = 'instantRepeat',
  iyzipay = 'iyzipay',
  konbini = 'konbini',
  lifetimeOffer = 'lifetimeOffer',
  limitedTimeOffer = 'limitedTimeOffer',
  limitedTimeOfferShown = 'limitedTimeOfferShown',
  liveCamCredits = 'liveCamCredits',
  liveCamExternal = 'liveCamExternal',
  liveCamOffer = 'liveCamOffer',
  liveCamResult = 'liveCamResult',
  liveJasmin = 'liveJasmin',
  membership = 'membership',
  message = 'message',
  metArt = 'metArt',
  microFeatures = 'microFeatures',
  microFeaturesSpecialOffer = 'microFeaturesSpecialOffer',
  miniMembership = 'miniMembership',
  mobile = 'mobile',
  motivationPopup = 'motivationPopup',
  moveCard = 'moveCard',
  multiSelectMicroFeatures = 'multiSelectMicroFeatures',
  multistep = 'multistep',
  neteller = 'neteller',
  newWayDating = 'newWayDating',
  newWayDatingCredits = 'newWayDatingCredits',
  newWayDatingMembership = 'newWayDatingMembership',
  notifyUser = 'notifyUser',
  offer = 'offer',
  oneClick = 'oneClick',
  oneTimePurchase = 'oneTimePurchase',
  onebip = 'onebip',
  openRemarketingPopup = 'openRemarketingPopup',
  package = 'package',
  partnersMotoPP = 'partnersMotoPP',
  pay = 'pay',
  payOn = 'payOn',
  paymentwall = 'paymentwall',
  paypal = 'paypal',
  paysafecard = 'paysafecard',
  pending = 'pending',
  popupBuyCredits = 'popupBuyCredits',
  popupFeatures = 'popupFeatures',
  popupGift = 'popupGift',
  popupMembership = 'popupMembership',
  popupOffer = 'popupOffer',
  popupPayment = 'popupPayment',
  popupStick = 'popupStick',
  popupTopDatesMicrofeature = 'popupTopDatesMicrofeature',
  posttrans_pup_promo = 'posttrans_pup_promo',
  preAuthPopup = 'preAuthPopup',
  preselectSpecialOffer = 'preselectSpecialOffer',
  product = 'product',
  promo = 'promo',
  promoFeatures = 'promoFeatures',
  promoMembership = 'promoMembership',
  remarketingOffer = 'remarketingOffer',
  restore = 'restore',
  result = 'result',
  retryPermission = 'retryPermission',
  rocketPay = 'rocketPay',
  sale3dSecure = 'sale3dSecure',
  saveStock = 'saveStock',
  sepa = 'sepa',
  smart2pay = 'smart2pay',
  sms = 'sms',
  smsChat = 'smsChat',
  smsPugglePay = 'smsPugglePay',
  sms_response = 'sms_response',
  sofort = 'sofort',
  spaCancelPayment = 'spaCancelPayment',
  speedDating = 'speedDating',
  stick = 'stick',
  stopSubscription = 'stopSubscription',
  streamate = 'streamate',
  success = 'success',
  successPopup = 'successPopup',
  takeMerchant = 'takeMerchant',
  tenYearsOffer = 'tenYearsOffer',
  threeDSecure = 'threeDSecure',
  threeDSecureComplete = 'threeDSecureComplete',
  threeDSecureIframe = 'threeDSecureIframe',
  threeDSecureTest = 'threeDSecureTest',
  topDates = 'topDates',
  track = 'track',
  trackVisit = 'trackVisit',
  truevo = 'truevo',
  trustPay = 'trustPay',
  trusted = 'trusted',
  tubeAgeVerification = 'tubeAgeVerification',
  unknown = 'unknown',
  updateForcedPayment = 'updateForcedPayment',
  userAnotherBankTrack = 'userAnotherBankTrack',
  userAutoFill = 'userAutoFill',
  userVisitsTracking = 'userVisitsTracking',
  validateBin = 'validateBin',
  verification = 'verification',
  verificationPage = 'verificationPage',
  verificationSuccess = 'verificationSuccess',
  vod = 'vod',
  vodOneClick = 'vodOneClick',
  vodPopup = 'vodPopup',
  webBillingSepa = 'webBillingSepa',
  webCamCredits = 'webCamCredits',
  wechatPayUrl = 'wechatPayUrl',
}

export type ActivationShortCode = {
  __typename?: 'ActivationShortCode';
  message?: Maybe<Scalars['String']['output']>;
  urlAfterRegistration?: Maybe<Scalars['String']['output']>;
};

export type ActivationShortCodeMutationResult = {
  __typename?: 'ActivationShortCodeMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<ActivationShortCode>;
};

export type ActiveSplit = {
  __typename?: 'ActiveSplit';
  splitGroup?: Maybe<Scalars['Int']['output']>;
  splitId?: Maybe<Scalars['String']['output']>;
};

export type Activity = {
  __typename?: 'Activity';
  id: Scalars['ID']['output'];
  isNew: Scalars['Boolean']['output'];
  msgType?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
  type: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
  weight?: Maybe<Scalars['Float']['output']>;
};

export type ActivityEvent = {
  __typename?: 'ActivityEvent';
  eventId?: Maybe<Scalars['String']['output']>;
  fragmentId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isNew: Scalars['Boolean']['output'];
  messageId?: Maybe<Scalars['String']['output']>;
  msgType?: Maybe<Scalars['String']['output']>;
  notify?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['String']['output'];
  type: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
  videoAction?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type ActivityStatuses = {
  __typename?: 'ActivityStatuses';
  isFavourited?: Maybe<Scalars['Boolean']['output']>;
  isLiked?: Maybe<Scalars['Boolean']['output']>;
};

export enum ActivityTypeEnum {
  activity = 'activity',
  mail = 'mail',
}

export type AdditionalPackage = {
  __typename?: 'AdditionalPackage';
  packageId?: Maybe<Scalars['String']['output']>;
  translateMessage?: Maybe<Scalars['String']['output']>;
};

export type AdditionalPackageCredit = {
  __typename?: 'AdditionalPackageCredit';
  additionalPackageType: AdditionalPackageTypeEnum;
  configId: Scalars['String']['output'];
  packageId: Scalars['String']['output'];
};

export type AdditionalPackageITunes = {
  __typename?: 'AdditionalPackageITunes';
  additionalPackageType: AdditionalPackageTypeEnum;
  configId: Scalars['String']['output'];
  packageId: Scalars['String']['output'];
};

export type AdditionalPackageList =
  | AdditionalPackageCredit
  | AdditionalPackageITunes
  | AdditionalPackageMessage
  | AdditionalPackageOther
  | AdditionalPackageRemarketing
  | AdditionalPackageXSale;

export type AdditionalPackageMessage = {
  __typename?: 'AdditionalPackageMessage';
  additionalPackageType: AdditionalPackageTypeEnum;
  configId: Scalars['String']['output'];
  messageQuantity: Scalars['Int']['output'];
  packageId: Scalars['String']['output'];
};

export type AdditionalPackageOther = {
  __typename?: 'AdditionalPackageOther';
  additionalPackageType: AdditionalPackageTypeEnum;
  isCheckedByDefault: Scalars['Boolean']['output'];
  packageId: Scalars['String']['output'];
  repeatPrice?: Maybe<Price>;
  withCheckbox: Scalars['Boolean']['output'];
};

export type AdditionalPackageRemarketing = {
  __typename?: 'AdditionalPackageRemarketing';
  additionalPackageType: AdditionalPackageTypeEnum;
  configId: Scalars['String']['output'];
  packageId: Scalars['String']['output'];
  targetSiteRepeatIntervals?: Maybe<Array<Scalars['Int']['output']>>;
};

export enum AdditionalPackageTypeEnum {
  credit = 'credit',
  itunes = 'itunes',
  message = 'message',
  other = 'other',
  remarketing = 'remarketing',
  vod = 'vod',
  xsale = 'xsale',
  xsaleAdditional = 'xsaleAdditional',
}

export type AdditionalPackageVerification = {
  __typename?: 'AdditionalPackageVerification';
  agreementChecked?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type AdditionalPackageXSale = {
  __typename?: 'AdditionalPackageXSale';
  additionalPackageType: AdditionalPackageTypeEnum;
  configId: Scalars['String']['output'];
  dayInterval?: Maybe<Scalars['Int']['output']>;
  externalDomain?: Maybe<Scalars['String']['output']>;
  isCheckedByDefault: Scalars['Boolean']['output'];
  isXsaleCheckBoxLogicInverted: Scalars['Boolean']['output'];
  orderSourceType: Scalars['String']['output'];
  packageId: Scalars['String']['output'];
  repeatInterval?: Maybe<Scalars['Int']['output']>;
  repeatPrice?: Maybe<Price>;
  targetSiteId: Scalars['String']['output'];
};

export type AdditionalTerms = {
  __typename?: 'AdditionalTerms';
  agreeAndContinue?: Maybe<Scalars['Boolean']['output']>;
  autoRenewalAgree?: Maybe<Scalars['Boolean']['output']>;
  autoRenewalAgreeXSale?: Maybe<Scalars['Boolean']['output']>;
  billingPolicy?: Maybe<Scalars['Boolean']['output']>;
  credentialsPolicy?: Maybe<Scalars['Boolean']['output']>;
  creditAutofillAgreement?: Maybe<Scalars['Boolean']['output']>;
};

export type AdvancedSearchParam = {
  __typename?: 'AdvancedSearchParam';
  order?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title?: Maybe<Scalars['String']['output']>;
  valueIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AdvancedSearchParamsList = {
  __typename?: 'AdvancedSearchParamsList';
  availableGenders?: Maybe<AdvancedSearchParam>;
  bloodType?: Maybe<AdvancedSearchParam>;
  build?: Maybe<AdvancedSearchParam>;
  children?: Maybe<AdvancedSearchParam>;
  drink?: Maybe<AdvancedSearchParam>;
  education?: Maybe<AdvancedSearchParam>;
  eye_color?: Maybe<AdvancedSearchParam>;
  gambling?: Maybe<AdvancedSearchParam>;
  genderIdentity?: Maybe<AdvancedSearchParam>;
  hair_color?: Maybe<AdvancedSearchParam>;
  height?: Maybe<AdvancedSearchParam>;
  hobby?: Maybe<AdvancedSearchParam>;
  income?: Maybe<AdvancedSearchParam>;
  living?: Maybe<AdvancedSearchParam>;
  looking_for?: Maybe<AdvancedSearchParam>;
  marital_status?: Maybe<AdvancedSearchParam>;
  occupation?: Maybe<AdvancedSearchParam>;
  photoLevel?: Maybe<AdvancedSearchParam>;
  pierced?: Maybe<AdvancedSearchParam>;
  race?: Maybe<AdvancedSearchParam>;
  religion?: Maybe<AdvancedSearchParam>;
  sexual_orientation?: Maybe<AdvancedSearchParam>;
  smoke?: Maybe<AdvancedSearchParam>;
  tattoo?: Maybe<AdvancedSearchParam>;
  tribesList?: Maybe<AdvancedSearchParam>;
  weight?: Maybe<AdvancedSearchParam>;
  withVideo?: Maybe<AdvancedSearchParam>;
  wphoto?: Maybe<AdvancedSearchParam>;
  zodiacSign?: Maybe<AdvancedSearchParam>;
};

export type AfterPayData = {
  __typename?: 'AfterPayData';
  bankMotivationText?: Maybe<BankMotivationText>;
  callMeBack?: Maybe<CallMeBack>;
  failPageLogic?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  userHumanId?: Maybe<Scalars['ID']['output']>;
};

export type AfterPayDataBankMotivationTextArgs = {
  action: Action;
};

export type AfterPayDataFailPageLogicArgs = {
  prevVia?: InputMaybe<ViaEnum>;
  via: Action;
};

export type AgeBoundaries = {
  __typename?: 'AgeBoundaries';
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
};

export type AgeRange = {
  __typename?: 'AgeRange';
  from: Scalars['Int']['output'];
  to: Scalars['Int']['output'];
};

export type AlertBanner = {
  __typename?: 'AlertBanner';
  kind?: Maybe<AlertBannerEnum>;
  via?: Maybe<ViaEnum>;
};

export type AllActivities = {
  __typename?: 'AllActivities';
  direction?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isNew: Scalars['Boolean']['output'];
  msgType: Scalars['String']['output'];
  timestamp: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type AllowedAttributes = {
  __typename?: 'AllowedAttributes';
  additionalInfo?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  culturalValuesFemale?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  culturalValuesMale?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  culturalValuesSearch?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  funnel?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  hobby?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  info?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  lookingFor?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  search?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  shortInfo?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum AltMethodScenarioEnum {
  SHORT = 'SHORT',
  addSubscription = 'addSubscription',
  callback = 'callback',
  callbackInitial = 'callbackInitial',
  callbackRefund = 'callbackRefund',
  cancel = 'cancel',
  cascade = 'cascade',
  debtInvoice = 'debtInvoice',
  debtPay = 'debtPay',
  decline = 'decline',
  deferredEvent = 'deferredEvent',
  deleteSubscription = 'deleteSubscription',
  emulateVerify = 'emulateVerify',
  emulateVerifyConsumablePurchase = 'emulateVerifyConsumablePurchase',
  error = 'error',
  failCallback = 'failCallback',
  initial = 'initial',
  instantRepeat = 'instantRepeat',
  motoPayment = 'motoPayment',
  new = 'new',
  notification = 'notification',
  oneClick = 'oneClick',
  paymentCheck = 'paymentCheck',
  phoneCheck = 'phoneCheck',
  pinCheck = 'pinCheck',
  redirect = 'redirect',
  refund = 'refund',
  refundCheck = 'refundCheck',
  renewal = 'renewal',
  repeat = 'repeat',
  restoreUserId = 'restoreUserId',
  retry = 'retry',
  return = 'return',
  savePackagesInfo = 'savePackagesInfo',
  success = 'success',
  successCallback = 'successCallback',
  transactionCheck = 'transactionCheck',
  transactionVerify = 'transactionVerify',
  updateSubscriptionPackage = 'updateSubscriptionPackage',
  updateSubscriptionStatus = 'updateSubscriptionStatus',
  verifyConsumablePurchase = 'verifyConsumablePurchase',
}

export type AltMethodsScenario = {
  __typename?: 'AltMethodsScenario';
  method?: Maybe<Method>;
  scenario?: Maybe<AltMethodScenarioEnum>;
};

export type AltMethodsSettings =
  | ApplePaySettings
  | CommDooSepaSettings
  | GooglePaySettings
  | KonbiniSettings
  | MicropaymentSepaSettings
  | MidasSettings
  | NovalNetIdealSettings
  | NovalNetSepaSettings
  | NuveiIdealSettings
  | NuveiMyBankSettings
  | PaygardenSettings
  | Paypalv2Settings
  | SaltEdgeAisSettings
  | SepaExpressV2Settings
  | SofortSettings
  | TrustPayIdealSettings
  | TrustPaySepaSettings
  | VendoSepaSettings
  | WebBillingSepaSettings;

export type AmountPartVideoGroupEvent = {
  __typename?: 'AmountPartVideoGroupEvent';
  count: Scalars['Int']['output'];
  group: Scalars['String']['output'];
};

export type ApplePayDisplaySettings = {
  __typename?: 'ApplePayDisplaySettings';
  merchantId?: Maybe<Scalars['String']['output']>;
};

export type ApplePaySettings = {
  __typename?: 'ApplePaySettings';
  cardWalletAdditionalFields?: Maybe<
    Array<Maybe<CardWalletAdditionalFieldEnum>>
  >;
  merchantCapabilities?: Maybe<Array<MerchantCapability>>;
  merchantId?: Maybe<Scalars['String']['output']>;
  method?: Maybe<Method>;
  supportedCountries?: Maybe<Array<Maybe<SupportedCountries>>>;
  supportedNetworks?: Maybe<Array<Network>>;
};

export type ApplePayUserDetailsResult = {
  __typename?: 'ApplePayUserDetailsResult';
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type ApplePayUserDetailsResultMutationResult = {
  __typename?: 'ApplePayUserDetailsResultMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<ApplePayUserDetailsResult>;
};

export enum AreYouSureAnswer {
  no = 'no',
  yes = 'yes',
}

export type ArgumentError = {
  __typename?: 'ArgumentError';
  argument: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export enum AskForTypes {
  askForDetails = 'askForDetails',
  askForDetailsAdded = 'askForDetailsAdded',
  askForMorePhotos = 'askForMorePhotos',
  askForMorePhotosUploaded = 'askForMorePhotosUploaded',
  askForPhoto = 'askForPhoto',
  askForPhotoUploaded = 'askForPhotoUploaded',
}

export type AttributesDictionary = {
  __typename?: 'AttributesDictionary';
  bloodType?: Maybe<Array<Maybe<BloodTypeUserAttributeDictionary>>>;
  build?: Maybe<Array<Maybe<BuildUserAttributeDictionary>>>;
  characteristics?: Maybe<Array<Maybe<CharacteristicsUserAttributeDictionary>>>;
  children?: Maybe<Array<Maybe<ChildrenUserAttributeDictionary>>>;
  diet?: Maybe<Array<Maybe<DietUserAttributeDictionary>>>;
  drink?: Maybe<Array<Maybe<DrinkUserAttributeDictionary>>>;
  drugs?: Maybe<Array<Maybe<DrugsUserAttributeDictionary>>>;
  eatingHabits?: Maybe<Array<Maybe<EatingHabitsUserAttributeDictionary>>>;
  education?: Maybe<Array<Maybe<EducationUserAttributeDictionary>>>;
  eyeColor?: Maybe<Array<Maybe<EyeColorUserAttributeDictionary>>>;
  fetish?: Maybe<Array<Maybe<FetishUserAttributeDictionary>>>;
  gambling?: Maybe<Array<Maybe<GamblingUserAttributeDictionary>>>;
  genderIdentity?: Maybe<Array<Maybe<GenderIdentityUserAttributeDictionary>>>;
  goal?: Maybe<Array<Maybe<GoalUserAttributeDictionary>>>;
  hairColor?: Maybe<Array<Maybe<HairColorUserAttributeDictionary>>>;
  height?: Maybe<Array<Maybe<HeightUserAttributeDictionary>>>;
  hijabWearing?: Maybe<Array<Maybe<HijabWearingUserAttributeDictionary>>>;
  hobby?: Maybe<Array<Maybe<HobbyUserAttributeDictionary>>>;
  hobbyEntertainment?: Maybe<
    Array<Maybe<HobbyEntertainmentUserAttributeDictionary>>
  >;
  hobbyFood?: Maybe<Array<Maybe<HobbyFoodUserAttributeDictionary>>>;
  hobbyMusic?: Maybe<Array<Maybe<HobbyMusicUserAttributeDictionary>>>;
  hobbyPlaySports?: Maybe<Array<Maybe<HobbyPlaySportsUserAttributeDictionary>>>;
  hobbyTV?: Maybe<Array<Maybe<HobbyTvUserAttributeDictionary>>>;
  hobbyWatchSports?: Maybe<
    Array<Maybe<HobbyWatchSportsUserAttributeDictionary>>
  >;
  income?: Maybe<Array<Maybe<IncomeUserAttributeDictionary>>>;
  indianCommunity?: Maybe<Array<Maybe<IndianCommunityUserAttributeDictionary>>>;
  islamCult?: Maybe<Array<Maybe<IslamCultUserAttributeDictionary>>>;
  literatureRead?: Maybe<Array<Maybe<LiteratureReadUserAttributeDictionary>>>;
  living?: Maybe<Array<Maybe<LivingUserAttributeDictionary>>>;
  maritalStatus?: Maybe<Array<Maybe<MaritalStatusUserAttributeDictionary>>>;
  moneyRole?: Maybe<Array<Maybe<MoneyRoleUserAttributeDictionary>>>;
  muslimBorn?: Maybe<Array<Maybe<MuslimBornUserAttributeDictionary>>>;
  netWorth?: Maybe<Array<Maybe<NetWorthUserAttributeDictionary>>>;
  occupation?: Maybe<Array<Maybe<OccupationUserAttributeDictionary>>>;
  pierced?: Maybe<Array<Maybe<PiercedUserAttributeDictionary>>>;
  politics?: Maybe<Array<Maybe<PoliticsUserAttributeDictionary>>>;
  practisingReligion?: Maybe<
    Array<Maybe<PractisingReligionUserAttributeDictionary>>
  >;
  professionId?: Maybe<Array<Maybe<ProfessionIdUserAttributeDictionary>>>;
  race?: Maybe<Array<Maybe<RaceUserAttributeDictionary>>>;
  readQuran?: Maybe<Array<Maybe<ReadQuranUserAttributeDictionary>>>;
  registrationReason?: Maybe<
    Array<Maybe<RegistrationReasonUserAttributeDictionary>>
  >;
  religion?: Maybe<Array<Maybe<ReligionUserAttributeDictionary>>>;
  religiousServices?: Maybe<
    Array<Maybe<ReligiousServicesUserAttributeDictionary>>
  >;
  religiousValues?: Maybe<Array<Maybe<ReligiousValuesUserAttributeDictionary>>>;
  sexualOrientation?: Maybe<
    Array<Maybe<SexualOrientationUserAttributeDictionary>>
  >;
  sexualRole?: Maybe<Array<Maybe<SexualRoleUserAttributeDictionary>>>;
  smoke?: Maybe<Array<Maybe<SmokeUserAttributeDictionary>>>;
  tattoo?: Maybe<Array<Maybe<TattooUserAttributeDictionary>>>;
  tribe?: Maybe<Array<Maybe<TribeUserAttributeDictionary>>>;
  weight?: Maybe<Array<Maybe<WeightUserAttributeDictionary>>>;
  zodiacSign?: Maybe<Array<Maybe<ZodiacSignUserAttributeDictionary>>>;
};

export type AttributesSearchForm = {
  __typename?: 'AttributesSearchForm';
  bloodType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  build?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  children?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  drink?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  education?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  eye_color?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  gambling?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  genderIdentity?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  hair_color?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  hobby?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  income?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  living?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  looking_for?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  marital_status?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  occupation?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  photoLevel?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  pierced?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  race?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  region?: Maybe<Scalars['String']['output']>;
  religion?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sexual_orientation?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  smoke?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tattoo?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tribesList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  weight?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  withVideo?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  wphoto?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  zodiacSign?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum AuthMethod {
  CRYPTOGRAM_3DS = 'CRYPTOGRAM_3DS',
  PAN_ONLY = 'PAN_ONLY',
}

export type Authorization = {
  __typename?: 'Authorization';
  result: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type AuthorizationMutationResult = {
  __typename?: 'AuthorizationMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<Authorization>;
};

export type AutofillSettings = {
  __typename?: 'AutofillSettings';
  isPreChecked: Scalars['Boolean']['output'];
};

export type BanInfo = {
  __typename?: 'BanInfo';
  isBanned?: Maybe<Scalars['Boolean']['output']>;
  period?: Maybe<Scalars['String']['output']>;
};

export type BannedUser = {
  __typename?: 'BannedUser';
  endBan?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  moderatorId?: Maybe<Scalars['String']['output']>;
  moderatorLogin?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  startBan?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  userLogin?: Maybe<Scalars['String']['output']>;
};

export type Banner = {
  __typename?: 'Banner';
  divId: Scalars['String']['output'];
  height: Scalars['String']['output'];
  ifrSrc: Scalars['String']['output'];
  iframeId: Scalars['String']['output'];
  placement: Scalars['String']['output'];
  width: Scalars['String']['output'];
  zoneId: Scalars['String']['output'];
};

export type BannerData = {
  __typename?: 'BannerData';
  alertBanner?: Maybe<AlertBanner>;
};

export type Banners = {
  __typename?: 'Banners';
  id: Scalars['String']['output'];
  list?: Maybe<Array<Maybe<MotivationBanner>>>;
  offset: Scalars['Int']['output'];
  repeats: Scalars['Int']['output'];
  step: Scalars['Int']['output'];
};

export type BannersOptions = {
  __typename?: 'BannersOptions';
  offset: Scalars['Int']['output'];
  placements: Array<Maybe<Scalars['String']['output']>>;
  step: Scalars['Int']['output'];
};

export type BasketEvent = {
  __typename?: 'BasketEvent';
  age: AgeRange;
  allPhotos: Array<Maybe<Image>>;
  category: StringIdElement;
  chosenFemaleTicketsAmount: Scalars['Int']['output'];
  chosenMaleTicketsAmount: Scalars['Int']['output'];
  currency: Currency;
  discountPrice?: Maybe<Scalars['Float']['output']>;
  eventOrientations: EventOrientation;
  extraInfoItems?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  format: EventFormat;
  gay: Scalars['Boolean']['output'];
  hasLeftFeedback: Scalars['Boolean']['output'];
  haveTicket: Scalars['Boolean']['output'];
  haveUnlimitedTicket: Scalars['Boolean']['output'];
  host: HostUser;
  id: Scalars['ID']['output'];
  isUnique: Scalars['Boolean']['output'];
  labels: Array<Maybe<EventLabel>>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  lesbian: Scalars['Boolean']['output'];
  location?: Maybe<EventLocation>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  primaryPhoto: Image;
  promoCodeActivated: Scalars['Boolean']['output'];
  promocode?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<EventRate>;
  readyToRate: Scalars['Boolean']['output'];
  reviews: Array<Maybe<Review>>;
  seoContent: Array<Maybe<SeoContent>>;
  startTime: Scalars['Int']['output'];
  status: EventStatus;
  steps?: Maybe<Array<Maybe<EventStep>>>;
  ticketsLeft: EventTicketsLeftByGender;
  type: StringIdElement;
  unlimitedPassApplied: Scalars['Boolean']['output'];
  unreadMatchesCount?: Maybe<Scalars['Int']['output']>;
  userIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  users?: Maybe<Array<Maybe<UserEvent>>>;
  virtualEventLink?: Maybe<Scalars['String']['output']>;
};

export type BasketEvents = {
  __typename?: 'BasketEvents';
  events?: Maybe<Array<Maybe<BasketEvent>>>;
  freeTicketsAmount?: Maybe<Scalars['Int']['output']>;
  money?: Maybe<Price>;
  /** @deprecated use money instead */
  totalPrice?: Maybe<BasketTotalPrice>;
};

export type BasketResponse = {
  __typename?: 'BasketResponse';
  basketId?: Maybe<Scalars['String']['output']>;
  event?: Maybe<BasketEvent>;
  freeTicketsAmount?: Maybe<Scalars['Int']['output']>;
  result: Scalars['Boolean']['output'];
  totalPrice?: Maybe<Scalars['Float']['output']>;
};

export type BasketResponseMutationResult = {
  __typename?: 'BasketResponseMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<BasketResponse>;
};

export type BasketTotalPrice = {
  __typename?: 'BasketTotalPrice';
  currency?: Maybe<Currency>;
  price: Scalars['Float']['output'];
};

export type BecomeTrustedOffer = {
  __typename?: 'BecomeTrustedOffer';
  active?: Maybe<Scalars['Boolean']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
  popup?: Maybe<BecomeTrustedOfferPopup>;
};

export type BecomeTrustedOfferPopup = {
  __typename?: 'BecomeTrustedOfferPopup';
  users: Array<Maybe<UserData>>;
  via?: Maybe<Scalars['String']['output']>;
};

export type BecomeTrustedOfferPopupUsersArgs = {
  limit: Scalars['Int']['input'];
};

export type BenefitTranslation = {
  __typename?: 'BenefitTranslation';
  name: Scalars['String']['output'];
  translation?: Maybe<Scalars['String']['output']>;
};

export type Benefits = {
  __typename?: 'Benefits';
  activeBenefits: Array<Maybe<Scalars['String']['output']>>;
  packageType: Scalars['String']['output'];
};

export type BicInfo = {
  __typename?: 'BicInfo';
  bankName: Scalars['String']['output'];
  bic: Scalars['String']['output'];
};

export type BillingHistory = {
  __typename?: 'BillingHistory';
  available?: Maybe<Scalars['Boolean']['output']>;
  creditInstantRepeatStatus?: Maybe<Scalars['Boolean']['output']>;
  freeMessagesBeforeCancel?: Maybe<FreeMessagesBeforeCancel>;
  isShortPriceEnabled: Scalars['Boolean']['output'];
  paymentHistory?: Maybe<Array<Maybe<PaymentHistoryUnit>>>;
  saltEdgeActiveConnection?: Maybe<Scalars['String']['output']>;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
};

export type BillingHistoryMutation = {
  __typename?: 'BillingHistoryMutation';
  acceptFreeMessagesBeforeCancel?: Maybe<BooleanMutationResult>;
};

export type BinValidation = {
  __typename?: 'BinValidation';
  isBanned?: Maybe<Scalars['Boolean']['output']>;
  isNonLocalProcessingBanned?: Maybe<Scalars['Boolean']['output']>;
};

export type BioGeneration = {
  __typename?: 'BioGeneration';
  available: Scalars['Boolean']['output'];
  form: BioGenerationForm;
  left: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  reason?: Maybe<BioGenerationReason>;
};

export enum BioGenerationButtonKey {
  CHARACTER = 'CHARACTER',
  FUNNY = 'FUNNY',
  HOBBY = 'HOBBY',
  MINIMIZE = 'MINIMIZE',
}

export type BioGenerationButtons = {
  __typename?: 'BioGenerationButtons';
  key: BioGenerationButtonKey;
  translation: Scalars['String']['output'];
};

export type BioGenerationForm = {
  __typename?: 'BioGenerationForm';
  interest: Array<BioGenerationInterest>;
  nature: Array<BioGenerationNature>;
};

export type BioGenerationInterest = {
  __typename?: 'BioGenerationInterest';
  key: BioGenerationInterestKey;
  translation: Scalars['String']['output'];
};

export enum BioGenerationInterestKey {
  AMERICAN_FOOTBALL = 'AMERICAN_FOOTBALL',
  ASTRONOMY = 'ASTRONOMY',
  BARBECUING_AND_GRILLING = 'BARBECUING_AND_GRILLING',
  BASEBALL = 'BASEBALL',
  BASKETBALL = 'BASKETBALL',
  CAMPING = 'CAMPING',
  CARD_GAMES = 'CARD_GAMES',
  COLLECTING = 'COLLECTING',
  COOKING = 'COOKING',
  CREATIVE_WRITING = 'CREATIVE_WRITING',
  CYCLING = 'CYCLING',
  DANCING = 'DANCING',
  DIY_HOME_PROJECTS = 'DIY_HOME_PROJECTS',
  DRAWING = 'DRAWING',
  FISHING = 'FISHING',
  GARDENING = 'GARDENING',
  HIKING = 'HIKING',
  MARTIAL_ARTS = 'MARTIAL_ARTS',
  MEDITATION = 'MEDITATION',
  PAINTING = 'PAINTING',
  PHOTOGRAPHY = 'PHOTOGRAPHY',
  PLAYING_MUSICAL_INSTRUMENTS = 'PLAYING_MUSICAL_INSTRUMENTS',
  RANDOM = 'RANDOM',
  READING = 'READING',
  SWIMMING = 'SWIMMING',
  TRAVELING = 'TRAVELING',
  VIDEO_GAMING = 'VIDEO_GAMING',
  WATCHING_MOVIES_AND_TV_SERIES = 'WATCHING_MOVIES_AND_TV_SERIES',
  WEIGHTLIFTING = 'WEIGHTLIFTING',
  WINE_OR_BEER_TASTING = 'WINE_OR_BEER_TASTING',
  WOODWORKING = 'WOODWORKING',
  WRITING = 'WRITING',
  YOGA = 'YOGA',
}

export type BioGenerationNature = {
  __typename?: 'BioGenerationNature';
  key: BioGenerationNatureKey;
  translation: Scalars['String']['output'];
};

export enum BioGenerationNatureKey {
  ADVENTUROUS = 'ADVENTUROUS',
  AMBIVERTED = 'AMBIVERTED',
  ANALYTICAL = 'ANALYTICAL',
  ASSERTIVE = 'ASSERTIVE',
  AUTHORITATIVE = 'AUTHORITATIVE',
  CAREFREE = 'CAREFREE',
  CAUTIOUS = 'CAUTIOUS',
  CHARISMATIC = 'CHARISMATIC',
  COMPASSIONATE = 'COMPASSIONATE',
  COMPETITIVE = 'COMPETITIVE',
  CONFIDENT = 'CONFIDENT',
  CREATIVE = 'CREATIVE',
  CURIOUS = 'CURIOUS',
  DETERMINED = 'DETERMINED',
  EASYGOING = 'EASYGOING',
  EMPATHETIC = 'EMPATHETIC',
  ENERGETIC = 'ENERGETIC',
  FLEXIBLE = 'FLEXIBLE',
  FRIENDLY = 'FRIENDLY',
  GENEROUS = 'GENEROUS',
  HARDWORKING = 'HARDWORKING',
  HUMBLE = 'HUMBLE',
  INTROVERTED = 'INTROVERTED',
  LOYAL = 'LOYAL',
  OPEN_MINDED = 'OPEN_MINDED',
  PASSIONATE = 'PASSIONATE',
  RANDOM = 'RANDOM',
  REALISTIC = 'REALISTIC',
  REBELLIOUS = 'REBELLIOUS',
  SHY = 'SHY',
  SPONTANEOUS = 'SPONTANEOUS',
}

export enum BioGenerationReason {
  LIMIT_REACHED = 'LIMIT_REACHED',
  NOT_ALLOWED = 'NOT_ALLOWED',
}

export type BioGenerationV2 = {
  __typename?: 'BioGenerationV2';
  available: Scalars['Boolean']['output'];
  buttons: Array<BioGenerationButtons>;
  historyLen: Scalars['Int']['output'];
  left: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  reason?: Maybe<BioGenerationReason>;
};

export type BioGenerationV2Response = {
  __typename?: 'BioGenerationV2Response';
  historyLen: Scalars['Int']['output'];
  left: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  reason: Scalars['String']['output'];
  variant: Scalars['String']['output'];
};

export type BlockDataMap = {
  __typename?: 'BlockDataMap';
  additionalPackages?: Maybe<Array<Maybe<AdditionalPackage>>>;
  complianceStatement?: Maybe<Scalars['String']['output']>;
  consumerRegulations?: Maybe<Scalars['String']['output']>;
  copyright?: Maybe<Copyright>;
  currencyConvertStatement?: Maybe<Scalars['String']['output']>;
  dnsmpiLink?: Maybe<Scalars['String']['output']>;
  features28DaysRepeatNoticePackageDescription?: Maybe<
    Scalars['String']['output']
  >;
  footerLicense?: Maybe<FooterLicense>;
  guaranteesAndReturnsStatement?: Maybe<Scalars['String']['output']>;
  hotlineCreditCard?: Maybe<HotlineCreditCard>;
  microFeatureDeclineText?: Maybe<Scalars['String']['output']>;
  oneClickFlowWith3ds?: Maybe<Scalars['String']['output']>;
  oneClickFlowWithCvv?: Maybe<Scalars['String']['output']>;
  packageDescriptions?: Maybe<Array<Maybe<PackageDescription>>>;
  securityLogos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  textBillingPolicy?: Maybe<TextBillingPolicy>;
  textBillingPolicyForTargetSite?: Maybe<TextBillingPolicy>;
  textCoinsAutofillAgreement?: Maybe<Scalars['String']['output']>;
  textSepaBillingPolicy?: Maybe<TextBillingPolicy>;
};

export type Blocked = {
  __typename?: 'Blocked';
  heMe?: Maybe<Scalars['Boolean']['output']>;
  iAm?: Maybe<Scalars['Boolean']['output']>;
};

export type Blog = {
  __typename?: 'Blog';
  featured?: Maybe<Array<Maybe<Post>>>;
  regular?: Maybe<Array<Maybe<Post>>>;
};

export type BlogFeaturedArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type BlogRegularArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export enum BloodTypeAttribute {
  A = 'A',
  AB = 'AB',
  B = 'B',
  NOT_GIVEN = 'NOT_GIVEN',
  O = 'O',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
}

export type BloodTypeDictionary = {
  __typename?: 'BloodTypeDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<BloodTypeAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type BloodTypeUserAttributeDictionary = {
  __typename?: 'BloodTypeUserAttributeDictionary';
  key?: Maybe<BloodTypeAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type BooleanMutationResult = {
  __typename?: 'BooleanMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<Scalars['Boolean']['output']>;
};

export type BrowserDetailsInput = {
  colorDepth: Scalars['Int']['input'];
  javaEnabled: Scalars['Boolean']['input'];
  javaScriptEnabled: Scalars['Boolean']['input'];
  language: Scalars['String']['input'];
  screenHeight: Scalars['Int']['input'];
  screenWidth: Scalars['Int']['input'];
  tz: Scalars['Int']['input'];
  userAgent: Scalars['String']['input'];
};

export enum BuildAttribute {
  ATHLETIC = 'ATHLETIC',
  AVERAGE = 'AVERAGE',
  BIG_BEAUTIFUL = 'BIG_BEAUTIFUL',
  CURVY = 'CURVY',
  NOT_GIVEN = 'NOT_GIVEN',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  SLIM = 'SLIM',
}

export type BuildDictionary = {
  __typename?: 'BuildDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<BuildAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type BuildUserAttributeDictionary = {
  __typename?: 'BuildUserAttributeDictionary';
  key?: Maybe<BuildAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum Button {
  RMBundle1s = 'RMBundle1s',
  RMBundle2s = 'RMBundle2s',
  buy3DayGet1Week = 'buy3DayGet1Week',
  buyOnePlusOne = 'buyOnePlusOne',
  extra_discount = 'extra_discount',
  interactiveLike = 'interactiveLike',
  likeAction = 'likeAction',
  pma = 'pma',
  priorityMan = 'priorityMan',
  profilesView = 'profilesView',
  search2x = 'search2x',
  spinNow = 'spinNow',
}

export type ButtonTitle = {
  __typename?: 'ButtonTitle';
  nextStep?: Maybe<Scalars['String']['output']>;
  pay?: Maybe<Scalars['String']['output']>;
  payButtonInfoText?: Maybe<Scalars['String']['output']>;
};

export type Buy3DayGet1Week = {
  __typename?: 'Buy3DayGet1Week';
  /** @deprecated use money instead */
  amount?: Maybe<Scalars['String']['output']>;
  /** @deprecated use money instead */
  currency?: Maybe<Currency>;
  enabled: Scalars['Boolean']['output'];
  money?: Maybe<Price>;
  /** @deprecated use money instead */
  price?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<Maybe<UserData>>>;
  via?: Maybe<Scalars['String']['output']>;
};

export type BuyOnePlusOne = {
  __typename?: 'BuyOnePlusOne';
  /** @deprecated use money instead */
  amount?: Maybe<Scalars['String']['output']>;
  /** @deprecated use money instead */
  currency?: Maybe<Currency>;
  enabled: Scalars['Boolean']['output'];
  money?: Maybe<Price>;
  /** @deprecated use money instead */
  price?: Maybe<Scalars['String']['output']>;
  via?: Maybe<Scalars['String']['output']>;
};

export type CallMeBack = {
  __typename?: 'CallMeBack';
  isAllowedAskMsisdn?: Maybe<Scalars['Boolean']['output']>;
  userMsisdn?: Maybe<Scalars['String']['output']>;
};

export type CallMeBackIsAllowedAskMsisdnArgs = {
  via?: InputMaybe<ViaEnum>;
};

export enum CallMeBackErrorTypeEnum {
  LogicError = 'LogicError',
  ValidationError = 'ValidationError',
}

export type CallMeBackResult = {
  __typename?: 'CallMeBackResult';
  errorType?: Maybe<CallMeBackErrorTypeEnum>;
  status?: Maybe<Scalars['Boolean']['output']>;
};

export type CallMeBackResultMutationResult = {
  __typename?: 'CallMeBackResultMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<CallMeBackResult>;
};

export type CanReadNotification = {
  __typename?: 'CanReadNotification';
  allowed: Scalars['Boolean']['output'];
  via?: Maybe<Scalars['String']['output']>;
};

export type CancelSubscription = {
  __typename?: 'CancelSubscription';
  datingCoursePrice?: Maybe<Price>;
  datingCourseUrl?: Maybe<Scalars['String']['output']>;
  firstStep: Scalars['String']['output'];
  isCancelViaEmailCode: Scalars['Boolean']['output'];
  isCancelViaPhoneCode: Scalars['Boolean']['output'];
  isCodeSent: Scalars['Boolean']['output'];
  isHotlineCallLegal: Scalars['Boolean']['output'];
  isProfileStatusHidden: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  preventSubscriptionCancelTextKey?: Maybe<Scalars['String']['output']>;
  reasons?: Maybe<Array<Maybe<CancellationReasons>>>;
  subscriptionExpireDate?: Maybe<Array<Maybe<SubscriptionExpireDate>>>;
  supportEmail?: Maybe<Scalars['String']['output']>;
  via: Scalars['String']['output'];
};

export type CancelSubscriptionIsCancelViaEmailCodeArgs = {
  subscriptionId?: InputMaybe<Scalars['Int']['input']>;
};

export type CancelSubscriptionIsCancelViaPhoneCodeArgs = {
  subscriptionId?: InputMaybe<Scalars['Int']['input']>;
};

export type CancelSubscriptionPreventSubscriptionCancelTextKeyArgs = {
  subscriptionId: Scalars['Int']['input'];
};

export type CancelSubscriptionMutation = {
  __typename?: 'CancelSubscriptionMutation';
  areYouSure?: Maybe<NextStepMutationResult>;
  cancelByEmail?: Maybe<NextStepMutationResult>;
  cancellationCode?: Maybe<NextStepMutationResult>;
  cancellationReason?: Maybe<NextStepMutationResult>;
  checkPassword?: Maybe<NextStepMutationResult>;
  datingCourse?: Maybe<NextStepMutationResult>;
  default?: Maybe<NextStepMutationResult>;
  emailSecurityCheck?: Maybe<NextStepMutationResult>;
  newSearch?: Maybe<NextStepMutationResult>;
  preventSubscriptionCancel?: Maybe<NextStepMutationResult>;
  reSubscribeRepeat?: Maybe<NextStepMutationResult>;
  safeMode?: Maybe<NextStepMutationResult>;
  sendCancellationCodeByEmail?: Maybe<NextStepMutationResult>;
  simple?: Maybe<SimpleCancelSubscriptionMutationResult>;
  simpleReSubscribeRepeat?: Maybe<BooleanMutationResult>;
  statusMembershipAnswer?: Maybe<NextStepMutationResult>;
  stayOnSite?: Maybe<NextStepMutationResult>;
  stillCancel?: Maybe<NextStepMutationResult>;
  stop?: Maybe<NextStepMutationResult>;
  transfer?: Maybe<NextStepMutationResult>;
  userStory?: Maybe<NextStepMutationResult>;
};

export type CancelSubscriptionMutationAreYouSureArgs = {
  areYouSureAnswer: AreYouSureAnswer;
  statusMembershipAnswer: StatusMembershipAnswer;
  subscriptionId: Scalars['Int']['input'];
  userReasonAnswer: Scalars['String']['input'];
};

export type CancelSubscriptionMutationCancelByEmailArgs = {
  subscriptionId: Scalars['Int']['input'];
};

export type CancelSubscriptionMutationCancellationCodeArgs = {
  code: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['String']['input']>;
  statusMembershipAnswer: StatusMembershipAnswer;
  subscriptionId?: InputMaybe<Scalars['Int']['input']>;
};

export type CancelSubscriptionMutationCancellationReasonArgs = {
  statusMembershipAnswer: StatusMembershipAnswer;
  userReasonAnswer: Scalars['String']['input'];
};

export type CancelSubscriptionMutationCheckPasswordArgs = {
  password: Scalars['String']['input'];
  subscriptionId?: InputMaybe<Scalars['Int']['input']>;
};

export type CancelSubscriptionMutationDefaultArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
};

export type CancelSubscriptionMutationEmailSecurityCheckArgs = {
  email: Scalars['String']['input'];
  statusMembershipAnswer: StatusMembershipAnswer;
  subscriptionId: Scalars['Int']['input'];
  userReasonAnswer: Scalars['String']['input'];
};

export type CancelSubscriptionMutationNewSearchArgs = {
  statusMembershipAnswer: StatusMembershipAnswer;
};

export type CancelSubscriptionMutationReSubscribeRepeatArgs = {
  statusMembershipAnswer: StatusMembershipAnswer;
  stayOnSite: Scalars['Boolean']['input'];
  subscriptionId: Scalars['Int']['input'];
  userReasonAnswer: Scalars['String']['input'];
};

export type CancelSubscriptionMutationSafeModeArgs = {
  safeMode: SafeModeEnum;
};

export type CancelSubscriptionMutationSendCancellationCodeByEmailArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};

export type CancelSubscriptionMutationSimpleArgs = {
  orderId: Scalars['String']['input'];
  processor?: InputMaybe<Scalars['String']['input']>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  via?: InputMaybe<Scalars['String']['input']>;
};

export type CancelSubscriptionMutationSimpleReSubscribeRepeatArgs = {
  orderId?: InputMaybe<Scalars['String']['input']>;
  via?: InputMaybe<Scalars['String']['input']>;
};

export type CancelSubscriptionMutationStatusMembershipAnswerArgs = {
  answer: StatusMembershipAnswer;
};

export type CancelSubscriptionMutationStayOnSiteArgs = {
  statusMembershipAnswer: StatusMembershipAnswer;
  stayOnSite: Scalars['Boolean']['input'];
};

export type CancelSubscriptionMutationStillCancelArgs = {
  step: Scalars['String']['input'];
};

export type CancelSubscriptionMutationStopArgs = {
  areYouSureAnswer?: InputMaybe<AreYouSureAnswer>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  statusMembershipAnswer: StatusMembershipAnswer;
  userReasonAnswer?: InputMaybe<Scalars['String']['input']>;
};

export type CancelSubscriptionMutationTransferArgs = {
  statusMembershipAnswer: StatusMembershipAnswer;
};

export type CancelSubscriptionMutationUserStoryArgs = {
  statusMembershipAnswer?: InputMaybe<StatusMembershipAnswer>;
  userStory: Scalars['String']['input'];
};

export type CancelViaEmail = {
  __typename?: 'CancelViaEmail';
  email: Scalars['String']['output'];
};

export type CancellationReasons = {
  __typename?: 'CancellationReasons';
  children?: Maybe<Array<Maybe<Reason>>>;
  main?: Maybe<Reason>;
};

export type Captcha = {
  __typename?: 'Captcha';
  publicKey?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type CaptchaReasonArgs = {
  protectType: CaptchaProtectTypeEnum;
};

export enum CaptchaProtectTypeEnum {
  login = 'login',
  onPage = 'onPage',
  passwordRecovery = 'passwordRecovery',
  registration = 'registration',
  viewProfile = 'viewProfile',
}

export type Card = {
  __typename?: 'Card';
  bin?: Maybe<Scalars['String']['output']>;
  cardId?: Maybe<Scalars['String']['output']>;
  debitType?: Maybe<Scalars['String']['output']>;
  financialServicePlatform?: Maybe<Scalars['String']['output']>;
  maskedCardHolderName?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  oneClickFlow?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CardBinInfo = {
  __typename?: 'CardBinInfo';
  cardTypeLogo?: Maybe<Scalars['String']['output']>;
  debitType?: Maybe<Scalars['String']['output']>;
  financialServicePlatform?: Maybe<Scalars['String']['output']>;
};

export type CardDataInput = {
  cardHolder: Scalars['String']['input'];
  cardNumber: Scalars['Int']['input'];
  expirationDateM: Scalars['Int']['input'];
  expirationDateY: Scalars['Int']['input'];
  securityNumber: Scalars['Int']['input'];
};

export enum CardNetwork {
  AMEX = 'AMEX',
  DISCOVER = 'DISCOVER',
  INTERAC = 'INTERAC',
  JCB = 'JCB',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
}

export type CardTypeRule = {
  __typename?: 'CardTypeRule';
  cardType?: Maybe<Scalars['String']['output']>;
  rule?: Maybe<Scalars['String']['output']>;
};

export enum CardWalletAdditionalFieldEnum {
  address = 'address',
  city = 'city',
  country = 'country',
  country_code = 'country_code',
  email = 'email',
  name_first = 'name_first',
  name_last = 'name_last',
  phone_number = 'phone_number',
  postal_code = 'postal_code',
}

export type CardWalletDisplaySettings = {
  __typename?: 'CardWalletDisplaySettings';
  applePayDisplaySettings?: Maybe<ApplePayDisplaySettings>;
  googlePayDisplaySettings?: Maybe<GooglePayDisplaySettings>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['String']['output'];
  subjects?: Maybe<Array<Maybe<Subject>>>;
  title: Scalars['String']['output'];
};

export enum CharacteristicsAttribute {
  ADVENTUROUS = 'ADVENTUROUS',
  BITCH = 'BITCH',
  BITCHY = 'BITCHY',
  CHAOTIC = 'CHAOTIC',
  CHEEKY = 'CHEEKY',
  COSY = 'COSY',
  CRAZY = 'CRAZY',
  CUDDLY = 'CUDDLY',
  DIRTY = 'DIRTY',
  EDUCATED = 'EDUCATED',
  ENJOYING_SHOW_OFF = 'ENJOYING_SHOW_OFF',
  FAITHFUL = 'FAITHFUL',
  FRIENDLY = 'FRIENDLY',
  FUNNY = 'FUNNY',
  HONEST = 'HONEST',
  KEEN_TO_EXPERIMENT = 'KEEN_TO_EXPERIMENT',
  LAZY = 'LAZY',
  LOVINGLY = 'LOVINGLY',
  MOODY = 'MOODY',
  NAIVE = 'NAIVE',
  NOT_GIVEN = 'NOT_GIVEN',
  NYMPHOMANIAC = 'NYMPHOMANIAC',
  PASSIONATE = 'PASSIONATE',
  PICKY = 'PICKY',
  ROMANTIC = 'ROMANTIC',
  SENSITIVE = 'SENSITIVE',
  SHY = 'SHY',
  SOPHISTICATED = 'SOPHISTICATED',
  SPONTANEOUS = 'SPONTANEOUS',
  TENDER = 'TENDER',
  WILD = 'WILD',
}

export type CharacteristicsDictionary = {
  __typename?: 'CharacteristicsDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<CharacteristicsAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type CharacteristicsUserAttributeDictionary = {
  __typename?: 'CharacteristicsUserAttributeDictionary';
  key?: Maybe<CharacteristicsAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type ChatRoomMessage = {
  __typename?: 'ChatRoomMessage';
  direction: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isDeleted: Scalars['Boolean']['output'];
  senderId: Scalars['String']['output'];
  text: Scalars['String']['output'];
  timestamp: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type Chatrooms = {
  __typename?: 'Chatrooms';
  activeRecipientsTab?: Maybe<Scalars['String']['output']>;
  banInfo?: Maybe<BanInfo>;
  banList?: Maybe<Array<Maybe<BannedUser>>>;
  canDeleteMessages: Scalars['Boolean']['output'];
  history?: Maybe<Array<Maybe<ChatRoomMessage>>>;
  isEnabled: Scalars['Boolean']['output'];
  messages?: Maybe<Array<Maybe<ChatRoomMessage>>>;
  recipientsIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  roomList?: Maybe<Array<Maybe<Room>>>;
};

export type ChatroomsBanListArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type ChatroomsHistoryArgs = {
  id: Scalars['String']['input'];
};

export type ChatroomsMutations = {
  __typename?: 'ChatroomsMutations';
  join?: Maybe<IdsErrorsResponse>;
  leave?: Maybe<ResultErrorsResponse>;
};

export type ChatroomsMutationsJoinArgs = {
  id: Scalars['ID']['input'];
};

export type ChatroomsMutationsLeaveArgs = {
  id: Scalars['ID']['input'];
};

export type CheckExpandSearchLimit = {
  __typename?: 'CheckExpandSearchLimit';
  enabled: Scalars['Boolean']['output'];
  expireAt?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use expireAt instead */
  expiredAt?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<Maybe<UserData>>>;
};

export type CheckExpandSearchLimitUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type CheckProfiles = {
  __typename?: 'CheckProfiles';
  enabled: Scalars['Boolean']['output'];
  expireAt?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use expireAt instead */
  expiredAt?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<Maybe<UserData>>>;
};

export type CheckProfilesUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export enum ChildrenAttribute {
  I_DON_T_HAVE_CHILDREN = 'I_DON_T_HAVE_CHILDREN',
  I_DON_T_WANT_CHILDREN = 'I_DON_T_WANT_CHILDREN',
  I_HAVE_CHILDREN = 'I_HAVE_CHILDREN',
  I_WANT_CHILDREN = 'I_WANT_CHILDREN',
  NOT_GIVEN = 'NOT_GIVEN',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  THEY_LEFT_THE_HOME = 'THEY_LEFT_THE_HOME',
}

export type ChildrenDictionary = {
  __typename?: 'ChildrenDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<ChildrenAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type ChildrenUserAttributeDictionary = {
  __typename?: 'ChildrenUserAttributeDictionary';
  key?: Maybe<ChildrenAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type ClientRoute = {
  __typename?: 'ClientRoute';
  current: Scalars['String']['output'];
  previous: Scalars['String']['output'];
};

export type Coins = {
  __typename?: 'Coins';
  becomeTrustedOffer?: Maybe<BecomeTrustedOffer>;
};

export type CommDooSepaSettings = {
  __typename?: 'CommDooSepaSettings';
  isPrefilled: Scalars['Boolean']['output'];
  maskedFields?: Maybe<MaskedUserFields>;
  sepaAutofillAccess: Scalars['Boolean']['output'];
};

export enum CommunicationPhotoRestrictionReasonEnum {
  NO_PHOTO = 'NO_PHOTO',
  WAIT_FOR_APPROVE = 'WAIT_FOR_APPROVE',
}

export enum ComplaintsActions {
  addUserComplaint = 'addUserComplaint',
  cancelScammerCandidate = 'cancelScammerCandidate',
  changeReasonId = 'changeReasonId',
  reportToAntiScam = 'reportToAntiScam',
}

export type ComplianceLink = {
  __typename?: 'ComplianceLink';
  autologinUrl?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
};

export type ConceptInfo = {
  __typename?: 'ConceptInfo';
  content?: Maybe<Content>;
  name?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<Maybe<UserData>>>;
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type Config = {
  __typename?: 'Config';
  canViewStatusIsRead?: Maybe<Scalars['Boolean']['output']>;
  maxMessageLength: Scalars['Int']['output'];
  shortTimeNotation?: Maybe<Scalars['Boolean']['output']>;
};

export type ConfirmMutation = {
  __typename?: 'ConfirmMutation';
  activationShortCode?: Maybe<ActivationShortCodeMutationResult>;
  remindPassword?: Maybe<StringMutationResult>;
  resendConfirmMail?: Maybe<ResendConfirmMailMutationResult>;
};

export type ConfirmMutationActivationShortCodeArgs = {
  code: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
};

export type ConfirmMutationRemindPasswordArgs = {
  email: Scalars['String']['input'];
};

export type ConfirmMutationResendConfirmMailArgs = {
  action: Scalars['String']['input'];
  email: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum ConfirmationTypeEnum {
  longText = 'longText',
  withDiscount = 'withDiscount',
  withFreeDay = 'withFreeDay',
  withFreeDayWithIcon = 'withFreeDayWithIcon',
  withOneDay = 'withOneDay',
}

export type Contact = {
  __typename?: 'Contact';
  categories?: Maybe<Array<Maybe<Category>>>;
  contactsForCommunication?: Maybe<ContactsForCommunication>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  isAllowedMerchantInfo?: Maybe<Scalars['Boolean']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type ContactUsPayload = {
  __typename?: 'ContactUsPayload';
  categoryId?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<MutationPayloadErrors>;
  message?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['Int']['output']>;
};

export type Contacts = {
  __typename?: 'Contacts';
  address: Scalars['String']['output'];
  company: Scalars['String']['output'];
  emails?: Maybe<Emails>;
  phoneNumber: Scalars['String']['output'];
  registrationNumber: Scalars['String']['output'];
  vatNumber: Scalars['String']['output'];
};

export type ContactsForCommunication = {
  __typename?: 'ContactsForCommunication';
  addressString?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  tel?: Maybe<Scalars['String']['output']>;
};

export type Content = {
  __typename?: 'Content';
  name: Scalars['String']['output'];
  texts?: Maybe<Array<Maybe<Text>>>;
};

export type ConvenienceStore = {
  __typename?: 'ConvenienceStore';
  name: Scalars['String']['output'];
  storeId: Scalars['Int']['output'];
};

export type Conversationalist = {
  __typename?: 'Conversationalist';
  usersForDialogue: Array<Maybe<UserData>>;
};

export type Copyright = {
  __typename?: 'Copyright';
  copyrightCompanyName?: Maybe<Scalars['String']['output']>;
  copyrightUnifyData?: Maybe<CopyrightUnifyData>;
  disclaimerFrontendData?: Maybe<DisclaimerFrontendData>;
};

export type CopyrightUnifyData = {
  __typename?: 'CopyrightUnifyData';
  companyOperator?: Maybe<Scalars['String']['output']>;
  isPlainText?: Maybe<Scalars['Boolean']['output']>;
  unifyCopyrightText?: Maybe<Scalars['String']['output']>;
};

export type Coregistration = {
  __typename?: 'Coregistration';
  iframeUrl?: Maybe<Scalars['String']['output']>;
  openInactiveTab: Scalars['Boolean']['output'];
  popupImages: Array<Maybe<Scalars['String']['output']>>;
  registerInNewTab: Scalars['Boolean']['output'];
  requestParams: Array<Maybe<RequestParam>>;
  successText?: Maybe<Scalars['String']['output']>;
  systemUrl?: Maybe<Scalars['String']['output']>;
  terms: Scalars['String']['output'];
  text: Scalars['String']['output'];
  useClientSessionStorage: Scalars['Boolean']['output'];
  useSmartElementSystem: Scalars['Boolean']['output'];
  validatePostMessageData?: Maybe<Array<Maybe<PostMessageValidationRule>>>;
};

export type CoregistrationPayload = {
  __typename?: 'CoregistrationPayload';
  autologin?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<MutationPayloadErrors>;
};

export type CountFreeMessages = {
  __typename?: 'CountFreeMessages';
  countFreeMessages?: Maybe<Scalars['Int']['output']>;
  show?: Maybe<Scalars['Boolean']['output']>;
};

export type CounterParam = {
  isNew: Scalars['Boolean']['input'];
  limit: Scalars['Int']['input'];
  type?: InputMaybe<ActivityTypeEnum>;
};

export type CountryDictionary = {
  __typename?: 'CountryDictionary';
  code?: Maybe<CountryType>;
  withState?: Maybe<Scalars['Boolean']['output']>;
};

export enum CountryType {
  ABW = 'ABW',
  AFG = 'AFG',
  AGO = 'AGO',
  AIA = 'AIA',
  ALA = 'ALA',
  ALB = 'ALB',
  AND = 'AND',
  ANT = 'ANT',
  ARE = 'ARE',
  ARG = 'ARG',
  ARM = 'ARM',
  ASM = 'ASM',
  ATA = 'ATA',
  ATF = 'ATF',
  ATG = 'ATG',
  AUS = 'AUS',
  AUT = 'AUT',
  AZE = 'AZE',
  BDI = 'BDI',
  BEL = 'BEL',
  BEN = 'BEN',
  BES = 'BES',
  BFA = 'BFA',
  BGD = 'BGD',
  BGR = 'BGR',
  BHR = 'BHR',
  BHS = 'BHS',
  BIH = 'BIH',
  BLM = 'BLM',
  BLR = 'BLR',
  BLZ = 'BLZ',
  BMU = 'BMU',
  BOL = 'BOL',
  BRA = 'BRA',
  BRB = 'BRB',
  BRN = 'BRN',
  BTN = 'BTN',
  BVT = 'BVT',
  BWA = 'BWA',
  CAF = 'CAF',
  CAN = 'CAN',
  CCK = 'CCK',
  CHE = 'CHE',
  CHL = 'CHL',
  CHN = 'CHN',
  CIV = 'CIV',
  CMR = 'CMR',
  COD = 'COD',
  COG = 'COG',
  COK = 'COK',
  COL = 'COL',
  COM = 'COM',
  CPV = 'CPV',
  CRI = 'CRI',
  CUB = 'CUB',
  CUW = 'CUW',
  CXR = 'CXR',
  CYM = 'CYM',
  CYP = 'CYP',
  CZE = 'CZE',
  DEU = 'DEU',
  DJI = 'DJI',
  DMA = 'DMA',
  DNK = 'DNK',
  DOM = 'DOM',
  DZA = 'DZA',
  ECU = 'ECU',
  EGY = 'EGY',
  ERI = 'ERI',
  ESH = 'ESH',
  ESP = 'ESP',
  EST = 'EST',
  ETH = 'ETH',
  FIN = 'FIN',
  FJI = 'FJI',
  FLK = 'FLK',
  FRA = 'FRA',
  FRO = 'FRO',
  FSM = 'FSM',
  GAB = 'GAB',
  GBR = 'GBR',
  GEO = 'GEO',
  GGY = 'GGY',
  GHA = 'GHA',
  GIB = 'GIB',
  GIN = 'GIN',
  GLP = 'GLP',
  GMB = 'GMB',
  GNB = 'GNB',
  GNQ = 'GNQ',
  GRC = 'GRC',
  GRD = 'GRD',
  GRL = 'GRL',
  GTM = 'GTM',
  GUF = 'GUF',
  GUM = 'GUM',
  GUY = 'GUY',
  HKG = 'HKG',
  HMD = 'HMD',
  HND = 'HND',
  HRV = 'HRV',
  HTI = 'HTI',
  HUN = 'HUN',
  IDN = 'IDN',
  IMN = 'IMN',
  IND = 'IND',
  IOT = 'IOT',
  IRL = 'IRL',
  IRN = 'IRN',
  IRQ = 'IRQ',
  ISL = 'ISL',
  ISR = 'ISR',
  ITA = 'ITA',
  JAM = 'JAM',
  JEY = 'JEY',
  JOR = 'JOR',
  JPN = 'JPN',
  KAZ = 'KAZ',
  KEN = 'KEN',
  KGZ = 'KGZ',
  KHM = 'KHM',
  KIR = 'KIR',
  KNA = 'KNA',
  KOR = 'KOR',
  KWT = 'KWT',
  LAO = 'LAO',
  LBN = 'LBN',
  LBR = 'LBR',
  LBY = 'LBY',
  LCA = 'LCA',
  LIE = 'LIE',
  LKA = 'LKA',
  LSO = 'LSO',
  LTU = 'LTU',
  LUX = 'LUX',
  LVA = 'LVA',
  MAC = 'MAC',
  MAF = 'MAF',
  MAR = 'MAR',
  MCO = 'MCO',
  MDA = 'MDA',
  MDG = 'MDG',
  MDV = 'MDV',
  MEX = 'MEX',
  MHL = 'MHL',
  MKD = 'MKD',
  MLI = 'MLI',
  MLT = 'MLT',
  MMR = 'MMR',
  MNE = 'MNE',
  MNG = 'MNG',
  MNP = 'MNP',
  MOZ = 'MOZ',
  MRT = 'MRT',
  MSR = 'MSR',
  MTQ = 'MTQ',
  MUS = 'MUS',
  MWI = 'MWI',
  MYS = 'MYS',
  MYT = 'MYT',
  NAM = 'NAM',
  NCL = 'NCL',
  NER = 'NER',
  NFK = 'NFK',
  NGA = 'NGA',
  NIC = 'NIC',
  NIU = 'NIU',
  NLD = 'NLD',
  NOR = 'NOR',
  NPL = 'NPL',
  NRU = 'NRU',
  NZL = 'NZL',
  OMN = 'OMN',
  PAK = 'PAK',
  PAN = 'PAN',
  PCN = 'PCN',
  PER = 'PER',
  PHL = 'PHL',
  PLW = 'PLW',
  PNG = 'PNG',
  POL = 'POL',
  PRI = 'PRI',
  PRK = 'PRK',
  PRT = 'PRT',
  PRY = 'PRY',
  PSE = 'PSE',
  PYF = 'PYF',
  QAT = 'QAT',
  REU = 'REU',
  ROU = 'ROU',
  RUS = 'RUS',
  RWA = 'RWA',
  SAU = 'SAU',
  SCG = 'SCG',
  SDN = 'SDN',
  SEN = 'SEN',
  SGP = 'SGP',
  SGS = 'SGS',
  SHN = 'SHN',
  SJM = 'SJM',
  SLB = 'SLB',
  SLE = 'SLE',
  SLV = 'SLV',
  SMR = 'SMR',
  SOM = 'SOM',
  SPM = 'SPM',
  SRB = 'SRB',
  SSD = 'SSD',
  STP = 'STP',
  SUR = 'SUR',
  SVK = 'SVK',
  SVN = 'SVN',
  SWE = 'SWE',
  SWZ = 'SWZ',
  SXM = 'SXM',
  SYC = 'SYC',
  SYR = 'SYR',
  TCA = 'TCA',
  TCD = 'TCD',
  TGO = 'TGO',
  THA = 'THA',
  TJK = 'TJK',
  TKL = 'TKL',
  TKM = 'TKM',
  TLS = 'TLS',
  TON = 'TON',
  TTO = 'TTO',
  TUN = 'TUN',
  TUR = 'TUR',
  TUV = 'TUV',
  TWN = 'TWN',
  TZA = 'TZA',
  UGA = 'UGA',
  UKR = 'UKR',
  UMI = 'UMI',
  URY = 'URY',
  USA = 'USA',
  UZB = 'UZB',
  VAT = 'VAT',
  VCT = 'VCT',
  VEN = 'VEN',
  VGB = 'VGB',
  VIR = 'VIR',
  VNM = 'VNM',
  VUT = 'VUT',
  WLF = 'WLF',
  WSM = 'WSM',
  XKX = 'XKX',
  YEM = 'YEM',
  ZAF = 'ZAF',
  ZMB = 'ZMB',
  ZWE = 'ZWE',
}

export type Course = {
  __typename?: 'Course';
  coach: HostUser;
  currency: Currency;
  discountPrice?: Maybe<Scalars['Float']['output']>;
  faqs?: Maybe<Array<Maybe<Faq>>>;
  hours?: Maybe<Scalars['Int']['output']>;
  lessons: Array<Maybe<Lesson>>;
  lessonsCount?: Maybe<Scalars['Int']['output']>;
  materials?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  video: Video;
};

export type CreditFeature = {
  __typename?: 'CreditFeature';
  activeFrom?: Maybe<Scalars['String']['output']>;
  creditAmount?: Maybe<Scalars['Int']['output']>;
  expiredAt?: Maybe<Scalars['String']['output']>;
  featureName: Scalars['String']['output'];
  interval: Scalars['Int']['output'];
  packageId: Scalars['Int']['output'];
  payButtonText: Scalars['String']['output'];
  period: Scalars['String']['output'];
};

export type Credits = {
  __typename?: 'Credits';
  autofillSettings: AutofillSettings;
  balance: Scalars['Int']['output'];
  blurMediaIncomeInMessenger: Scalars['Boolean']['output'];
  debtBalance?: Maybe<Scalars['Int']['output']>;
  hasUserDiscreetModeFeature: Scalars['Boolean']['output'];
  isAllowedCreditsRefill: Scalars['Boolean']['output'];
  isAutofillAvailable: Scalars['Boolean']['output'];
  isTopChataholicEnabled: Scalars['Boolean']['output'];
  paidFeatures?: Maybe<UserPaidFeatures>;
  rewardForTasks?: Maybe<RewardForTasks>;
  showAllProfilesInSearchAllowed: Scalars['Boolean']['output'];
  time: Scalars['Int']['output'];
};

export type CreditsBalanceDecrease = {
  __typename?: 'CreditsBalanceDecrease';
  amount?: Maybe<Scalars['Int']['output']>;
  balance?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
};

export type CreditsBalanceIncrease = {
  __typename?: 'CreditsBalanceIncrease';
  amount?: Maybe<Scalars['Int']['output']>;
  balance?: Maybe<Scalars['Int']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['Int']['output']>;
};

export type CreditsEvent = {
  __typename?: 'CreditsEvent';
  balanceDecrease?: Maybe<CreditsBalanceDecrease>;
  balanceIncrease?: Maybe<CreditsBalanceIncrease>;
  debtBalance?: Maybe<Scalars['Int']['output']>;
  openConfirmPopup?: Maybe<Scalars['Boolean']['output']>;
  openExtraCoinsPopup?: Maybe<Scalars['Boolean']['output']>;
  openSpecialOfferPopup?: Maybe<OpenSpecialOfferPopup>;
};

export type CreditsMutation = {
  __typename?: 'CreditsMutation';
  autoRefillBalance?: Maybe<BooleanMutationResult>;
  notifyAboutReadMessage?: Maybe<BooleanMutationResult>;
  openMediaViewWithCredits?: Maybe<BooleanMutationResult>;
  openMessageViewWithCredits?: Maybe<BooleanMutationResult>;
  showAllPrivatePhotos?: Maybe<BooleanMutationResult>;
  showAllProfilesInSearch?: Maybe<BooleanMutationResult>;
};

export type CreditsMutationAutoRefillBalanceArgs = {
  useAutoBill?: Scalars['Boolean']['input'];
};

export type CreditsMutationOpenMediaViewWithCreditsArgs = {
  mediaId: Scalars['ID']['input'];
  mediaType: ProfileMediaType;
  userId: Scalars['ID']['input'];
};

export type CreditsMutationOpenMessageViewWithCreditsArgs = {
  id: Scalars['ID']['input'];
};

export type CreditsMutationShowAllPrivatePhotosArgs = {
  userId: Scalars['String']['input'];
};

export type CrossVision = {
  __typename?: 'CrossVision';
  available: Scalars['Boolean']['output'];
  hideDomainsInfo: Scalars['Boolean']['output'];
};

export type Currency = {
  __typename?: 'Currency';
  prefix?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type CustomAppearance = {
  __typename?: 'CustomAppearance';
  confirmation: ConfirmationTypeEnum;
  mediaUpload: MediaUploadTypeEnum;
  messenger: Scalars['String']['output'];
};

export type Debtor = {
  __typename?: 'Debtor';
  bankName: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  canCloseDebtorPopup?: Maybe<Scalars['Boolean']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  debtorContact?: Maybe<DebtorContact>;
  fee?: Maybe<Price>;
  iban: Scalars['String']['output'];
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  money?: Maybe<Price>;
  saleTransactionReference?: Maybe<Scalars['String']['output']>;
  status?: Maybe<DebtorStatusEnum>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type DebtorContact = {
  __typename?: 'DebtorContact';
  email?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export enum DebtorStatusEnum {
  cancel = 'cancel',
  expire = 'expire',
  new = 'new',
  partiallyCollected = 'partiallyCollected',
  pending = 'pending',
  readyForCollecting = 'readyForCollecting',
  repay = 'repay',
  sentForCollecting = 'sentForCollecting',
}

export type DeclineVideoGroupEvent = {
  __typename?: 'DeclineVideoGroupEvent';
  error?: Maybe<Scalars['String']['output']>;
  group: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
};

export type Delay = {
  __typename?: 'Delay';
  priority: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
};

export type DeleteConversationResponse = {
  __typename?: 'DeleteConversationResponse';
  error?: Maybe<Scalars['String']['output']>;
  ids?: Maybe<Array<Scalars['String']['output']>>;
};

export type DeletedRoomMessage = {
  __typename?: 'DeletedRoomMessage';
  id: Scalars['String']['output'];
};

export type Descriptor = {
  __typename?: 'Descriptor';
  companyName?: Maybe<Scalars['String']['output']>;
  descriptor?: Maybe<Scalars['String']['output']>;
};

export type DeviceDetection = {
  __typename?: 'DeviceDetection';
  deviceDetectionJs?: Maybe<Scalars['String']['output']>;
  /** @deprecated Uses for deviceDetection testing */
  deviceDetectionTest?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DictionaryAttributes = {
  __typename?: 'DictionaryAttributes';
  bloodType?: Maybe<Array<Maybe<BloodTypeDictionary>>>;
  build?: Maybe<Array<Maybe<BuildDictionary>>>;
  children?: Maybe<Array<Maybe<ChildrenDictionary>>>;
  drink?: Maybe<Array<Maybe<DrinkDictionary>>>;
  eatingHabits?: Maybe<Array<Maybe<EatingHabitsDictionary>>>;
  education?: Maybe<Array<Maybe<EducationDictionary>>>;
  eyeColor?: Maybe<Array<Maybe<EyeColorDictionary>>>;
  fetish?: Maybe<Array<Maybe<FetishDictionary>>>;
  gambling?: Maybe<Array<Maybe<GamblingDictionary>>>;
  genderIdentity?: Maybe<Array<Maybe<GenderIdentityDictionary>>>;
  goal?: Maybe<Array<Maybe<GoalDictionary>>>;
  hairColor?: Maybe<Array<Maybe<HairColorDictionary>>>;
  height?: Maybe<Array<Maybe<HeightDictionary>>>;
  hijabWearing?: Maybe<Array<Maybe<HijabWearingDictionary>>>;
  hobby?: Maybe<Array<Maybe<HobbyDictionary>>>;
  income?: Maybe<Array<Maybe<IncomeDictionary>>>;
  indianCommunity?: Maybe<Array<Maybe<IndianCommunityDictionary>>>;
  islamCult?: Maybe<Array<Maybe<IslamCultDictionary>>>;
  maritalStatus?: Maybe<Array<Maybe<MaritalStatusDictionary>>>;
  moneyRole?: Maybe<Array<Maybe<MoneyRoleDictionary>>>;
  muslimBorn?: Maybe<Array<Maybe<MuslimBornDictionary>>>;
  netWorth?: Maybe<Array<Maybe<NetWorthDictionary>>>;
  occupation?: Maybe<Array<Maybe<OccupationDictionary>>>;
  pierced?: Maybe<Array<Maybe<PiercedDictionary>>>;
  professionId?: Maybe<Array<Maybe<ProfessionIdDictionary>>>;
  race?: Maybe<Array<Maybe<RaceDictionary>>>;
  readQuran?: Maybe<Array<Maybe<ReadQuranDictionary>>>;
  registrationReason?: Maybe<Array<Maybe<RegistrationReasonDictionary>>>;
  religion?: Maybe<Array<Maybe<ReligionDictionary>>>;
  religiousServices?: Maybe<Array<Maybe<ReligiousServicesDictionary>>>;
  religiousValues?: Maybe<Array<Maybe<ReligiousValuesDictionary>>>;
  sexualOrientation?: Maybe<Array<Maybe<SexualOrientationDictionary>>>;
  smoke?: Maybe<Array<Maybe<SmokeDictionary>>>;
  tattoo?: Maybe<Array<Maybe<TattooDictionary>>>;
  tribe?: Maybe<Array<Maybe<TribeDictionary>>>;
  weight?: Maybe<Array<Maybe<WeightDictionary>>>;
  zodiacSign?: Maybe<Array<Maybe<ZodiacSignDictionary>>>;
};

export enum DietAttribute {
  EAT_EVERYTHING = 'EAT_EVERYTHING',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  TRY_TO_EAT_HEALTHILY = 'TRY_TO_EAT_HEALTHILY',
  VEGAN = 'VEGAN',
  VEGETARIAN = 'VEGETARIAN',
}

export type DietDictionary = {
  __typename?: 'DietDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<DietAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type DietUserAttributeDictionary = {
  __typename?: 'DietUserAttributeDictionary';
  key?: Maybe<DietAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum Direction {
  incoming = 'incoming',
  outgoing = 'outgoing',
}

export enum DirectionEnum {
  income = 'income',
  outcome = 'outcome',
}

export type Disapprove = {
  __typename?: 'Disapprove';
  reasons?: Maybe<DisapproveReasons>;
  statuses: DisapproveStatuses;
};

export type DisapproveEvent = {
  __typename?: 'DisapproveEvent';
  id: Scalars['ID']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  screenname?: Maybe<Scalars['String']['output']>;
  textStatus?: Maybe<Scalars['String']['output']>;
  video?: Maybe<Scalars['String']['output']>;
};

export type DisapproveReasons = {
  __typename?: 'DisapproveReasons';
  messenger?: Maybe<Array<Maybe<IntIdElement>>>;
  profileMedia?: Maybe<Array<Maybe<IntIdElement>>>;
  profilePhoto?: Maybe<Array<Maybe<IntIdElement>>>;
  profileVideo?: Maybe<Array<Maybe<IntIdElement>>>;
};

export type DisapproveStatuses = {
  __typename?: 'DisapproveStatuses';
  photo: Scalars['Boolean']['output'];
  screenname: Scalars['Boolean']['output'];
  textStatus: Scalars['Boolean']['output'];
  video: Scalars['Boolean']['output'];
};

export type DisclaimerFrontendData = {
  __typename?: 'DisclaimerFrontendData';
  disclaimerUrl?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type Discount = {
  __typename?: 'Discount';
  buttonType?: Maybe<Button>;
  default?: Maybe<Scalars['Int']['output']>;
  expireAt?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use expireAt instead */
  expiredAt?: Maybe<Scalars['String']['output']>;
  extra?: Maybe<Scalars['Int']['output']>;
  holiday?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<Maybe<UserData>>>;
  via?: Maybe<Scalars['String']['output']>;
};

export type DiscountUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type DiscountMutationResult = {
  __typename?: 'DiscountMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<Discount>;
};

export type DiscreetProfile = {
  __typename?: 'DiscreetProfile';
  opened?: Maybe<Array<Maybe<UserData>>>;
  users?: Maybe<Array<Maybe<UserData>>>;
};

export type DiscreetProfileOpenedArgs = {
  offset?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};

export type DiscreetProfileUsersArgs = {
  size?: Scalars['Int']['input'];
};

export enum DiscreetProfileModeEnum {
  disabled = 'disabled',
  enabled = 'enabled',
}

export type DiscreetProfilePayload = {
  __typename?: 'DiscreetProfilePayload';
  setDiscreetProfileMode?: Maybe<BooleanMutationResult>;
  setOpenedProfile?: Maybe<BooleanMutationResult>;
};

export type DiscreetProfilePayloadSetDiscreetProfileModeArgs = {
  mode?: InputMaybe<DiscreetProfileModeEnum>;
};

export type DiscreetProfilePayloadSetOpenedProfileArgs = {
  userId: Scalars['ID']['input'];
};

export type DisplaySettingData = {
  __typename?: 'DisplaySettingData';
  strict18Years?: Maybe<Scalars['String']['output']>;
  strictAdults?: Maybe<Scalars['String']['output']>;
  strictLegalMode?: Maybe<Scalars['Boolean']['output']>;
  userPublicId?: Maybe<UserPublicId>;
};

export type DisplaySettings = {
  __typename?: 'DisplaySettings';
  availableRoutes: Array<Maybe<Scalars['String']['output']>>;
  countPerSession: Scalars['Int']['output'];
  intervalInSeconds: Scalars['Int']['output'];
};

export type DistanceGroup = {
  __typename?: 'DistanceGroup';
  toMe?: Maybe<Scalars['Int']['output']>;
  toMySearch?: Maybe<Scalars['Int']['output']>;
  toMySearchType?: Maybe<DistanceGroupEnum>;
  type?: Maybe<DistanceGroupEnum>;
};

export enum DistanceGroupEnum {
  less = 'less',
  more = 'more',
}

export enum DocumentStatusEnum {
  approved = 'approved',
  disapproved = 'disapproved',
  notExists = 'notExists',
  pendingToApprove = 'pendingToApprove',
}

export enum DrinkAttribute {
  NO = 'NO',
  NOT_GIVEN = 'NOT_GIVEN',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  YES__REGULARLY = 'YES__REGULARLY',
  YES__SOCIALLY = 'YES__SOCIALLY',
}

export type DrinkDictionary = {
  __typename?: 'DrinkDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<DrinkAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type DrinkUserAttributeDictionary = {
  __typename?: 'DrinkUserAttributeDictionary';
  key?: Maybe<DrinkAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum DrugsAttribute {
  I_LIKE_TO_EXPERIMENT = 'I_LIKE_TO_EXPERIMENT',
  NEVER = 'NEVER',
  NOT_GIVEN = 'NOT_GIVEN',
  OFTEN = 'OFTEN',
  OTHER = 'OTHER',
  SOMETIMES = 'SOMETIMES',
}

export type DrugsDictionary = {
  __typename?: 'DrugsDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<DrugsAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type DrugsUserAttributeDictionary = {
  __typename?: 'DrugsUserAttributeDictionary';
  key?: Maybe<DrugsAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum EatingHabitsAttribute {
  HALAL_FOODS_ALWAYS = 'HALAL_FOODS_ALWAYS',
  HALAL_FOODS_WHEN_I_CAN = 'HALAL_FOODS_WHEN_I_CAN',
  NOT_GIVEN = 'NOT_GIVEN',
  NO_SPECIAL_RESTRICTIONS = 'NO_SPECIAL_RESTRICTIONS',
}

export type EatingHabitsDictionary = {
  __typename?: 'EatingHabitsDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<EatingHabitsAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type EatingHabitsUserAttributeDictionary = {
  __typename?: 'EatingHabitsUserAttributeDictionary';
  key?: Maybe<EatingHabitsAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum EducationAttribute {
  BACHELOR_S_DEGREE = 'BACHELOR_S_DEGREE',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  MASTER_S_DEGREE_DOCTORATE = 'MASTER_S_DEGREE_DOCTORATE',
  NOT_GIVEN = 'NOT_GIVEN',
  NO_DEGREE = 'NO_DEGREE',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  SOME_COLLEGE = 'SOME_COLLEGE',
  STILL_STUDYING = 'STILL_STUDYING',
}

export type EducationDictionary = {
  __typename?: 'EducationDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<EducationAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type EducationUserAttributeDictionary = {
  __typename?: 'EducationUserAttributeDictionary';
  key?: Maybe<EducationAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type EmailData = {
  __typename?: 'EmailData';
  email: Scalars['String']['output'];
  isConfirmed: Scalars['Boolean']['output'];
  isInvalid: Scalars['Boolean']['output'];
  service: Scalars['String']['output'];
};

export type EmailServiceInfo = {
  __typename?: 'EmailServiceInfo';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Emails = {
  __typename?: 'Emails';
  admin?: Maybe<Scalars['String']['output']>;
  bar?: Maybe<Scalars['String']['output']>;
  job?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Scalars['String']['output']>;
};

export enum EnvironmentEnum {
  mobSite = 'mobSite',
  webSite = 'webSite',
}

export type ErrorResponce = {
  __typename?: 'ErrorResponce';
  error?: Maybe<Scalars['String']['output']>;
};

export type EventCategory = {
  __typename?: 'EventCategory';
  eventTypes?: Maybe<Array<Maybe<EventDictionary>>>;
  name: EventDictionary;
};

export type EventCityCategories = {
  __typename?: 'EventCityCategories';
  categories?: Maybe<Array<Maybe<EventCategory>>>;
  city: EventDictionary;
};

export type EventDictionary = {
  __typename?: 'EventDictionary';
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum EventFormat {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export enum EventLabel {
  NEW = 'NEW',
  SALE = 'SALE',
}

export type EventLocation = {
  __typename?: 'EventLocation';
  city: StringIdElement;
  description: Scalars['String']['output'];
  map: EventMapPoint;
  name: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
  /** @deprecated use name and city.value instead */
  venue: Scalars['String']['output'];
};

export type EventMapPoint = {
  __typename?: 'EventMapPoint';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  zoom: Scalars['Int']['output'];
};

export enum EventMatchRelation {
  DISLIKE = 'DISLIKE',
  EMPTY = 'EMPTY',
  LIKE = 'LIKE',
  MATCH = 'MATCH',
}

export type EventMutation = {
  __typename?: 'EventMutation';
  addToBasket?: Maybe<BasketResponseMutationResult>;
  applyPromoCode?: Maybe<BasketResponseMutationResult>;
  changeTicketsAmount?: Maybe<BasketResponseMutationResult>;
  createInvoice?: Maybe<IdMutationResult>;
  readMatches?: Maybe<BooleanMutationResult>;
  removeFromBasket?: Maybe<BasketResponseMutationResult>;
  visitorsIdentification?: Maybe<VisitorsPayload>;
};

export type EventMutationAddToBasketArgs = {
  basketId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  femaleTicketsAmount?: InputMaybe<Scalars['Int']['input']>;
  maleTicketsAmount?: InputMaybe<Scalars['Int']['input']>;
};

export type EventMutationApplyPromoCodeArgs = {
  basketId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  promoCode: Scalars['String']['input'];
  ticketsCount?: InputMaybe<Scalars['Int']['input']>;
};

export type EventMutationChangeTicketsAmountArgs = {
  basketId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  femaleTicketsAmount?: InputMaybe<Scalars['Int']['input']>;
  maleTicketsAmount?: InputMaybe<Scalars['Int']['input']>;
  unlimitedPassApplied?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventMutationCreateInvoiceArgs = {
  events: Array<InputMaybe<InvoiceEvent>>;
};

export type EventMutationReadMatchesArgs = {
  eventId: Scalars['ID']['input'];
};

export type EventMutationRemoveFromBasketArgs = {
  basketId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
};

export type EventMutationVisitorsIdentificationArgs = {
  visitors: Array<InputMaybe<VisitorInput>>;
};

export enum EventOrientation {
  GAY = 'GAY',
  LESBIAN = 'LESBIAN',
  STRAIGHT = 'STRAIGHT',
}

export type EventParamsDictionary = {
  __typename?: 'EventParamsDictionary';
  age: AgeRange;
  offline?: Maybe<Array<Maybe<EventCityCategories>>>;
  online?: Maybe<Array<Maybe<EventCityCategories>>>;
  tags?: Maybe<Array<Maybe<EventSearchTag>>>;
};

export type EventRate = {
  __typename?: 'EventRate';
  completed: Scalars['Boolean']['output'];
  matchRelation: EventMatchRelation;
  refused: Scalars['Boolean']['output'];
};

export type EventSearchParams = {
  __typename?: 'EventSearchParams';
  age?: Maybe<Scalars['Int']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  format?: Maybe<EventFormat>;
  timestampFrom?: Maybe<Scalars['Int']['output']>;
  timestampTo?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type EventSearchParamsInput = {
  age?: InputMaybe<Scalars['Int']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<EventFormat>;
  timestampFrom?: InputMaybe<Scalars['Int']['input']>;
  timestampTo?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type EventSearchTag = {
  __typename?: 'EventSearchTag';
  icon: Scalars['String']['output'];
  name: Scalars['String']['output'];
  params: EventSearchParams;
};

export enum EventStatus {
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
  NEW = 'NEW',
}

export type EventStep = {
  __typename?: 'EventStep';
  name: Scalars['String']['output'];
  time: Scalars['String']['output'];
};

export type EventTicketsLeft = {
  __typename?: 'EventTicketsLeft';
  areRunningOut: Scalars['Boolean']['output'];
  count: Scalars['Int']['output'];
};

export type EventTicketsLeftByGender = {
  __typename?: 'EventTicketsLeftByGender';
  female: EventTicketsLeft;
  male: EventTicketsLeft;
};

export type EventsInput = {
  errorType: Scalars['String']['input'];
  format?: InputMaybe<Scalars['String']['input']>;
};

export type ExpertAnswer = {
  __typename?: 'ExpertAnswer';
  answer: Scalars['String']['output'];
  authorName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
};

export type ExternalSiteInfo = {
  __typename?: 'ExternalSiteInfo';
  logo?: Maybe<ExternalSiteInfoLogo>;
  name?: Maybe<Scalars['String']['output']>;
  site?: Maybe<Scalars['String']['output']>;
};

export type ExternalSiteInfoLogo = {
  __typename?: 'ExternalSiteInfoLogo';
  default?: Maybe<Scalars['String']['output']>;
  inverse?: Maybe<Scalars['String']['output']>;
};

export type ExtraBillingInformation = {
  __typename?: 'ExtraBillingInformation';
  ip?: Maybe<Scalars['String']['output']>;
  showSepaConfirmation?: Maybe<Scalars['Boolean']['output']>;
  siteDescriptor?: Maybe<Scalars['String']['output']>;
};

export type ExtraDiscount = {
  __typename?: 'ExtraDiscount';
  expireAt?: Maybe<Scalars['Int']['output']>;
  previousDiscount?: Maybe<DiscountData>;
};

export type ExtraEvents = {
  __typename?: 'ExtraEvents';
  isComboPedalSecondPart?: Maybe<Scalars['Boolean']['output']>;
  processId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type ExtraPaymentBanner = {
  __typename?: 'ExtraPaymentBanner';
  active: Scalars['Boolean']['output'];
  expiredAt: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  via: Scalars['String']['output'];
};

export type ExtraPaymentBannerPayload = {
  __typename?: 'ExtraPaymentBannerPayload';
  active?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<MutationPayloadErrors>;
  expiredAt?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  via?: Maybe<Scalars['String']['output']>;
};

export type ExtraPopups = {
  __typename?: 'ExtraPopups';
  RMBundle?: Maybe<RmBundle>;
  buy3DayGet1Week?: Maybe<Buy3DayGet1Week>;
  buyOnePlusOne?: Maybe<BuyOnePlusOne>;
  checkExpandSearchLimit?: Maybe<CheckExpandSearchLimit>;
  checkProfiles?: Maybe<CheckProfiles>;
  discount?: Maybe<Discount>;
  interactiveLike?: Maybe<InteractiveLike>;
  priorityMan?: Maybe<PriorityMan>;
  spinNow?: Maybe<SpinNow>;
  upsellPopupStatistic?: Maybe<UpsellPopupStatistic>;
};

export type ExtraPopupsRmBundleArgs = {
  processId: Scalars['ID']['input'];
};

export type ExtraPopupsBuy3DayGet1WeekArgs = {
  processId: Scalars['ID']['input'];
};

export type ExtraPopupsBuyOnePlusOneArgs = {
  processId: Scalars['ID']['input'];
};

export type ExtraPopupsCheckExpandSearchLimitArgs = {
  processId: Scalars['ID']['input'];
};

export type ExtraPopupsCheckProfilesArgs = {
  processId: Scalars['ID']['input'];
};

export type ExtraPopupsDiscountArgs = {
  processId: Scalars['ID']['input'];
};

export type ExtraPopupsInteractiveLikeArgs = {
  processId: Scalars['ID']['input'];
};

export type ExtraPopupsSpinNowArgs = {
  processId: Scalars['ID']['input'];
};

export type ExtraPopupsUpsellPopupStatisticArgs = {
  featureName: Scalars['String']['input'];
};

export enum EyeColorAttribute {
  BLUE = 'BLUE',
  BROWN = 'BROWN',
  DARK_BROWN = 'DARK_BROWN',
  GREEN = 'GREEN',
  GREY = 'GREY',
  HAZEL = 'HAZEL',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
}

export type EyeColorDictionary = {
  __typename?: 'EyeColorDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<EyeColorAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type EyeColorUserAttributeDictionary = {
  __typename?: 'EyeColorUserAttributeDictionary';
  key?: Maybe<EyeColorAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type Faq = {
  __typename?: 'FAQ';
  answer: Scalars['String']['output'];
  question: Scalars['String']['output'];
};

export type Favorites = {
  __typename?: 'Favorites';
  users: Array<Maybe<UserData>>;
};

export type FavoritesUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Feature = {
  __typename?: 'Feature';
  activeFrom?: Maybe<Scalars['Int']['output']>;
  canExchange?: Maybe<Scalars['Boolean']['output']>;
  creditAmount?: Maybe<Scalars['Int']['output']>;
  expiredAt?: Maybe<Scalars['Int']['output']>;
  featureName?: Maybe<Scalars['String']['output']>;
  interval?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  package?: Maybe<MicroFeaturePackage>;
  payButtonText?: Maybe<Scalars['String']['output']>;
  period?: Maybe<Scalars['String']['output']>;
};

export type Features = {
  __typename?: 'Features';
  locateMeFeature?: Maybe<Scalars['Boolean']['output']>;
  paidSearchForm?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated split removed */
  paidSearchFormMotivation?: Maybe<SearchFormMotivationEnum>;
  withArabic?: Maybe<Scalars['Boolean']['output']>;
  withFetish?: Maybe<Scalars['Boolean']['output']>;
  withHobbyAndLookingForAttributes?: Maybe<Scalars['Boolean']['output']>;
  withJapanese?: Maybe<Scalars['Boolean']['output']>;
  withMaritalStatus?: Maybe<Scalars['Boolean']['output']>;
  withMuslim?: Maybe<Scalars['Boolean']['output']>;
  withParanja?: Maybe<Scalars['Boolean']['output']>;
  withProfession?: Maybe<Scalars['Boolean']['output']>;
  withSugarAttributes?: Maybe<Scalars['Boolean']['output']>;
  withTribe?: Maybe<Scalars['Boolean']['output']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  enabled: Scalars['Boolean']['output'];
  limitReached: Scalars['Boolean']['output'];
};

export type FeedbackPayload = {
  __typename?: 'FeedbackPayload';
  errors?: Maybe<MutationPayloadErrors>;
  limitReached?: Maybe<Scalars['Boolean']['output']>;
};

export enum FetishAttribute {
  BDSM = 'BDSM',
  FOOT = 'FOOT',
  NOT_GIVEN = 'NOT_GIVEN',
  PANTY = 'PANTY',
}

export type FetishDictionary = {
  __typename?: 'FetishDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<FetishAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type FetishUserAttributeDictionary = {
  __typename?: 'FetishUserAttributeDictionary';
  key?: Maybe<FetishAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type FirstMessageGeneration = {
  __typename?: 'FirstMessageGeneration';
  available: Scalars['Boolean']['output'];
  left: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  reason?: Maybe<FirstMessageGenerationReason>;
};

export enum FirstMessageGenerationReason {
  LIMIT_REACHED = 'LIMIT_REACHED',
  NOT_ALLOWED = 'NOT_ALLOWED',
}

export type Flirtcast = {
  __typename?: 'Flirtcast';
  cooldown: Scalars['Int']['output'];
  id?: Maybe<Scalars['String']['output']>;
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  messages?: Maybe<Array<Scalars['String']['output']>>;
  timeDelay: Scalars['Int']['output'];
};

export type FlirtcastPayload = {
  __typename?: 'FlirtcastPayload';
  errors?: Maybe<MutationPayloadErrors>;
  flirtcast?: Maybe<Flirtcast>;
};

export type Footer = {
  __typename?: 'Footer';
  allowedAntiSlaveryPolicy: Scalars['Boolean']['output'];
  allowedCancellationPolicy: Scalars['Boolean']['output'];
  allowedConsentAgreement: Scalars['Boolean']['output'];
  allowedImprint: Scalars['Boolean']['output'];
  allowedPciCertified: Scalars['Boolean']['output'];
  allowedPolicyAgainstHumanTrafficking: Scalars['Boolean']['output'];
  copyright: Scalars['String']['output'];
  digitalServiceAct?: Maybe<Scalars['String']['output']>;
  disclaimer?: Maybe<Scalars['String']['output']>;
  footerDNSMPI?: Maybe<Scalars['String']['output']>;
  license: Array<Maybe<Scalars['String']['output']>>;
  mobSiteDomain?: Maybe<Scalars['String']['output']>;
  seoColumns: Array<Maybe<SeoColumn>>;
  siteMap: Array<Maybe<SiteMapItem>>;
};

export type FooterLicense = {
  __typename?: 'FooterLicense';
  formattedList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ForeignCommunicationFeature = {
  __typename?: 'ForeignCommunicationFeature';
  checked?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
};

export type ForeignCommunicationFeaturePayload = {
  __typename?: 'ForeignCommunicationFeaturePayload';
  checked?: Maybe<Scalars['Boolean']['output']>;
  errors?: Maybe<MutationPayloadErrors>;
};

export type FormAttributes = {
  __typename?: 'FormAttributes';
  city?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  nameFirst?: Maybe<Scalars['String']['output']>;
  nameLast?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type FortuneWheelDiscountBanner = {
  __typename?: 'FortuneWheelDiscountBanner';
  expiredAt?: Maybe<Scalars['Int']['output']>;
  via?: Maybe<Scalars['String']['output']>;
};

export type FraudNet = {
  __typename?: 'FraudNet';
  sessionId: Scalars['String']['output'];
  webSiteId: Scalars['String']['output'];
};

export type FreeAssistantMessages = {
  __typename?: 'FreeAssistantMessages';
  count?: Maybe<Scalars['Int']['output']>;
  show?: Maybe<Scalars['Boolean']['output']>;
};

export type FreeConversation = {
  __typename?: 'FreeConversation';
  count: Scalars['Int']['output'];
  isStarted: Scalars['Boolean']['output'];
};

export enum FreeMessageNotificationBannerEnum {
  free = 'free',
  paid = 'paid',
}

export type FreeMessageNotificationEvent = {
  __typename?: 'FreeMessageNotificationEvent';
  bannerType: Scalars['String']['output'];
  isAvailable: Scalars['Boolean']['output'];
  period: Scalars['Int']['output'];
};

export type FreeMessages = {
  __typename?: 'FreeMessages';
  count?: Maybe<Scalars['Int']['output']>;
  show?: Maybe<Scalars['Boolean']['output']>;
};

export type FreeMessagesBeforeCancel = {
  __typename?: 'FreeMessagesBeforeCancel';
  amount?: Maybe<Scalars['Int']['output']>;
  available?: Maybe<Scalars['Boolean']['output']>;
};

export type FreeViews = {
  __typename?: 'FreeViews';
  remainingCount: Scalars['Int']['output'];
  rewards: Array<Maybe<FreeViewsReward>>;
};

export type FreeViewsReward = {
  __typename?: 'FreeViewsReward';
  activated: Scalars['Boolean']['output'];
  amount: Scalars['Int']['output'];
  type: FreeViewsRewardEnum;
};

export enum FreeViewsRewardEnum {
  firstPhotoReward = 'firstPhotoReward',
}

export type FrontendTracking = {
  __typename?: 'FrontendTracking';
  trackCreditPopup?: Maybe<BooleanMutationResult>;
  trackMessageDelivery?: Maybe<BooleanMutationResult>;
};

export type FrontendTrackingTrackMessageDeliveryArgs = {
  time: Scalars['Int']['input'];
};

export type FrontendTrackingParams = {
  __typename?: 'FrontendTrackingParams';
  isTrackingAllowed: Scalars['Boolean']['output'];
};

export type FrontendTrackingParamsIsTrackingAllowedArgs = {
  trackingName: Scalars['String']['input'];
};

export type Funnel = {
  __typename?: 'Funnel';
  allowedAttributes?: Maybe<AllowedAttributes>;
  confirmWithPhotoUpload: Scalars['Boolean']['output'];
  inAppMotivationAllowed?: Maybe<Scalars['Boolean']['output']>;
  infoUpload: InfoUpload;
  isScreennameEditable: Scalars['Boolean']['output'];
  moreInfo?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  photoUploadAllowed: Scalars['Boolean']['output'];
  preferredContent?: Maybe<PreferredContent>;
  randomizedInfoParams: Scalars['Boolean']['output'];
  /** @deprecated Unused field can be removed in 2 months after front part, front has been already removed in FE-36677 */
  searchFiltersAllowed: Scalars['Boolean']['output'];
  statusUpload: StatusUpload;
  userResaleAllowed: Scalars['Boolean']['output'];
  userResaleConcept?: Maybe<Scalars['String']['output']>;
  withTermsAndPrivacyCheckbox: Scalars['Boolean']['output'];
};

export type FunnelPayload = {
  __typename?: 'FunnelPayload';
  StatusUpload?: Maybe<BooleanMutationResult>;
  agreeTermsAndPrivacy?: Maybe<BooleanMutationResult>;
  preferredContent?: Maybe<PreferredContentPayload>;
};

export type FunnelPayloadStatusUploadArgs = {
  statusId: Scalars['String']['input'];
};

export type FunnelPayloadAgreeTermsAndPrivacyArgs = {
  policy: Scalars['Boolean']['input'];
  terms: Scalars['Boolean']['input'];
};

export type FunnelRmBundle = {
  __typename?: 'FunnelRMBundle';
  sites?: Maybe<Array<Maybe<FunnelRmBundleSites>>>;
};

export type FunnelRmBundleSiteLogos = {
  __typename?: 'FunnelRMBundleSiteLogos';
  default?: Maybe<Scalars['String']['output']>;
  inverse?: Maybe<Scalars['String']['output']>;
};

export type FunnelRmBundleSites = {
  __typename?: 'FunnelRMBundleSites';
  id?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<FunnelRmBundleSiteLogos>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Gallery = {
  __typename?: 'Gallery';
  currentIndex?: Maybe<Scalars['Int']['output']>;
  freeUsersEnded?: Maybe<Scalars['Boolean']['output']>;
  hasMore?: Maybe<Scalars['Boolean']['output']>;
  profilesLeftCount?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<UserData>>;
};

export type GalleryUsersArgs = {
  currentUserId?: InputMaybe<Scalars['String']['input']>;
  skipShownUsers?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GamblingAttribute {
  NO = 'NO',
  NOT_GIVEN = 'NOT_GIVEN',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  SOMETIMES = 'SOMETIMES',
  YES = 'YES',
}

export type GamblingDictionary = {
  __typename?: 'GamblingDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<GamblingAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type GamblingUserAttributeDictionary = {
  __typename?: 'GamblingUserAttributeDictionary';
  key?: Maybe<GamblingAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type GenderDictionary = {
  __typename?: 'GenderDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<GenderTypeEnum>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum GenderEnum {
  FEMALE = 'FEMALE',
  GAY = 'GAY',
  LESBIAN = 'LESBIAN',
  MALE = 'MALE',
}

export enum GenderIdentityAttribute {
  LADYBOY = 'LADYBOY',
  NOT_GIVEN = 'NOT_GIVEN',
}

export type GenderIdentityDictionary = {
  __typename?: 'GenderIdentityDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<GenderIdentityAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type GenderIdentityUserAttributeDictionary = {
  __typename?: 'GenderIdentityUserAttributeDictionary';
  key?: Maybe<GenderIdentityAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum GenderTypeEnum {
  female = 'female',
  male = 'male',
}

export type GeneralErrors = {
  __typename?: 'GeneralErrors';
  general?: Maybe<Scalars['String']['output']>;
};

export type GeneralOption = {
  __typename?: 'GeneralOption';
  disableAfterDenyCount?: Maybe<Scalars['Int']['output']>;
  disableAfterIgnoreCount?: Maybe<Scalars['Int']['output']>;
  logic?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pauseAfterDeny?: Maybe<Scalars['Int']['output']>;
  pauseAfterIgnore?: Maybe<Scalars['Int']['output']>;
  popup?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  timeout?: Maybe<Scalars['Int']['output']>;
};

export type Gift = {
  __typename?: 'Gift';
  creditsAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  user?: Maybe<UserData>;
};

export type GiftList = {
  __typename?: 'GiftList';
  freeAmount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type GlobalFreeMessageEvent = {
  __typename?: 'GlobalFreeMessageEvent';
  count: Scalars['Int']['output'];
  time: Scalars['Int']['output'];
};

export type GlobalFreeMessages = {
  __typename?: 'GlobalFreeMessages';
  available?: Maybe<Scalars['Boolean']['output']>;
  buyMessagesForFreeUser?: Maybe<Scalars['Boolean']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  freeMessagesTimeout?: Maybe<Scalars['Int']['output']>;
  time: Scalars['Int']['output'];
};

export type GlobalSearchTrialEndedNotification = {
  __typename?: 'GlobalSearchTrialEndedNotification';
  type?: Maybe<Scalars['String']['output']>;
};

export enum GlobalSearchTypeEnum {
  borderless = 'borderless',
  extended = 'extended',
  local = 'local',
}

export enum GoalAttribute {
  EMAIL_OR_CHAT = 'EMAIL_OR_CHAT',
  FIND_ROMANCE = 'FIND_ROMANCE',
  FRIENDSHIP = 'FRIENDSHIP',
  FUN = 'FUN',
  GET_MARRIED = 'GET_MARRIED',
  MEET_FRIENDS_WITH_BENEFITS = 'MEET_FRIENDS_WITH_BENEFITS',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  RELATIONSHIP = 'RELATIONSHIP',
  SOMETHING_LONG_TERM = 'SOMETHING_LONG_TERM',
  SOMETHING_SHORT_TERM = 'SOMETHING_SHORT_TERM',
  TELL_YOU_LATER = 'TELL_YOU_LATER',
}

export type GoalDictionary = {
  __typename?: 'GoalDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<GoalAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type GoalUserAttributeDictionary = {
  __typename?: 'GoalUserAttributeDictionary';
  key?: Maybe<GoalAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type GooglePayDisplaySettings = {
  __typename?: 'GooglePayDisplaySettings';
  allowedAuthMethods?: Maybe<Array<AuthMethod>>;
  allowedCardNetworks?: Maybe<Array<CardNetwork>>;
  environment?: Maybe<Scalars['String']['output']>;
  isNeedRequestCvv?: Maybe<Scalars['Boolean']['output']>;
};

export type GooglePaySettings = {
  __typename?: 'GooglePaySettings';
  allowedAuthMethods?: Maybe<Array<AuthMethod>>;
  allowedCardNetworks?: Maybe<Array<CardNetwork>>;
  environment?: Maybe<Scalars['String']['output']>;
  gateway?: Maybe<Scalars['String']['output']>;
  gatewayMerchantId?: Maybe<Scalars['String']['output']>;
  isNeedRequestCvv?: Maybe<Scalars['Boolean']['output']>;
  merchantId?: Maybe<Scalars['String']['output']>;
  merchantName?: Maybe<Scalars['String']['output']>;
  method?: Maybe<Method>;
};

export enum HairColorAttribute {
  BALD_SHAVEN = 'BALD_SHAVEN',
  BLACK = 'BLACK',
  BLOND_E = 'BLOND_E',
  BROWN = 'BROWN',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  RED = 'RED',
  WHITE = 'WHITE',
}

export type HairColorDictionary = {
  __typename?: 'HairColorDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<HairColorAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type HairColorUserAttributeDictionary = {
  __typename?: 'HairColorUserAttributeDictionary';
  key?: Maybe<HairColorAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum HeightAttribute {
  FT4_IN0 = 'FT4_IN0',
  FT4_IN1 = 'FT4_IN1',
  FT4_IN2 = 'FT4_IN2',
  FT4_IN3 = 'FT4_IN3',
  FT4_IN4 = 'FT4_IN4',
  FT4_IN5 = 'FT4_IN5',
  FT4_IN6 = 'FT4_IN6',
  FT4_IN7 = 'FT4_IN7',
  FT4_IN8 = 'FT4_IN8',
  FT4_IN9 = 'FT4_IN9',
  FT4_IN10 = 'FT4_IN10',
  FT4_IN11 = 'FT4_IN11',
  FT5_IN0 = 'FT5_IN0',
  FT5_IN1 = 'FT5_IN1',
  FT5_IN2 = 'FT5_IN2',
  FT5_IN3 = 'FT5_IN3',
  FT5_IN4 = 'FT5_IN4',
  FT5_IN5 = 'FT5_IN5',
  FT5_IN6 = 'FT5_IN6',
  FT5_IN7 = 'FT5_IN7',
  FT5_IN8 = 'FT5_IN8',
  FT5_IN9 = 'FT5_IN9',
  FT5_IN10 = 'FT5_IN10',
  FT5_IN11 = 'FT5_IN11',
  FT6_IN0 = 'FT6_IN0',
  FT6_IN1 = 'FT6_IN1',
  FT6_IN2 = 'FT6_IN2',
  FT6_IN3 = 'FT6_IN3',
  FT6_IN4 = 'FT6_IN4',
  FT6_IN5 = 'FT6_IN5',
  FT6_IN6 = 'FT6_IN6',
  FT6_IN7 = 'FT6_IN7',
  FT6_IN8 = 'FT6_IN8',
  FT6_IN9 = 'FT6_IN9',
  FT6_IN10 = 'FT6_IN10',
  FT6_IN11 = 'FT6_IN11',
  FT7_IN0 = 'FT7_IN0',
  NOT_GIVEN = 'NOT_GIVEN',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
}

export type HeightDictionary = {
  __typename?: 'HeightDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<HeightAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type HeightUserAttributeDictionary = {
  __typename?: 'HeightUserAttributeDictionary';
  key?: Maybe<HeightAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type HelpChatSettings = {
  __typename?: 'HelpChatSettings';
  declineCount?: Maybe<Scalars['Int']['output']>;
  group?: Maybe<Scalars['Int']['output']>;
  license?: Maybe<Scalars['Int']['output']>;
};

export enum HijabWearingAttribute {
  NO = 'NO',
  NOT_GIVEN = 'NOT_GIVEN',
  SOMETIMES = 'SOMETIMES',
  YES = 'YES',
}

export type HijabWearingDictionary = {
  __typename?: 'HijabWearingDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<HijabWearingAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type HijabWearingUserAttributeDictionary = {
  __typename?: 'HijabWearingUserAttributeDictionary';
  key?: Maybe<HijabWearingAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum HobbyAttribute {
  ACTING = 'ACTING',
  ART = 'ART',
  CAR_BIKE = 'CAR_BIKE',
  COOKING_GOURMET = 'COOKING_GOURMET',
  CYCLING = 'CYCLING',
  DANCING = 'DANCING',
  DIY_THINGS = 'DIY_THINGS',
  GAMES = 'GAMES',
  GARDENING = 'GARDENING',
  HEALTH_FITNESS = 'HEALTH_FITNESS',
  INTERNET = 'INTERNET',
  KARAOKE_AND_BAND = 'KARAOKE_AND_BAND',
  LANGUAGE_STUDY = 'LANGUAGE_STUDY',
  LISTENING_TO_MUSIC = 'LISTENING_TO_MUSIC',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  OUTDOOR_ACTIVITY = 'OUTDOOR_ACTIVITY',
  PETS = 'PETS',
  PHOTOGRAPHY = 'PHOTOGRAPHY',
  READING_MANGA_BOOKS = 'READING_MANGA_BOOKS',
  SHOPPING_FASHION = 'SHOPPING_FASHION',
  TRAVELLING = 'TRAVELLING',
  WATCHING_PLAYING_SPORTS = 'WATCHING_PLAYING_SPORTS',
  WATCHING_TV_FILMS_SERIALS = 'WATCHING_TV_FILMS_SERIALS',
}

export type HobbyDictionary = {
  __typename?: 'HobbyDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<HobbyAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum HobbyEntertainmentAttribute {
  CINEMA = 'CINEMA',
  CONCERTS = 'CONCERTS',
  MUSEUMS_ART_GALLERIES = 'MUSEUMS_ART_GALLERIES',
  NIGHT_CLUBS = 'NIGHT_CLUBS',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  OUTDOOR_ACTIVITIES = 'OUTDOOR_ACTIVITIES',
  PUBS_RESTAURANTS = 'PUBS_RESTAURANTS',
  THEATRE = 'THEATRE',
}

export type HobbyEntertainmentDictionary = {
  __typename?: 'HobbyEntertainmentDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<HobbyEntertainmentAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type HobbyEntertainmentUserAttributeDictionary = {
  __typename?: 'HobbyEntertainmentUserAttributeDictionary';
  key?: Maybe<HobbyEntertainmentAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum HobbyFoodAttribute {
  CHINESE_FOOD = 'CHINESE_FOOD',
  ENGLISH_FOOD = 'ENGLISH_FOOD',
  FAST_FOOD = 'FAST_FOOD',
  FRENCH_FOOD = 'FRENCH_FOOD',
  INDIAN_FOOD = 'INDIAN_FOOD',
  ITALIAN_FOOD = 'ITALIAN_FOOD',
  JAPANESE_FOOD = 'JAPANESE_FOOD',
  MEXICAN_FOOD = 'MEXICAN_FOOD',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  THAI_FOOD = 'THAI_FOOD',
}

export type HobbyFoodDictionary = {
  __typename?: 'HobbyFoodDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<HobbyFoodAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type HobbyFoodUserAttributeDictionary = {
  __typename?: 'HobbyFoodUserAttributeDictionary';
  key?: Maybe<HobbyFoodAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum HobbyMusicAttribute {
  BLUES = 'BLUES',
  CLASSICAL = 'CLASSICAL',
  COUNTRY_WESTERN = 'COUNTRY_WESTERN',
  DANCE = 'DANCE',
  FOLK = 'FOLK',
  HEAVY_METAL = 'HEAVY_METAL',
  INDIE = 'INDIE',
  JAZZ = 'JAZZ',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  POP = 'POP',
  ROCK = 'ROCK',
  R_B = 'R_B',
  SOUL = 'SOUL',
}

export type HobbyMusicDictionary = {
  __typename?: 'HobbyMusicDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<HobbyMusicAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type HobbyMusicUserAttributeDictionary = {
  __typename?: 'HobbyMusicUserAttributeDictionary';
  key?: Maybe<HobbyMusicAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum HobbyPlaySportsAttribute {
  CRICKET = 'CRICKET',
  FITNESS = 'FITNESS',
  FOOTBALL = 'FOOTBALL',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  RUGBY = 'RUGBY',
  TENNIS = 'TENNIS',
}

export type HobbyPlaySportsDictionary = {
  __typename?: 'HobbyPlaySportsDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<HobbyPlaySportsAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type HobbyPlaySportsUserAttributeDictionary = {
  __typename?: 'HobbyPlaySportsUserAttributeDictionary';
  key?: Maybe<HobbyPlaySportsAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum HobbyTvAttribute {
  COMEDY = 'COMEDY',
  DOCUMENTARY = 'DOCUMENTARY',
  DRAMA = 'DRAMA',
  FILMS = 'FILMS',
  NATURE_WILDLIFE = 'NATURE_WILDLIFE',
  NEWS = 'NEWS',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  QUIZ_SHOWS = 'QUIZ_SHOWS',
  SCI_FI = 'SCI_FI',
  SOAPS = 'SOAPS',
  SPORT = 'SPORT',
}

export type HobbyTvDictionary = {
  __typename?: 'HobbyTVDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<HobbyTvAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type HobbyTvUserAttributeDictionary = {
  __typename?: 'HobbyTVUserAttributeDictionary';
  key?: Maybe<HobbyTvAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type HobbyUserAttributeDictionary = {
  __typename?: 'HobbyUserAttributeDictionary';
  key?: Maybe<HobbyAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum HobbyWatchSportsAttribute {
  ATHLETICS = 'ATHLETICS',
  BASKETBALL = 'BASKETBALL',
  BOXING = 'BOXING',
  CRICKET = 'CRICKET',
  CYCLING = 'CYCLING',
  FISHING = 'FISHING',
  FOOTBALL = 'FOOTBALL',
  HOCKEY = 'HOCKEY',
  HORCE_RIDING = 'HORCE_RIDING',
  ICE_SKATING = 'ICE_SKATING',
  MOTOR_RACING = 'MOTOR_RACING',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  RUNNING = 'RUNNING',
  SKIING = 'SKIING',
  TENNIS = 'TENNIS',
}

export type HobbyWatchSportsDictionary = {
  __typename?: 'HobbyWatchSportsDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<HobbyWatchSportsAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type HobbyWatchSportsUserAttributeDictionary = {
  __typename?: 'HobbyWatchSportsUserAttributeDictionary';
  key?: Maybe<HobbyWatchSportsAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type Holiday = {
  __typename?: 'Holiday';
  color?: Maybe<Scalars['String']['output']>;
  layout?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  processors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  typeOccurrence?: Maybe<Scalars['String']['output']>;
};

export type HostUser = {
  __typename?: 'HostUser';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Image>;
  status?: Maybe<Scalars['String']['output']>;
};

export type HotlineCreditCard = {
  __typename?: 'HotlineCreditCard';
  phoneNumber?: Maybe<Scalars['String']['output']>;
  translateMessageEntityMap?: Maybe<TranslateMessageEntityMap>;
  workingHoursFrom?: Maybe<Scalars['String']['output']>;
  workingHoursTo?: Maybe<Scalars['String']['output']>;
};

export type IdMutationResult = {
  __typename?: 'IDMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<Scalars['ID']['output']>;
};

export type Icon = {
  __typename?: 'Icon';
  id: Scalars['String']['output'];
  selected: Scalars['Boolean']['output'];
};

export type IdentifyData = {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type IdsErrorsResponse = {
  __typename?: 'IdsErrorsResponse';
  errors?: Maybe<MutationPayloadErrors>;
  ids?: Maybe<Array<Scalars['String']['output']>>;
};

export type Image = {
  __typename?: 'Image';
  alt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ImbData = {
  __typename?: 'ImbData';
  messageMarkers: Array<Maybe<Scalars['String']['output']>>;
  photoEnabled: Scalars['Boolean']['output'];
  videoEnabled: Scalars['Boolean']['output'];
};

export enum InAppBrowsersEnum {
  facebook = 'facebook',
  instagram = 'instagram',
  notInApp = 'notInApp',
  snapchat = 'snapchat',
  tiktok = 'tiktok',
}

export type InAppMotivation = {
  __typename?: 'InAppMotivation';
  allowed: Scalars['Boolean']['output'];
  alterBtns?: Maybe<Scalars['Boolean']['output']>;
  linkType?: Maybe<Scalars['String']['output']>;
  payBtnUp?: Maybe<Scalars['Boolean']['output']>;
};

export type InAppParams = {
  __typename?: 'InAppParams';
  motivation: InAppMotivation;
  photoRestriction: Scalars['Boolean']['output'];
};

export type IncognitoMode = {
  __typename?: 'IncognitoMode';
  isAcquired?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<IncognitoModeStatus>;
};

export enum IncognitoModeStatus {
  off = 'off',
  on = 'on',
}

export enum IncomeAttribute {
  AVERAGE = 'AVERAGE',
  CAN_AFFORD_ANYTHING = 'CAN_AFFORD_ANYTHING',
  LOW = 'LOW',
  NOT_GIVEN = 'NOT_GIVEN',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  WELL_PAID = 'WELL_PAID',
}

export type IncomeDictionary = {
  __typename?: 'IncomeDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<IncomeAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type IncomeUserAttributeDictionary = {
  __typename?: 'IncomeUserAttributeDictionary';
  key?: Maybe<IncomeAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum IndianCommunityAttribute {
  BENGALI = 'BENGALI',
  BRAHMIN = 'BRAHMIN',
  GUJARATI = 'GUJARATI',
  HINDI_SPEAKING = 'HINDI_SPEAKING',
  JATT = 'JATT',
  KANADA = 'KANADA',
  KASHMIRI = 'KASHMIRI',
  MAHARASHTRIAN = 'MAHARASHTRIAN',
  MALAYALI = 'MALAYALI',
  NO_COMMUNITY = 'NO_COMMUNITY',
  OTHER = 'OTHER',
  PUNJABI = 'PUNJABI',
  RAJASTHANI = 'RAJASTHANI',
  SHIA = 'SHIA',
  SINDHI = 'SINDHI',
  SUNNI = 'SUNNI',
  TAMIL = 'TAMIL',
  TELUGU = 'TELUGU',
  URDU = 'URDU',
}

export type IndianCommunityDictionary = {
  __typename?: 'IndianCommunityDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<IndianCommunityAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type IndianCommunityUserAttributeDictionary = {
  __typename?: 'IndianCommunityUserAttributeDictionary';
  key?: Maybe<IndianCommunityAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type InfoUpload = {
  __typename?: 'InfoUpload';
  allowed: Scalars['Boolean']['output'];
  list?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type InputAgeRange = {
  from: Scalars['Int']['input'];
  to: Scalars['Int']['input'];
};

export type InputParams = {
  age: InputAgeRange;
  attributes?: InputMaybe<InputSearchAttributes>;
  gender?: InputMaybe<GenderTypeEnum>;
  location: InputSearchLocation;
  photoLevel?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sortType?: InputMaybe<SortSearchType>;
  tab?: InputMaybe<TabSearchType>;
  withPhoto: Scalars['Boolean']['input'];
  withPrivateAlbum?: InputMaybe<Scalars['Boolean']['input']>;
  withVideo: Scalars['Boolean']['input'];
};

export type InputSearchAttributes = {
  bloodType?: InputMaybe<Array<InputMaybe<BloodTypeAttribute>>>;
  build?: InputMaybe<Array<InputMaybe<BuildAttribute>>>;
  children?: InputMaybe<Array<InputMaybe<ChildrenAttribute>>>;
  drink?: InputMaybe<Array<InputMaybe<DrinkAttribute>>>;
  eatingHabits?: InputMaybe<Array<InputMaybe<EatingHabitsAttribute>>>;
  education?: InputMaybe<Array<InputMaybe<EducationAttribute>>>;
  eyeColor?: InputMaybe<Array<InputMaybe<EyeColorAttribute>>>;
  fetish?: InputMaybe<Array<InputMaybe<FetishAttribute>>>;
  gambling?: InputMaybe<Array<InputMaybe<GamblingAttribute>>>;
  genderIdentity?: InputMaybe<Array<InputMaybe<GenderIdentityAttribute>>>;
  goal?: InputMaybe<Array<InputMaybe<GoalAttribute>>>;
  hairColor?: InputMaybe<Array<InputMaybe<HairColorAttribute>>>;
  height?: InputMaybe<Array<InputMaybe<HeightAttribute>>>;
  hijabWearing?: InputMaybe<Array<InputMaybe<HijabWearingAttribute>>>;
  hobby?: InputMaybe<Array<InputMaybe<HobbyAttribute>>>;
  income?: InputMaybe<Array<InputMaybe<IncomeAttribute>>>;
  indianCommunity?: InputMaybe<Array<InputMaybe<IndianCommunityAttribute>>>;
  islamCult?: InputMaybe<Array<InputMaybe<IslamCultAttribute>>>;
  maritalStatus?: InputMaybe<Array<InputMaybe<MaritalStatusAttribute>>>;
  moneyRole?: InputMaybe<Array<InputMaybe<MoneyRoleAttribute>>>;
  muslimBorn?: InputMaybe<Array<InputMaybe<MuslimBornAttribute>>>;
  netWorth?: InputMaybe<Array<InputMaybe<NetWorthAttribute>>>;
  occupation?: InputMaybe<Array<InputMaybe<OccupationAttribute>>>;
  pierced?: InputMaybe<Array<InputMaybe<PiercedAttribute>>>;
  professionId?: InputMaybe<Array<InputMaybe<ProfessionIdAttribute>>>;
  race?: InputMaybe<Array<InputMaybe<RaceAttribute>>>;
  readQuran?: InputMaybe<Array<InputMaybe<ReadQuranAttribute>>>;
  registrationReason?: InputMaybe<
    Array<InputMaybe<RegistrationReasonAttribute>>
  >;
  religion?: InputMaybe<Array<InputMaybe<ReligionAttribute>>>;
  religiousServices?: InputMaybe<Array<InputMaybe<ReligiousServicesAttribute>>>;
  religiousValues?: InputMaybe<Array<InputMaybe<ReligiousValuesAttribute>>>;
  sexualOrientation?: InputMaybe<Array<InputMaybe<SexualOrientationAttribute>>>;
  smoke?: InputMaybe<Array<InputMaybe<SmokeAttribute>>>;
  tattoo?: InputMaybe<Array<InputMaybe<TattooAttribute>>>;
  tribe?: InputMaybe<Array<InputMaybe<TribeAttribute>>>;
  weight?: InputMaybe<Array<InputMaybe<WeightAttribute>>>;
  zodiacSign?: InputMaybe<Array<InputMaybe<ZodiacSignAttribute>>>;
};

export type InputSearchLocation = {
  city?: InputMaybe<Scalars['String']['input']>;
  country: CountryType;
  distance?: InputMaybe<Scalars['Int']['input']>;
  distanceUnits?: InputMaybe<DistanceUnits>;
  isAvailableForSearch?: InputMaybe<Scalars['Boolean']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  regionAbbreviation?: InputMaybe<Scalars['String']['input']>;
};

export type InputSearchWithMapLocation = {
  distance?: InputMaybe<Scalars['Int']['input']>;
  distanceUnits?: InputMaybe<DistanceUnits>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
};

export type InputUserAttributes = {
  bloodType?: InputMaybe<BloodTypeAttribute>;
  build?: InputMaybe<BuildAttribute>;
  characteristics?: InputMaybe<CharacteristicsAttribute>;
  children?: InputMaybe<ChildrenAttribute>;
  diet?: InputMaybe<DietAttribute>;
  drink?: InputMaybe<DrinkAttribute>;
  drugs?: InputMaybe<DrugsAttribute>;
  eatingHabits?: InputMaybe<EatingHabitsAttribute>;
  education?: InputMaybe<EducationAttribute>;
  eyeColor?: InputMaybe<EyeColorAttribute>;
  fetish?: InputMaybe<FetishAttribute>;
  gambling?: InputMaybe<GamblingAttribute>;
  genderIdentity?: InputMaybe<GenderIdentityAttribute>;
  goal?: InputMaybe<Array<InputMaybe<GoalAttribute>>>;
  hairColor?: InputMaybe<HairColorAttribute>;
  height?: InputMaybe<HeightAttribute>;
  hijabWearing?: InputMaybe<HijabWearingAttribute>;
  hobby?: InputMaybe<Array<InputMaybe<HobbyAttribute>>>;
  hobbyEntertainment?: InputMaybe<
    Array<InputMaybe<HobbyEntertainmentAttribute>>
  >;
  hobbyFood?: InputMaybe<Array<InputMaybe<HobbyFoodAttribute>>>;
  hobbyMusic?: InputMaybe<Array<InputMaybe<HobbyMusicAttribute>>>;
  hobbyPlaySports?: InputMaybe<Array<InputMaybe<HobbyPlaySportsAttribute>>>;
  hobbyTV?: InputMaybe<Array<InputMaybe<HobbyTvAttribute>>>;
  hobbyWatchSports?: InputMaybe<Array<InputMaybe<HobbyWatchSportsAttribute>>>;
  income?: InputMaybe<IncomeAttribute>;
  indianCommunity?: InputMaybe<IndianCommunityAttribute>;
  islamCult?: InputMaybe<IslamCultAttribute>;
  literatureRead?: InputMaybe<Array<InputMaybe<LiteratureReadAttribute>>>;
  living?: InputMaybe<LivingAttribute>;
  maritalStatus?: InputMaybe<MaritalStatusAttribute>;
  moneyRole?: InputMaybe<MoneyRoleAttribute>;
  muslimBorn?: InputMaybe<MuslimBornAttribute>;
  netWorth?: InputMaybe<NetWorthAttribute>;
  occupation?: InputMaybe<OccupationAttribute>;
  pierced?: InputMaybe<PiercedAttribute>;
  politics?: InputMaybe<PoliticsAttribute>;
  practisingReligion?: InputMaybe<PractisingReligionAttribute>;
  professionId?: InputMaybe<ProfessionIdAttribute>;
  race?: InputMaybe<RaceAttribute>;
  readQuran?: InputMaybe<ReadQuranAttribute>;
  registrationReason?: InputMaybe<RegistrationReasonAttribute>;
  religion?: InputMaybe<ReligionAttribute>;
  religiousServices?: InputMaybe<ReligiousServicesAttribute>;
  religiousValues?: InputMaybe<ReligiousValuesAttribute>;
  sexualOrientation?: InputMaybe<SexualOrientationAttribute>;
  sexualRole?: InputMaybe<SexualRoleAttribute>;
  smoke?: InputMaybe<SmokeAttribute>;
  tattoo?: InputMaybe<TattooAttribute>;
  tribe?: InputMaybe<Array<InputMaybe<TribeAttribute>>>;
  weight?: InputMaybe<WeightAttribute>;
  zodiacSign?: InputMaybe<ZodiacSignAttribute>;
};

export type IntIdBoolValueElement = {
  __typename?: 'IntIdBoolValueElement';
  key?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type IntIdElement = {
  __typename?: 'IntIdElement';
  key?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type InteractiveLike = {
  __typename?: 'InteractiveLike';
  expireAt: Scalars['Int']['output'];
  extra: Scalars['Int']['output'];
  userIdToGuess?: Maybe<Scalars['ID']['output']>;
  users: Array<UserData>;
};

export type InternalVideoData = {
  __typename?: 'InternalVideoData';
  appKey: Scalars['String']['output'];
  host: Scalars['String']['output'];
  port: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export type InventoryTracking = {
  __typename?: 'InventoryTracking';
  countObjects: Scalars['Int']['output'];
  delay: Scalars['Int']['output'];
  dictionaries?: Maybe<InventoryTrackingDictionaries>;
};

export type InventoryTrackingAttribute = {
  __typename?: 'InventoryTrackingAttribute';
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type InventoryTrackingDictionaries = {
  __typename?: 'InventoryTrackingDictionaries';
  anchors: Array<Maybe<InventoryTrackingDictionary>>;
  events: Array<Maybe<InventoryTrackingDictionary>>;
  placements: Array<Maybe<InventoryTrackingDictionary>>;
};

export type InventoryTrackingDictionary = {
  __typename?: 'InventoryTrackingDictionary';
  key?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type InvoiceData = {
  __typename?: 'InvoiceData';
  discountPrice?: Maybe<Price>;
  event?: Maybe<SdEvent>;
  female?: Maybe<Array<Scalars['ID']['output']>>;
  male?: Maybe<Array<Scalars['ID']['output']>>;
  price: Price;
};

export type InvoiceDatas = {
  __typename?: 'InvoiceDatas';
  discountPrice?: Maybe<Price>;
  items?: Maybe<Array<Maybe<InvoiceData>>>;
  price: Price;
  tokenPrice?: Maybe<TokenPrice>;
};

export type InvoiceEvent = {
  eventId: Scalars['ID']['input'];
  femaleTicketsAmount: Scalars['Int']['input'];
  maleTicketsAmount: Scalars['Int']['input'];
  promoCodeDiscount?: InputMaybe<Scalars['String']['input']>;
  unlimitedPassApplied?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvoiceValidation = {
  __typename?: 'InvoiceValidation';
  errors?: Maybe<Array<Maybe<ArgumentError>>>;
  result: Scalars['Boolean']['output'];
};

export enum IslamCultAttribute {
  ISLAM_AHMADIYYA = 'ISLAM_AHMADIYYA',
  ISLAM_OTHER = 'ISLAM_OTHER',
  ISLAM_SHIITE = 'ISLAM_SHIITE',
  ISLAM_SUFISM = 'ISLAM_SUFISM',
  ISLAM_SUNNI = 'ISLAM_SUNNI',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  WILLING_TO_REVERT = 'WILLING_TO_REVERT',
}

export type IslamCultDictionary = {
  __typename?: 'IslamCultDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<IslamCultAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type IslamCultUserAttributeDictionary = {
  __typename?: 'IslamCultUserAttributeDictionary';
  key?: Maybe<IslamCultAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type KonbiniSettings = {
  __typename?: 'KonbiniSettings';
  method?: Maybe<Method>;
  stores?: Maybe<Array<Maybe<ConvenienceStore>>>;
};

export type L10n = {
  __typename?: 'L10n';
  availableLocales: Array<Maybe<Locale>>;
};

export type LastActivity = {
  __typename?: 'LastActivity';
  direction?: Maybe<Direction>;
  type: Scalars['String']['output'];
};

export type LastMessage = {
  __typename?: 'LastMessage';
  id: Scalars['ID']['output'];
  isSending?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type Lesson = {
  __typename?: 'Lesson';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sources?: Maybe<Array<Maybe<SdSource>>>;
  video?: Maybe<Video>;
};

export type LikeGallery = {
  __typename?: 'LikeGallery';
  gallery?: Maybe<Gallery>;
};

export enum LikePlaceEnum {
  likeGallery = 'likeGallery',
  search = 'search',
}

export type Links = {
  __typename?: 'Links';
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum LiteratureReadAttribute {
  BUSINESS__FINANCE_LAW = 'BUSINESS__FINANCE_LAW',
  CLASSIC = 'CLASSIC',
  CRIME__THRILLERS_MYSTERY = 'CRIME__THRILLERS_MYSTERY',
  MIND__BODY_SPIRIT = 'MIND__BODY_SPIRIT',
  NOT_GIVEN = 'NOT_GIVEN',
  POETRY_DRAMA = 'POETRY_DRAMA',
  POLITICS__PHILISOPHY_SOCIAL_SCIENCES = 'POLITICS__PHILISOPHY_SOCIAL_SCIENCES',
  ROMANCE = 'ROMANCE',
  SCIENCE_FICTION_FANTASY = 'SCIENCE_FICTION_FANTASY',
}

export type LiteratureReadDictionary = {
  __typename?: 'LiteratureReadDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<LiteratureReadAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type LiteratureReadUserAttributeDictionary = {
  __typename?: 'LiteratureReadUserAttributeDictionary';
  key?: Maybe<LiteratureReadAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type Livecam = {
  __typename?: 'Livecam';
  /** @deprecated Always false. Will be removed soon */
  allowedFgfPromotion: Scalars['Boolean']['output'];
  cleanCamsAllowed: Scalars['Boolean']['output'];
  /** @deprecated Deprecated. Use webCamPromo to get correct partner name */
  providerName?: Maybe<Scalars['String']['output']>;
  webCamPromo?: Maybe<WebCamPromo>;
};

export type LivecamWebCamPromoArgs = {
  promo?: InputMaybe<Array<InputMaybe<WebCamPromoNames>>>;
};

export enum LivingAttribute {
  I_LIVE_ALONE = 'I_LIVE_ALONE',
  I_LIVE_WITH_PARENTS = 'I_LIVE_WITH_PARENTS',
  I_LIVE_WITH_ROOMMATE = 'I_LIVE_WITH_ROOMMATE',
  NOT_GIVEN = 'NOT_GIVEN',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  STUDENT_RESIDENCE = 'STUDENT_RESIDENCE',
}

export type LivingDictionary = {
  __typename?: 'LivingDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<LivingAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type LivingUserAttributeDictionary = {
  __typename?: 'LivingUserAttributeDictionary';
  key?: Maybe<LivingAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type Locale = {
  __typename?: 'Locale';
  locale: Scalars['String']['output'];
  nativeName: Scalars['String']['output'];
};

export type LocationSearchDictionary = {
  __typename?: 'LocationSearchDictionary';
  citiesByCountry?: Maybe<Array<Maybe<SearchLocation>>>;
  citiesByLocationString?: Maybe<Array<Maybe<SearchLocation>>>;
  cittyByCoordinatesOrUserIp?: Maybe<Array<Maybe<SearchLocation>>>;
  country?: Maybe<Array<Maybe<CountryDictionary>>>;
  distance?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  distanceUnits?: Maybe<Array<Maybe<DistanceUnits>>>;
  region?: Maybe<Array<Maybe<RegionDictionary>>>;
};

export type LocationSearchDictionaryCitiesByCountryArgs = {
  country: CountryType;
  regionAbbreviation?: InputMaybe<Scalars['String']['input']>;
};

export type LocationSearchDictionaryCitiesByLocationStringArgs = {
  country?: InputMaybe<CountryType>;
  locationString?: InputMaybe<Scalars['String']['input']>;
};

export type LocationSearchDictionaryCittyByCoordinatesOrUserIpArgs = {
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
};

export type LocationSearchDictionaryRegionArgs = {
  country?: InputMaybe<CountryType>;
};

export type LogicOptions = {
  __typename?: 'LogicOptions';
  addFirstFavorite?: Maybe<MinorOption>;
  addLike?: Maybe<MinorOption>;
  chat?: Maybe<MinorOption>;
  freeMessageFinished?: Maybe<MinorOption>;
  general?: Maybe<GeneralOption>;
  like?: Maybe<MinorOption>;
  openProfile?: Maybe<MinorOption>;
  route?: Maybe<MinorOption>;
  sendFirstMessage?: Maybe<MinorOption>;
  sendSecondMessage?: Maybe<MinorOption>;
};

export type LongFormField = {
  __typename?: 'LongFormField';
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  longFormField?: Maybe<Scalars['String']['output']>;
};

export type LongFormFields = {
  __typename?: 'LongFormFields';
  anonymousAutoFillFields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  longFormFields?: Maybe<Array<Maybe<LongFormField>>>;
};

export type LookingForData = {
  __typename?: 'LookingForData';
  age?: Maybe<AgeRange>;
  gender?: Maybe<GenderTypeEnum>;
  location?: Maybe<LookingForLocationData>;
};

export type LookingForLocationData = {
  __typename?: 'LookingForLocationData';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  regionAbbreviation?: Maybe<Scalars['String']['output']>;
};

export type MainGifts = {
  __typename?: 'MainGifts';
  list?: Maybe<Array<Maybe<GiftList>>>;
  needOpenPopupAfterCommunicationMatch: Scalars['Boolean']['output'];
  showInProfileAllowed: Scalars['Boolean']['output'];
};

export type MainGiftsListArgs = {
  placement?: InputMaybe<Scalars['String']['input']>;
};

export enum MaritalStatusAttribute {
  DIVORCED = 'DIVORCED',
  IN_A_RELATIONSHIP = 'IN_A_RELATIONSHIP',
  MARRIED = 'MARRIED',
  NOT_GIVEN = 'NOT_GIVEN',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  SEPARATED = 'SEPARATED',
  SINGLE = 'SINGLE',
  WIDOWED = 'WIDOWED',
}

export type MaritalStatusDictionary = {
  __typename?: 'MaritalStatusDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<MaritalStatusAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type MaritalStatusUserAttributeDictionary = {
  __typename?: 'MaritalStatusUserAttributeDictionary';
  key?: Maybe<MaritalStatusAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type MarkAsScammerRoomsEvent = {
  __typename?: 'MarkAsScammerRoomsEvent';
  userId?: Maybe<Scalars['String']['output']>;
};

export type MarkNotificationPayload = {
  __typename?: 'MarkNotificationPayload';
  activity?: Maybe<Activity>;
  errors?: Maybe<MutationPayloadErrors>;
};

export type MaskedUserFields = {
  __typename?: 'MaskedUserFields';
  firstName?: Maybe<Scalars['String']['output']>;
  iban: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type MediaUpload = {
  __typename?: 'MediaUpload';
  completed?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  reward?: Maybe<Scalars['Int']['output']>;
};

export enum MediaUploadTypeEnum {
  withFullSizePhotoMotivation = 'withFullSizePhotoMotivation',
  withGalleryListAsMotivation = 'withGalleryListAsMotivation',
  withHighlightedMotivationAsNotice = 'withHighlightedMotivationAsNotice',
  withHorizontalIconsAsMotivation = 'withHorizontalIconsAsMotivation',
  withInverseBackgroundAndUploadActionMotivation = 'withInverseBackgroundAndUploadActionMotivation',
  withLargePhotoMotivationAndLogo = 'withLargePhotoMotivationAndLogo',
  withLargePhotoMotivationAndNoticeIcon = 'withLargePhotoMotivationAndNoticeIcon',
  withLargePhotoMotivationAndUploadIcon = 'withLargePhotoMotivationAndUploadIcon',
  withMotivationAsNotice = 'withMotivationAsNotice',
  withMotivationAsUploadNotice = 'withMotivationAsUploadNotice',
  withPhotoMotivation = 'withPhotoMotivation',
  withPhotoOnBackgroundAsMotivation = 'withPhotoOnBackgroundAsMotivation',
  withProfileCompletionMotivation = 'withProfileCompletionMotivation',
  withProfileProgressAsMotivation = 'withProfileProgressAsMotivation',
  withRandomUsersAndNoticeIconAsMotivation = 'withRandomUsersAndNoticeIconAsMotivation',
  withRandomUsersAsMotivation = 'withRandomUsersAsMotivation',
  withTileMotivation = 'withTileMotivation',
}

export type Menu = {
  __typename?: 'Menu';
  hasBillingHistory: Scalars['Boolean']['output'];
  isAllowedAlternativeNavigation: Scalars['Boolean']['output'];
  isMostPopularAvailable: Scalars['Boolean']['output'];
  isWebVersionAllowed: Scalars['Boolean']['output'];
  links?: Maybe<Array<Links>>;
};

export enum MerchantCapability {
  supports3DS = 'supports3DS',
}

export type Message = {
  __typename?: 'Message';
  chatLegend?: Maybe<Scalars['String']['output']>;
  direction?: Maybe<Direction>;
  id?: Maybe<Scalars['ID']['output']>;
  isBlured?: Maybe<Scalars['Boolean']['output']>;
  isPaidForView?: Maybe<Scalars['Boolean']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  isSending?: Maybe<Scalars['Boolean']['output']>;
  isUnsent?: Maybe<Scalars['Boolean']['output']>;
  needBlur?: Maybe<Scalars['Boolean']['output']>;
  photo?: Maybe<MessagePhoto>;
  senderId?: Maybe<Scalars['ID']['output']>;
  sticker?: Maybe<MessageSticker>;
  subject?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  upgradeReadVia?: Maybe<Scalars['String']['output']>;
  video?: Maybe<MessageVideo>;
};

export type MessageAssistantResponse = {
  __typename?: 'MessageAssistantResponse';
  message?: Maybe<Scalars['String']['output']>;
  prevMessage?: Maybe<Scalars['String']['output']>;
};

export type MessageEvent = {
  __typename?: 'MessageEvent';
  canSendNext?: Maybe<Scalars['Boolean']['output']>;
  chatLegend?: Maybe<Scalars['String']['output']>;
  direction?: Maybe<Direction>;
  id?: Maybe<Scalars['ID']['output']>;
  isBlured?: Maybe<Scalars['Boolean']['output']>;
  isPaidForView?: Maybe<Scalars['Boolean']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  isUnsent?: Maybe<Scalars['Boolean']['output']>;
  needBlur?: Maybe<Scalars['Boolean']['output']>;
  needSendFirst?: Maybe<Scalars['Boolean']['output']>;
  photo?: Maybe<MessagePhoto>;
  reason?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['ID']['output']>;
  sticker?: Maybe<MessageSticker>;
  subject?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  upgradeReadVia?: Maybe<Scalars['String']['output']>;
  video?: Maybe<MessageVideo>;
};

export type MessageMutationResult = {
  __typename?: 'MessageMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<Message>;
};

export type MessagePermissions = {
  __typename?: 'MessagePermissions';
  canSend?: Maybe<Scalars['Boolean']['output']>;
  canSendNext?: Maybe<Scalars['Boolean']['output']>;
  needSendFirst?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export type MessagePhoto = PhotoInterface & {
  __typename?: 'MessagePhoto';
  censored?: Maybe<Scalars['Boolean']['output']>;
  error: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isApprovedFromMessenger: Scalars['Int']['output'];
  isPaidForView?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  updatedOn?: Maybe<Scalars['String']['output']>;
};

export type MessageSticker = {
  __typename?: 'MessageSticker';
  contentLevel?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type MessageTemplateText = {
  __typename?: 'MessageTemplateText';
  id: Scalars['String']['output'];
  translateText: Scalars['String']['output'];
};

export type MessageTemplates = {
  __typename?: 'MessageTemplates';
  available: Scalars['Boolean']['output'];
  text?: Maybe<Array<Maybe<MessageTemplateText>>>;
};

export type MessageVideo = VideoInterface & {
  __typename?: 'MessageVideo';
  error: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isApprovedFromMessenger: Scalars['Int']['output'];
  isConverted?: Maybe<Scalars['Boolean']['output']>;
  isPaidForView?: Maybe<Scalars['Boolean']['output']>;
  level: Scalars['Int']['output'];
  percentage: Scalars['Int']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  videoPreviewUrl?: Maybe<Scalars['String']['output']>;
};

export type MessagesInput = {
  messageId?: InputMaybe<Scalars['String']['input']>;
  recipientId?: InputMaybe<Scalars['String']['input']>;
  senderId?: InputMaybe<Scalars['String']['input']>;
};

export type Messenger = {
  __typename?: 'Messenger';
  advancedStickers: Scalars['Boolean']['output'];
  config?: Maybe<Config>;
  gallery?: Maybe<MessengerGallery>;
  /** @deprecated Useless functionality. Need to stop use it in front and remove from scheme after. */
  inboxAvailable: Scalars['Boolean']['output'];
  initialData?: Maybe<MessengerData>;
  isAllowedSendViaEmail: Scalars['Boolean']['output'];
  messengerMaximizeAllowed: Scalars['Boolean']['output'];
  miniMessengerAllowed: Scalars['Boolean']['output'];
  photoSend?: Maybe<PhotoSend>;
  quickStickers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  quickStickersSendAmount?: Maybe<Scalars['Int']['output']>;
  recipient?: Maybe<Recipient>;
  ssg?: Maybe<Ssg>;
  videoSend?: Maybe<VideoSend>;
};

export type MessengerAdvancedStickersArgs = {
  recipientId?: InputMaybe<Scalars['ID']['input']>;
};

export type MessengerGalleryArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MessengerRecipientArgs = {
  recipientId?: InputMaybe<Scalars['ID']['input']>;
};

export type MessengerData = {
  __typename?: 'MessengerData';
  freeAssistantMessages: FreeMessages;
  freeMessages: FreeMessages;
  freeMessagesBanner?: Maybe<FreeMessagesBanner>;
  freeViews: FreeViews;
  globalFreeMessages?: Maybe<GlobalFreeMessages>;
  imb: ImbData;
  internalVideoData?: Maybe<InternalVideoData>;
  isAdditionalMotivationEnabled: Scalars['Boolean']['output'];
  isMoveToTrashEnabled: Scalars['Boolean']['output'];
  isNeedPhotoUpload?: Maybe<Scalars['Boolean']['output']>;
  isResendMessageAfterPayEnabled?: Maybe<Scalars['Boolean']['output']>;
  messageTemplates?: Maybe<MessageTemplates>;
  recipients?: Maybe<Array<Maybe<Recipient>>>;
  rewardForFirstPhotoOrEmailConfirm?: Maybe<Scalars['Int']['output']>;
};

export type MessengerGallery = {
  __typename?: 'MessengerGallery';
  available: Scalars['Boolean']['output'];
  items: Array<MessengerGalleryPhotoMessage>;
  unseenCount: Scalars['Int']['output'];
};

export type MessengerGalleryPhotoMessage = {
  __typename?: 'MessengerGalleryPhotoMessage';
  id: Scalars['ID']['output'];
  isSeen: Scalars['Boolean']['output'];
  needBlur?: Maybe<Scalars['Boolean']['output']>;
  photo?: Maybe<MessagePhoto>;
  user?: Maybe<UserData>;
};

export type MessengerMutation = {
  __typename?: 'MessengerMutation';
  changeMessageVisibility?: Maybe<BooleanMutationResult>;
  createVirtualAssistantSession?: Maybe<BooleanMutationResult>;
  deleteMessage?: Maybe<BooleanMutationResult>;
  deleteUnsentMessages?: Maybe<BooleanMutationResult>;
  deliveryMessage?: Maybe<BooleanMutationResult>;
  galleryItemViewed?: Maybe<BooleanMutationResult>;
  markAsRead?: Maybe<MutationPayload>;
  openExHumanAssistantChat?: Maybe<BooleanMutationResult>;
  setMessageVisibility?: Maybe<BooleanMutationResult>;
  viewMessage?: Maybe<MessageMutationResult>;
};

export type MessengerMutationChangeMessageVisibilityArgs = {
  needBlur?: InputMaybe<Scalars['Boolean']['input']>;
  senderId?: InputMaybe<Scalars['ID']['input']>;
};

export type MessengerMutationCreateVirtualAssistantSessionArgs = {
  virtualAssistantId?: InputMaybe<Scalars['ID']['input']>;
};

export type MessengerMutationDeleteMessageArgs = {
  messageId?: InputMaybe<Scalars['ID']['input']>;
};

export type MessengerMutationDeleteUnsentMessagesArgs = {
  recipientId?: InputMaybe<Scalars['ID']['input']>;
};

export type MessengerMutationDeliveryMessageArgs = {
  messageId?: InputMaybe<Scalars['ID']['input']>;
};

export type MessengerMutationGalleryItemViewedArgs = {
  itemId: Scalars['String']['input'];
};

export type MessengerMutationMarkAsReadArgs = {
  messages?: InputMaybe<Array<InputMaybe<MessagesInput>>>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MessengerMutationOpenExHumanAssistantChatArgs = {
  assistantId: Scalars['String']['input'];
};

export type MessengerMutationSetMessageVisibilityArgs = {
  messageId?: InputMaybe<Scalars['ID']['input']>;
};

export type MessengerMutationViewMessageArgs = {
  messageId: Scalars['ID']['input'];
};

export type MessengerPaidFeatures = {
  __typename?: 'MessengerPaidFeatures';
  messageCost?: Maybe<Scalars['Int']['output']>;
  photoSendCost?: Maybe<Scalars['Int']['output']>;
  videoSendCost?: Maybe<Scalars['Int']['output']>;
};

export enum Method {
  BCcard = 'BCcard',
  aliPay = 'aliPay',
  aliPayDirect = 'aliPayDirect',
  aliPayGlobal = 'aliPayGlobal',
  applePay = 'applePay',
  astropay = 'astropay',
  bancontact = 'bancontact',
  blik = 'blik',
  card = 'card',
  carteBancaire = 'carteBancaire',
  cartePrepagate = 'cartePrepagate',
  ccbill = 'ccbill',
  commDoo = 'commDoo',
  commDooSepa = 'commDooSepa',
  curoIdeal = 'curoIdeal',
  ddBillWeChat = 'ddBillWeChat',
  dimoco = 'dimoco',
  directa24 = 'directa24',
  directa24Boleto = 'directa24Boleto',
  directa24Oxxo = 'directa24Oxxo',
  directa24PagoEfectivo = 'directa24PagoEfectivo',
  directa24SPEI = 'directa24SPEI',
  directa24SevenEleven = 'directa24SevenEleven',
  directa24WebPay = 'directa24WebPay',
  ebanx = 'ebanx',
  ebanxDirect = 'ebanxDirect',
  eft = 'eft',
  genomePrzelewy24 = 'genomePrzelewy24',
  genomeTrustly = 'genomeTrustly',
  googlePay = 'googlePay',
  huawei = 'huawei',
  iDeal = 'iDeal',
  infobip = 'infobip',
  interac = 'interac',
  jcb = 'jcb',
  konbini = 'konbini',
  maxpayExternal = 'maxpayExternal',
  maxpaySms = 'maxpaySms',
  micropaymentSepa = 'micropaymentSepa',
  midas = 'midas',
  mobiGW = 'mobiGW',
  nebupay = 'nebupay',
  neteller = 'neteller',
  novalNetIDeal = 'novalNetIDeal',
  novalNetSepa = 'novalNetSepa',
  nuveiIdeal = 'nuveiIdeal',
  nuveiMyBank = 'nuveiMyBank',
  onebip = 'onebip',
  paygarden = 'paygarden',
  paypal = 'paypal',
  paypalv2 = 'paypalv2',
  paysafecard = 'paysafecard',
  pix = 'pix',
  pugglePay = 'pugglePay',
  rakuten = 'rakuten',
  razorpayUpi = 'razorpayUpi',
  razorpayWallet = 'razorpayWallet',
  rocketPay = 'rocketPay',
  royalPayQiwi = 'royalPayQiwi',
  royalPayYandex = 'royalPayYandex',
  saltEdgeAis = 'saltEdgeAis',
  sepa = 'sepa',
  sepaExpress = 'sepaExpress',
  sepaExpressV2 = 'sepaExpressV2',
  smart2PayGiroPay = 'smart2PayGiroPay',
  smart2PayIdeal = 'smart2PayIdeal',
  smart2PayKonbini = 'smart2PayKonbini',
  smart2PayMobile = 'smart2PayMobile',
  smart2PayPoli = 'smart2PayPoli',
  smart2paymercado = 'smart2paymercado',
  sofort = 'sofort',
  statisticApm = 'statisticApm',
  stripe = 'stripe',
  targetMediaIdeal = 'targetMediaIdeal',
  trustPayAlternative = 'trustPayAlternative',
  trustPayIDeal = 'trustPayIDeal',
  trustPaySepa = 'trustPaySepa',
  trustly = 'trustly',
  unionPay = 'unionPay',
  unionPayDirect = 'unionPayDirect',
  vendoSepa = 'vendoSepa',
  webBillingSepa = 'webBillingSepa',
  wechat = 'wechat',
}

export type MethodTabs = {
  __typename?: 'MethodTabs';
  isMultiSelect?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use tabName instead */
  method: Scalars['String']['output'];
  packages?: Maybe<Array<Maybe<PackageList>>>;
  paymentMethod: Scalars['String']['output'];
  tabLogo: Scalars['String']['output'];
  tabName: Scalars['String']['output'];
};

export type MicroFeature = {
  __typename?: 'MicroFeature';
  placements?: Maybe<Placements>;
};

export type MicroFeatureConfiguration = {
  __typename?: 'MicroFeatureConfiguration';
  isUseAlternativeTitles: Scalars['Boolean']['output'];
  modelType: ModelTypeEnum;
};

export type MicroFeatureList = {
  __typename?: 'MicroFeatureList';
  activeFrom?: Maybe<Scalars['Int']['output']>;
  bundledFeatures?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  expiredAt?: Maybe<Scalars['Int']['output']>;
  featureName: Scalars['String']['output'];
  microFeaturePackagePrice?: Maybe<Price>;
  packageDatePeriod: Scalars['String']['output'];
  packageId: Scalars['Int']['output'];
  packageInterval: Scalars['Int']['output'];
  payButtonText: Scalars['String']['output'];
  stockId: Scalars['String']['output'];
};

export type MicroFeaturePackage = {
  __typename?: 'MicroFeaturePackage';
  id?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Price>;
  stockId?: Maybe<Scalars['String']['output']>;
};

export type MicroFeatures = {
  __typename?: 'MicroFeatures';
  creditFeatureList?: Maybe<Array<Maybe<CreditFeature>>>;
  featuresList?: Maybe<Array<Maybe<Feature>>>;
  isAllowed: Scalars['Boolean']['output'];
  isCreditModelAllowed: Scalars['Boolean']['output'];
  isMultiSelectAllowed: Scalars['Boolean']['output'];
  isOneClickAllowed: Scalars['Boolean']['output'];
  isShortPriceEnabled: Scalars['Boolean']['output'];
  isSpecialOfferAllowed: Scalars['Boolean']['output'];
  isUseAlternativeTitles: Scalars['Boolean']['output'];
  microFeatureList?: Maybe<Array<Maybe<MicroFeatureList>>>;
  microFeaturesConfiguration?: Maybe<MicroFeatureConfiguration>;
  modelType?: Maybe<ModelTypeEnum>;
  pauseTime?: Maybe<Scalars['Int']['output']>;
  pedalOffer: PedalOffer;
  placements?: Maybe<Placements>;
  popularityLevel: PopularityLevelEnum;
  sexAlarm?: Maybe<SexAlarm>;
  unlockProfile?: Maybe<UnlockProfile>;
};

export type MicroFeaturesFeaturesListArgs = {
  resultType: ResultTypeEnum;
};

export type MicroFeaturesUnlockProfileArgs = {
  featureName: UnlockProfileFeature;
  packageId: Scalars['String']['input'];
};

export type MicroFeaturesEvent = {
  __typename?: 'MicroFeaturesEvent';
  microFeatureList?: Maybe<Array<Maybe<MicroFeatureList>>>;
};

export type MicropaymentSepaSettings = {
  __typename?: 'MicropaymentSepaSettings';
  maskedFields?: Maybe<MaskedUserFields>;
  sepaAutofillAccess: Scalars['Boolean']['output'];
};

export type MidasSettings = {
  __typename?: 'MidasSettings';
  iframeUrl: Scalars['String']['output'];
  paymentSessionId: Scalars['String']['output'];
};

export type MinorOption = {
  __typename?: 'MinorOption';
  alter?: Maybe<Option>;
  ignoreTimeout?: Maybe<Scalars['Int']['output']>;
  logic?: Maybe<Scalars['String']['output']>;
  popup?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  timeout?: Maybe<Scalars['Int']['output']>;
};

export type MobileSolutions = {
  __typename?: 'MobileSolutions';
  androidUrl?: Maybe<Scalars['String']['output']>;
  iosUrl?: Maybe<Scalars['String']['output']>;
};

export enum Model {
  coins = 'coins',
  membership = 'membership',
}

export enum ModelTypeEnum {
  credit = 'credit',
  default = 'default',
  free = 'free',
  multiSelect = 'multiSelect',
}

export type ModeratorMutations = {
  __typename?: 'ModeratorMutations';
  banUser?: Maybe<ResultErrorsResponse>;
  deleteRoomMessage?: Maybe<ResultErrorsResponse>;
  markScammer?: Maybe<ResultErrorsResponse>;
  unbanUser?: Maybe<ResultErrorsResponse>;
};

export type ModeratorMutationsBanUserArgs = {
  userId: Scalars['String']['input'];
};

export type ModeratorMutationsDeleteRoomMessageArgs = {
  fromUserId: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
  roomId: Scalars['String']['input'];
};

export type ModeratorMutationsMarkScammerArgs = {
  userId: Scalars['String']['input'];
};

export type ModeratorMutationsUnbanUserArgs = {
  id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export enum MoneyRoleAttribute {
  NOT_GIVEN = 'NOT_GIVEN',
  SUGARBABBY = 'SUGARBABBY',
  SUGARDADDY = 'SUGARDADDY',
  SUGARMOMMY = 'SUGARMOMMY',
}

export type MoneyRoleDictionary = {
  __typename?: 'MoneyRoleDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<MoneyRoleAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type MoneyRoleUserAttributeDictionary = {
  __typename?: 'MoneyRoleUserAttributeDictionary';
  key?: Maybe<MoneyRoleAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type MotivationBackgrounds = {
  __typename?: 'MotivationBackgrounds';
  bannerUpgrade?: Maybe<Scalars['String']['output']>;
  offerPopup?: Maybe<Scalars['String']['output']>;
  searchExtraTools?: Maybe<Scalars['String']['output']>;
  searchUpgrade?: Maybe<Scalars['String']['output']>;
};

export type MotivationBanner = {
  __typename?: 'MotivationBanner';
  displayOnlyTop: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
};

export type MotivationData = {
  __typename?: 'MotivationData';
  motivationKey?: Maybe<Scalars['String']['output']>;
  motivationTextRules?: Maybe<Array<Maybe<MotivationTextRuleData>>>;
};

export type MotivationParamsData = {
  __typename?: 'MotivationParamsData';
  action?: Maybe<Scalars['String']['output']>;
  replacePackageId?: Maybe<Scalars['Int']['output']>;
  via?: Maybe<Array<ViaEnum>>;
};

export type MotivationTextData = {
  __typename?: 'MotivationTextData';
  motivationTemplate?: Maybe<Scalars['String']['output']>;
  motivationText: Scalars['String']['output'];
};

export type MotivationTextRuleData = {
  __typename?: 'MotivationTextRuleData';
  motivationText?: Maybe<MotivationTextData>;
  params?: Maybe<MotivationParamsData>;
};

export enum MuslimBornAttribute {
  BORN_A_MUSLIM = 'BORN_A_MUSLIM',
  NOT_GIVEN = 'NOT_GIVEN',
  PLAN_TO_REVERT_TO_ISLAM = 'PLAN_TO_REVERT_TO_ISLAM',
  REVERTED_TO_ISLAM = 'REVERTED_TO_ISLAM',
}

export type MuslimBornDictionary = {
  __typename?: 'MuslimBornDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<MuslimBornAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type MuslimBornUserAttributeDictionary = {
  __typename?: 'MuslimBornUserAttributeDictionary';
  key?: Maybe<MuslimBornAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  allowExtraPopups?: Maybe<BooleanMutationResult>;
  billingHistory?: Maybe<BillingHistoryMutation>;
  canSendNext?: Maybe<Scalars['Boolean']['output']>;
  cancelSubscription?: Maybe<CancelSubscriptionMutation>;
  chatrooms?: Maybe<ChatroomsMutations>;
  confirm?: Maybe<ConfirmMutation>;
  credits?: Maybe<CreditsMutation>;
  deleteConversation?: Maybe<DeleteConversationResponse>;
  deviceDetectionTrack?: Maybe<BooleanMutationResult>;
  discreetProfile?: Maybe<DiscreetProfilePayload>;
  error?: Maybe<Scalars['String']['output']>;
  funnel?: Maybe<FunnelPayload>;
  like?: Maybe<BooleanMutationResult>;
  logout: Scalars['Boolean']['output'];
  markNotification?: Maybe<MarkNotificationPayload>;
  messenger?: Maybe<MessengerMutation>;
  moderator?: Maybe<ModeratorMutations>;
  notifications?: Maybe<NotificationsMutation>;
  passwordRecovery?: Maybe<PasswordRecoveryMutation>;
  payment?: Maybe<PaymentMutation>;
  privateVideo?: Maybe<PrivateVideoResponse>;
  pwa?: Maybe<PwaPayload>;
  reason?: Maybe<Scalars['String']['output']>;
  reasonExtraData?: Maybe<ReasonExtraData>;
  remarketing?: Maybe<RemarketingMutation>;
  removeAccount?: Maybe<RemoveAccountMutation>;
  search?: Maybe<SearchMutation>;
  searchWithMap?: Maybe<SearchWithMapPayload>;
  sendMessage?: Maybe<SendMessageResponse>;
  sendRoomMessage?: Maybe<ResultErrorsResponse>;
  setInteractiveLikeBonus?: Maybe<DiscountMutationResult>;
  site?: Maybe<SiteMutation>;
  speedDating?: Maybe<SpeedDatingMutation>;
  spin?: Maybe<SpinMutation>;
  stories?: Maybe<StoriesMutation>;
  support?: Maybe<SupportMutation>;
  /** @deprecated Use Phoenix\GraphQL\Components\FrontendTracking */
  track?: Maybe<Track>;
  trackCTR?: Maybe<ErrorResponce>;
  trackInventory?: Maybe<ErrorResponce>;
  trackMediaUploadError?: Maybe<ErrorResponce>;
  trackPaymentPageDescriptor?: Maybe<ErrorResponce>;
  trackPaymentPendingPageStep?: Maybe<ErrorResponce>;
  trackRmPopup?: Maybe<ErrorResponce>;
  trusted?: Maybe<TrustedMutation>;
  upgradeSendVia?: Maybe<ViaEnum>;
  user?: Maybe<UserMutation>;
  userFeatures?: Maybe<UserFeaturesPayload>;
  userIdentify?: Maybe<UserIdentify>;
  virtualAssistant?: Maybe<VirtualAssistantPayload>;
  webCamRegistrationLink?: Maybe<UrlResponse>;
};

export type MutationDeleteConversationArgs = {
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationLikeArgs = {
  photoId?: InputMaybe<Scalars['ID']['input']>;
  place?: InputMaybe<LikePlaceEnum>;
  userId: Scalars['ID']['input'];
};

export type MutationMarkNotificationArgs = {
  id: Scalars['ID']['input'];
  targetUserId: Scalars['ID']['input'];
};

export type MutationPrivateVideoArgs = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['String']['input'];
};

export type MutationSendMessageArgs = {
  copyPasteDetected: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  messageType: Scalars['String']['input'];
  storyFragmentId?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  toId: Scalars['String']['input'];
};

export type MutationSendRoomMessageArgs = {
  copyPasteDetected: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  rid: Scalars['String']['input'];
};

export type MutationSetInteractiveLikeBonusArgs = {
  processId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationTrackCtrArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  idsBlurred?: InputMaybe<Array<Scalars['String']['input']>>;
  type: Scalars['String']['input'];
};

export type MutationTrackInventoryArgs = {
  data: TrackingData;
};

export type MutationTrackMediaUploadErrorArgs = {
  events: Array<InputMaybe<EventsInput>>;
};

export type MutationTrackPaymentPageDescriptorArgs = {
  descriptor?: InputMaybe<Scalars['String']['input']>;
  method: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationTrackPaymentPendingPageStepArgs = {
  orderId: Scalars['String']['input'];
  step: Scalars['String']['input'];
  trackingName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MutationTrackRmPopupArgs = {
  events?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MutationWebCamRegistrationLinkArgs = {
  url: Scalars['String']['input'];
};

export type MutationArgumentErrors = {
  __typename?: 'MutationArgumentErrors';
  argument?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type MutationPayload = {
  __typename?: 'MutationPayload';
  errors?: Maybe<MutationPayloadErrors>;
};

export type MutationPayloadErrors = {
  __typename?: 'MutationPayloadErrors';
  arguments?: Maybe<Array<Maybe<MutationArgumentErrors>>>;
  general?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type MyUserDictionary = {
  __typename?: 'MyUserDictionary';
  attributes?: Maybe<ProfileDictionaryAttributesTypes>;
};

export type MyUserMutation = {
  __typename?: 'MyUserMutation';
  attributes?: Maybe<BooleanMutationResult>;
  birthday?: Maybe<BooleanMutationResult>;
  changeEmail?: Maybe<BooleanMutationResult>;
  changePassword?: Maybe<BooleanMutationResult>;
  changePhoneNumber?: Maybe<BooleanMutationResult>;
  chatUpLine?: Maybe<BooleanMutationResult>;
  descriptionLookingFor?: Maybe<BooleanMutationResult>;
  firstLogin?: Maybe<BooleanMutationResult>;
  login?: Maybe<BooleanMutationResult>;
  photo?: Maybe<MyUserPhotoType>;
  resetPassword?: Maybe<Scalars['String']['output']>;
  video?: Maybe<MyUserVideoType>;
};

export type MyUserMutationAttributesArgs = {
  attributes: InputUserAttributes;
};

export type MyUserMutationBirthdayArgs = {
  birthday: Scalars['String']['input'];
};

export type MyUserMutationChangeEmailArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MyUserMutationChangePasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type MyUserMutationChangePhoneNumberArgs = {
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type MyUserMutationChatUpLineArgs = {
  chatUpLine: Scalars['String']['input'];
  variantId?: InputMaybe<Scalars['String']['input']>;
};

export type MyUserMutationDescriptionLookingForArgs = {
  descriptionLookingFor: Scalars['String']['input'];
};

export type MyUserMutationLoginArgs = {
  login: Scalars['String']['input'];
};

export type MyUserPhotoDeleteType = {
  __typename?: 'MyUserPhotoDeleteType';
  pendingDelete?: Maybe<Scalars['Boolean']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MyUserPhotoDeleteTypeMutationResult = {
  __typename?: 'MyUserPhotoDeleteTypeMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<MyUserPhotoDeleteType>;
};

export type MyUserPhotoType = {
  __typename?: 'MyUserPhotoType';
  changePrimary?: Maybe<BooleanMutationResult>;
  deletePhoto?: Maybe<MyUserPhotoDeleteTypeMutationResult>;
};

export type MyUserPhotoTypeChangePrimaryArgs = {
  photoId: Scalars['String']['input'];
};

export type MyUserPhotoTypeDeletePhotoArgs = {
  photoId: Scalars['String']['input'];
};

export type MyUserVideoType = {
  __typename?: 'MyUserVideoType';
  deleteVideo?: Maybe<BooleanMutationResult>;
};

export type MyUserVideoTypeDeleteVideoArgs = {
  videoId: Scalars['String']['input'];
};

export type NaughtyMode = {
  __typename?: 'NaughtyMode';
  available: Scalars['Boolean']['output'];
  availableModes?: Maybe<Array<Maybe<NaughtyModeInfo>>>;
  freeModes: Array<Maybe<Scalars['Int']['output']>>;
  isAllowedAdultMedia: Scalars['Boolean']['output'];
  /** @deprecated NaughtyMode isn't content level. Use field NaughtyMode */
  level: Scalars['Int']['output'];
  naughtyMode: Scalars['Int']['output'];
};

export type NaughtyModeInfo = {
  __typename?: 'NaughtyModeInfo';
  contentLevels: Array<Maybe<Scalars['Int']['output']>>;
  id: Scalars['Int']['output'];
};

export type NeoOffer = {
  __typename?: 'NeoOffer';
  notRegistered?: Maybe<Array<Maybe<NeoOfferSite>>>;
};

export type NeoOfferSite = {
  __typename?: 'NeoOfferSite';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum NetWorthAttribute {
  MORE_THAN__100_MILLION = 'MORE_THAN__100_MILLION',
  NOT_GIVEN = 'NOT_GIVEN',
  USD_1_MILLION = 'USD_1_MILLION',
  USD_2_MILLION = 'USD_2_MILLION',
  USD_5_MILLION = 'USD_5_MILLION',
  USD_10_MILLION = 'USD_10_MILLION',
  USD_50_MILLION = 'USD_50_MILLION',
  USD_100_MILLION = 'USD_100_MILLION',
  USD_250_000 = 'USD_250_000',
  USD_500_000 = 'USD_500_000',
}

export type NetWorthDictionary = {
  __typename?: 'NetWorthDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<NetWorthAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type NetWorthUserAttributeDictionary = {
  __typename?: 'NetWorthUserAttributeDictionary';
  key?: Maybe<NetWorthAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum Network {
  amex = 'amex',
  bancomat = 'bancomat',
  bancontact = 'bancontact',
  cartesBancaires = 'cartesBancaires',
  chinaUnionPay = 'chinaUnionPay',
  dankort = 'dankort',
  discover = 'discover',
  eftpos = 'eftpos',
  electron = 'electron',
  elo = 'elo',
  girocard = 'girocard',
  interac = 'interac',
  jcb = 'jcb',
  mada = 'mada',
  maestro = 'maestro',
  masterCard = 'masterCard',
  mir = 'mir',
  privateLabel = 'privateLabel',
  vPay = 'vPay',
  visa = 'visa',
}

export type NextStep = {
  __typename?: 'NextStep';
  cancelViaEmailData?: Maybe<CancelViaEmail>;
  isFinished?: Maybe<Scalars['Boolean']['output']>;
  isHotlineCall: Scalars['Boolean']['output'];
  nextStep: Step;
  reactivateDiscountPrice?: Maybe<Price>;
  redirectPath?: Maybe<Scalars['String']['output']>;
  subscriptionExpireDate?: Maybe<Scalars['String']['output']>;
  transferUrl?: Maybe<Scalars['String']['output']>;
};

export type NextStepMutationResult = {
  __typename?: 'NextStepMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<NextStep>;
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  messagesActivity: Array<Maybe<NotificationSettingsItem>>;
  newActivity: Array<Maybe<NotificationSettingsItem>>;
  newsFeedActivity: Array<Maybe<NotificationSettingsItem>>;
  subscriptions?: Maybe<Subscriptions>;
};

export type NotificationSettingsItem = {
  __typename?: 'NotificationSettingsItem';
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type NotificationSettingsItemInput = {
  name: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationWidgets = {
  __typename?: 'NotificationWidgets';
  delay?: Maybe<Delay>;
  list?: Maybe<Array<Maybe<Widget>>>;
};

export type Notifications = {
  __typename?: 'Notifications';
  freeMessageNotification?: Maybe<FreeMessageNotification>;
  notificationSettings?: Maybe<NotificationSettings>;
  notificationWidgets?: Maybe<NotificationWidgets>;
  stickedActivityNotification?: Maybe<StickedActivityNotification>;
};

export type NotificationsMutation = {
  __typename?: 'NotificationsMutation';
  allSubscriptions?: Maybe<BooleanMutationResult>;
  messagesActivity?: Maybe<BooleanMutationResult>;
  newActivity?: Maybe<BooleanMutationResult>;
  newsFeedActivity?: Maybe<BooleanMutationResult>;
  subscriptions?: Maybe<BooleanMutationResult>;
};

export type NotificationsMutationAllSubscriptionsArgs = {
  emailTrack?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<SourceInput>;
  subscribe: Scalars['Boolean']['input'];
  transports: Array<SubscriptionTypeItem>;
  uniqueMessageId?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationsMutationMessagesActivityArgs = {
  params: Array<InputMaybe<NotificationSettingsItemInput>>;
};

export type NotificationsMutationNewActivityArgs = {
  params: Array<InputMaybe<NotificationSettingsItemInput>>;
};

export type NotificationsMutationNewsFeedActivityArgs = {
  params: Array<InputMaybe<NotificationSettingsItemInput>>;
};

export type NotificationsMutationSubscriptionsArgs = {
  emailTrack?: InputMaybe<Scalars['String']['input']>;
  params: Array<InputMaybe<SubscriptionItemInput>>;
  source?: InputMaybe<SourceInput>;
  uniqueMessageId?: InputMaybe<Scalars['String']['input']>;
};

export type NovalNetIdealSettings = {
  __typename?: 'NovalNetIdealSettings';
  bicList: Array<BicInfo>;
  formAttributes?: Maybe<FormAttributes>;
  method?: Maybe<Method>;
  requiredFields?: Maybe<Array<Scalars['String']['output']>>;
  sepaFormStyle?: Maybe<Scalars['String']['output']>;
};

export type NovalNetSepaSettings = {
  __typename?: 'NovalNetSepaSettings';
  isPrefilled: Scalars['Boolean']['output'];
  maskedFields?: Maybe<MaskedUserFields>;
  method?: Maybe<Method>;
  sepaAutofillAccess: Scalars['Boolean']['output'];
  sepaFormStyle?: Maybe<Scalars['String']['output']>;
};

export type NuveiIdealSettings = {
  __typename?: 'NuveiIdealSettings';
  bicList: Array<BicInfo>;
  isPrefilled: Scalars['Boolean']['output'];
  maskedFields?: Maybe<MaskedUserFields>;
};

export type NuveiMyBankSettings = {
  __typename?: 'NuveiMyBankSettings';
  isPrefilled: Scalars['Boolean']['output'];
  maskedFields?: Maybe<MaskedUserFields>;
  requiredFields?: Maybe<Array<Scalars['String']['output']>>;
};

export enum OccupationAttribute {
  ADMINISTRATIVE_SECRETARIAL = 'ADMINISTRATIVE_SECRETARIAL',
  ARTISTIC_CREATIVE = 'ARTISTIC_CREATIVE',
  BANKING_FINANCIAL_SERVICES = 'BANKING_FINANCIAL_SERVICES',
  CHARITY = 'CHARITY',
  CONSULTANCY_ANALYSIS = 'CONSULTANCY_ANALYSIS',
  EDUCATION = 'EDUCATION',
  EXECUTIVE_MANAGEMENT = 'EXECUTIVE_MANAGEMENT',
  FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
  HEALTHCARE = 'HEALTHCARE',
  IT_COMPUTING = 'IT_COMPUTING',
  LABOR_CONSTRUCTION = 'LABOR_CONSTRUCTION',
  LEGAL_PROFESSIONAL = 'LEGAL_PROFESSIONAL',
  LEISURE_HOTELS_CATERING = 'LEISURE_HOTELS_CATERING',
  MANUFACTURING_ENGINEERING = 'MANUFACTURING_ENGINEERING',
  MEDIA_ADVERTISING_PR = 'MEDIA_ADVERTISING_PR',
  MEDICAL_VETERINARY = 'MEDICAL_VETERINARY',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  POLITICAL_MILITARY = 'POLITICAL_MILITARY',
  PUBLIC_SECTOR_ARMED_FORCES = 'PUBLIC_SECTOR_ARMED_FORCES',
  RETAIL_FOOD_SERVICES = 'RETAIL_FOOD_SERVICES',
  RETAIL_WHOLESALE_TRADE = 'RETAIL_WHOLESALE_TRADE',
  SALES_MARKETING = 'SALES_MARKETING',
  SCIENTIST_RESEARCHER = 'SCIENTIST_RESEARCHER',
  SKILLED_UNSKILLED_TRADE = 'SKILLED_UNSKILLED_TRADE',
  TEACHER_PROFESSOR = 'TEACHER_PROFESSOR',
  TELECOMMUNICATIONS_UTILITIES = 'TELECOMMUNICATIONS_UTILITIES',
  TRAVEL_HOSPITALITY = 'TRAVEL_HOSPITALITY',
}

export type OccupationDictionary = {
  __typename?: 'OccupationDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<OccupationAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type OccupationUserAttributeDictionary = {
  __typename?: 'OccupationUserAttributeDictionary';
  key?: Maybe<OccupationAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type OffersResponse = {
  __typename?: 'OffersResponse';
  offers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type OnlyFromMyCountryDictionary = {
  __typename?: 'OnlyFromMyCountryDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['Boolean']['output']>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum OpenProfileSource {
  fromChat = 'fromChat',
  fromSearch = 'fromSearch',
}

export type OpenSpecialOfferPopup = {
  __typename?: 'OpenSpecialOfferPopup';
  timestamp?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Option = {
  __typename?: 'Option';
  ignoreTimeout?: Maybe<Scalars['Int']['output']>;
  logic?: Maybe<Scalars['String']['output']>;
  popup?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  timeout?: Maybe<Scalars['Int']['output']>;
};

export type Order = {
  __typename?: 'Order';
  domain?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Price>;
  repeatPackage?: Maybe<RepeatPackage>;
  title?: Maybe<Scalars['String']['output']>;
};

export type OrderData = {
  __typename?: 'OrderData';
  status?: Maybe<OrderStatus>;
};

export enum OrderStatus {
  failure = 'failure',
  pending = 'pending',
  success = 'success',
}

export type Package = {
  __typename?: 'Package';
  additionalPackageList?: Maybe<Array<Maybe<AdditionalPackageList>>>;
  interval: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  isTrialMembership: Scalars['Boolean']['output'];
  /** @deprecated Use tabName instead */
  method: Scalars['String']['output'];
  packageBenefits: PackageBenefits;
  packageDescription?: Maybe<Scalars['String']['output']>;
  packageId: Scalars['Int']['output'];
  packageName: Scalars['String']['output'];
  packagePricingDetails: PackagePricingDetails;
  packageTypes: Array<Scalars['String']['output']>;
  paymentMethod: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  repeatPackage?: Maybe<RepeatPackage>;
  stockId: Scalars['String']['output'];
  tabLogo: Scalars['String']['output'];
  tabName: Scalars['String']['output'];
  title: Scalars['String']['output'];
  tokenPrice?: Maybe<TokenPrice>;
  visualizationType?: Maybe<PackageVisualizationTypeEnum>;
};

export type PackageBenefits = {
  __typename?: 'PackageBenefits';
  additionalBenefits: Array<Scalars['String']['output']>;
  benefits: Array<Scalars['String']['output']>;
  packageBenefit: Scalars['String']['output'];
  translatedAdditionalBenefits: Array<Maybe<BenefitTranslation>>;
  translatedBenefits: Array<Maybe<BenefitTranslation>>;
  translatedPackageBenefit?: Maybe<Scalars['String']['output']>;
};

export type PackageData = {
  __typename?: 'PackageData';
  packagePriceData?: Maybe<PriceData>;
};

export type PackageDescription = {
  __typename?: 'PackageDescription';
  footerRule: Scalars['String']['output'];
  packageList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  translateMessages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type PackageList = {
  __typename?: 'PackageList';
  additionalPackageList?: Maybe<Array<Maybe<AdditionalPackageList>>>;
  interval: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  isTrialMembership: Scalars['Boolean']['output'];
  packageBenefits: PackageBenefits;
  packageDescription?: Maybe<Scalars['String']['output']>;
  packageId: Scalars['Int']['output'];
  packageName: Scalars['String']['output'];
  packagePricingDetails: PackagePricingDetails;
  packageTypes: Array<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  repeatPackage?: Maybe<RepeatPackage>;
  stockId: Scalars['String']['output'];
  title: Scalars['String']['output'];
  tokenPrice?: Maybe<TokenPrice>;
  visualizationType?: Maybe<PackageVisualizationTypeEnum>;
};

export type PackageOffer = {
  __typename?: 'PackageOffer';
  discount?: Maybe<Scalars['Int']['output']>;
  discountedPrice?: Maybe<Price>;
  fullPrice?: Maybe<Price>;
  stockId: Scalars['String']['output'];
  template: Scalars['String']['output'];
};

export type PackagePrice = {
  __typename?: 'PackagePrice';
  currency?: Maybe<Currency>;
  full?: Maybe<PriceValues>;
  partsCount: Scalars['Int']['output'];
  perPart?: Maybe<PriceValues>;
  /** @deprecated Use perPart instead */
  perPeriod?: Maybe<PriceValues>;
  quantityPerPart: Scalars['Int']['output'];
  repeatAmount?: Maybe<PriceValues>;
};

export type PackagePriceData = {
  __typename?: 'PackagePriceData';
  fullPrice?: Maybe<Price>;
  priceParts: Scalars['Int']['output'];
  pricePerPart?: Maybe<Price>;
  quantityPerPart: Scalars['Int']['output'];
  /** @deprecated use repeatPackage->price */
  repeatAmount?: Maybe<Price>;
};

export type PackagePricingDetails = {
  __typename?: 'PackagePricingDetails';
  /** @deprecated use packageDiscountPriceData field instead */
  discountedPrice?: Maybe<PackagePrice>;
  packageDiscountPriceData?: Maybe<PackagePriceData>;
  /** @deprecated use packagePriceData field instead */
  packagePrice: PackagePrice;
  packagePriceData?: Maybe<PackagePriceData>;
  percentDiscount?: Maybe<Scalars['Int']['output']>;
  translateKeyPerPeriod?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type PackageTypeBenefits = {
  __typename?: 'PackageTypeBenefits';
  benefits: Array<Maybe<Benefits>>;
  translatedBenefits: Array<Maybe<BenefitTranslation>>;
};

export enum PackageVisualizationTypeEnum {
  default = 'default',
  withTickets = 'withTickets',
}

export type Packages = {
  __typename?: 'Packages';
  altMethodsScenario?: Maybe<Array<Maybe<AltMethodsScenario>>>;
  altMethodsSettings?: Maybe<Array<Maybe<AltMethodsSettings>>>;
  defaultActiveTab?: Maybe<Method>;
  methodTabs?: Maybe<Array<Maybe<MethodTabs>>>;
  /** @deprecated packagesData.packagesList is deprecated, use packagesData.methodTabs  instead, remove after 2 month FE-27330 */
  packagesList?: Maybe<Array<Maybe<Package>>>;
  paymentMethodLogos?: Maybe<Array<Maybe<PaymentMethodLogoData>>>;
};

export type PaginationData = {
  __typename?: 'PaginationData';
  isNextPageExist?: Maybe<Scalars['Boolean']['output']>;
  photoPage?: Maybe<Scalars['Int']['output']>;
};

export type PaidFunnel = {
  __typename?: 'PaidFunnel';
  package?: Maybe<Array<Maybe<Package>>>;
  payUrl?: Maybe<Scalars['String']['output']>;
  payUrlParams?: Maybe<PayUrlParams>;
};

export enum PaidFunnelViaEnum {
  ftcard_1st = 'ftcard_1st',
  ftcard_1st_special = 'ftcard_1st_special',
  ftcard_2st = 'ftcard_2st',
  ftcard_2st_lifetime = 'ftcard_2st_lifetime',
  ftcard_pp = 'ftcard_pp',
  ftcard_trial = 'ftcard_trial',
}

export type Params = {
  __typename?: 'Params';
  age?: Maybe<AgeRange>;
  attributes?: Maybe<SearchAttributes>;
  gender?: Maybe<GenderTypeEnum>;
  location?: Maybe<SearchLocation>;
  onlyFromMyCountry?: Maybe<Scalars['Boolean']['output']>;
  photoLevel?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  sortType?: Maybe<SortSearchType>;
  tab?: Maybe<TabSearchType>;
  withPhoto: Scalars['Boolean']['output'];
  withPrivateAlbum?: Maybe<Scalars['Boolean']['output']>;
  withVideo: Scalars['Boolean']['output'];
};

export type ParamsDictionary = {
  __typename?: 'ParamsDictionary';
  age?: Maybe<AgeRange>;
  attributes?: Maybe<DictionaryAttributes>;
  gender?: Maybe<Array<Maybe<GenderDictionary>>>;
  location?: Maybe<LocationSearchDictionary>;
  onlyFromMyCountry?: Maybe<OnlyFromMyCountryDictionary>;
  photoLevel?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  sortType?: Maybe<Array<Maybe<SortSearchType>>>;
  tab?: Maybe<Array<Maybe<TabSearchType>>>;
  withPhoto?: Maybe<WithPhotoDictionary>;
  withPrivateAlbum?: Maybe<WithPrivateAlbumDictionary>;
  withVideo?: Maybe<WithVideoDictionary>;
};

export type ParamsLegacy = {
  __typename?: 'ParamsLegacy';
  advanced?: Maybe<AdvancedSearchParamsList>;
  ageBoundaries?: Maybe<AgeBoundaries>;
  attributes?: Maybe<AttributesSearchForm>;
  currenTab?: Maybe<Scalars['String']['output']>;
  currentSortType?: Maybe<Scalars['String']['output']>;
  defaultCities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  possibleSortTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  possibleTabs?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  userLocation?: Maybe<Scalars['String']['output']>;
};

export type PasswordRecoveryMutation = {
  __typename?: 'PasswordRecoveryMutation';
  recovery?: Maybe<RecoveryMutationResult>;
  track?: Maybe<BooleanMutationResult>;
};

export type PasswordRecoveryMutationRecoveryArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  newPassword?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type PasswordRecoveryMutationTrackArgs = {
  code: Scalars['String']['input'];
  emlTrack: Scalars['String']['input'];
  uniqId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type PayAnswer = {
  __typename?: 'PayAnswer';
  declineMessageType?: Maybe<Scalars['String']['output']>;
  declineScreenTemplate?: Maybe<Scalars['String']['output']>;
  failPageLogic?: Maybe<Scalars['String']['output']>;
  failReason?: Maybe<Scalars['String']['output']>;
  isEmptyFormAfterDecline?: Maybe<Scalars['Boolean']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
};

export enum PayButtonEnum {
  applePay = 'applePay',
  default = 'default',
  googlePay = 'googlePay',
  paypal = 'paypal',
}

export type PaygardenSettings = {
  __typename?: 'PaygardenSettings';
  action: Scalars['String']['output'];
  method?: Maybe<Method>;
};

export type Payment = {
  __typename?: 'Payment';
  acceptedCardLengths: Array<Scalars['Int']['output']>;
  accountStatus?: Maybe<AccountStatus>;
  afterPayData?: Maybe<AfterPayData>;
  alternativeSidebarMotivation: Scalars['Boolean']['output'];
  appPaidFunnelViaList?: Maybe<Array<Maybe<PaidFunnelViaEnum>>>;
  banner?: Maybe<BannerData>;
  binValidation?: Maybe<BinValidation>;
  card?: Maybe<Card>;
  cardBinInfo?: Maybe<CardBinInfo>;
  cardTypeRules?: Maybe<Array<Maybe<CardTypeRule>>>;
  cardWalletDisplaySettings?: Maybe<CardWalletDisplaySettings>;
  cvvLength: Scalars['Int']['output'];
  debtor?: Maybe<Debtor>;
  descriptor?: Maybe<Descriptor>;
  /** @deprecated use payment.packagesData.packagesList.percentDiscount */
  discount?: Maybe<Scalars['Int']['output']>;
  discountData?: Maybe<DiscountData>;
  extraDiscount?: Maybe<ExtraDiscount>;
  /** @deprecated use ExtraDiscount field instead */
  extraHourTimer?: Maybe<Scalars['ID']['output']>;
  fraudNet?: Maybe<FraudNet>;
  helpChatSettings?: Maybe<HelpChatSettings>;
  hideDescriptor: Scalars['Boolean']['output'];
  isAdditionalTermsChecked?: Maybe<Scalars['Boolean']['output']>;
  isAllowedAdminPhoto: Scalars['Boolean']['output'];
  isAllowedAnonymousCheckout: Scalars['Boolean']['output'];
  isFastPaymentPage: Scalars['Boolean']['output'];
  isOneClickAllowed?: Maybe<Scalars['Boolean']['output']>;
  isPaymentPageTimeTrackingEnabled: Scalars['Boolean']['output'];
  isUserBoughtPackage: Scalars['Boolean']['output'];
  last3dsPaymentStatus?: Maybe<Scalars['String']['output']>;
  longFormFields?: Maybe<LongFormFields>;
  model: Model;
  motivation?: Maybe<MotivationData>;
  /** @deprecated Use stepFlow->nextStep instead */
  nextStep?: Maybe<StepNameEnum>;
  orderData?: Maybe<OrderData>;
  packageOfferData?: Maybe<PackageOffer>;
  packageTypeBenefits?: Maybe<PackageTypeBenefits>;
  packagesData?: Maybe<Packages>;
  pageEnterTime?: Maybe<Scalars['Int']['output']>;
  payAnswer?: Maybe<PayAnswer>;
  paymentFooter?: Maybe<PaymentFooter>;
  paymentMethodSettings?: Maybe<AltMethodsSettings>;
  paymentPageDetails?: Maybe<Array<Maybe<PaymentPageDetailTypeEnum>>>;
  paymentPageVisits: Scalars['Int']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  processingFee?: Maybe<ProcessingFee>;
  processingStatus?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<RedirectUrl>;
  remarketingOfferData?: Maybe<Array<Maybe<RemarketingOffer>>>;
  replaceRedirectToPostMessage?: Maybe<Scalars['Boolean']['output']>;
  retryPermissionUrl?: Maybe<RetryPermissionUrl>;
  secondPPCSiteDomain?: Maybe<Scalars['String']['output']>;
  speedDating?: Maybe<SpeedDatingPayment>;
  stepFlow: StepFlow;
  successData?: Maybe<SuccessData>;
  templateSettings?: Maybe<TemplateSettings>;
  uniqueVisitId?: Maybe<Scalars['String']['output']>;
  userHumanId?: Maybe<Scalars['ID']['output']>;
  vatRate?: Maybe<Scalars['Float']['output']>;
  verification?: Maybe<VerificationQuery>;
  yearOfferData?: Maybe<YearOffer>;
};

export type PaymentAcceptedCardLengthsArgs = {
  bin: Scalars['Int']['input'];
};

export type PaymentBinValidationArgs = {
  cardNumber: Scalars['String']['input'];
  packageId: Scalars['Int']['input'];
};

export type PaymentCardArgs = {
  packageId?: InputMaybe<Scalars['Int']['input']>;
  via?: InputMaybe<ViaEnum>;
};

export type PaymentCardBinInfoArgs = {
  cardNumber: Scalars['String']['input'];
};

export type PaymentCvvLengthArgs = {
  bin: Scalars['Int']['input'];
};

export type PaymentDescriptorArgs = {
  bin?: InputMaybe<Scalars['Int']['input']>;
  cardId?: InputMaybe<Scalars['String']['input']>;
  isOneClickUsed?: InputMaybe<Scalars['Boolean']['input']>;
  isPopupPayment?: InputMaybe<Scalars['Boolean']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  stockId?: InputMaybe<Scalars['String']['input']>;
  via?: InputMaybe<ViaEnum>;
};

export type PaymentDiscountArgs = {
  action?: InputMaybe<Action>;
  prevVia?: InputMaybe<ViaEnum>;
  via?: InputMaybe<ViaEnum>;
};

export type PaymentDiscountDataArgs = {
  action?: InputMaybe<Action>;
  prevVia?: InputMaybe<ViaEnum>;
  via?: InputMaybe<ViaEnum>;
};

export type PaymentExtraDiscountArgs = {
  action: Action;
  prevVia?: InputMaybe<ViaEnum>;
  via?: InputMaybe<ViaEnum>;
};

export type PaymentHelpChatSettingsArgs = {
  identifier: Scalars['String']['input'];
};

export type PaymentHideDescriptorArgs = {
  action: Action;
  orderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  prevVia?: InputMaybe<ViaEnum>;
  via?: InputMaybe<ViaEnum>;
};

export type PaymentIsOneClickAllowedArgs = {
  packageId?: InputMaybe<Scalars['Int']['input']>;
  source: Source;
  via?: InputMaybe<ViaEnum>;
};

export type PaymentIsUserBoughtPackageArgs = {
  action: Action;
  via: ViaEnum;
};

export type PaymentMotivationArgs = {
  action?: InputMaybe<Action>;
  via?: InputMaybe<ViaEnum>;
};

export type PaymentNextStepArgs = {
  action: Action;
  via?: InputMaybe<ViaEnum>;
};

export type PaymentOrderDataArgs = {
  id: Scalars['String']['input'];
};

export type PaymentPackageOfferDataArgs = {
  bin: Scalars['Int']['input'];
  orderSourceType: Scalars['String']['input'];
  packageId: Scalars['Int']['input'];
};

export type PaymentPackagesDataArgs = {
  action: Action;
  prevVia?: InputMaybe<ViaEnum>;
  source?: InputMaybe<Source>;
  stockId?: InputMaybe<Scalars['String']['input']>;
  via?: InputMaybe<ViaEnum>;
};

export type PaymentPaymentFooterArgs = {
  action?: InputMaybe<Action>;
  prevVia?: InputMaybe<ViaEnum>;
  source?: InputMaybe<Source>;
  via?: InputMaybe<ViaEnum>;
  viaBundle?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentPaymentMethodSettingsArgs = {
  action: Action;
  paymentMethod: PaymentMethod;
};

export type PaymentProcessingFeeArgs = {
  action: Action;
  bin: Scalars['Int']['input'];
};

export type PaymentRedirectUrlArgs = {
  action: Action;
  orderId?: InputMaybe<Scalars['String']['input']>;
  via?: InputMaybe<ViaEnum>;
};

export type PaymentRemarketingOfferDataArgs = {
  prevVia?: InputMaybe<ViaEnum>;
};

export type PaymentReplaceRedirectToPostMessageArgs = {
  action: Action;
};

export type PaymentRetryPermissionUrlArgs = {
  orderId: Scalars['String']['input'];
  via?: InputMaybe<ViaEnum>;
};

export type PaymentStepFlowArgs = {
  action: Action;
  via?: InputMaybe<ViaEnum>;
};

export type PaymentBanner = {
  __typename?: 'PaymentBanner';
  active?: Maybe<Scalars['Boolean']['output']>;
  isOpenInNewWindow?: Maybe<Scalars['Boolean']['output']>;
  packages?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  searchPayButtonAvailable?: Maybe<Scalars['Boolean']['output']>;
  vias?: Maybe<PaymentBannerVias>;
};

export type PaymentBannerVias = {
  __typename?: 'PaymentBannerVias';
  home?: Maybe<Scalars['String']['output']>;
  menu?: Maybe<Scalars['String']['output']>;
  notif?: Maybe<Scalars['String']['output']>;
};

export type PaymentDataPayload = {
  __typename?: 'PaymentDataPayload';
  acsUrl: Scalars['String']['output'];
  creditBalance: Scalars['Int']['output'];
  failPageLogic: Scalars['String']['output'];
  isEmptyFormAfterDecline: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
  status: Scalars['Boolean']['output'];
};

export type PaymentDataPayloadMutationResult = {
  __typename?: 'PaymentDataPayloadMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<PaymentDataPayload>;
};

export type PaymentEvent = {
  __typename?: 'PaymentEvent';
  paymentStatus?: Maybe<Scalars['String']['output']>;
};

export type PaymentFooter = {
  __typename?: 'PaymentFooter';
  blockDataMap?: Maybe<BlockDataMap>;
  displaySettingData?: Maybe<DisplaySettingData>;
};

export enum PaymentFormEnum {
  AdminPaymentForm = 'AdminPaymentForm',
  AgeVerificationPageModel = 'AgeVerificationPageModel',
  AliPayDirectPaymentForm = 'AliPayDirectPaymentForm',
  AliPayGlobalPaymentForm = 'AliPayGlobalPaymentForm',
  AliPayPaymentForm = 'AliPayPaymentForm',
  ApplePayPaymentForm = 'ApplePayPaymentForm',
  AutoOneClickModel = 'AutoOneClickModel',
  BancontactPaymentForm = 'BancontactPaymentForm',
  CardAuthorizationModel = 'CardAuthorizationModel',
  CardFrameModel = 'CardFrameModel',
  CardWalletPaymentForm = 'CardWalletPaymentForm',
  CcbillPaymentForm = 'CcbillPaymentForm',
  CoinfidePaymentForm = 'CoinfidePaymentForm',
  CommDooPaymentForm = 'CommDooPaymentForm',
  CommDooSepaPaymentForm = 'CommDooSepaPaymentForm',
  CreditCardPaymentForm = 'CreditCardPaymentForm',
  CuroIdealPaymentForm = 'CuroIdealPaymentForm',
  DdBillWeChatPaymentForm = 'DdBillWeChatPaymentForm',
  DimocoPaymentForm = 'DimocoPaymentForm',
  Directa24BoletoPaymentForm = 'Directa24BoletoPaymentForm',
  Directa24OxxoPaymentForm = 'Directa24OxxoPaymentForm',
  Directa24PagoEfectivoPaymentForm = 'Directa24PagoEfectivoPaymentForm',
  Directa24PaymentForm = 'Directa24PaymentForm',
  Directa24SPEIPaymentForm = 'Directa24SPEIPaymentForm',
  Directa24SevenElevenPaymentForm = 'Directa24SevenElevenPaymentForm',
  Directa24WebPayPaymentForm = 'Directa24WebPayPaymentForm',
  EftPaymentForm = 'EftPaymentForm',
  FanclubPaymentForm = 'FanclubPaymentForm',
  GenomePrzelewy24PaymentForm = 'GenomePrzelewy24PaymentForm',
  GenomeTrustlyPaymentForm = 'GenomeTrustlyPaymentForm',
  GiftPaymentForm = 'GiftPaymentForm',
  GooglePayPaymentForm = 'GooglePayPaymentForm',
  HookupPlanPaymentForm = 'HookupPlanPaymentForm',
  IDealHandlerForm = 'IDealHandlerForm',
  IDealPaymentForm = 'IDealPaymentForm',
  IbanOneClickPaymentForm = 'IbanOneClickPaymentForm',
  InfobipHandlerForm = 'InfobipHandlerForm',
  InfobipPaymentForm = 'InfobipPaymentForm',
  ItunesPaymentForm = 'ItunesPaymentForm',
  KonbiniPaymentForm = 'KonbiniPaymentForm',
  LiveCamExternalPaymentForm = 'LiveCamExternalPaymentForm',
  MaxpayExternalPaymentForm = 'MaxpayExternalPaymentForm',
  MicropaymentSepaPaymentForm = 'MicropaymentSepaPaymentForm',
  MidasPaymentForm = 'MidasPaymentForm',
  MobiGWPaymentForm = 'MobiGWPaymentForm',
  NetellerPaymentForm = 'NetellerPaymentForm',
  NewWayDatingPaymentForm = 'NewWayDatingPaymentForm',
  NovalNetIDealPaymentForm = 'NovalNetIDealPaymentForm',
  NovalNetSepaPaymentForm = 'NovalNetSepaPaymentForm',
  NuveiIdealPaymentForm = 'NuveiIdealPaymentForm',
  NuveiMyBankPaymentForm = 'NuveiMyBankPaymentForm',
  OnebipPaymentForm = 'OnebipPaymentForm',
  PaygardenPaymentForm = 'PaygardenPaymentForm',
  PaypalPaymentForm = 'PaypalPaymentForm',
  Paypalv2PaymentForm = 'Paypalv2PaymentForm',
  PaysafecardPaymentForm = 'PaysafecardPaymentForm',
  PugglePayPaymentForm = 'PugglePayPaymentForm',
  RazorpayUpiPaymentForm = 'RazorpayUpiPaymentForm',
  RazorpayWalletPaymentForm = 'RazorpayWalletPaymentForm',
  RocketPayPaymentForm = 'RocketPayPaymentForm',
  RoyalPayQiwiPaymentForm = 'RoyalPayQiwiPaymentForm',
  RoyalPayYandexPaymentForm = 'RoyalPayYandexPaymentForm',
  SaltEdgeAisPaymentForm = 'SaltEdgeAisPaymentForm',
  SepaExpressPaymentForm = 'SepaExpressPaymentForm',
  SepaExpressV2PaymentForm = 'SepaExpressV2PaymentForm',
  SepaPaymentForm = 'SepaPaymentForm',
  Smart2PayGiroPayPaymentForm = 'Smart2PayGiroPayPaymentForm',
  Smart2PayIdealPaymentForm = 'Smart2PayIdealPaymentForm',
  Smart2PayKonbiniPaymentForm = 'Smart2PayKonbiniPaymentForm',
  Smart2PayMobilePaymentForm = 'Smart2PayMobilePaymentForm',
  Smart2PayPoliPaymentForm = 'Smart2PayPoliPaymentForm',
  Smart2paymercadoPaymentForm = 'Smart2paymercadoPaymentForm',
  SmsNebupayResponseForm = 'SmsNebupayResponseForm',
  SmsPaymentForm = 'SmsPaymentForm',
  SofortPaymentForm = 'SofortPaymentForm',
  SpeedDatingCardWalletPaymentForm = 'SpeedDatingCardWalletPaymentForm',
  SpeedDatingMidasPaymentForm = 'SpeedDatingMidasPaymentForm',
  SpeedDatingPaymentForm = 'SpeedDatingPaymentForm',
  StatisticApmPaymentForm = 'StatisticApmPaymentForm',
  StickAddressForm = 'StickAddressForm',
  StripePaymentForm = 'StripePaymentForm',
  TargetMediaIdealPaymentForm = 'TargetMediaIdealPaymentForm',
  ThreeDSecurePaymentForm = 'ThreeDSecurePaymentForm',
  TopDatesPaymentForm = 'TopDatesPaymentForm',
  TrustPayAlternativePaymentForm = 'TrustPayAlternativePaymentForm',
  TrustPayIDealPaymentForm = 'TrustPayIDealPaymentForm',
  TrustPaySepaPaymentForm = 'TrustPaySepaPaymentForm',
  UnionPayDirectPaymentForm = 'UnionPayDirectPaymentForm',
  UnionPayPaymentForm = 'UnionPayPaymentForm',
  UserTransactionStatusForm = 'UserTransactionStatusForm',
  VendoSepaPaymentForm = 'VendoSepaPaymentForm',
  VerificationPageForm = 'VerificationPageForm',
  VodPaymentForm = 'VodPaymentForm',
  WebBillingSepaPaymentForm = 'WebBillingSepaPaymentForm',
  WebCamPaymentForm = 'WebCamPaymentForm',
  WebcamCreditCardPaymentForm = 'WebcamCreditCardPaymentForm',
  WechatPaymentForm = 'WechatPaymentForm',
  aliPayDirect = 'aliPayDirect',
}

export type PaymentGuest = {
  __typename?: 'PaymentGuest';
  packages?: Maybe<Array<Maybe<PackageData>>>;
};

export type PaymentGuestPackagesArgs = {
  action: Action;
  prevVia?: InputMaybe<ViaEnum>;
  stockId?: InputMaybe<Scalars['String']['input']>;
  via?: InputMaybe<ViaEnum>;
};

export type PaymentHistoryUnit = {
  __typename?: 'PaymentHistoryUnit';
  /** @deprecated use price field */
  amount?: Maybe<Scalars['String']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Price>;
  siteDescriptor?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleTranslated?: Maybe<Scalars['String']['output']>;
};

export type PaymentLastOrder = {
  __typename?: 'PaymentLastOrder';
  failStatus: Scalars['Boolean']['output'];
  retryUrl: Scalars['String']['output'];
};

export enum PaymentMethod {
  BCcard = 'BCcard',
  aliPay = 'aliPay',
  aliPayDirect = 'aliPayDirect',
  aliPayGlobal = 'aliPayGlobal',
  applePay = 'applePay',
  astropay = 'astropay',
  bancontact = 'bancontact',
  blik = 'blik',
  card = 'card',
  carteBancaire = 'carteBancaire',
  cartePrepagate = 'cartePrepagate',
  ccbill = 'ccbill',
  commDoo = 'commDoo',
  commDooSepa = 'commDooSepa',
  curoIdeal = 'curoIdeal',
  ddBillWeChat = 'ddBillWeChat',
  dimoco = 'dimoco',
  directa24 = 'directa24',
  directa24Boleto = 'directa24Boleto',
  directa24Oxxo = 'directa24Oxxo',
  directa24PagoEfectivo = 'directa24PagoEfectivo',
  directa24SPEI = 'directa24SPEI',
  directa24SevenEleven = 'directa24SevenEleven',
  directa24WebPay = 'directa24WebPay',
  ebanx = 'ebanx',
  ebanxDirect = 'ebanxDirect',
  eft = 'eft',
  genomePrzelewy24 = 'genomePrzelewy24',
  genomeTrustly = 'genomeTrustly',
  googlePay = 'googlePay',
  huawei = 'huawei',
  iDeal = 'iDeal',
  infobip = 'infobip',
  interac = 'interac',
  jcb = 'jcb',
  konbini = 'konbini',
  maxpayExternal = 'maxpayExternal',
  maxpaySms = 'maxpaySms',
  micropaymentSepa = 'micropaymentSepa',
  midas = 'midas',
  mobiGW = 'mobiGW',
  nebupay = 'nebupay',
  neteller = 'neteller',
  novalNetIDeal = 'novalNetIDeal',
  novalNetSepa = 'novalNetSepa',
  nuveiIdeal = 'nuveiIdeal',
  nuveiMyBank = 'nuveiMyBank',
  onebip = 'onebip',
  paygarden = 'paygarden',
  paypal = 'paypal',
  paypalv2 = 'paypalv2',
  paysafecard = 'paysafecard',
  pix = 'pix',
  pugglePay = 'pugglePay',
  rakuten = 'rakuten',
  razorpayUpi = 'razorpayUpi',
  razorpayWallet = 'razorpayWallet',
  rocketPay = 'rocketPay',
  royalPayQiwi = 'royalPayQiwi',
  royalPayYandex = 'royalPayYandex',
  saltEdgeAis = 'saltEdgeAis',
  sepa = 'sepa',
  sepaExpress = 'sepaExpress',
  sepaExpressV2 = 'sepaExpressV2',
  smart2PayGiroPay = 'smart2PayGiroPay',
  smart2PayIdeal = 'smart2PayIdeal',
  smart2PayKonbini = 'smart2PayKonbini',
  smart2PayMobile = 'smart2PayMobile',
  smart2PayPoli = 'smart2PayPoli',
  smart2paymercado = 'smart2paymercado',
  sofort = 'sofort',
  statisticApm = 'statisticApm',
  stripe = 'stripe',
  targetMediaIdeal = 'targetMediaIdeal',
  trustPayAlternative = 'trustPayAlternative',
  trustPayIDeal = 'trustPayIDeal',
  trustPaySepa = 'trustPaySepa',
  trustly = 'trustly',
  unionPay = 'unionPay',
  unionPayDirect = 'unionPayDirect',
  vendoSepa = 'vendoSepa',
  webBillingSepa = 'webBillingSepa',
  wechat = 'wechat',
}

export type PaymentMethodButtonTitle = {
  __typename?: 'PaymentMethodButtonTitle';
  nextStep?: Maybe<Scalars['String']['output']>;
  oneClickButtonText?: Maybe<Scalars['String']['output']>;
  pay?: Maybe<Scalars['String']['output']>;
  payButtonInfoText?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodLogoData = {
  __typename?: 'PaymentMethodLogoData';
  method?: Maybe<PaymentMethod>;
  securityLogos?: Maybe<Array<Maybe<SecurityLogos>>>;
};

export type PaymentMutation = {
  __typename?: 'PaymentMutation';
  applePayUserDetails?: Maybe<ApplePayUserDetailsResultMutationResult>;
  callMeBack?: Maybe<CallMeBackResultMutationResult>;
  displayCallMeBack?: Maybe<BooleanMutationResult>;
  incrementTemplateViaGroupCounter?: Maybe<BooleanMutationResult>;
  payClick?: Maybe<BooleanMutationResult>;
  paymentData?: Maybe<PaymentDataPayloadMutationResult>;
  saltEdgeAisRevoke?: Maybe<BooleanMutationResult>;
  trackCloneTabClick?: Maybe<BooleanMutationResult>;
  trackPaymentLoadComplete?: Maybe<BooleanMutationResult>;
  trackPaymentVisit?: Maybe<BooleanMutationResult>;
  trackSessionRequest?: Maybe<BooleanMutationResult>;
  verification?: Maybe<Verification>;
};

export type PaymentMutationApplePayUserDetailsArgs = {
  response: Scalars['String']['input'];
};

export type PaymentMutationCallMeBackArgs = {
  callMeBackMsisdn: Scalars['String']['input'];
  via?: InputMaybe<ViaEnum>;
};

export type PaymentMutationDisplayCallMeBackArgs = {
  via?: InputMaybe<ViaEnum>;
};

export type PaymentMutationIncrementTemplateViaGroupCounterArgs = {
  via: ViaEnum;
};

export type PaymentMutationPayClickArgs = {
  actionId?: InputMaybe<Action>;
  isButtonActive?: InputMaybe<Scalars['Boolean']['input']>;
  isFormHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isFrontendValidationPassed?: InputMaybe<Scalars['Boolean']['input']>;
  isOneClickShown?: InputMaybe<Scalars['Boolean']['input']>;
  payButton?: InputMaybe<PayButtonEnum>;
  paymentForm: PaymentFormEnum;
  timeInterval?: InputMaybe<Scalars['Int']['input']>;
};

export type PaymentMutationPaymentDataArgs = {
  params?: InputMaybe<PaymentParamsInput>;
};

export type PaymentMutationTrackCloneTabClickArgs = {
  tabName: PaymentMethod;
};

export type PaymentMutationTrackPaymentLoadCompleteArgs = {
  action: Action;
  altMethods?: InputMaybe<Array<InputMaybe<PaymentMethod>>>;
  clientLoadingTime?: InputMaybe<Scalars['Int']['input']>;
  route: Scalars['String']['input'];
  time: Scalars['Int']['input'];
  via?: InputMaybe<ViaEnum>;
};

export type PaymentMutationTrackPaymentVisitArgs = {
  action: Action;
  prevVia?: InputMaybe<ViaEnum>;
  source?: InputMaybe<Scalars['String']['input']>;
  via?: InputMaybe<ViaEnum>;
  viaProfileId?: InputMaybe<Scalars['String']['input']>;
};

export type PaymentMutationTrackSessionRequestArgs = {
  payButton: PayButtonEnum;
};

export enum PaymentPageDetailTypeEnum {
  customFooterLayout = 'customFooterLayout',
  customYouWillPayText = 'customYouWillPayText',
}

export type PaymentParamsInput = {
  browserDetails: BrowserDetailsInput;
  cardData: CardDataInput;
  card_number: Scalars['Int']['input'];
  country_code: Scalars['String']['input'];
  currency_code: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  hidePaymentForm: Scalars['Boolean']['input'];
  isAdditionalPackageRepeated: Scalars['Boolean']['input'];
  locale: Scalars['String']['input'];
  method: Scalars['String']['input'];
  package_id: Scalars['String']['input'];
  product_id: Scalars['Int']['input'];
  uniqueVisitId: Scalars['String']['input'];
  user_id: Scalars['ID']['input'];
  via: PaymentViaInput;
  withCardHolder: Scalars['Boolean']['input'];
};

export type PaymentProcessedEvent = {
  __typename?: 'PaymentProcessedEvent';
  declineScreenTemplate?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  paymentStatus?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
  via?: Maybe<ViaEnum>;
};

export type PaymentViaInput = {
  prevVia?: InputMaybe<ViaEnum>;
  via?: InputMaybe<ViaEnum>;
};

export type Paypalv2Settings = {
  __typename?: 'Paypalv2Settings';
  isOneClickForm: Scalars['Boolean']['output'];
};

export type PedalOffer = {
  __typename?: 'PedalOffer';
  isAvailable: Scalars['Boolean']['output'];
  permissions: Array<Maybe<Scalars['String']['output']>>;
};

export type PersonalIdentity = {
  __typename?: 'PersonalIdentity';
  availableOnDemand: Scalars['Boolean']['output'];
  documentStatus: DocumentStatusEnum;
  firstDayLogicFlag: Scalars['Int']['output'];
  improvesAllowed: Scalars['Boolean']['output'];
  isNeedToShowPopup: Scalars['Int']['output'];
  /** @deprecated Use paymentVia and generate url on front */
  paymentUrl: Scalars['String']['output'];
  paymentVia: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  verificationStatus: Scalars['Int']['output'];
};

export type PersonalIdentityEvent = {
  __typename?: 'PersonalIdentityEvent';
  documentStatus?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type PhotoAlbum = {
  __typename?: 'PhotoAlbum';
  allPhotos: Array<Maybe<Image>>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  primaryPhoto: Image;
};

export type PhotoInterface = {
  censored?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isPaidForView?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  updatedOn?: Maybe<Scalars['String']['output']>;
};

export type PhotoPrivateAttributes = {
  __typename?: 'PhotoPrivateAttributes';
  isPrivate: Scalars['Boolean']['output'];
  isRequested: Scalars['Boolean']['output'];
  requestType?: Maybe<PhotoRequestTypeEnum>;
};

export enum PhotoRequestTypeEnum {
  pmaPhoto = 'pmaPhoto',
  privateAlbum = 'privateAlbum',
  privatePhoto = 'privatePhoto',
}

export type PhotoResolution = {
  __typename?: 'PhotoResolution';
  height: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
};

export type PhotoSend = {
  __typename?: 'PhotoSend';
  isAvailable: Scalars['Boolean']['output'];
  upgradeViaToSendPhoto: Scalars['String']['output'];
  upgradeViaToViewPhoto: Scalars['String']['output'];
};

export type PhotoSettings = {
  __typename?: 'PhotoSettings';
  allowedExtensions: Array<Maybe<Scalars['String']['output']>>;
  allowedQuality: Scalars['Boolean']['output'];
  maxSizeInMb: Scalars['Int']['output'];
  minPhotoResolution?: Maybe<PhotoResolution>;
};

export type PhotoType = {
  __typename?: 'PhotoType';
  cachedUrl: Scalars['String']['output'];
  censored?: Maybe<Scalars['Boolean']['output']>;
  createdOn?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isPaidForView?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  pendingDelete?: Maybe<Scalars['Boolean']['output']>;
  privateAttributes?: Maybe<PhotoPrivateAttributes>;
  updatedOn?: Maybe<Scalars['String']['output']>;
};

export type PhotoUploadEvent = {
  __typename?: 'PhotoUploadEvent';
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  photoId: Scalars['ID']['output'];
  via?: Maybe<ViaEnum>;
};

export type PhotoUploadMotivationSettings = {
  __typename?: 'PhotoUploadMotivationSettings';
  count?: Maybe<Scalars['Int']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  featureName?: Maybe<Scalars['String']['output']>;
};

export type PhotosType = {
  __typename?: 'PhotosType';
  allPhotos?: Maybe<Array<Maybe<PhotoType>>>;
  count?: Maybe<Scalars['Int']['output']>;
  hasPrivatePhotos?: Maybe<Scalars['Boolean']['output']>;
  livecamProfilePhotoCount?: Maybe<Scalars['Int']['output']>;
  paginationData?: Maybe<PaginationData>;
  primaryPhoto?: Maybe<PhotoType>;
};

export enum PiercedAttribute {
  NO = 'NO',
  NOT_GIVEN = 'NOT_GIVEN',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  YES = 'YES',
}

export type PiercedDictionary = {
  __typename?: 'PiercedDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<PiercedAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type PiercedUserAttributeDictionary = {
  __typename?: 'PiercedUserAttributeDictionary';
  key?: Maybe<PiercedAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum PlaceEnum {
  ACTIVITY_POPOVER = 'ACTIVITY_POPOVER',
  ACTIVITY_POPUP = 'ACTIVITY_POPUP',
  NEWS_FEED = 'NEWS_FEED',
  STICKED_NOTIFICATION = 'STICKED_NOTIFICATION',
}

export type Placement = {
  __typename?: 'Placement';
  isDefaultLogic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  partner: Scalars['String']['output'];
  promoCode: Scalars['String']['output'];
  target: Scalars['String']['output'];
  trackingAttributes: Array<Maybe<InventoryTrackingAttribute>>;
  trackingName: Scalars['String']['output'];
  url: Scalars['String']['output'];
  useAsyncTransition: Scalars['Boolean']['output'];
};

export type Placements = {
  __typename?: 'Placements';
  activity: Array<Maybe<Scalars['String']['output']>>;
  chatWith: Array<Maybe<Scalars['String']['output']>>;
  chats: Array<Maybe<Scalars['String']['output']>>;
  likeGallery: Array<Maybe<Scalars['String']['output']>>;
  /** @deprecated Will be removed next interaction, invalid from 07.02.2024 */
  menu: Array<Maybe<Scalars['String']['output']>>;
  microfeatures: Array<Maybe<Scalars['String']['output']>>;
  notifications: Array<Maybe<Scalars['String']['output']>>;
  paymentPageMotivation: Array<Maybe<Scalars['String']['output']>>;
  search: Array<Maybe<Scalars['String']['output']>>;
  topSearch: Array<Maybe<Scalars['String']['output']>>;
  topUserProfile: Array<Maybe<Scalars['String']['output']>>;
  userProfile: Array<Maybe<Scalars['String']['output']>>;
};

export enum PlacementsEnum {
  chat = 'chat',
  profile = 'profile',
  search = 'search',
}

export type PlacementsInput = {
  activity: Array<InputMaybe<Scalars['String']['input']>>;
  chatWith: Array<InputMaybe<Scalars['String']['input']>>;
  chats: Array<InputMaybe<Scalars['String']['input']>>;
  likeGallery: Array<InputMaybe<Scalars['String']['input']>>;
  menu: Array<InputMaybe<Scalars['String']['input']>>;
  microfeatures: Array<InputMaybe<Scalars['String']['input']>>;
  notifications: Array<InputMaybe<Scalars['String']['input']>>;
  paymentPageMotivation: Array<InputMaybe<Scalars['String']['input']>>;
  search: Array<InputMaybe<Scalars['String']['input']>>;
  topSearch: Array<InputMaybe<Scalars['String']['input']>>;
  topUserProfile: Array<InputMaybe<Scalars['String']['input']>>;
  userProfile: Array<InputMaybe<Scalars['String']['input']>>;
};

export type PmaRequestPhoto = {
  __typename?: 'PmaRequestPhoto';
  allowedRequestsCount: Scalars['Int']['output'];
  available: Scalars['Boolean']['output'];
  isConfirmedAgeVerification: Scalars['Boolean']['output'];
  needShowAgeConfirmationPopup: Scalars['Boolean']['output'];
};

export type PmaRequestPhotoType = {
  __typename?: 'PmaRequestPhotoType';
  markAsVerified?: Maybe<BooleanMutationResult>;
};

export enum PoliticsAttribute {
  CENTRE = 'CENTRE',
  CENTRE_LEFT = 'CENTRE_LEFT',
  CENTRE_RIGHT = 'CENTRE_RIGHT',
  DEMOCRATIC = 'DEMOCRATIC',
  GREEN = 'GREEN',
  INDEPENDENT = 'INDEPENDENT',
  LEFT_WING = 'LEFT_WING',
  LIBERTARIAN = 'LIBERTARIAN',
  NOT_GIVEN = 'NOT_GIVEN',
  NOT_INTERESTED = 'NOT_INTERESTED',
  OTHER = 'OTHER',
  PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY',
  PROGRESSIVE = 'PROGRESSIVE',
  REPUBLICAN = 'REPUBLICAN',
  RIGHT_WING = 'RIGHT_WING',
}

export type PoliticsDictionary = {
  __typename?: 'PoliticsDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<PoliticsAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type PoliticsUserAttributeDictionary = {
  __typename?: 'PoliticsUserAttributeDictionary';
  key?: Maybe<PoliticsAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum PopularityLevelEnum {
  high = 'high',
  low = 'low',
  medium = 'medium',
}

export type Popunder = {
  __typename?: 'Popunder';
  bannerUrl?: Maybe<Scalars['String']['output']>;
  deliveryUrl?: Maybe<Scalars['String']['output']>;
  excludeRoutes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  zonePlacements?: Maybe<Array<Maybe<Zone>>>;
};

export type PopunderActionDelayItem = {
  __typename?: 'PopunderActionDelayItem';
  delay?: Maybe<Scalars['Int']['output']>;
  userAction: Scalars['String']['output'];
};

export type PopunderTargetUrlResult = {
  __typename?: 'PopunderTargetUrlResult';
  result: Scalars['String']['output'];
};

export enum PopunderZoneEnum {
  logoutPopunder = 'logoutPopunder',
  popunder = 'popunder',
  popunder_profile_menu = 'popunder_profile_menu',
  pwaNextStep = 'pwaNextStep',
}

export type PopupInfo = {
  __typename?: 'PopupInfo';
  altMethodProcessor?: Maybe<Scalars['String']['output']>;
  availableRoutes: Array<Maybe<Scalars['String']['output']>>;
  buttonTitle?: Maybe<ButtonTitle>;
  conceptInfo?: Maybe<ConceptInfo>;
  displaySettings?: Maybe<DisplaySettings>;
  hash?: Maybe<Scalars['String']['output']>;
  isPopupAvailable: Scalars['Boolean']['output'];
  isRedirectAfterConfirmationAvailable: Scalars['Boolean']['output'];
  package?: Maybe<Array<Maybe<Package>>>;
};

export type PopupInfoConceptInfoArgs = {
  isCached?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Post = {
  __typename?: 'Post';
  author: HostUser;
  featured: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  image: Image;
  name: Scalars['String']['output'];
  preview: Scalars['String']['output'];
  relatedPosts?: Maybe<Array<Maybe<Post>>>;
  seoContent: Scalars['String']['output'];
  slug: Scalars['ID']['output'];
  tags?: Maybe<Array<Maybe<EventSearchTag>>>;
  timestamp: Scalars['Int']['output'];
  type: PostCategory;
};

export enum PostCategory {
  ALL_BLOGS_PAGE = 'ALL_BLOGS_PAGE',
  ALL_HOME = 'ALL_HOME',
  IDEA = 'IDEA',
  NEWS = 'NEWS',
  TIP = 'TIP',
}

export type PostMessageValidationBannerRule = {
  __typename?: 'PostMessageValidationBannerRule';
  name: Scalars['String']['output'];
  regexp?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type PostMessageValidationBannerRuleSet = {
  __typename?: 'PostMessageValidationBannerRuleSet';
  eventName: Scalars['String']['output'];
  rules?: Maybe<Array<Maybe<PostMessageValidationBannerRule>>>;
};

export type PostMessageValidationRule = {
  __typename?: 'PostMessageValidationRule';
  eventName: Scalars['String']['output'];
  propertyName: Scalars['String']['output'];
  regexp?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum PractisingReligionAttribute {
  NON_PRACTISING = 'NON_PRACTISING',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  PRACTISING = 'PRACTISING',
}

export type PractisingReligionDictionary = {
  __typename?: 'PractisingReligionDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<PractisingReligionAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type PractisingReligionUserAttributeDictionary = {
  __typename?: 'PractisingReligionUserAttributeDictionary';
  key?: Maybe<PractisingReligionAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type PreferredContent = {
  __typename?: 'PreferredContent';
  enabled: Scalars['Boolean']['output'];
  icons?: Maybe<Array<Maybe<Icon>>>;
  showOnScroll: Scalars['Boolean']['output'];
};

export type PreferredContentPayload = {
  __typename?: 'PreferredContentPayload';
  preferences?: Maybe<BooleanMutationResult>;
  setShown?: Maybe<BooleanMutationResult>;
};

export type PreferredContentPayloadPreferencesArgs = {
  iconIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PressRoom = {
  __typename?: 'PressRoom';
  imagePacks: Array<Maybe<Image>>;
  logos: Array<Maybe<Image>>;
  posts: Array<Maybe<Post>>;
  videos: Array<Maybe<Video>>;
};

export type Price = {
  __typename?: 'Price';
  /** @deprecated use formatted.amount */
  amount: Scalars['String']['output'];
  currency: Currency;
  currencyCode: Scalars['String']['output'];
  formatted?: Maybe<PriceValues>;
};

export type PriceData = {
  __typename?: 'PriceData';
  fullPrice?: Maybe<Price>;
};

export type PriceValues = {
  __typename?: 'PriceValues';
  amount: Scalars['String']['output'];
  hi: Scalars['String']['output'];
  lo?: Maybe<Scalars['String']['output']>;
  shortPrice?: Maybe<Scalars['String']['output']>;
};

export type PriorityMan = {
  __typename?: 'PriorityMan';
  begin?: Maybe<PriorityManBegin>;
  statistic?: Maybe<PriorityManStatistic>;
};

export type PriorityManBeginArgs = {
  processId: Scalars['ID']['input'];
};

export type PriorityManStatisticArgs = {
  featureName: Scalars['String']['input'];
};

export type PriorityManBegin = {
  __typename?: 'PriorityManBegin';
  enabled: Scalars['Boolean']['output'];
  expireAt?: Maybe<Scalars['Float']['output']>;
};

export type PriorityManPrice = {
  __typename?: 'PriorityManPrice';
  amount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Currency>;
};

export type PriorityManStatistic = {
  __typename?: 'PriorityManStatistic';
  like?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['Int']['output']>;
  money?: Maybe<Price>;
  paymentUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated use money instead */
  price?: Maybe<PriorityManPrice>;
  view?: Maybe<Scalars['Int']['output']>;
};

export type PrivatePhoto = {
  __typename?: 'PrivatePhoto';
  allowedAutoSend: Scalars['Boolean']['output'];
  available: Scalars['Boolean']['output'];
};

export type PrivateVideoResponse = {
  __typename?: 'PrivateVideoResponse';
  error?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Message>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
  result: Scalars['String']['output'];
};

export type ProcessingFee = {
  __typename?: 'ProcessingFee';
  fee?: Maybe<Price>;
};

export enum ProductTypeEnum {
  datingCourse = 'datingCourse',
  default = 'default',
  eventTicket = 'eventTicket',
  unlimitedPass = 'unlimitedPass',
}

export type ProfessionIcons = {
  __typename?: 'ProfessionIcons';
  enabled?: Maybe<Scalars['Boolean']['output']>;
};

export enum ProfessionIdAttribute {
  ACTOR_ACTRESS = 'ACTOR_ACTRESS',
  AIR_CREW = 'AIR_CREW',
  ARMY = 'ARMY',
  CIVIL_SERVANT = 'CIVIL_SERVANT',
  COMPANY_EMPLOYEE = 'COMPANY_EMPLOYEE',
  COMPANY_EXECUTIVE = 'COMPANY_EXECUTIVE',
  DANCER = 'DANCER',
  DOCTOR = 'DOCTOR',
  FIRE_SERVICE = 'FIRE_SERVICE',
  GEISHA = 'GEISHA',
  HOSTESS = 'HOSTESS',
  KARAOKE_GIRL = 'KARAOKE_GIRL',
  KAYABAJO = 'KAYABAJO',
  MASSEUSE = 'MASSEUSE',
  MEDICAL_STAFF = 'MEDICAL_STAFF',
  MODEL = 'MODEL',
  NAVY = 'NAVY',
  NON_UNIFORM = 'NON_UNIFORM',
  NOT_GIVEN = 'NOT_GIVEN',
  NURSE = 'NURSE',
  PHOTOGRAPHER = 'PHOTOGRAPHER',
  PILOT = 'PILOT',
  POLICE = 'POLICE',
  RAF = 'RAF',
  SALESMAN = 'SALESMAN',
  SCIENTIST_RESEARCHER = 'SCIENTIST_RESEARCHER',
  SECRETARY = 'SECRETARY',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  SHOP_CLERK = 'SHOP_CLERK',
  TEACHER = 'TEACHER',
  TV_ADVERTISEMENT = 'TV_ADVERTISEMENT',
  UNIFORMED_SECURITY = 'UNIFORMED_SECURITY',
  WAITER_WAITRESS = 'WAITER_WAITRESS',
}

export type ProfessionIdDictionary = {
  __typename?: 'ProfessionIdDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<ProfessionIdAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type ProfessionIdUserAttributeDictionary = {
  __typename?: 'ProfessionIdUserAttributeDictionary';
  key?: Maybe<ProfessionIdAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type Profile = {
  __typename?: 'Profile';
  age?: Maybe<Scalars['Int']['output']>;
  attributes?: Maybe<ProfileAttributes>;
  chatUpLine?: Maybe<Scalars['String']['output']>;
  contentLevels: Array<Maybe<Scalars['Int']['output']>>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionLookingFor?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<GenderTypeEnum>;
  id?: Maybe<Scalars['ID']['output']>;
  location?: Maybe<UserLocationType>;
  login?: Maybe<Scalars['String']['output']>;
  personalIdentity?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photos?: Maybe<PhotosType>;
  statuses?: Maybe<UserStatuses>;
  /** @deprecated USE profile.attributes.tribe */
  tribe?: Maybe<Array<Maybe<IntIdBoolValueElement>>>;
  videos?: Maybe<Videos>;
};

export type ProfilePhotosArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ProfileAttributes = {
  __typename?: 'ProfileAttributes';
  bloodType?: Maybe<BloodTypeAttribute>;
  build?: Maybe<BuildAttribute>;
  characteristics?: Maybe<CharacteristicsAttribute>;
  children?: Maybe<ChildrenAttribute>;
  diet?: Maybe<DietAttribute>;
  drink?: Maybe<DrinkAttribute>;
  drugs?: Maybe<DrugsAttribute>;
  eatingHabits?: Maybe<EatingHabitsAttribute>;
  education?: Maybe<EducationAttribute>;
  eyeColor?: Maybe<EyeColorAttribute>;
  fetish?: Maybe<FetishAttribute>;
  gambling?: Maybe<GamblingAttribute>;
  genderIdentity?: Maybe<GenderIdentityAttribute>;
  goal?: Maybe<Array<Maybe<GoalAttribute>>>;
  hairColor?: Maybe<HairColorAttribute>;
  height?: Maybe<HeightAttribute>;
  hijabWearing?: Maybe<HijabWearingAttribute>;
  hobby?: Maybe<Array<Maybe<HobbyAttribute>>>;
  hobbyEntertainment?: Maybe<Array<Maybe<HobbyEntertainmentAttribute>>>;
  hobbyFood?: Maybe<Array<Maybe<HobbyFoodAttribute>>>;
  hobbyMusic?: Maybe<Array<Maybe<HobbyMusicAttribute>>>;
  hobbyPlaySports?: Maybe<Array<Maybe<HobbyPlaySportsAttribute>>>;
  hobbyTV?: Maybe<Array<Maybe<HobbyTvAttribute>>>;
  hobbyWatchSports?: Maybe<Array<Maybe<HobbyWatchSportsAttribute>>>;
  income?: Maybe<IncomeAttribute>;
  indianCommunity?: Maybe<IndianCommunityAttribute>;
  islamCult?: Maybe<IslamCultAttribute>;
  literatureRead?: Maybe<Array<Maybe<LiteratureReadAttribute>>>;
  living?: Maybe<LivingAttribute>;
  maritalStatus?: Maybe<MaritalStatusAttribute>;
  moneyRole?: Maybe<MoneyRoleAttribute>;
  muslimBorn?: Maybe<MuslimBornAttribute>;
  netWorth?: Maybe<NetWorthAttribute>;
  occupation?: Maybe<OccupationAttribute>;
  pierced?: Maybe<PiercedAttribute>;
  politics?: Maybe<PoliticsAttribute>;
  practisingReligion?: Maybe<PractisingReligionAttribute>;
  professionId?: Maybe<ProfessionIdAttribute>;
  race?: Maybe<RaceAttribute>;
  readQuran?: Maybe<ReadQuranAttribute>;
  registrationReason?: Maybe<RegistrationReasonAttribute>;
  religion?: Maybe<ReligionAttribute>;
  religiousServices?: Maybe<ReligiousServicesAttribute>;
  religiousValues?: Maybe<ReligiousValuesAttribute>;
  sexualOrientation?: Maybe<SexualOrientationAttribute>;
  sexualRole?: Maybe<SexualRoleAttribute>;
  smoke?: Maybe<SmokeAttribute>;
  tattoo?: Maybe<TattooAttribute>;
  tribe?: Maybe<Array<Maybe<TribeAttribute>>>;
  weight?: Maybe<WeightAttribute>;
  zodiacSign?: Maybe<ZodiacSignAttribute>;
};

export type ProfileDictionaryAttributesTypes = {
  __typename?: 'ProfileDictionaryAttributesTypes';
  bloodType?: Maybe<Array<Maybe<BloodTypeDictionary>>>;
  build?: Maybe<Array<Maybe<BuildDictionary>>>;
  characteristics?: Maybe<Array<Maybe<CharacteristicsDictionary>>>;
  children?: Maybe<Array<Maybe<ChildrenDictionary>>>;
  diet?: Maybe<Array<Maybe<DietDictionary>>>;
  drink?: Maybe<Array<Maybe<DrinkDictionary>>>;
  drugs?: Maybe<Array<Maybe<DrugsDictionary>>>;
  eatingHabits?: Maybe<Array<Maybe<EatingHabitsDictionary>>>;
  education?: Maybe<Array<Maybe<EducationDictionary>>>;
  eyeColor?: Maybe<Array<Maybe<EyeColorDictionary>>>;
  fetish?: Maybe<Array<Maybe<FetishDictionary>>>;
  gambling?: Maybe<Array<Maybe<GamblingDictionary>>>;
  genderIdentity?: Maybe<Array<Maybe<GenderIdentityDictionary>>>;
  goal?: Maybe<Array<Maybe<GoalDictionary>>>;
  hairColor?: Maybe<Array<Maybe<HairColorDictionary>>>;
  height?: Maybe<Array<Maybe<HeightDictionary>>>;
  hijabWearing?: Maybe<Array<Maybe<HijabWearingDictionary>>>;
  hobby?: Maybe<Array<Maybe<HobbyDictionary>>>;
  hobbyEntertainment?: Maybe<Array<Maybe<HobbyEntertainmentDictionary>>>;
  hobbyFood?: Maybe<Array<Maybe<HobbyFoodDictionary>>>;
  hobbyMusic?: Maybe<Array<Maybe<HobbyMusicDictionary>>>;
  hobbyPlaySports?: Maybe<Array<Maybe<HobbyPlaySportsDictionary>>>;
  hobbyTV?: Maybe<Array<Maybe<HobbyTvDictionary>>>;
  hobbyWatchSports?: Maybe<Array<Maybe<HobbyWatchSportsDictionary>>>;
  income?: Maybe<Array<Maybe<IncomeDictionary>>>;
  indianCommunity?: Maybe<Array<Maybe<IndianCommunityDictionary>>>;
  islamCult?: Maybe<Array<Maybe<IslamCultDictionary>>>;
  literatureRead?: Maybe<Array<Maybe<LiteratureReadDictionary>>>;
  living?: Maybe<Array<Maybe<LivingDictionary>>>;
  maritalStatus?: Maybe<Array<Maybe<MaritalStatusDictionary>>>;
  moneyRole?: Maybe<Array<Maybe<MoneyRoleDictionary>>>;
  muslimBorn?: Maybe<Array<Maybe<MuslimBornDictionary>>>;
  netWorth?: Maybe<Array<Maybe<NetWorthDictionary>>>;
  occupation?: Maybe<Array<Maybe<OccupationDictionary>>>;
  pierced?: Maybe<Array<Maybe<PiercedDictionary>>>;
  politics?: Maybe<Array<Maybe<PoliticsDictionary>>>;
  practisingReligion?: Maybe<Array<Maybe<PractisingReligionDictionary>>>;
  professionId?: Maybe<Array<Maybe<ProfessionIdDictionary>>>;
  race?: Maybe<Array<Maybe<RaceDictionary>>>;
  readQuran?: Maybe<Array<Maybe<ReadQuranDictionary>>>;
  registrationReason?: Maybe<Array<Maybe<RegistrationReasonDictionary>>>;
  religion?: Maybe<Array<Maybe<ReligionDictionary>>>;
  religiousServices?: Maybe<Array<Maybe<ReligiousServicesDictionary>>>;
  religiousValues?: Maybe<Array<Maybe<ReligiousValuesDictionary>>>;
  sexualOrientation?: Maybe<Array<Maybe<SexualOrientationDictionary>>>;
  sexualRole?: Maybe<Array<Maybe<SexualRoleDictionary>>>;
  smoke?: Maybe<Array<Maybe<SmokeDictionary>>>;
  tattoo?: Maybe<Array<Maybe<TattooDictionary>>>;
  tribe?: Maybe<Array<Maybe<TribeDictionary>>>;
  weight?: Maybe<Array<Maybe<WeightDictionary>>>;
  zodiacSign?: Maybe<Array<Maybe<ZodiacSignDictionary>>>;
};

export type ProfileOpen = {
  __typename?: 'ProfileOpen';
  gender?: Maybe<GenderTypeEnum>;
  id?: Maybe<Scalars['ID']['output']>;
  login?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photos?: Maybe<PhotosType>;
};

export type ProfileOpenPhotosArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type Promocode = {
  __typename?: 'Promocode';
  eventType: Scalars['String']['output'];
  expiryTime: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  reason: Scalars['String']['output'];
};

export type PromoteMyAccount = {
  __typename?: 'PromoteMyAccount';
  activated: Scalars['Boolean']['output'];
  canActivateWithoutPhoto: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  hideCheckbox: Scalars['Boolean']['output'];
  messageTheme: Scalars['Int']['output'];
};

export type PurchaseType = {
  __typename?: 'PurchaseType';
  orderId: Scalars['String']['output'];
  price?: Maybe<Price>;
  productType: ProductTypeEnum;
};

export type Pwa = {
  __typename?: 'Pwa';
  scenario?: Maybe<ScenarioEnum>;
  settings?: Maybe<Settings>;
};

export type PwaInstallType = {
  __typename?: 'PwaInstallType';
  completed?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  reward?: Maybe<Scalars['Int']['output']>;
};

export type PwaPayload = {
  __typename?: 'PwaPayload';
  pwaInstalled?: Maybe<BooleanMutationResult>;
};

export type Query = {
  __typename?: 'Query';
  activities?: Maybe<Array<Activity>>;
  activitiesForCounters?: Maybe<Array<Activity>>;
  activityUsers?: Maybe<UserData>;
  allActivities?: Maybe<AllActivities>;
  backOfferTargetUrl?: Maybe<TargetUrl>;
  billingHistory?: Maybe<BillingHistory>;
  blockedUsers: Array<Maybe<UserData>>;
  cancelSubscription?: Maybe<CancelSubscription>;
  chatrooms?: Maybe<Chatrooms>;
  coins?: Maybe<Coins>;
  conversationalist?: Maybe<Conversationalist>;
  credits?: Maybe<Credits>;
  currentUserAdmin?: Maybe<UserData>;
  customAppearance?: Maybe<CustomAppearance>;
  deviceDetection?: Maybe<DeviceDetection>;
  disapprove?: Maybe<Disapprove>;
  discreetProfile?: Maybe<DiscreetProfile>;
  externalSiteInfo?: Maybe<Array<Maybe<ExternalSiteInfo>>>;
  extraPopups?: Maybe<ExtraPopups>;
  favorites?: Maybe<Favorites>;
  flirtcast?: Maybe<Flirtcast>;
  funnel?: Maybe<Funnel>;
  funnelRmBundle?: Maybe<FunnelRmBundle>;
  gifts?: Maybe<MainGifts>;
  isAuthorizedZone: Scalars['Boolean']['output'];
  isBanned: Scalars['Boolean']['output'];
  likeGallery?: Maybe<LikeGallery>;
  locale: Scalars['String']['output'];
  matureDatingTips?: Maybe<Array<Maybe<Tip>>>;
  menu?: Maybe<Menu>;
  messenger?: Maybe<Messenger>;
  messengerActiveDialogId?: Maybe<Scalars['String']['output']>;
  microFeatures?: Maybe<MicroFeatures>;
  myUser?: Maybe<UserData>;
  myUserDictionary?: Maybe<MyUserDictionary>;
  notifications?: Maybe<Notifications>;
  paidFunnel?: Maybe<PaidFunnel>;
  payment?: Maybe<Payment>;
  paymentGuest?: Maybe<PaymentGuest>;
  pwa?: Maybe<Pwa>;
  recentActivity?: Maybe<RecentActivity>;
  registration?: Maybe<Registration>;
  remarketing?: Maybe<Remarketing>;
  removeAccount?: Maybe<RemoveAccount>;
  reportedUsers: Array<Maybe<UserData>>;
  rmOfferList?: Maybe<OffersResponse>;
  rmPaidFunnel?: Maybe<RmPaidFunnel>;
  route?: Maybe<ClientRoute>;
  search?: Maybe<Search>;
  searchWithMap?: Maybe<SearchWithMap>;
  site?: Maybe<Site>;
  speedDating?: Maybe<SpeedDating>;
  spin?: Maybe<Spin>;
  staticPage?: Maybe<StaticPage>;
  stories?: Maybe<Stories>;
  support?: Maybe<Support>;
  targetUserDictionary?: Maybe<TargetUserDictionary>;
  trusted?: Maybe<TrustedVerificationSettings>;
  user?: Maybe<UserData>;
  userFeatures?: Maybe<UserFeatures>;
  userList?: Maybe<Array<Maybe<UserData>>>;
  virtualAssistant?: Maybe<VirtualAssistant>;
  worldWideSearch?: Maybe<WorldWideSearch>;
};

export type QueryActivitiesArgs = {
  direction?: DirectionEnum;
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  withActivity?: InputMaybe<Scalars['Boolean']['input']>;
  withMail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryActivitiesForCountersArgs = {
  counters?: InputMaybe<Array<InputMaybe<CounterParam>>>;
};

export type QueryExternalSiteInfoArgs = {
  sites: Array<InputMaybe<Scalars['String']['input']>>;
};

export type QueryPaidFunnelArgs = {
  isRM?: InputMaybe<Scalars['Boolean']['input']>;
  via?: InputMaybe<ViaEnum>;
};

export type QueryRmOfferListArgs = {
  currentPlatform: Scalars['String']['input'];
  forbiddenSiteDomains: Array<InputMaybe<Scalars['String']['input']>>;
  userId: Scalars['String']['input'];
  zones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryRmPaidFunnelArgs = {
  via?: InputMaybe<ViaEnum>;
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUserListArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type QueryVirtualAssistantArgs = {
  virtualAssistantId?: InputMaybe<Scalars['ID']['input']>;
};

export type RmBundle = {
  __typename?: 'RMBundle';
  buttonType?: Maybe<Button>;
  enabled: Scalars['Boolean']['output'];
  sites?: Maybe<Array<Maybe<RmBundleSites>>>;
  via?: Maybe<Scalars['String']['output']>;
};

export type RmBundleSiteLogos = {
  __typename?: 'RMBundleSiteLogos';
  default?: Maybe<Scalars['String']['output']>;
  inverse?: Maybe<Scalars['String']['output']>;
};

export type RmBundleSites = {
  __typename?: 'RMBundleSites';
  domain?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<RmBundleSiteLogos>;
  siteId?: Maybe<Scalars['String']['output']>;
};

export enum RaceAttribute {
  ASIAN = 'ASIAN',
  BLACK_AFRICAN = 'BLACK_AFRICAN',
  INDIAN = 'INDIAN',
  LATINO_HISPANIC = 'LATINO_HISPANIC',
  MIDDLE_EASTERN = 'MIDDLE_EASTERN',
  MIXED_OTHER = 'MIXED_OTHER',
  NOT_GIVEN = 'NOT_GIVEN',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  WHITE_CAUCASIAN = 'WHITE_CAUCASIAN',
}

export type RaceDictionary = {
  __typename?: 'RaceDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<RaceAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type RaceUserAttributeDictionary = {
  __typename?: 'RaceUserAttributeDictionary';
  key?: Maybe<RaceAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum RandomUsersRequestTypeEnum {
  NeoBank = 'NeoBank',
  ignoreDistanceSearch = 'ignoreDistanceSearch',
  randomPretty = 'randomPretty',
}

export enum ReadQuranAttribute {
  DAILY = 'DAILY',
  NEVER_READ = 'NEVER_READ',
  NOT_GIVEN = 'NOT_GIVEN',
  OCASSIONALLY = 'OCASSIONALLY',
  ONLY_DURING_RAMADAN = 'ONLY_DURING_RAMADAN',
  ONLY_ON_JUMMAH_FRIDAYS = 'ONLY_ON_JUMMAH_FRIDAYS',
  PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY',
  READ_TRANSLATED_VERSION = 'READ_TRANSLATED_VERSION',
}

export type ReadQuranDictionary = {
  __typename?: 'ReadQuranDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<ReadQuranAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type ReadQuranUserAttributeDictionary = {
  __typename?: 'ReadQuranUserAttributeDictionary';
  key?: Maybe<ReadQuranAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type Reason = {
  __typename?: 'Reason';
  id: Scalars['String']['output'];
  reasonText: Scalars['String']['output'];
};

export type ReasonExtraData = {
  __typename?: 'ReasonExtraData';
  freeMessagesTimeout?: Maybe<Scalars['Int']['output']>;
  timeout?: Maybe<Scalars['Int']['output']>;
};

export type RecentActivity = {
  __typename?: 'RecentActivity';
  options?: Maybe<RecentActivityOptions>;
};

export type RecentActivityOptions = {
  __typename?: 'RecentActivityOptions';
  count?: Maybe<Scalars['Int']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
};

export type Recipient = {
  __typename?: 'Recipient';
  canShowSendFormForApprovedScammer?: Maybe<Scalars['Boolean']['output']>;
  disabledBySafeMode: Scalars['Boolean']['output'];
  /** @deprecated Remove it after 01.05.2025, task - TNC-30051 */
  freeConversation?: Maybe<FreeConversation>;
  freeMessageMotivationBanner?: Maybe<Scalars['Boolean']['output']>;
  hasMoreHistory?: Maybe<Scalars['Boolean']['output']>;
  hasOutgoingMessage: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  isTopChataholic?: Maybe<Scalars['Boolean']['output']>;
  isTopInChat: Scalars['Boolean']['output'];
  lastActivity?: Maybe<LastActivity>;
  lastMessage?: Maybe<LastMessage>;
  messages: Array<Maybe<Message>>;
  paidFeatures?: Maybe<MessengerPaidFeatures>;
  showGifsButton: Scalars['Boolean']['output'];
  showGiftsButton: Scalars['Boolean']['output'];
  showPhotoSendButton: Scalars['Boolean']['output'];
  showStickersButton: Scalars['Boolean']['output'];
  showVideoSendButton: Scalars['Boolean']['output'];
  topRecipient: Scalars['Boolean']['output'];
  unreadMessageCount?: Maybe<Scalars['Int']['output']>;
  unsentMessageCount: Scalars['Int']['output'];
  user?: Maybe<UserData>;
};

export type RecipientMessagesArgs = {
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type Recovery = {
  __typename?: 'Recovery';
  autologinKey?: Maybe<Scalars['String']['output']>;
  result: Scalars['Boolean']['output'];
};

export type RecoveryMutationResult = {
  __typename?: 'RecoveryMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<Recovery>;
};

export type RedirectUrl = {
  __typename?: 'RedirectUrl';
  afterPaymentFail: Scalars['String']['output'];
  afterPaymentSuccess: Scalars['String']['output'];
  tryLaterStep: Scalars['String']['output'];
};

export type Reels = {
  __typename?: 'Reels';
  isLiked: Scalars['Boolean']['output'];
  isNeedMotivation: Scalars['Boolean']['output'];
  isViewed: Scalars['Boolean']['output'];
  previewUrl: Scalars['String']['output'];
  user?: Maybe<UserData>;
  videoId: Scalars['String']['output'];
  videoUrl: Scalars['String']['output'];
};

export type ReelsFeature = {
  __typename?: 'ReelsFeature';
  newReelsLabelAvailable?: Maybe<Scalars['Boolean']['output']>;
  reels: Array<Maybe<Reels>>;
};

export type RegionDictionary = {
  __typename?: 'RegionDictionary';
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Registration = {
  __typename?: 'Registration';
  genders?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  location: Scalars['String']['output'];
  suggestLocation?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type RegistrationSuggestLocationArgs = {
  limit: Scalars['Int']['input'];
  text: Scalars['String']['input'];
};

export enum RegistrationReasonAttribute {
  JUST_CURIOUS_AND_LOOKING = 'JUST_CURIOUS_AND_LOOKING',
  NOT_GIVEN = 'NOT_GIVEN',
  ONLINE_AFFAIR__EROTIC_CHAT = 'ONLINE_AFFAIR__EROTIC_CHAT',
  PREFER_NO_TO_SAY = 'PREFER_NO_TO_SAY',
  SOMETHING_LONG_TERM = 'SOMETHING_LONG_TERM',
  SOMETHING_SHORT_TERM = 'SOMETHING_SHORT_TERM',
  WHATEVER_EXCITES_ME = 'WHATEVER_EXCITES_ME',
}

export type RegistrationReasonDictionary = {
  __typename?: 'RegistrationReasonDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<RegistrationReasonAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type RegistrationReasonUserAttributeDictionary = {
  __typename?: 'RegistrationReasonUserAttributeDictionary';
  key?: Maybe<RegistrationReasonAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum ReligionAttribute {
  AGNOSTIC = 'AGNOSTIC',
  ATHEIST = 'ATHEIST',
  BUDDHIST = 'BUDDHIST',
  CATHOLIC = 'CATHOLIC',
  CHRISTIAN = 'CHRISTIAN',
  HINDU = 'HINDU',
  JAIN = 'JAIN',
  JEWISH = 'JEWISH',
  MUSLIM = 'MUSLIM',
  NOT_GIVEN = 'NOT_GIVEN',
  OTHER = 'OTHER',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  SIKH = 'SIKH',
  SPIRITUAL = 'SPIRITUAL',
}

export type ReligionDictionary = {
  __typename?: 'ReligionDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<ReligionAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type ReligionUserAttributeDictionary = {
  __typename?: 'ReligionUserAttributeDictionary';
  key?: Maybe<ReligionAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum ReligiousServicesAttribute {
  DAILY = 'DAILY',
  NEVER = 'NEVER',
  NOT_GIVEN = 'NOT_GIVEN',
  ONLY_DURING_RAMADAN = 'ONLY_DURING_RAMADAN',
  ONLY_ON_JUMMAH_FRIDAYS = 'ONLY_ON_JUMMAH_FRIDAYS',
  SOMETIMES = 'SOMETIMES',
}

export type ReligiousServicesDictionary = {
  __typename?: 'ReligiousServicesDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<ReligiousServicesAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type ReligiousServicesUserAttributeDictionary = {
  __typename?: 'ReligiousServicesUserAttributeDictionary';
  key?: Maybe<ReligiousServicesAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum ReligiousValuesAttribute {
  NOT_GIVEN = 'NOT_GIVEN',
  NOT_RELIGIOUS = 'NOT_RELIGIOUS',
  RELIGIOUS = 'RELIGIOUS',
  VERY_RELIGIOUS = 'VERY_RELIGIOUS',
}

export type ReligiousValuesDictionary = {
  __typename?: 'ReligiousValuesDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<ReligiousValuesAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type ReligiousValuesUserAttributeDictionary = {
  __typename?: 'ReligiousValuesUserAttributeDictionary';
  key?: Maybe<ReligiousValuesAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type Relogins = {
  __typename?: 'Relogins';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  reward?: Maybe<Scalars['Int']['output']>;
  stayed?: Maybe<Scalars['Int']['output']>;
};

export type Remarketing = {
  __typename?: 'Remarketing';
  banner?: Maybe<Array<Maybe<Banner>>>;
  bannerSystemUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated Remove it after 15.03.2025 */
  neoOffer?: Maybe<NeoOffer>;
  paymentPopup?: Maybe<PopupInfo>;
  resaleLink: Scalars['String']['output'];
  validatePostMessageData?: Maybe<
    Array<Maybe<PostMessageValidationBannerRuleSet>>
  >;
};

export type RemarketingBannerArgs = {
  environment?: InputMaybe<EnvironmentEnum>;
  zone: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RemarketingMutation = {
  __typename?: 'RemarketingMutation';
  cancel?: Maybe<BooleanMutationResult>;
  close?: Maybe<BooleanMutationResult>;
  track?: Maybe<BooleanMutationResult>;
  transferNeoOffer?: Maybe<StringMutationResult>;
};

export type RemarketingMutationCancelArgs = {
  orderId: Scalars['String']['input'];
};

export type RemarketingMutationCloseArgs = {
  hash: Scalars['String']['input'];
};

export type RemarketingMutationTrackArgs = {
  action: Action;
  hash: Scalars['String']['input'];
  isCancelSubscription: Scalars['Boolean']['input'];
  isSuccess: Scalars['Boolean']['input'];
  orderId: Scalars['String']['input'];
};

export type RemarketingMutationTransferNeoOfferArgs = {
  siteId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type RemarketingOffer = {
  __typename?: 'RemarketingOffer';
  targetSiteBackgroundImagePath?: Maybe<Scalars['String']['output']>;
  targetSiteDomain: Scalars['String']['output'];
  targetSiteLogo?: Maybe<Scalars['String']['output']>;
  targetSiteLogoBlack?: Maybe<Scalars['String']['output']>;
  targetSiteLogoWhite?: Maybe<Scalars['String']['output']>;
};

export type RemoveAccount = {
  __typename?: 'RemoveAccount';
  firstStep: Step;
  isCancelBillingPageBeforeRemove: Scalars['Boolean']['output'];
  isCancelViaEmailCode: Scalars['Boolean']['output'];
  isCodeSent: Scalars['Boolean']['output'];
  isEnabled: Scalars['Boolean']['output'];
  isProfileStatusHidden: Scalars['Boolean']['output'];
  reasons?: Maybe<Array<Maybe<CancellationReasons>>>;
};

export type RemoveAccountMutation = {
  __typename?: 'RemoveAccountMutation';
  areYouSure?: Maybe<NextStepMutationResult>;
  cancellationCode?: Maybe<NextStepMutationResult>;
  cancellationReason?: Maybe<NextStepMutationResult>;
  checkPassword?: Maybe<NextStepMutationResult>;
  emailSecurityCheck?: Maybe<NextStepMutationResult>;
  statusMembershipAnswer?: Maybe<NextStepMutationResult>;
  stop?: Maybe<NextStepMutationResult>;
};

export type RemoveAccountMutationAreYouSureArgs = {
  areYouSureAnswer: AreYouSureAnswer;
  password: Scalars['String']['input'];
  statusMembershipAnswer: StatusMembershipAnswer;
  userReasonAnswer: Scalars['String']['input'];
};

export type RemoveAccountMutationCancellationCodeArgs = {
  code: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['String']['input']>;
  statusMembershipAnswer: StatusMembershipAnswer;
  subscriptionId?: InputMaybe<Scalars['Int']['input']>;
};

export type RemoveAccountMutationCancellationReasonArgs = {
  statusMembershipAnswer: StatusMembershipAnswer;
  userReasonAnswer: Scalars['String']['input'];
};

export type RemoveAccountMutationCheckPasswordArgs = {
  password: Scalars['String']['input'];
};

export type RemoveAccountMutationEmailSecurityCheckArgs = {
  email: Scalars['String']['input'];
  statusMembershipAnswer: StatusMembershipAnswer;
  userReasonAnswer: Scalars['String']['input'];
};

export type RemoveAccountMutationStatusMembershipAnswerArgs = {
  answer: StatusMembershipAnswer;
};

export type RemoveAccountMutationStopArgs = {
  areYouSureAnswer?: InputMaybe<AreYouSureAnswer>;
  password: Scalars['String']['input'];
  statusMembershipAnswer: StatusMembershipAnswer;
  userReasonAnswer?: InputMaybe<Scalars['String']['input']>;
};

export type RepeatPackage = {
  __typename?: 'RepeatPackage';
  bits?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Price>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ReportUser = {
  __typename?: 'ReportUser';
  actions?: Maybe<Array<ComplaintsActions>>;
  heMe?: Maybe<Scalars['Boolean']['output']>;
  iAm?: Maybe<Scalars['Boolean']['output']>;
  reasonId?: Maybe<Scalars['Int']['output']>;
  reasonIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type ReportUserStream = {
  __typename?: 'ReportUserStream';
  reasonId?: Maybe<Scalars['Int']['output']>;
  reasonIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type RequestParam = {
  __typename?: 'RequestParam';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type RequestParamInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ResendConfirmMail = {
  __typename?: 'ResendConfirmMail';
  changedEmail?: Maybe<Scalars['String']['output']>;
  changed_successfully?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailServiceInfo?: Maybe<EmailServiceInfo>;
  mailChanged?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  userWithValidMail?: Maybe<Scalars['Boolean']['output']>;
};

export type ResendConfirmMailMutationResult = {
  __typename?: 'ResendConfirmMailMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<ResendConfirmMail>;
};

export type ResultErrorsResponse = {
  __typename?: 'ResultErrorsResponse';
  errors?: Maybe<MutationPayloadErrors>;
  result: Scalars['String']['output'];
};

export type ResultPage = {
  __typename?: 'ResultPage';
  orders?: Maybe<Array<Maybe<Order>>>;
  template?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Price>;
};

export enum ResultTypeEnum {
  microFeatures = 'microFeatures',
  specialOffer = 'specialOffer',
}

export type RetryPermissionUrl = {
  __typename?: 'RetryPermissionUrl';
  url: Scalars['String']['output'];
};

export type Review = {
  __typename?: 'Review';
  author: SdAuthor;
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rate: Scalars['Int']['output'];
  timestamp: Scalars['Int']['output'];
};

export type RewardForTasks = {
  __typename?: 'RewardForTasks';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  tasks?: Maybe<Tasks>;
};

export type RmPaidFunnel = {
  __typename?: 'RmPaidFunnel';
  conceptInfo?: Maybe<ConceptInfo>;
  inAppBackToFunelAllowed: Scalars['Boolean']['output'];
  package?: Maybe<Package>;
  payUrlParameters?: Maybe<PayUrlParams>;
};

export type RmPaidFunnelConceptInfoArgs = {
  isCached?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RmPaidFunnelPackageArgs = {
  source?: InputMaybe<Source>;
};

export type Room = {
  __typename?: 'Room';
  count?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type RoomBannedEvent = {
  __typename?: 'RoomBannedEvent';
  period: Scalars['String']['output'];
};

export type RoomEvent = {
  __typename?: 'RoomEvent';
  roomId: Scalars['String']['output'];
  user?: Maybe<UserData>;
};

export type RoomMessageEvent = {
  __typename?: 'RoomMessageEvent';
  message?: Maybe<ChatRoomMessage>;
  roomId: Scalars['String']['output'];
};

export type SdAuthor = HostUser | UserDataOpen;

export type SdEvent = {
  __typename?: 'SDEvent';
  age: AgeRange;
  allPhotos: Array<Maybe<Image>>;
  category: StringIdElement;
  currency: Currency;
  discountPrice?: Maybe<Scalars['Float']['output']>;
  eventOrientations: EventOrientation;
  extraInfoItems?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  format: EventFormat;
  gay: Scalars['Boolean']['output'];
  hasLeftFeedback: Scalars['Boolean']['output'];
  haveTicket: Scalars['Boolean']['output'];
  haveUnlimitedTicket: Scalars['Boolean']['output'];
  host: HostUser;
  id: Scalars['ID']['output'];
  isUnique: Scalars['Boolean']['output'];
  labels: Array<Maybe<EventLabel>>;
  legacyId?: Maybe<Scalars['Int']['output']>;
  lesbian: Scalars['Boolean']['output'];
  location?: Maybe<EventLocation>;
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  primaryPhoto: Image;
  promocode?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<EventRate>;
  readyToRate: Scalars['Boolean']['output'];
  reviews: Array<Maybe<Review>>;
  seoContent: Array<Maybe<SeoContent>>;
  startTime: Scalars['Int']['output'];
  status: EventStatus;
  steps?: Maybe<Array<Maybe<EventStep>>>;
  ticketsLeft: EventTicketsLeftByGender;
  type: StringIdElement;
  unlimitedPassApplied: Scalars['Boolean']['output'];
  unreadMatchesCount?: Maybe<Scalars['Int']['output']>;
  userIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  users?: Maybe<Array<Maybe<UserEvent>>>;
  virtualEventLink?: Maybe<Scalars['String']['output']>;
};

export type SdSource = {
  __typename?: 'SDSource';
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Seo = {
  __typename?: 'SEO';
  description: Scalars['String']['output'];
  keywords: Scalars['String']['output'];
  robots: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type SeoContent = {
  __typename?: 'SEOContent';
  body?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  head?: Maybe<Scalars['String']['output']>;
};

export type SeoCustomizationParams = {
  name: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type SpdClientSearchParams = {
  __typename?: 'SPDClientSearchParams';
  age: Scalars['Int']['output'];
  category: Scalars['String']['output'];
  city: Scalars['String']['output'];
  format: Scalars['String']['output'];
  timestampFrom: Scalars['Int']['output'];
  timestampTo: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type Ssg = {
  __typename?: 'SSG';
  isAllowed: Scalars['Boolean']['output'];
  smiles: Array<Maybe<SsgPack>>;
  stickers: Array<Maybe<SsgPack>>;
};

export type SsgStickersArgs = {
  recipientId?: InputMaybe<Scalars['ID']['input']>;
};

export type SsgPack = {
  __typename?: 'SSGPack';
  contentLevel?: Maybe<Scalars['Int']['output']>;
  isActive: Scalars['Boolean']['output'];
  isInactive: Scalars['Boolean']['output'];
  isPaid: Scalars['Boolean']['output'];
  list: Array<Maybe<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
};

export type SafariWebService = {
  __typename?: 'SafariWebService';
  webServiceUrl?: Maybe<Scalars['Boolean']['output']>;
  websitePushId?: Maybe<Scalars['Boolean']['output']>;
};

export enum SafeMode {
  basic = 'basic',
  full = 'full',
  off = 'off',
}

export enum SafeModeEnum {
  basic = 'basic',
  full = 'full',
  off = 'off',
}

export type SaltEdgeAisSettings = {
  __typename?: 'SaltEdgeAisSettings';
  isPrefilled: Scalars['Boolean']['output'];
  maskedFields?: Maybe<MaskedUserFields>;
  sepaAutofillAccess: Scalars['Boolean']['output'];
};

export enum ScenarioEnum {
  promo = 'promo',
  remarketing = 'remarketing',
}

export type Search = {
  __typename?: 'Search';
  defaultParams?: Maybe<Params>;
  features?: Maybe<Features>;
  livecam?: Maybe<Array<Maybe<UserData>>>;
  params?: Maybe<Params>;
  paramsDictionary?: Maybe<ParamsDictionary>;
  /** @deprecated Use params field */
  paramsLegacy?: Maybe<ParamsLegacy>;
  randomUsers?: Maybe<Users>;
  settings?: Maybe<SettingsSearch>;
  topUsers?: Maybe<TopUsers>;
  users?: Maybe<Users>;
};

export type SearchLivecamArgs = {
  excludeModelUserIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  partnerId?: InputMaybe<Scalars['Int']['input']>;
  placement?: InputMaybe<WebCamPromoNames>;
  usersSearchParams?: InputMaybe<InputParams>;
};

export type SearchRandomUsersArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<RandomUsersRequestTypeEnum>;
};

export type SearchUsersArgs = {
  excludeModelUserIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  usersSearchParams?: InputMaybe<InputParams>;
  viewFrom?: InputMaybe<Scalars['String']['input']>;
};

export enum SearchAppearanceEnum {
  ONE_COLUMN_GRID = 'ONE_COLUMN_GRID',
  REGULAR_GRID = 'REGULAR_GRID',
}

export type SearchAttributes = {
  __typename?: 'SearchAttributes';
  bloodType?: Maybe<Array<Maybe<BloodTypeAttribute>>>;
  build?: Maybe<Array<Maybe<BuildAttribute>>>;
  children?: Maybe<Array<Maybe<ChildrenAttribute>>>;
  drink?: Maybe<Array<Maybe<DrinkAttribute>>>;
  eatingHabits?: Maybe<Array<Maybe<EatingHabitsAttribute>>>;
  education?: Maybe<Array<Maybe<EducationAttribute>>>;
  eyeColor?: Maybe<Array<Maybe<EyeColorAttribute>>>;
  fetish?: Maybe<Array<Maybe<FetishAttribute>>>;
  gambling?: Maybe<Array<Maybe<GamblingAttribute>>>;
  genderIdentity?: Maybe<Array<Maybe<GenderIdentityAttribute>>>;
  goal?: Maybe<Array<Maybe<GoalAttribute>>>;
  hairColor?: Maybe<Array<Maybe<HairColorAttribute>>>;
  height?: Maybe<Array<Maybe<HeightAttribute>>>;
  hijabWearing?: Maybe<Array<Maybe<HijabWearingAttribute>>>;
  hobby?: Maybe<Array<Maybe<HobbyAttribute>>>;
  income?: Maybe<Array<Maybe<IncomeAttribute>>>;
  indianCommunity?: Maybe<Array<Maybe<IndianCommunityAttribute>>>;
  islamCult?: Maybe<Array<Maybe<IslamCultAttribute>>>;
  maritalStatus?: Maybe<Array<Maybe<MaritalStatusAttribute>>>;
  moneyRole?: Maybe<Array<Maybe<MoneyRoleAttribute>>>;
  muslimBorn?: Maybe<Array<Maybe<MuslimBornAttribute>>>;
  netWorth?: Maybe<Array<Maybe<NetWorthAttribute>>>;
  occupation?: Maybe<Array<Maybe<OccupationAttribute>>>;
  pierced?: Maybe<Array<Maybe<PiercedAttribute>>>;
  professionId?: Maybe<Array<Maybe<ProfessionIdAttribute>>>;
  race?: Maybe<Array<Maybe<RaceAttribute>>>;
  readQuran?: Maybe<Array<Maybe<ReadQuranAttribute>>>;
  registrationReason?: Maybe<Array<Maybe<RegistrationReasonAttribute>>>;
  religion?: Maybe<Array<Maybe<ReligionAttribute>>>;
  religiousServices?: Maybe<Array<Maybe<ReligiousServicesAttribute>>>;
  religiousValues?: Maybe<Array<Maybe<ReligiousValuesAttribute>>>;
  sexualOrientation?: Maybe<Array<Maybe<SexualOrientationAttribute>>>;
  smoke?: Maybe<Array<Maybe<SmokeAttribute>>>;
  tattoo?: Maybe<Array<Maybe<TattooAttribute>>>;
  tribe?: Maybe<Array<Maybe<TribeAttribute>>>;
  weight?: Maybe<Array<Maybe<WeightAttribute>>>;
  zodiacSign?: Maybe<Array<Maybe<ZodiacSignAttribute>>>;
};

export enum SearchBannerTypeEnum {
  features = 'features',
  membership = 'membership',
}

export enum SearchFormMotivationEnum {
  separated = 'separated',
}

export type SearchLocation = {
  __typename?: 'SearchLocation';
  city?: Maybe<Scalars['String']['output']>;
  country: CountryType;
  distance?: Maybe<Scalars['Int']['output']>;
  distanceUnits?: Maybe<DistanceUnits>;
  isAvailableForSearch?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  regionAbbreviation?: Maybe<Scalars['String']['output']>;
};

export type SearchMutation = {
  __typename?: 'SearchMutation';
  openAdditionalLocationPopup?: Maybe<BooleanMutationResult>;
  updateSearchParams?: Maybe<BooleanMutationResult>;
};

export type SearchMutationUpdateSearchParamsArgs = {
  usersSearchParams: InputParams;
};

export type SearchSliderParams = {
  __typename?: 'SearchSliderParams';
  currentMaxDistance: Scalars['Int']['output'];
  minDistance: Scalars['Int']['output'];
  paidMaxDistance: Scalars['Int']['output'];
};

export type SearchWithMap = {
  __typename?: 'SearchWithMap';
  defaultParams?: Maybe<SearchWithMapParams>;
  /** @deprecated field is not used by client */
  freeTrialIsActivated: Scalars['Boolean']['output'];
  globalSearchType: GlobalSearchTypeEnum;
  hasFreeTrialAvailable: Scalars['Boolean']['output'];
  params?: Maybe<SearchWithMapParams>;
  searchBannerType?: Maybe<SearchBannerTypeEnum>;
  searchSliderParams?: Maybe<SearchSliderParams>;
  statistic?: Maybe<PriorityManStatistic>;
  users?: Maybe<Users>;
};

export type SearchWithMapUsersArgs = {
  excludeModelUserIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  usersSearchParams?: InputMaybe<SearchWithMapInputParams>;
  viewFrom?: InputMaybe<Scalars['String']['input']>;
};

export type SearchWithMapInputParams = {
  age: InputAgeRange;
  attributes?: InputMaybe<InputSearchAttributes>;
  gender?: InputMaybe<GenderTypeEnum>;
  location: InputSearchWithMapLocation;
  onlyFromMyCountry?: InputMaybe<Scalars['Boolean']['input']>;
  photoLevel?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sortType?: InputMaybe<SortSearchType>;
  tab?: InputMaybe<TabSearchType>;
  withPhoto: Scalars['Boolean']['input'];
  withVideo: Scalars['Boolean']['input'];
};

export type SearchWithMapLocation = {
  __typename?: 'SearchWithMapLocation';
  distance?: Maybe<Scalars['Int']['output']>;
  distanceUnits?: Maybe<DistanceUnits>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type SearchWithMapParams = {
  __typename?: 'SearchWithMapParams';
  age?: Maybe<AgeRange>;
  attributes?: Maybe<SearchAttributes>;
  gender?: Maybe<GenderTypeEnum>;
  location?: Maybe<SearchWithMapLocation>;
  onlyFromMyCountry?: Maybe<Scalars['Boolean']['output']>;
  photoLevel?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  sortType?: Maybe<SortSearchType>;
  tab?: Maybe<TabSearchType>;
  withPhoto: Scalars['Boolean']['output'];
  withPrivateAlbum?: Maybe<Scalars['Boolean']['output']>;
  withVideo: Scalars['Boolean']['output'];
};

export type SearchWithMapPayload = {
  __typename?: 'SearchWithMapPayload';
  enableTrialForNextFeature?: Maybe<BooleanMutationResult>;
  leavePaymentPage?: Maybe<BooleanMutationResult>;
  updateSearchWithMapParams?: Maybe<BooleanMutationResult>;
};

export type SearchWithMapPayloadUpdateSearchWithMapParamsArgs = {
  usersSearchParams: SearchWithMapInputParams;
};

export enum SecurityLogos {
  BCcard = 'BCcard',
  airtel = 'airtel',
  amEx = 'amEx',
  amipci = 'amipci',
  bacs = 'bacs',
  bancontact = 'bancontact',
  blik = 'blik',
  boleto = 'boleto',
  card = 'card',
  cartasi = 'cartasi',
  carteBancaire = 'carteBancaire',
  cartePrepagate = 'cartePrepagate',
  comodo = 'comodo',
  dci = 'dci',
  deutschequalitat = 'deutschequalitat',
  directDebit = 'directDebit',
  discover = 'discover',
  electroniccash = 'electroniccash',
  elo = 'elo',
  eps = 'eps',
  familymart = 'familymart',
  freecharge = 'freecharge',
  hipercard = 'hipercard',
  interac = 'interac',
  jcb = 'jcb',
  jiomoney = 'jiomoney',
  klarna = 'klarna',
  lawson = 'lawson',
  letsEncrypt = 'letsEncrypt',
  lock = 'lock',
  masterCard = 'masterCard',
  masterCardCompliance = 'masterCardCompliance',
  mercado = 'mercado',
  ministop = 'ministop',
  mobikwik = 'mobikwik',
  moneyBack = 'moneyBack',
  mybank = 'mybank',
  oxxo = 'oxxo',
  pagoEfectivo = 'pagoEfectivo',
  payByBank = 'payByBank',
  paySafeSecure = 'paySafeSecure',
  payzapp = 'payzapp',
  pciCertified = 'pciCertified',
  pix = 'pix',
  postepay = 'postepay',
  przelewy24 = 'przelewy24',
  rakuten = 'rakuten',
  seicomart = 'seicomart',
  sepalastschrift = 'sepalastschrift',
  sevenEleven = 'sevenEleven',
  sofort = 'sofort',
  spei = 'spei',
  stripe = 'stripe',
  transferencia = 'transferencia',
  trustly = 'trustly',
  upi = 'upi',
  visa = 'visa',
  visaCardCompliance = 'visaCardCompliance',
  webPay = 'webPay',
}

export type SendMessageResponse = {
  __typename?: 'SendMessageResponse';
  countFreeMessages?: Maybe<CountFreeMessages>;
  freeAssistantMessages?: Maybe<FreeAssistantMessages>;
  id?: Maybe<Scalars['ID']['output']>;
  isUnsent?: Maybe<Scalars['Boolean']['output']>;
  needSendFirst?: Maybe<Scalars['Boolean']['output']>;
  photo?: Maybe<MessagePhoto>;
  timestamp?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  video?: Maybe<MessageVideo>;
};

export type SeoColumn = {
  __typename?: 'SeoColumn';
  items: Array<Maybe<SeoColumnItem>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SeoColumnItem = {
  __typename?: 'SeoColumnItem';
  name: Scalars['String']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type SepaExpressV2Settings = {
  __typename?: 'SepaExpressV2Settings';
  sepaAutofillAccess: Scalars['Boolean']['output'];
};

export type Settings = {
  __typename?: 'Settings';
  amountCancelBlockDisplayPopup?: Maybe<Scalars['Int']['output']>;
  blockDisplayPopupInterval?: Maybe<Scalars['Int']['output']>;
  displayLimit?: Maybe<Scalars['Int']['output']>;
  displayLimitInterval?: Maybe<Scalars['Int']['output']>;
  displayStep?: Maybe<Scalars['Int']['output']>;
  placements?: Maybe<Array<Maybe<PlacementsEnum>>>;
  timeoutAfterDisplay?: Maybe<Scalars['Int']['output']>;
};

export type SettingsSearch = {
  __typename?: 'SettingsSearch';
  allowedSearchForm: Scalars['Boolean']['output'];
  banners?: Maybe<Banners>;
  chunkSize?: Maybe<Scalars['Int']['output']>;
  searchAppearance?: Maybe<SearchAppearanceEnum>;
  searchLimit?: Maybe<Scalars['Int']['output']>;
  searchLivecamLimit?: Maybe<Scalars['Int']['output']>;
  searchWithPhotoAccess: Scalars['Boolean']['output'];
  showAdditionalLocationPopup: Scalars['Boolean']['output'];
  showSearchLimitBanner: Scalars['Boolean']['output'];
};

export type SettingsSearchBannersArgs = {
  environment?: InputMaybe<EnvironmentEnum>;
};

export type SexAlarm = {
  __typename?: 'SexAlarm';
  amountRecipients?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<StringIdElement>;
};

export enum SexualOrientationAttribute {
  BISEXUAL = 'BISEXUAL',
  HOMOSEXUAL = 'HOMOSEXUAL',
  STRAIGHT = 'STRAIGHT',
}

export type SexualOrientationDictionary = {
  __typename?: 'SexualOrientationDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<SexualOrientationAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type SexualOrientationUserAttributeDictionary = {
  __typename?: 'SexualOrientationUserAttributeDictionary';
  key?: Maybe<SexualOrientationAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export enum SexualRoleAttribute {
  ACTIVE_AND_PASSIVE = 'ACTIVE_AND_PASSIVE',
  ACTIVE_ONLY = 'ACTIVE_ONLY',
  NOT_GIVEN = 'NOT_GIVEN',
  PASSIVE_ONLY = 'PASSIVE_ONLY',
  RATHER_ACTIVE = 'RATHER_ACTIVE',
  RATHER_PASSIVE = 'RATHER_PASSIVE',
}

export type SexualRoleDictionary = {
  __typename?: 'SexualRoleDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<SexualRoleAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type SexualRoleUserAttributeDictionary = {
  __typename?: 'SexualRoleUserAttributeDictionary';
  key?: Maybe<SexualRoleAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type SimpleCancelSubscription = {
  __typename?: 'SimpleCancelSubscription';
  isReactivateRepeatAvailable: Scalars['Boolean']['output'];
};

export type SimpleCancelSubscriptionMutationResult = {
  __typename?: 'SimpleCancelSubscriptionMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<SimpleCancelSubscription>;
};

export type Site = {
  __typename?: 'Site';
  captcha?: Maybe<Captcha>;
  cdnDomain: Scalars['String']['output'];
  contacts?: Maybe<Contacts>;
  csrfToken?: Maybe<Scalars['String']['output']>;
  dateFormat: Scalars['String']['output'];
  defaultRoute: Scalars['String']['output'];
  firstLoginDelayedSetAllowed: Scalars['Boolean']['output'];
  footer?: Maybe<Footer>;
  googleMapsApiKey?: Maybe<Scalars['String']['output']>;
  imageFormat: Scalars['String']['output'];
  isHeaderAllowed: Scalars['Boolean']['output'];
  isMobileEnvironment: Scalars['Boolean']['output'];
  isSiteClosed: Scalars['Boolean']['output'];
  isWeb2AppSite: Scalars['Boolean']['output'];
  logo?: Maybe<SiteLogo>;
  mobileSolutions?: Maybe<MobileSolutions>;
  name: Scalars['String']['output'];
  photoUrlExtension?: Maybe<Scalars['String']['output']>;
  rangeYears?: Maybe<YearsRange>;
  siteId: Scalars['String']['output'];
  socialLinks?: Maybe<SocialLinks>;
};

export type SiteLogo = {
  __typename?: 'SiteLogo';
  default: Scalars['String']['output'];
  inverse: Scalars['String']['output'];
};

export type SiteMapItem = {
  __typename?: 'SiteMapItem';
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  openThroughNewWindow: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

export type SiteMutation = {
  __typename?: 'SiteMutation';
  dnsmpi?: Maybe<BooleanMutationResult>;
  sendLinkAsSms?: Maybe<BooleanMutationResult>;
  validateCaptcha?: Maybe<BooleanMutationResult>;
};

export type SiteMutationDnsmpiArgs = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  recaptchaResponse?: InputMaybe<Scalars['String']['input']>;
  zip: Scalars['String']['input'];
};

export type SiteMutationSendLinkAsSmsArgs = {
  link: Scalars['String']['input'];
};

export type SiteMutationValidateCaptchaArgs = {
  protectType: CaptchaProtectTypeEnum;
  recaptchaResponse: Scalars['String']['input'];
};

export enum SmokeAttribute {
  NO = 'NO',
  NOT_GIVEN = 'NOT_GIVEN',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  YES__REGULARLY = 'YES__REGULARLY',
  YES__SOCIALLY = 'YES__SOCIALLY',
}

export type SmokeDictionary = {
  __typename?: 'SmokeDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<SmokeAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type SmokeUserAttributeDictionary = {
  __typename?: 'SmokeUserAttributeDictionary';
  key?: Maybe<SmokeAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type SmsValidation = {
  __typename?: 'SmsValidation';
  availableUserPersonalData: Scalars['Boolean']['output'];
  countries: Array<Maybe<ValidationCountry>>;
  defaultCountry: Scalars['String']['output'];
  defaultLocale: Scalars['String']['output'];
  defaultPhone: Scalars['Int']['output'];
  isPopupAvailable: Scalars['Boolean']['output'];
  locales: Array<Maybe<Scalars['String']['output']>>;
};

export type SocialLinks = {
  __typename?: 'SocialLinks';
  facebook?: Maybe<Scalars['String']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  tiktok?: Maybe<Scalars['String']['output']>;
  twitter?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type SofortSettings = {
  __typename?: 'SofortSettings';
  isPrefilled: Scalars['Boolean']['output'];
  maskedFields?: Maybe<MaskedUserFields>;
};

export enum SortSearchType {
  age = 'age',
  distance = 'distance',
  lastvisit = 'lastvisit',
  photo_count = 'photo_count',
  photo_quality = 'photo_quality',
  similarProfile = 'similarProfile',
}

export enum Source {
  paymentPage = 'paymentPage',
  popup = 'popup',
}

export enum SourceInput {
  acc_man = 'acc_man',
  acc_man_email = 'acc_man_email',
  direct_link = 'direct_link',
  email_link = 'email_link',
}

export type SpdMatch = {
  __typename?: 'SpdMatch';
  eventId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type SpeedDating = {
  __typename?: 'SpeedDating';
  aboutUs: AboutUs;
  basket?: Maybe<BasketEvents>;
  blog: Blog;
  course: Course;
  event?: Maybe<SdEvent>;
  events: Array<Maybe<SdEvent>>;
  expertAnswers?: Maybe<Array<Maybe<ExpertAnswer>>>;
  freeTicketsAmount?: Maybe<Scalars['Int']['output']>;
  invoiceData?: Maybe<InvoiceDatas>;
  invoiceValidation?: Maybe<InvoiceValidation>;
  nearbyEvents: Array<Maybe<SdEvent>>;
  paramsDictionary: EventParamsDictionary;
  photoAlbum?: Maybe<PhotoAlbum>;
  photoAlbums: Array<Maybe<PhotoAlbum>>;
  post?: Maybe<Post>;
  pressRoom: PressRoom;
  reviews?: Maybe<Array<Maybe<Review>>>;
  searchEvents: Array<Maybe<SdEvent>>;
  searchParams?: Maybe<SpdClientSearchParams>;
  seo?: Maybe<Seo>;
  seoContent?: Maybe<SeoContent>;
  successStories?: Maybe<Array<Maybe<SuccessStory>>>;
  uniqueEvents: Array<Maybe<SdEvent>>;
  unlimitedPass?: Maybe<UnlimitedPass>;
  videos: Array<Maybe<Video>>;
};

export type SpeedDatingBasketArgs = {
  basketId?: InputMaybe<Scalars['String']['input']>;
};

export type SpeedDatingBlogArgs = {
  type?: InputMaybe<PostCategory>;
};

export type SpeedDatingEventArgs = {
  id: Scalars['ID']['input'];
};

export type SpeedDatingEventsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type SpeedDatingExpertAnswersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SpeedDatingInvoiceDataArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type SpeedDatingInvoiceValidationArgs = {
  invoiceId: Scalars['ID']['input'];
};

export type SpeedDatingNearbyEventsArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
};

export type SpeedDatingPhotoAlbumArgs = {
  id: Scalars['String']['input'];
};

export type SpeedDatingPhotoAlbumsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type SpeedDatingPostArgs = {
  id: Scalars['String']['input'];
};

export type SpeedDatingReviewsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type SpeedDatingSearchEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  params?: InputMaybe<EventSearchParamsInput>;
};

export type SpeedDatingSeoArgs = {
  customizationParams?: InputMaybe<Array<InputMaybe<SeoCustomizationParams>>>;
  url: Scalars['String']['input'];
};

export type SpeedDatingSeoContentArgs = {
  params?: InputMaybe<EventSearchParamsInput>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type SpeedDatingSuccessStoriesArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type SpeedDatingVideosArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type SpeedDatingMutation = {
  __typename?: 'SpeedDatingMutation';
  askExpert?: Maybe<BooleanMutationResult>;
  eventMutation?: Maybe<EventMutation>;
  leaveReview?: Maybe<BooleanMutationResult>;
  leaveSuccessStory?: Maybe<BooleanMutationResult>;
  vote?: Maybe<BooleanMutationResult>;
};

export type SpeedDatingMutationAskExpertArgs = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type SpeedDatingMutationLeaveReviewArgs = {
  content: Scalars['String']['input'];
  eventId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  rate: Scalars['Int']['input'];
};

export type SpeedDatingMutationLeaveSuccessStoryArgs = {
  content: Scalars['String']['input'];
  name: Scalars['String']['input'];
  wantToPublish?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SpeedDatingMutationVoteArgs = {
  eventId: Scalars['ID']['input'];
  eventRate?: InputMaybe<Scalars['Int']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  hostRate?: InputMaybe<Scalars['Int']['input']>;
  refusedToTick: Scalars['Boolean']['input'];
  users?: InputMaybe<Array<InputMaybe<VoteUser>>>;
};

export type SpeedDatingPayment = {
  __typename?: 'SpeedDatingPayment';
  boughtDatingCourse: Scalars['Boolean']['output'];
  subscriptionType: SubscriptionStatusEnum;
};

export type Spin = {
  __typename?: 'Spin';
  available: Scalars['Boolean']['output'];
  availableSpinCount?: Maybe<Scalars['Int']['output']>;
  costPerSpin?: Maybe<Scalars['Int']['output']>;
  gift?: Maybe<Gift>;
  giftList?: Maybe<Array<Maybe<Gift>>>;
  nextGameAvailableSpinCount?: Maybe<Scalars['Int']['output']>;
  nextGameTimeout?: Maybe<Scalars['Int']['output']>;
};

export type SpinGiftArgs = {
  spinId: Scalars['ID']['input'];
};

export type SpinMutation = {
  __typename?: 'SpinMutation';
  accept?: Maybe<ActMutationResult>;
  skip?: Maybe<ActMutationResult>;
  start?: Maybe<StartMutationResult>;
};

export type SpinMutationAcceptArgs = {
  gameId: Scalars['ID']['input'];
  spinId: Scalars['ID']['input'];
};

export type SpinNow = {
  __typename?: 'SpinNow';
  enabled: Scalars['Boolean']['output'];
  expiredAt?: Maybe<Scalars['Int']['output']>;
};

export type Start = {
  __typename?: 'Start';
  gameId?: Maybe<Scalars['ID']['output']>;
  spinId?: Maybe<Scalars['ID']['output']>;
};

export type StartMutationResult = {
  __typename?: 'StartMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<Start>;
};

export type StaticPage = {
  __typename?: 'StaticPage';
  content?: Maybe<Scalars['String']['output']>;
  isCopyEnabled: Scalars['Boolean']['output'];
};

export type StaticPageContentArgs = {
  page: Scalars['String']['input'];
  siteId?: InputMaybe<Scalars['String']['input']>;
};

export type Status = {
  __typename?: 'Status';
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export enum StatusMembershipAnswer {
  cancelBilling = 'cancelBilling',
  hide = 'hide',
  hideOff = 'hideOff',
  offMailing = 'offMailing',
  removeAccount = 'removeAccount',
  show = 'show',
}

export type StatusUpload = {
  __typename?: 'StatusUpload';
  enabled: Scalars['Boolean']['output'];
  texts?: Maybe<Array<Maybe<Status>>>;
};

export enum Step {
  areYouSure = 'areYouSure',
  checkPassword = 'checkPassword',
  confirmCancelByEmail = 'confirmCancelByEmail',
  datingCourse = 'datingCourse',
  default = 'default',
  emailSecurityCheck = 'emailSecurityCheck',
  enterCode = 'enterCode',
  errorOccurred = 'errorOccurred',
  getInfo = 'getInfo',
  hotlineCall = 'hotlineCall',
  incognitoMode = 'incognitoMode',
  newSearch = 'newSearch',
  preventSubscriptionCancel = 'preventSubscriptionCancel',
  reactivateSubscription = 'reactivateSubscription',
  safeMode = 'safeMode',
  simple = 'simple',
  statusMembership = 'statusMembership',
  stayOnTheSite = 'stayOnTheSite',
  stop = 'stop',
  transfer = 'transfer',
  userReason = 'userReason',
  userStory = 'userStory',
}

export type StepFlow = {
  __typename?: 'StepFlow';
  nextStep?: Maybe<StepNameEnum>;
  tryLaterStep?: Maybe<StepNameEnum>;
};

export enum StepNameEnum {
  _getFailedStep = '_getFailedStep',
  _processPaymentResult = '_processPaymentResult',
  account = 'account',
  ageVerificationPage = 'ageVerificationPage',
  autologin = 'autologin',
  bongaCams = 'bongaCams',
  bundle = 'bundle',
  buyCredits = 'buyCredits',
  buyCreditsFromChat = 'buyCreditsFromChat',
  cardAuthorization = 'cardAuthorization',
  cardFrame = 'cardFrame',
  credit = 'credit',
  crossSales = 'crossSales',
  datingCourse = 'datingCourse',
  devents = 'devents',
  error = 'error',
  fail = 'fail',
  fanclub = 'fanclub',
  features = 'features',
  freeMembership = 'freeMembership',
  funnel = 'funnel',
  getTheApp = 'getTheApp',
  gift = 'gift',
  holidayOffer = 'holidayOffer',
  hookupPlan = 'hookupPlan',
  inbox = 'inbox',
  lifetimeOffer = 'lifetimeOffer',
  membership = 'membership',
  message = 'message',
  metArt = 'metArt',
  metArtIndex = 'metArtIndex',
  microFeatures = 'microFeatures',
  miniMembership = 'miniMembership',
  multiSelectMicroFeatures = 'multiSelectMicroFeatures',
  newWayDating = 'newWayDating',
  newWayDatingCredits = 'newWayDatingCredits',
  newWayDatingMembership = 'newWayDatingMembership',
  nextStep = 'nextStep',
  oneClick = 'oneClick',
  paidChannel = 'paidChannel',
  popup = 'popup',
  popupBuyCredits = 'popupBuyCredits',
  popupGift = 'popupGift',
  popupOffer = 'popupOffer',
  popupTopDates = 'popupTopDates',
  posttrans_pup_promo = 'posttrans_pup_promo',
  preAuthPopup = 'preAuthPopup',
  preAuthPopupFail = 'preAuthPopupFail',
  preAuthPopupSuccess = 'preAuthPopupSuccess',
  preselectSpecialOffer = 'preselectSpecialOffer',
  product = 'product',
  promo = 'promo',
  promoFeatures = 'promoFeatures',
  promoMembership = 'promoMembership',
  remarketingOffer = 'remarketingOffer',
  result = 'result',
  return = 'return',
  search = 'search',
  searchModel = 'searchModel',
  smschat = 'smschat',
  speedDating = 'speedDating',
  stick = 'stick',
  success = 'success',
  tenYearsOffer = 'tenYearsOffer',
  topDates = 'topDates',
  tubeAgeVerification = 'tubeAgeVerification',
  verification = 'verification',
  verificationSuccess = 'verificationSuccess',
  videoChannel = 'videoChannel',
  vod = 'vod',
  vodIndex = 'vodIndex',
  vodOneClick = 'vodOneClick',
  wall = 'wall',
  webCamPostTrans = 'webCamPostTrans',
}

export type StickedActivityNotification = {
  __typename?: 'StickedActivityNotification';
  isEnabled: Scalars['Boolean']['output'];
};

export type StickedNotifications = {
  __typename?: 'StickedNotifications';
  expireTime: Scalars['Int']['output'];
  showTime: Scalars['Int']['output'];
};

export type Stories = {
  __typename?: 'Stories';
  available: Scalars['Boolean']['output'];
  countFreeParts?: Maybe<Scalars['Int']['output']>;
  list?: Maybe<Array<Maybe<StoryItem>>>;
  one?: Maybe<StoryItem>;
  uploadAllowed?: Maybe<Scalars['Boolean']['output']>;
};

export type StoriesOneArgs = {
  viewedUserId: Scalars['ID']['input'];
};

export type StoriesMutation = {
  __typename?: 'StoriesMutation';
  blockStoryPart?: Maybe<BooleanMutationResult>;
  deleteStoryPart?: Maybe<BooleanMutationResult>;
  likeStoryPart?: Maybe<BooleanMutationResult>;
  viewStoryPart?: Maybe<ViewStoryPartMutationResult>;
};

export type StoriesMutationBlockStoryPartArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
  reasonId?: InputMaybe<Scalars['Int']['input']>;
  storyPartId: Scalars['ID']['input'];
  type: StoryPartType;
};

export type StoriesMutationDeleteStoryPartArgs = {
  storyPartId: Scalars['ID']['input'];
  type: StoryPartType;
};

export type StoriesMutationLikeStoryPartArgs = {
  storyPartId: Scalars['ID']['input'];
  type: StoryPartType;
  userId: Scalars['ID']['input'];
};

export type StoriesMutationViewStoryPartArgs = {
  storyPartId: Scalars['ID']['input'];
  type: StoryPartType;
  userId: Scalars['ID']['input'];
};

export type StoryItem = {
  __typename?: 'StoryItem';
  areAllViewed: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  parts?: Maybe<Array<Maybe<StoryPartItem>>>;
  partsCount: Scalars['Int']['output'];
};

export type StoryPartAddedEvent = {
  __typename?: 'StoryPartAddedEvent';
  storyId: Scalars['String']['output'];
  storyPart?: Maybe<StoryPartItem>;
  userId: Scalars['String']['output'];
};

export type StoryPartItem = {
  __typename?: 'StoryPartItem';
  createdAt: Scalars['String']['output'];
  group: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isLiked: Scalars['Boolean']['output'];
  isViewed: Scalars['Boolean']['output'];
  likeCount: Scalars['Int']['output'];
  order: Scalars['Int']['output'];
  photo?: Maybe<PhotoType>;
  type: StoryPartType;
  video?: Maybe<VideoType>;
  viewCount: Scalars['Int']['output'];
};

export type StoryPartSubscriptionItemEvent = {
  __typename?: 'StoryPartSubscriptionItemEvent';
  id: Scalars['String']['output'];
  isVideo: Scalars['Boolean']['output'];
};

export enum StoryPartType {
  photo = 'photo',
  video = 'video',
}

export type StreamingEvent = {
  __typename?: 'StreamingEvent';
  blocked: Scalars['Boolean']['output'];
};

export type StringIdElement = {
  __typename?: 'StringIdElement';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type StringMutationResult = {
  __typename?: 'StringMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<Scalars['String']['output']>;
};

export type Subject = {
  __typename?: 'Subject';
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
  tooltip: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  action: Scalars['String']['output'];
  activity?: Maybe<ActivityEvent>;
  allUnsentMessagesCount: Scalars['Boolean']['output'];
  /** @deprecated use price field */
  amount?: Maybe<Scalars['String']['output']>;
  amountPartVideoGroup?: Maybe<AmountPartVideoGroupEvent>;
  canConfirmCancelByEmail?: Maybe<Scalars['Boolean']['output']>;
  cardId?: Maybe<Scalars['ID']['output']>;
  censored: Scalars['Boolean']['output'];
  credits?: Maybe<CreditsEvent>;
  declineVideoGroup?: Maybe<DeclineVideoGroupEvent>;
  deletedRoomMessage?: Maybe<DeletedRoomMessage>;
  disapprove?: Maybe<DisapproveEvent>;
  domain?: Maybe<Scalars['String']['output']>;
  error: Scalars['String']['output'];
  extraEvents?: Maybe<ExtraEvents>;
  freeMessageNotification?: Maybe<FreeMessageNotificationEvent>;
  freeViews?: Maybe<FreeViews>;
  fromId: Scalars['ID']['output'];
  fromUserId: Scalars['ID']['output'];
  globalFreeMessage?: Maybe<GlobalFreeMessageEvent>;
  globalSearchTrialEndedNotification?: Maybe<GlobalSearchTrialEndedNotification>;
  id: Scalars['ID']['output'];
  ip?: Maybe<Scalars['String']['output']>;
  isApprovedFromMessenger: Scalars['Boolean']['output'];
  isBluredForRecipient: Scalars['Boolean']['output'];
  isConverted?: Maybe<Scalars['Boolean']['output']>;
  level: Scalars['String']['output'];
  markAsScammerRooms?: Maybe<MarkAsScammerRoomsEvent>;
  matchCommunicationUserId: Scalars['ID']['output'];
  mediaId: Scalars['ID']['output'];
  mediaType: Scalars['String']['output'];
  message?: Maybe<MessageEvent>;
  messageId: Scalars['ID']['output'];
  messageIds: Array<Maybe<Scalars['ID']['output']>>;
  microFeatures?: Maybe<MicroFeaturesEvent>;
  needRemarketingReload: Scalars['Boolean']['output'];
  orderId?: Maybe<Scalars['ID']['output']>;
  packageName?: Maybe<Scalars['String']['output']>;
  paused?: Maybe<Scalars['Boolean']['output']>;
  payment?: Maybe<PaymentEvent>;
  paymentProcessed?: Maybe<PaymentProcessedEvent>;
  pendingDelete: Scalars['Boolean']['output'];
  personalIdentity?: Maybe<PersonalIdentityEvent>;
  photoUpload?: Maybe<PhotoUploadEvent>;
  price?: Maybe<Price>;
  processor?: Maybe<Scalars['String']['output']>;
  reactivateSubscriptionId?: Maybe<Scalars['String']['output']>;
  recipientId: Scalars['ID']['output'];
  recipients?: Maybe<Array<Maybe<Recipient>>>;
  remarketing?: Maybe<Remarketing>;
  repeatPackage?: Maybe<RepeatPackage>;
  reporterId: Scalars['ID']['output'];
  roomBanned?: Maybe<RoomBannedEvent>;
  roomJoin?: Maybe<RoomEvent>;
  roomLeave?: Maybe<RoomEvent>;
  roomMessage?: Maybe<RoomMessageEvent>;
  roomUnbanned: Scalars['Boolean']['output'];
  senderId: Scalars['ID']['output'];
  showBuyCoinsMotivationBanner: Scalars['Boolean']['output'];
  simpleCancelAllowed?: Maybe<Scalars['Boolean']['output']>;
  siteDescriptor?: Maybe<Scalars['String']['output']>;
  spdMatch?: Maybe<SpdMatch>;
  storyPart?: Maybe<StoryPartSubscriptionItemEvent>;
  storyPartAdded?: Maybe<StoryPartAddedEvent>;
  streaming?: Maybe<StreamingEvent>;
  subscriptionId?: Maybe<Scalars['ID']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleTranslated?: Maybe<Scalars['String']['output']>;
  toUserId: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  unseenCount?: Maybe<Scalars['Int']['output']>;
  updatedOn: Scalars['Int']['output'];
  upsell?: Maybe<UpsellEvent>;
  user?: Maybe<UserData>;
  userId: Scalars['ID']['output'];
  via?: Maybe<ViaEnum>;
  video?: Maybe<VideoEvent>;
  videoCall?: Maybe<VideoCallEvent>;
  videoMute?: Maybe<VideoMutedEvent>;
  webSocketConnected: Scalars['Boolean']['output'];
};

export type SubscriptionExpireDate = {
  __typename?: 'SubscriptionExpireDate';
  expireDate: Scalars['String']['output'];
  permissionSetId: Scalars['String']['output'];
};

export type SubscriptionItemInput = {
  name: Scalars['String']['input'];
  type: SubscriptionTypeItem;
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SubscriptionStatusEnum {
  basic = 'basic',
  unlimited = 'unlimited',
  unsubscribed = 'unsubscribed',
}

export enum SubscriptionTypeItem {
  allPushes = 'allPushes',
  eEmail = 'eEmail',
  pushAndroid = 'pushAndroid',
  pushIos = 'pushIos',
  sms = 'sms',
  webPush = 'webPush',
}

export type Subscriptions = {
  __typename?: 'Subscriptions';
  allPushes?: Maybe<Array<Maybe<NotificationSettingsItem>>>;
  eEmail?: Maybe<Array<Maybe<NotificationSettingsItem>>>;
  pushAndroid?: Maybe<Array<Maybe<NotificationSettingsItem>>>;
  pushIos?: Maybe<Array<Maybe<NotificationSettingsItem>>>;
  sms?: Maybe<Array<Maybe<NotificationSettingsItem>>>;
  webPush?: Maybe<Array<Maybe<NotificationSettingsItem>>>;
};

export type SuccessData = {
  __typename?: 'SuccessData';
  extraBillingInformation?: Maybe<ExtraBillingInformation>;
  orderId?: Maybe<Scalars['String']['output']>;
  purchaseTypeList?: Maybe<Array<Maybe<PurchaseType>>>;
  repeatAt?: Maybe<Scalars['String']['output']>;
  resultPackages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  resultPage?: Maybe<ResultPage>;
  saltEdgeAisLeadUrl?: Maybe<Scalars['String']['output']>;
  showAdditionalFeesWarning?: Maybe<Scalars['Boolean']['output']>;
  simpleCancel?: Maybe<Scalars['Boolean']['output']>;
  xSaleAutologinUrl?: Maybe<Scalars['String']['output']>;
};

export type SuccessDataExtraBillingInformationArgs = {
  orderIds: Array<Scalars['String']['input']>;
};

export type SuccessDataPurchaseTypeListArgs = {
  orderIds: Array<Scalars['String']['input']>;
};

export type SuccessDataResultPackagesArgs = {
  orderIds: Array<Scalars['String']['input']>;
};

export type SuccessDataResultPageArgs = {
  orderIds: Array<Scalars['String']['input']>;
};

export type SuccessDataSaltEdgeAisLeadUrlArgs = {
  orderIds: Array<Scalars['String']['input']>;
};

export type SuccessDataShowAdditionalFeesWarningArgs = {
  orderIds: Array<Scalars['String']['input']>;
};

export type SuccessStory = {
  __typename?: 'SuccessStory';
  author: SdAuthor;
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  timestamp: Scalars['Int']['output'];
};

export type Support = {
  __typename?: 'Support';
  contact?: Maybe<Contact>;
  feedback?: Maybe<Feedback>;
};

export type SupportMutation = {
  __typename?: 'SupportMutation';
  contactUs?: Maybe<ContactUsPayload>;
  feedback?: Maybe<FeedbackPayload>;
};

export type SupportMutationContactUsArgs = {
  categoryId: Scalars['ID']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  subjectId?: InputMaybe<Scalars['ID']['input']>;
};

export type SupportMutationFeedbackArgs = {
  categoryId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  screenHeight: Scalars['Int']['input'];
  screenWidth: Scalars['Int']['input'];
  selectedText: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export enum SupportedCountries {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CS = 'CS',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  EU = 'EU',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  FX = 'FX',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  QZ = 'QZ',
  RE = 'RE',
  RM = 'RM',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  XX = 'XX',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

export enum TabSearchType {
  all_members = 'all_members',
  chating = 'chating',
  distance = 'distance',
  ios_geo = 'ios_geo',
  livecam = 'livecam',
  near_me = 'near_me',
  near_you = 'near_you',
  new_members = 'new_members',
  online = 'online',
  photo_count = 'photo_count',
  premium_users = 'premium_users',
  sassyPhotos = 'sassyPhotos',
}

export type TargetUrl = {
  __typename?: 'TargetUrl';
  setActiveNewTab?: Maybe<Scalars['Boolean']['output']>;
  targetUrl?: Maybe<Scalars['String']['output']>;
};

export type TargetUserDictionary = {
  __typename?: 'TargetUserDictionary';
  attributes?: Maybe<ProfileDictionaryAttributesTypes>;
};

export type Tasks = {
  __typename?: 'Tasks';
  emailVerification?: Maybe<TypesEmailVerification>;
  mediaUpload?: Maybe<MediaUpload>;
  pwaInstall?: Maybe<PwaInstallType>;
  relogins?: Maybe<Relogins>;
  telegramSubscription?: Maybe<TelegramSubscription>;
  webPush?: Maybe<WebPushType>;
};

export enum TattooAttribute {
  NO = 'NO',
  NOT_GIVEN = 'NOT_GIVEN',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  YES = 'YES',
}

export type TattooDictionary = {
  __typename?: 'TattooDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<TattooAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type TattooUserAttributeDictionary = {
  __typename?: 'TattooUserAttributeDictionary';
  key?: Maybe<TattooAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type TemplateSettings = {
  __typename?: 'TemplateSettings';
  additionalTerms: AdditionalTerms;
  additionalTermsStatusMap?: Maybe<AdditionalTerms>;
  buttonTitle?: Maybe<ButtonTitle>;
  isAvailableFreePermissions?: Maybe<Scalars['Boolean']['output']>;
  isClickableElements: Scalars['Boolean']['output'];
  isClickablePackages: Scalars['Boolean']['output'];
  paymentMethodButtonTitle?: Maybe<Array<Maybe<PaymentMethodButtonTitle>>>;
  templateSettingsData?: Maybe<TemplateSettingsData>;
};

export type TemplateSettingsAdditionalTermsArgs = {
  action: Action;
};

export type TemplateSettingsAdditionalTermsStatusMapArgs = {
  action: Action;
};

export type TemplateSettingsButtonTitleArgs = {
  action: Action;
  prevVia?: InputMaybe<ViaEnum>;
  via?: InputMaybe<ViaEnum>;
};

export type TemplateSettingsIsAvailableFreePermissionsArgs = {
  action?: InputMaybe<Action>;
};

export type TemplateSettingsPaymentMethodButtonTitleArgs = {
  action: Action;
  prevVia?: InputMaybe<ViaEnum>;
  via?: InputMaybe<ViaEnum>;
};

export type TemplateSettingsTemplateSettingsDataArgs = {
  action?: InputMaybe<Action>;
  prevVia?: InputMaybe<ViaEnum>;
  via?: InputMaybe<ViaEnum>;
};

export type TemplateSettingsData = {
  __typename?: 'TemplateSettingsData';
  compactDeclinePage?: Maybe<Scalars['Boolean']['output']>;
  isComplianceFriendly?: Maybe<Scalars['Boolean']['output']>;
  motivationTemplate?: Maybe<Scalars['String']['output']>;
  template?: Maybe<Scalars['String']['output']>;
};

export type Text = {
  __typename?: 'Text';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TextBillingPolicy = {
  __typename?: 'TextBillingPolicy';
  isShowCredentialsPolicy?: Maybe<Scalars['Boolean']['output']>;
  isShowRefundPolicy?: Maybe<Scalars['Boolean']['output']>;
  showConsentAgreement?: Maybe<Scalars['Boolean']['output']>;
  showPrivacyPolicy?: Maybe<Scalars['Boolean']['output']>;
  translateMessageEntityMap?: Maybe<TranslateMessageEntityMap>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Tip = {
  __typename?: 'Tip';
  content?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TokenPrice = {
  __typename?: 'TokenPrice';
  amount: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
};

export type TopUsers = {
  __typename?: 'TopUsers';
  result?: Maybe<Array<Maybe<UserData>>>;
};

export type Track = {
  __typename?: 'Track';
  /** @deprecated Use Phoenix\GraphQL\Components\FrontendTracking\TrackCreditPopup */
  creditPopup?: Maybe<BooleanMutationResult>;
};

export type TrackingCodes = {
  __typename?: 'TrackingCodes';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type TrackingData = {
  actionId?: InputMaybe<Scalars['String']['input']>;
  anchorId?: InputMaybe<Scalars['String']['input']>;
  placementId?: InputMaybe<Scalars['String']['input']>;
  promo?: InputMaybe<Scalars['String']['input']>;
  promocode?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
};

export type TranslateMessageEntityMap = {
  __typename?: 'TranslateMessageEntityMap';
  assistanceText?: Maybe<Scalars['String']['output']>;
  billingPolicyLinkText?: Maybe<Scalars['String']['output']>;
  billingPolicyText?: Maybe<Scalars['String']['output']>;
  hotlineCreditCard?: Maybe<Scalars['String']['output']>;
  openningWorkTime?: Maybe<Scalars['String']['output']>;
  publicUserIdText?: Maybe<Scalars['String']['output']>;
  workTimeHour?: Maybe<Scalars['String']['output']>;
};

export enum TribeAttribute {
  BEAR = 'BEAR',
  CLEAN_CUT = 'CLEAN_CUT',
  DADDY = 'DADDY',
  GEEK = 'GEEK',
  JOCK = 'JOCK',
  LEATHER = 'LEATHER',
  NOT_GIVEN = 'NOT_GIVEN',
  TWINKS = 'TWINKS',
}

export type TribeDictionary = {
  __typename?: 'TribeDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<TribeAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type TribeUserAttributeDictionary = {
  __typename?: 'TribeUserAttributeDictionary';
  key?: Maybe<TribeAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type TrustPayIdealSettings = {
  __typename?: 'TrustPayIdealSettings';
  bicList: Array<BicInfo>;
  isPrefilled: Scalars['Boolean']['output'];
  maskedFields?: Maybe<MaskedUserFields>;
};

export type TrustPaySepaSettings = {
  __typename?: 'TrustPaySepaSettings';
  isPrefilled: Scalars['Boolean']['output'];
  maskedFields?: Maybe<MaskedUserFields>;
  sepaAutofillAccess: Scalars['Boolean']['output'];
};

export type Trusted = {
  __typename?: 'Trusted';
  isTrusted?: Maybe<Scalars['Boolean']['output']>;
  isValidationNeeded?: Maybe<Scalars['Boolean']['output']>;
  sv: Scalars['Int']['output'];
};

export type TrustedMutation = {
  __typename?: 'TrustedMutation';
  sendSms?: Maybe<StringMutationResult>;
  trackVisit?: Maybe<BooleanMutationResult>;
  verifySmsCode?: Maybe<BooleanMutationResult>;
};

export type TrustedMutationSendSmsArgs = {
  countryPhoneCode: Scalars['Int']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};

export type TrustedMutationTrackVisitArgs = {
  via: Scalars['String']['input'];
};

export type TrustedMutationVerifySmsCodeArgs = {
  code: Scalars['String']['input'];
  referenceId: Scalars['String']['input'];
  setFullSafeMode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TrustedValidationMethods = {
  __typename?: 'TrustedValidationMethods';
  isExceed: Scalars['Boolean']['output'];
  sms: Scalars['Boolean']['output'];
  support: Scalars['Boolean']['output'];
};

export type TrustedVerificationSettings = {
  __typename?: 'TrustedVerificationSettings';
  available: Scalars['Boolean']['output'];
  sms?: Maybe<SmsValidation>;
  textScenario?: Maybe<VerificationTextScenarioEnum>;
  validationMethods: TrustedValidationMethods;
};

export type TypesEmailVerification = {
  __typename?: 'TypesEmailVerification';
  completed?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  reward?: Maybe<Scalars['Int']['output']>;
};

export type UnlimitedPass = {
  __typename?: 'UnlimitedPass';
  faqs?: Maybe<Array<Maybe<Faq>>>;
  reviews?: Maybe<Array<Maybe<Review>>>;
};

export type UnlimitedPassReviewsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type UnlockProfile = {
  __typename?: 'UnlockProfile';
  money?: Maybe<Price>;
  /** @deprecated use money instead */
  price?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<Maybe<UserData>>>;
  via: Scalars['String']['output'];
};

export enum UnlockProfileFeature {
  unlockProfile = 'unlockProfile',
  unlockProfileAdult = 'unlockProfileAdult',
}

export type UpdateEmail = {
  __typename?: 'UpdateEmail';
  redirectUrl: Scalars['String']['output'];
};

export type UpsellEvent = {
  __typename?: 'UpsellEvent';
  type: Scalars['String']['output'];
};

export type UpsellPopupStatistic = {
  __typename?: 'UpsellPopupStatistic';
  enabled: Scalars['Boolean']['output'];
  like?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['Int']['output']>;
  money?: Maybe<Price>;
  packageTitle?: Maybe<Scalars['String']['output']>;
  /** @deprecated use money instead */
  price?: Maybe<Scalars['String']['output']>;
  view?: Maybe<Scalars['Int']['output']>;
};

export type UrlResponse = {
  __typename?: 'UrlResponse';
  url: Scalars['String']['output'];
};

export type UserData = {
  __typename?: 'UserData';
  askFor?: Maybe<AskFor>;
  blockUser?: Maybe<Blocked>;
  crossVisionData?: Maybe<CrossVisionData>;
  id: Scalars['ID']['output'];
  isBlurred: Scalars['Boolean']['output'];
  lookingFor?: Maybe<LookingForData>;
  messagePermissions?: Maybe<MessagePermissions>;
  nearMe: Scalars['Boolean']['output'];
  onlyBaseSearchSegments?: Maybe<Scalars['Int']['output']>;
  profile?: Maybe<Profile>;
  reportUser?: Maybe<ReportUser>;
  reportUserStream?: Maybe<ReportUserStream>;
  similarProfiles?: Maybe<Array<Maybe<UserData>>>;
  siteName: Scalars['String']['output'];
  speedDating?: Maybe<UserSpeedDating>;
  trusted?: Maybe<Trusted>;
  userActivity?: Maybe<ActivityStatuses>;
  videoChat?: Maybe<VideoChatType>;
  viewPermissions?: Maybe<ViewPermissions>;
  webcamPlacements?: Maybe<WebcamPlacements>;
};

export type UserDataWebcamPlacementsArgs = {
  partner?: InputMaybe<Scalars['String']['input']>;
  placements?: InputMaybe<Array<InputMaybe<WebCamPromoNames>>>;
};

export type UserDataOpen = {
  __typename?: 'UserDataOpen';
  id: Scalars['ID']['output'];
  profile?: Maybe<ProfileOpen>;
};

export type UserEvent = {
  __typename?: 'UserEvent';
  badgeNumber: Scalars['Int']['output'];
  rate?: Maybe<EventRate>;
  user?: Maybe<UserData>;
};

export type UserFeatures = {
  __typename?: 'UserFeatures';
  activeSplit?: Maybe<ActiveSplit>;
  adminBot?: Maybe<Scalars['Boolean']['output']>;
  allowedAboutUs: Scalars['Boolean']['output'];
  allowedActivity: Scalars['Boolean']['output'];
  allowedDiscreetProfiles: Scalars['Boolean']['output'];
  allowedLegalInfo: Scalars['Boolean']['output'];
  allowedLikeGallery: Scalars['Boolean']['output'];
  allowedMatches: Scalars['Boolean']['output'];
  allowedMutualActivity: Scalars['Boolean']['output'];
  allowedMyActivity: Scalars['Boolean']['output'];
  allowedSafetyTips: Scalars['Boolean']['output'];
  appFunnelRegType?: Maybe<Scalars['String']['output']>;
  /** @deprecated dublicates MyUserDictionary */
  attributesDictionary?: Maybe<AttributesDictionary>;
  autologinUrl?: Maybe<Scalars['String']['output']>;
  bannersOptions?: Maybe<BannersOptions>;
  bioGeneration?: Maybe<BioGeneration>;
  bioGenerationV2?: Maybe<BioGenerationV2>;
  canReadNotification?: Maybe<CanReadNotification>;
  cancelRepeat24h?: Maybe<Scalars['String']['output']>;
  changeBrowserMotivationEnabled?: Maybe<Scalars['Boolean']['output']>;
  communicationPhotoRestriction?: Maybe<CommunicationPhotoRestrictionReasonEnum>;
  completionTotal?: Maybe<Scalars['Int']['output']>;
  complianceLink?: Maybe<ComplianceLink>;
  coregistration?: Maybe<Coregistration>;
  coregistrationUseClientSessionStorage?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated use credits */
  creditBalance: Scalars['Int']['output'];
  crossVision?: Maybe<CrossVision>;
  displayVideoTooltip: Scalars['Boolean']['output'];
  emailData?: Maybe<EmailData>;
  enabledActivityTypes: Array<Maybe<Scalars['String']['output']>>;
  enabledRequiredPhotoUpload: Scalars['Boolean']['output'];
  extraPaymentBanner?: Maybe<ExtraPaymentBanner>;
  favoriteInUserActions: Scalars['Boolean']['output'];
  firstLoginDelayedSetAllowed: Scalars['Boolean']['output'];
  firstMessageGeneration?: Maybe<FirstMessageGeneration>;
  foreignCommunicationFeature?: Maybe<ForeignCommunicationFeature>;
  fortuneWheelDiscountBanner?: Maybe<FortuneWheelDiscountBanner>;
  /** @deprecated Remove it after 01.05.2025, task - TNC-30051 */
  freeConversation: Scalars['Boolean']['output'];
  fromExternalAdultSite: Scalars['Boolean']['output'];
  frontendTrackingParams?: Maybe<FrontendTrackingParams>;
  gdpr: Scalars['Boolean']['output'];
  haveActiveStreamSession?: Maybe<Scalars['Boolean']['output']>;
  holiday?: Maybe<Holiday>;
  inAppBrowserName?: Maybe<InAppBrowsersEnum>;
  /** @deprecated User inAppParams */
  inAppMotivation?: Maybe<InAppMotivation>;
  inAppParams: InAppParams;
  incognitoMode: IncognitoMode;
  infinityPhotoUploadAvailable: Scalars['Boolean']['output'];
  inventoryTracking?: Maybe<InventoryTracking>;
  isAdultContent: Scalars['Boolean']['output'];
  isEmailConfirmAllowed: Scalars['Boolean']['output'];
  isEmailConfirmAllowedOnProfileView: Scalars['Boolean']['output'];
  isEmailConfirmRequired: Scalars['Boolean']['output'];
  isEnabledEmailValidationInAccount: Scalars['Boolean']['output'];
  isHideCity: Scalars['Boolean']['output'];
  isLargerContent: Scalars['Boolean']['output'];
  isNotificationBannerAvailable?: Maybe<Scalars['Boolean']['output']>;
  isOpenProfilesAllowed: Scalars['Boolean']['output'];
  isPhotoUploadCloseButtonHideEnabled: Scalars['Boolean']['output'];
  isPhotoUploadDisabled: Scalars['Boolean']['output'];
  isPreloadPaymentPage: Scalars['Boolean']['output'];
  isProfileVideoUploadAllow?: Maybe<Scalars['Boolean']['output']>;
  isStrictNudityLaws: Scalars['Boolean']['output'];
  isTest: Scalars['Boolean']['output'];
  isUserDebug?: Maybe<Scalars['Boolean']['output']>;
  l10n: L10n;
  livecam?: Maybe<Livecam>;
  marketingProductConfigurationName?: Maybe<Scalars['String']['output']>;
  messageAssistant?: Maybe<MessageAssistantResponse>;
  /** @deprecated use GraphQL\Components\MicroFeatures\Fields\MicroFeatures */
  microfeature?: Maybe<MicroFeature>;
  motivationBackgrounds?: Maybe<MotivationBackgrounds>;
  nativeLanguageName: Scalars['String']['output'];
  naughtyMode: NaughtyMode;
  nonPaidNotification?: Maybe<Scalars['Boolean']['output']>;
  openProfile?: Maybe<OpenProfile>;
  paymentBanner?: Maybe<PaymentBanner>;
  paymentDiscount?: Maybe<Scalars['Int']['output']>;
  paymentLastOrder?: Maybe<PaymentLastOrder>;
  personalIdentity?: Maybe<PersonalIdentity>;
  photoSettings?: Maybe<PhotoSettings>;
  photoTips?: Maybe<Scalars['Boolean']['output']>;
  photoUploadMotivationSettings?: Maybe<PhotoUploadMotivationSettings>;
  pma: PromoteMyAccount;
  pmaRequestPhoto?: Maybe<PmaRequestPhoto>;
  popunder?: Maybe<Popunder>;
  popunderActionDelay?: Maybe<Array<Maybe<PopunderActionDelayItem>>>;
  privatePhoto?: Maybe<PrivatePhoto>;
  professionIcons?: Maybe<ProfessionIcons>;
  protectFromSpam: Scalars['Boolean']['output'];
  publicId: Scalars['String']['output'];
  quiz?: Maybe<Quiz>;
  reels: Array<Maybe<Reels>>;
  reelsFeature: ReelsFeature;
  safeMode: SafeMode;
  safeModeAvailable: Scalars['Boolean']['output'];
  separatedDialogs: Scalars['Boolean']['output'];
  showConfirmActivationCode: Scalars['Boolean']['output'];
  showLocation: Scalars['Boolean']['output'];
  stickedNotifications?: Maybe<StickedNotifications>;
  svAllowed: Scalars['Boolean']['output'];
  telegramBotInviteUrl?: Maybe<Scalars['String']['output']>;
  trackingCodes?: Maybe<Array<Maybe<TrackingCodes>>>;
  uniform?: Maybe<Scalars['Boolean']['output']>;
  urlAfterConfirmation: Scalars['String']['output'];
  userIdAfterFunnel?: Maybe<Scalars['String']['output']>;
  userVideo?: Maybe<UserVideo>;
  webPush?: Maybe<WebPush>;
  withCleanProductRestrictions: Scalars['Boolean']['output'];
};

export type UserFeaturesBannersOptionsArgs = {
  environment?: InputMaybe<EnvironmentEnum>;
  listType?: InputMaybe<Scalars['String']['input']>;
};

export type UserFeaturesCanReadNotificationArgs = {
  activityId: Scalars['ID']['input'];
  place?: InputMaybe<PlaceEnum>;
};

export type UserFeaturesCommunicationPhotoRestrictionArgs = {
  type?: InputMaybe<CommunicationPhotoRestrictionActivityType>;
};

export type UserFeaturesCoregistrationArgs = {
  zone: Scalars['String']['input'];
};

export type UserFeaturesMessageAssistantArgs = {
  toUserId: Scalars['String']['input'];
};

export type UserFeaturesMotivationBackgroundsArgs = {
  naughtyMode: Scalars['Int']['input'];
};

export type UserFeaturesPopunderArgs = {
  async: Scalars['Boolean']['input'];
  zone: PopunderZoneEnum;
};

export type UserFeaturesTelegramBotInviteUrlArgs = {
  via: Scalars['String']['input'];
};

export type UserFeaturesTrackingCodesArgs = {
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  useAnalytics?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserFeaturesPayload = {
  __typename?: 'UserFeaturesPayload';
  answerFunnelQuizQuestion?: Maybe<BooleanMutationResult>;
  bioGenerationV2?: Maybe<BioGenerationV2Response>;
  coregistration?: Maybe<CoregistrationPayload>;
  crossVisionActivate?: Maybe<BooleanMutationResult>;
  flirtcast?: Maybe<FlirtcastPayload>;
  foreignCommunicationFeature?: Maybe<ForeignCommunicationFeaturePayload>;
  frontendTracking?: Maybe<FrontendTracking>;
  incognitoMode?: Maybe<BooleanMutationResult>;
  naughtyMode?: Maybe<BooleanMutationResult>;
  personalIdentityResetValues?: Maybe<BooleanMutationResult>;
  pma?: Maybe<BooleanMutationResult>;
  pmaRequestPhoto?: Maybe<PmaRequestPhotoType>;
  popunderTargetUrl?: Maybe<PopunderTargetUrlResult>;
  privatePhoto?: Maybe<BooleanMutationResult>;
  reelsAction?: Maybe<BooleanMutationResult>;
  safeMode?: Maybe<BooleanMutationResult>;
  setLastAppPwa?: Maybe<BooleanMutationResult>;
  trackPopunder?: Maybe<BooleanMutationResult>;
  updateEmail?: Maybe<UpdateEmail>;
  updateLocale?: Maybe<BooleanMutationResult>;
  updateTimeOffset?: Maybe<BooleanMutationResult>;
};

export type UserFeaturesPayloadAnswerFunnelQuizQuestionArgs = {
  answerId: Scalars['Int']['input'];
};

export type UserFeaturesPayloadBioGenerationV2Args = {
  button?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserFeaturesPayloadCoregistrationArgs = {
  params: Array<InputMaybe<RequestParamInput>>;
};

export type UserFeaturesPayloadCrossVisionActivateArgs = {
  active: Scalars['Boolean']['input'];
};

export type UserFeaturesPayloadFlirtcastArgs = {
  body: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UserFeaturesPayloadForeignCommunicationFeatureArgs = {
  checked: Scalars['Boolean']['input'];
};

export type UserFeaturesPayloadIncognitoModeArgs = {
  status?: InputMaybe<IncognitoModeStatus>;
};

export type UserFeaturesPayloadNaughtyModeArgs = {
  naughtyMode: Scalars['Int']['input'];
};

export type UserFeaturesPayloadPersonalIdentityResetValuesArgs = {
  userId: Scalars['ID']['input'];
};

export type UserFeaturesPayloadPmaArgs = {
  activated: Scalars['Boolean']['input'];
  messageTheme?: InputMaybe<Scalars['Int']['input']>;
};

export type UserFeaturesPayloadPopunderTargetUrlArgs = {
  deliveryUrl: Scalars['String']['input'];
  zonePlacements: PlacementsInput;
};

export type UserFeaturesPayloadPrivatePhotoArgs = {
  allowedAutoSend: Scalars['Boolean']['input'];
};

export type UserFeaturesPayloadReelsActionArgs = {
  action: Scalars['String']['input'];
  videoId: Scalars['String']['input'];
};

export type UserFeaturesPayloadSafeModeArgs = {
  safeMode: SafeMode;
};

export type UserFeaturesPayloadSetLastAppPwaArgs = {
  isLastAppPwa: Scalars['Boolean']['input'];
};

export type UserFeaturesPayloadUpdateEmailArgs = {
  newEmail: Scalars['String']['input'];
  verificationCode?: InputMaybe<Scalars['String']['input']>;
};

export type UserFeaturesPayloadUpdateLocaleArgs = {
  locale: Scalars['String']['input'];
};

export type UserFeaturesPayloadUpdateTimeOffsetArgs = {
  offset: Scalars['Int']['input'];
};

export type UserIdentify = {
  __typename?: 'UserIdentify';
  authorization?: Maybe<AuthorizationMutationResult>;
  registration?: Maybe<AuthorizationMutationResult>;
};

export type UserIdentifyAuthorizationArgs = {
  identifyData?: InputMaybe<IdentifyData>;
};

export type UserIdentifyRegistrationArgs = {
  age: Scalars['Int']['input'];
  gender: GenderEnum;
  identifyData?: InputMaybe<IdentifyData>;
  location: Scalars['String']['input'];
};

export type UserLocationType = {
  __typename?: 'UserLocationType';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<CountryType>;
  distanceGroup?: Maybe<DistanceGroup>;
  distanceToMe?: Maybe<Scalars['Float']['output']>;
  distanceToMySearch?: Maybe<Scalars['Float']['output']>;
  distanceUnit?: Maybe<Scalars['String']['output']>;
  locationString?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  regionAbbreviation?: Maybe<Scalars['String']['output']>;
};

export type UserMutation = {
  __typename?: 'UserMutation';
  addOpenProfile?: Maybe<BooleanMutationResult>;
  askFor?: Maybe<BooleanMutationResult>;
  blockUser?: Maybe<BooleanMutationResult>;
  favorite?: Maybe<BooleanMutationResult>;
  like?: Maybe<BooleanMutationResult>;
  myUser?: Maybe<MyUserMutation>;
  reportUser?: Maybe<BooleanMutationResult>;
  reportUserStream?: Maybe<BooleanMutationResult>;
  skipLike?: Maybe<BooleanMutationResult>;
  userView?: Maybe<BooleanMutationResult>;
  wink?: Maybe<BooleanMutationResult>;
};

export type UserMutationAddOpenProfileArgs = {
  source: OpenProfileSource;
  userId: Scalars['ID']['input'];
};

export type UserMutationAskForArgs = {
  type: AskForTypes;
  userId: Scalars['ID']['input'];
};

export type UserMutationBlockUserArgs = {
  action: BlockUser;
  userId: Scalars['ID']['input'];
};

export type UserMutationFavoriteArgs = {
  action: FavoriteMutationAction;
  userId: Scalars['ID']['input'];
};

export type UserMutationLikeArgs = {
  photoId?: InputMaybe<Scalars['ID']['input']>;
  place?: InputMaybe<LikePlaceEnum>;
  userId: Scalars['ID']['input'];
};

export type UserMutationReportUserArgs = {
  action: ComplaintsActions;
  appKey?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  reasonId?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};

export type UserMutationReportUserStreamArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['Int']['input']>;
  reasonId?: InputMaybe<Scalars['Int']['input']>;
  streamId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type UserMutationSkipLikeArgs = {
  photoId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type UserMutationUserViewArgs = {
  subType?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  viewFrom?: InputMaybe<Scalars['String']['input']>;
};

export type UserMutationWinkArgs = {
  userId: Scalars['ID']['input'];
};

export type UserPaidFeatures = {
  __typename?: 'UserPaidFeatures';
  discreetProfileCandidateCost?: Maybe<Scalars['Int']['output']>;
  kissCost?: Maybe<Scalars['Int']['output']>;
  notifyAboutReadMessageCost?: Maybe<Scalars['Int']['output']>;
  showAllPrivatePhotosCost?: Maybe<Scalars['Int']['output']>;
  showAllProfilesInSearchCost?: Maybe<Scalars['Int']['output']>;
  viewDiscreetProfileCost?: Maybe<Scalars['Int']['output']>;
  viewMessagePhotoCost?: Maybe<Scalars['Int']['output']>;
  viewMessageVideoCost?: Maybe<Scalars['Int']['output']>;
  viewProfilePhotoCost?: Maybe<Scalars['Int']['output']>;
  viewProfileVideoCost?: Maybe<Scalars['Int']['output']>;
};

export type UserPublicId = {
  __typename?: 'UserPublicId';
  publicUserId?: Maybe<Scalars['String']['output']>;
  translateMessageEntityMap?: Maybe<TranslateMessageEntityMap>;
};

export type UserSpeedDating = {
  __typename?: 'UserSpeedDating';
  events: Array<Maybe<SdEvent>>;
  promocodes?: Maybe<Array<Maybe<Promocode>>>;
};

export type UserVideo = {
  __typename?: 'UserVideo';
  isEnabled: Scalars['Boolean']['output'];
  settings?: Maybe<UserVideoSettings>;
};

export type UserVideoSettings = {
  __typename?: 'UserVideoSettings';
  actionType: Scalars['String']['output'];
  allowedExtensions: Array<Maybe<Scalars['String']['output']>>;
  attachToUser: Scalars['Boolean']['output'];
  entityName: Scalars['String']['output'];
  limit: Scalars['Int']['output'];
  maxLenght: Scalars['Int']['output'];
  maxSize: Scalars['Int']['output'];
  minLenght: Scalars['Int']['output'];
};

export type Users = {
  __typename?: 'Users';
  completionUsers?: Maybe<Array<Maybe<UserData>>>;
  resultingUsers?: Maybe<Array<Maybe<UserData>>>;
};

export type ValidationCountry = {
  __typename?: 'ValidationCountry';
  country: Scalars['String']['output'];
  phone: Scalars['Int']['output'];
  phoneLengths?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  prefixes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type VendoSepaSettings = {
  __typename?: 'VendoSepaSettings';
  isPrefilled: Scalars['Boolean']['output'];
  maskedFields?: Maybe<MaskedUserFields>;
  sepaAutofillAccess: Scalars['Boolean']['output'];
};

export type Verification = {
  __typename?: 'Verification';
  additionalPackagePay?: Maybe<BooleanMutationResult>;
  /** @deprecated Use query payment.verification.additionalPackage */
  additionalPackageText?: Maybe<StringMutationResult>;
};

export type VerificationQuery = {
  __typename?: 'VerificationQuery';
  additionalPackage?: Maybe<AdditionalPackageVerification>;
};

export enum VerificationTextScenarioEnum {
  gender = 'gender',
  ip = 'ip',
}

export enum ViaEnum {
  Final_stream_gag_free_user = 'Final_stream_gag_free_user',
  LikeGallery = 'LikeGallery',
  RM_TN86056 = 'RM_TN86056',
  RMbundle_popup_1s = 'RMbundle_popup_1s',
  RMbundle_popup_2s = 'RMbundle_popup_2s',
  RMbundle_popup_5in1 = 'RMbundle_popup_5in1',
  SearchPriorityManMotiv = 'SearchPriorityManMotiv',
  Stream_buzz_membership = 'Stream_buzz_membership',
  Stream_gag_free_user = 'Stream_gag_free_user',
  Stream_gifts_for_credit_package_2 = 'Stream_gifts_for_credit_package_2',
  Stream_gifts_for_credit_package_4 = 'Stream_gifts_for_credit_package_4',
  Stream_gifts_membership = 'Stream_gifts_membership',
  Stream_private_membership = 'Stream_private_membership',
  Stream_tips_for_credit_package_2 = 'Stream_tips_for_credit_package_2',
  Stream_tips_for_credit_package_4 = 'Stream_tips_for_credit_package_4',
  Stream_tips_membership = 'Stream_tips_membership',
  TopGirls_2 = 'TopGirls_2',
  VOD_oneclickpopup = 'VOD_oneclickpopup',
  VOD_popup = 'VOD_popup',
  VOD_preview = 'VOD_preview',
  VOD_sexart = 'VOD_sexart',
  VOD_sideMenu = 'VOD_sideMenu',
  account = 'account',
  account_banner = 'account_banner',
  act_chat_read = 'act_chat_read',
  act_chat_read_2 = 'act_chat_read_2',
  act_chat_read_3 = 'act_chat_read_3',
  act_chat_read_4 = 'act_chat_read_4',
  act_chat_read_5 = 'act_chat_read_5',
  act_chat_read_6 = 'act_chat_read_6',
  act_chat_read_7 = 'act_chat_read_7',
  act_chat_type_1 = 'act_chat_type_1',
  act_chat_type_2 = 'act_chat_type_2',
  act_chat_type_3 = 'act_chat_type_3',
  act_chat_type_4 = 'act_chat_type_4',
  activ_income_like = 'activ_income_like',
  active_chat = 'active_chat',
  activity_addto_friends = 'activity_addto_friends',
  activity_banner = 'activity_banner',
  activity_browsed = 'activity_browsed',
  activity_browsed_n = 'activity_browsed_n',
  activity_browsetype = 'activity_browsetype',
  activity_chat_read = 'activity_chat_read',
  activity_chat_read3 = 'activity_chat_read3',
  activity_chat_read_2 = 'activity_chat_read_2',
  activity_chat_read_3 = 'activity_chat_read_3',
  activity_chat_read_4 = 'activity_chat_read_4',
  activity_chat_read_5 = 'activity_chat_read_5',
  activity_chat_read_6 = 'activity_chat_read_6',
  activity_chat_read_7 = 'activity_chat_read_7',
  activity_chat_read_8 = 'activity_chat_read_8',
  activity_chat_read_9 = 'activity_chat_read_9',
  activity_chat_read_10 = 'activity_chat_read_10',
  activity_chat_read_11 = 'activity_chat_read_11',
  activity_chat_read_12 = 'activity_chat_read_12',
  activity_chat_read_13 = 'activity_chat_read_13',
  activity_chat_read_14 = 'activity_chat_read_14',
  activity_chat_read_14_b = 'activity_chat_read_14_b',
  activity_chat_read_15 = 'activity_chat_read_15',
  activity_chat_read_17 = 'activity_chat_read_17',
  activity_chat_read_18 = 'activity_chat_read_18',
  activity_chat_read_19 = 'activity_chat_read_19',
  activity_chat_read_20 = 'activity_chat_read_20',
  activity_chat_read_21 = 'activity_chat_read_21',
  activity_chat_read_22 = 'activity_chat_read_22',
  activity_chat_read_23 = 'activity_chat_read_23',
  activity_chat_read_24 = 'activity_chat_read_24',
  activity_chat_read_25 = 'activity_chat_read_25',
  activity_chat_read_26 = 'activity_chat_read_26',
  activity_chat_read_27 = 'activity_chat_read_27',
  activity_chat_read_28 = 'activity_chat_read_28',
  activity_chat_read_n = 'activity_chat_read_n',
  activity_chat_type = 'activity_chat_type',
  activity_distance = 'activity_distance',
  activity_feed = 'activity_feed',
  activity_feed_imagefeed = 'activity_feed_imagefeed',
  activity_feed_vod = 'activity_feed_vod',
  activity_imbread = 'activity_imbread',
  activity_letter_read = 'activity_letter_read',
  activity_like = 'activity_like',
  activity_limit = 'activity_limit',
  activity_list = 'activity_list',
  activity_location = 'activity_location',
  activity_ms = 'activity_ms',
  activity_notif = 'activity_notif',
  activity_photo_mail = 'activity_photo_mail',
  activity_stories = 'activity_stories',
  activity_stories_like = 'activity_stories_like',
  activity_stories_view = 'activity_stories_view',
  activity_text_chat_btn = 'activity_text_chat_btn',
  activity_updateline_photo = 'activity_updateline_photo',
  activity_updateline_video = 'activity_updateline_video',
  activity_video_chat_btn = 'activity_video_chat_btn',
  activity_video_mail = 'activity_video_mail',
  activity_videochatinvite = 'activity_videochatinvite',
  activity_views_imagefeed = 'activity_views_imagefeed',
  activity_views_vod = 'activity_views_vod',
  activity_winked = 'activity_winked',
  activity_winked_n = 'activity_winked_n',
  activity_winks_imagefeed = 'activity_winks_imagefeed',
  activity_winks_vod = 'activity_winks_vod',
  addMsisdnAfterLogin = 'addMsisdnAfterLogin',
  addMsisdnAfterPayment = 'addMsisdnAfterPayment',
  addMsisdnFromChat = 'addMsisdnFromChat',
  addMsisdnFromNotification = 'addMsisdnFromNotification',
  addMsisdnFromNotificationFeed = 'addMsisdnFromNotificationFeed',
  addMsisdnFromNotificationFeedList = 'addMsisdnFromNotificationFeedList',
  addMsisdnFromPSMS = 'addMsisdnFromPSMS',
  addMsisdnFromProfile = 'addMsisdnFromProfile',
  addMsisdnOneClickChat = 'addMsisdnOneClickChat',
  addMsisdnOneClickPayment = 'addMsisdnOneClickPayment',
  addMsisdnOneClickPhoto = 'addMsisdnOneClickPhoto',
  add_comment = 'add_comment',
  add_comment_photo = 'add_comment_photo',
  add_comment_status = 'add_comment_status',
  add_comment_vod = 'add_comment_vod',
  add_comment_wallaction = 'add_comment_wallaction',
  add_favor = 'add_favor',
  add_like = 'add_like',
  adminExtraFeature = 'adminExtraFeature',
  adminPromoOrderDeclined = 'adminPromoOrderDeclined',
  admin_match = 'admin_match',
  advSearch_paramChange = 'advSearch_paramChange',
  advanced_parameters = 'advanced_parameters',
  after_reg = 'after_reg',
  after_registration = 'after_registration',
  afterreg_offer = 'afterreg_offer',
  ageVerificationPage = 'ageVerificationPage',
  age_verif = 'age_verif',
  allphoto = 'allphoto',
  answer_message = 'answer_message',
  appData_index = 'appData_index',
  appMinimal = 'appMinimal',
  app_index = 'app_index',
  app_index_h = 'app_index_h',
  ask_for_photo = 'ask_for_photo',
  ask_for_photo_wc = 'ask_for_photo_wc',
  assistant = 'assistant',
  assistant_features = 'assistant_features',
  badges_50off = 'badges_50off',
  badges_page = 'badges_page',
  balance_header = 'balance_header',
  balance_profile = 'balance_profile',
  banner_notif = 'banner_notif',
  banner_unlimWeek = 'banner_unlimWeek',
  banner_upgrade_link = 'banner_upgrade_link',
  basic_features = 'basic_features',
  basic_member = 'basic_member',
  bell_addto_friends = 'bell_addto_friends',
  bell_browsed = 'bell_browsed',
  bell_chat_promotion = 'bell_chat_promotion',
  bell_chat_read = 'bell_chat_read',
  bell_chat_read_2 = 'bell_chat_read_2',
  bell_chat_read_3 = 'bell_chat_read_3',
  bell_chat_read_4 = 'bell_chat_read_4',
  bell_chat_read_5 = 'bell_chat_read_5',
  bell_chat_read_6 = 'bell_chat_read_6',
  bell_chat_read_7 = 'bell_chat_read_7',
  bell_chat_read_8 = 'bell_chat_read_8',
  bell_chat_read_9 = 'bell_chat_read_9',
  bell_chat_read_10 = 'bell_chat_read_10',
  bell_chat_read_11 = 'bell_chat_read_11',
  bell_chat_read_12 = 'bell_chat_read_12',
  bell_chat_read_13 = 'bell_chat_read_13',
  bell_chat_read_14 = 'bell_chat_read_14',
  bell_chat_read_14_b = 'bell_chat_read_14_b',
  bell_chat_read_17 = 'bell_chat_read_17',
  bell_chat_read_18 = 'bell_chat_read_18',
  bell_chat_read_19 = 'bell_chat_read_19',
  bell_chat_read_20 = 'bell_chat_read_20',
  bell_chat_read_21 = 'bell_chat_read_21',
  bell_chat_read_22 = 'bell_chat_read_22',
  bell_chat_read_23 = 'bell_chat_read_23',
  bell_chat_read_24 = 'bell_chat_read_24',
  bell_chat_read_25 = 'bell_chat_read_25',
  bell_chat_read_26 = 'bell_chat_read_26',
  bell_chat_read_27 = 'bell_chat_read_27',
  bell_chat_read_28 = 'bell_chat_read_28',
  bell_chat_read_reelsLike = 'bell_chat_read_reelsLike',
  bell_pma_photosend = 'bell_pma_photosend',
  bell_pma_reply_like = 'bell_pma_reply_like',
  bell_pma_reply_view = 'bell_pma_reply_view',
  bell_stories_like = 'bell_stories_like',
  bell_stories_view = 'bell_stories_view',
  bell_userlike = 'bell_userlike',
  bell_winked = 'bell_winked',
  big_photo_adv_search = 'big_photo_adv_search',
  big_photo_feed = 'big_photo_feed',
  big_photo_tab_search = 'big_photo_tab_search',
  billingHistory_index = 'billingHistory_index',
  billingHistory_stopSubscription = 'billingHistory_stopSubscription',
  birth_disc = 'birth_disc',
  blind_chat = 'blind_chat',
  blured_photo_for_split_1 = 'blured_photo_for_split_1',
  blured_photo_for_split_2 = 'blured_photo_for_split_2',
  blured_photo_for_split_3 = 'blured_photo_for_split_3',
  blured_photo_search = 'blured_photo_search',
  blurred_activity = 'blurred_activity',
  blurred_carousel = 'blurred_carousel',
  blurred_chat = 'blurred_chat',
  blurred_fav = 'blurred_fav',
  blurred_incoming = 'blurred_incoming',
  blurred_nearby = 'blurred_nearby',
  blurred_online = 'blurred_online',
  bn_ft1 = 'bn_ft1',
  bn_ft2 = 'bn_ft2',
  bn_ft3 = 'bn_ft3',
  bn_ft4 = 'bn_ft4',
  bongaCams = 'bongaCams',
  bottom_notice_posttrans = 'bottom_notice_posttrans',
  br1 = 'br1',
  br2 = 'br2',
  br3 = 'br3',
  br4 = 'br4',
  br5 = 'br5',
  browserlimit = 'browserlimit',
  bundle = 'bundle',
  bundle_1 = 'bundle_1',
  bundle_2 = 'bundle_2',
  bundle_3 = 'bundle_3',
  bundle_4 = 'bundle_4',
  bundle_5 = 'bundle_5',
  bundle_6 = 'bundle_6',
  bundle_reserved_1 = 'bundle_reserved_1',
  bundle_reserved_2 = 'bundle_reserved_2',
  bundle_reserved_3 = 'bundle_reserved_3',
  bundle_reserved_4 = 'bundle_reserved_4',
  bundle_reserved_5 = 'bundle_reserved_5',
  buy3DayGet1WeekPopup = 'buy3DayGet1WeekPopup',
  buyCredits = 'buyCredits',
  buyMinutes = 'buyMinutes',
  buy_content = 'buy_content',
  buy_content_low_balance = 'buy_content_low_balance',
  c2c = 'c2c',
  cam_search = 'cam_search',
  celebrity_id = 'celebrity_id',
  chat = 'chat',
  chat_1click = 'chat_1click',
  chat_blocked = 'chat_blocked',
  chat_end = 'chat_end',
  chat_history_autoclean = 'chat_history_autoclean',
  chat_notif = 'chat_notif',
  chat_notif_1 = 'chat_notif_1',
  chat_notif_2 = 'chat_notif_2',
  chat_promotion = 'chat_promotion',
  chat_purchase_credits = 'chat_purchase_credits',
  chat_read = 'chat_read',
  chat_read_2 = 'chat_read_2',
  chat_read_3 = 'chat_read_3',
  chat_read_4 = 'chat_read_4',
  chat_read_5 = 'chat_read_5',
  chat_read_6 = 'chat_read_6',
  chat_read_6_n = 'chat_read_6_n',
  chat_read_7 = 'chat_read_7',
  chat_read_8 = 'chat_read_8',
  chat_read_9 = 'chat_read_9',
  chat_read_10 = 'chat_read_10',
  chat_read_11 = 'chat_read_11',
  chat_read_12 = 'chat_read_12',
  chat_read_13 = 'chat_read_13',
  chat_read_14 = 'chat_read_14',
  chat_read_14_a = 'chat_read_14_a',
  chat_read_14_b = 'chat_read_14_b',
  chat_read_15 = 'chat_read_15',
  chat_read_15_a = 'chat_read_15_a',
  chat_read_20 = 'chat_read_20',
  chat_read_21 = 'chat_read_21',
  chat_read_22 = 'chat_read_22',
  chat_read_23 = 'chat_read_23',
  chat_read_24 = 'chat_read_24',
  chat_read_25 = 'chat_read_25',
  chat_read_26 = 'chat_read_26',
  chat_read_27 = 'chat_read_27',
  chat_read_28 = 'chat_read_28',
  chat_read_29 = 'chat_read_29',
  chat_read_30 = 'chat_read_30',
  chat_read_31 = 'chat_read_31',
  chat_read_n = 'chat_read_n',
  chat_read_notif = 'chat_read_notif',
  chat_read_notif_dir = 'chat_read_notif_dir',
  chat_read_question_game = 'chat_read_question_game',
  chat_read_reelsLike = 'chat_read_reelsLike',
  chat_read_s = 'chat_read_s',
  chat_read_tutorial = 'chat_read_tutorial',
  chat_read_tutorial_wc = 'chat_read_tutorial_wc',
  chat_read_updateline = 'chat_read_updateline',
  chat_room_promo_free = 'chat_room_promo_free',
  chat_room_promo_paid = 'chat_room_promo_paid',
  chat_roulette_limit = 'chat_roulette_limit',
  chat_roulette_type = 'chat_roulette_type',
  chat_template = 'chat_template',
  chat_trysend = 'chat_trysend',
  chat_txt = 'chat_txt',
  chat_type = 'chat_type',
  chat_type_16 = 'chat_type_16',
  chat_type_aivirtualpartner = 'chat_type_aivirtualpartner',
  chat_type_ent = 'chat_type_ent',
  chat_type_fav = 'chat_type_fav',
  chat_type_hot = 'chat_type_hot',
  chat_type_lg = 'chat_type_lg',
  chat_type_matchpopup = 'chat_type_matchpopup',
  chat_type_n = 'chat_type_n',
  chat_type_ph = 'chat_type_ph',
  chat_type_updateline = 'chat_type_updateline',
  chat_type_wc = 'chat_type_wc',
  chataholic_pack_banner = 'chataholic_pack_banner',
  chatroom_chat_type = 'chatroom_chat_type',
  chatroom_privatevideo = 'chatroom_privatevideo',
  chatroom_profileview = 'chatroom_profileview',
  chatroom_wc = 'chatroom_wc',
  chatrooms_imagefeed = 'chatrooms_imagefeed',
  chatrooms_index_view = 'chatrooms_index_view',
  chatrooms_vod = 'chatrooms_vod',
  check_me_looking_for_city = 'check_me_looking_for_city',
  check_me_looking_for_like_you = 'check_me_looking_for_like_you',
  check_me_looking_for_match_perfectly = 'check_me_looking_for_match_perfectly',
  check_me_looking_for_partner = 'check_me_looking_for_partner',
  check_me_new_photo_explicit = 'check_me_new_photo_explicit',
  check_me_new_photo_naughty = 'check_me_new_photo_naughty',
  coin_mms = 'coin_mms',
  coin_sms = 'coin_sms',
  coinfide = 'coinfide',
  coinsAdminFeatures = 'coinsAdminFeatures',
  coinsPopupFeatures = 'coinsPopupFeatures',
  coinsSearchFeatures = 'coinsSearchFeatures',
  coins_banner_activity = 'coins_banner_activity',
  coins_banner_discreetProfiles = 'coins_banner_discreetProfiles',
  coins_banner_messenger = 'coins_banner_messenger',
  coins_banner_msg = 'coins_banner_msg',
  coins_banner_popup = 'coins_banner_popup',
  coins_banner_profile = 'coins_banner_profile',
  coins_banner_search = 'coins_banner_search',
  coins_discreetProfiles = 'coins_discreetProfiles',
  coins_home = 'coins_home',
  coins_notification = 'coins_notification',
  coins_spin = 'coins_spin',
  comment_type = 'comment_type',
  conf_1d_for_free = 'conf_1d_for_free',
  conf_50pct_for_ms = 'conf_50pct_for_ms',
  contact_information_request = 'contact_information_request',
  country_limit = 'country_limit',
  credit = 'credit',
  credits_history = 'credits_history',
  credits_main = 'credits_main',
  credits_profile = 'credits_profile',
  credits_profile_1 = 'credits_profile_1',
  credits_profile_2 = 'credits_profile_2',
  credits_profile_likebook = 'credits_profile_likebook',
  credits_profile_story = 'credits_profile_story',
  credits_profile_undo = 'credits_profile_undo',
  credits_promo_likebook = 'credits_promo_likebook',
  credits_promo_likes = 'credits_promo_likes',
  credits_promo_stories = 'credits_promo_stories',
  credits_promo_topstories = 'credits_promo_topstories',
  credits_promo_visitors = 'credits_promo_visitors',
  credits_respin = 'credits_respin',
  credits_story_boost = 'credits_story_boost',
  credits_undo = 'credits_undo',
  crm = 'crm',
  crm_50 = 'crm_50',
  crm_70 = 'crm_70',
  crm_banner = 'crm_banner',
  crm_event = 'crm_event',
  crm_sepa_tab = 'crm_sepa_tab',
  crm_sofort_tab = 'crm_sofort_tab',
  cross_imb_chat = 'cross_imb_chat',
  cross_imb_coreg = 'cross_imb_coreg',
  cross_imb_coreg1 = 'cross_imb_coreg1',
  cross_imb_coreg2 = 'cross_imb_coreg2',
  cross_imb_coreg3 = 'cross_imb_coreg3',
  cross_imb_profile = 'cross_imb_profile',
  dailyrewars_resubscribe = 'dailyrewars_resubscribe',
  date_request = 'date_request',
  dating_course = 'dating_course',
  debtnotification = 'debtnotification',
  decline_alert = 'decline_alert',
  decline_page = 'decline_page',
  decline_page_back_btn = 'decline_page_back_btn',
  descriptionslimit = 'descriptionslimit',
  dialogue = 'dialogue',
  dialogue_expired = 'dialogue_expired',
  dimoco = 'dimoco',
  direct_link = 'direct_link',
  disable_adds = 'disable_adds',
  email = 'email',
  event_book = 'event_book',
  event_shortview_invite = 'event_shortview_invite',
  event_you_can_invite_invite = 'event_you_can_invite_invite',
  exit_offer_lgns = 'exit_offer_lgns',
  exit_offer_nolgns = 'exit_offer_nolgns',
  extended_distance = 'extended_distance',
  extended_location = 'extended_location',
  externalVod_index = 'externalVod_index',
  extra50discount = 'extra50discount',
  extraDayPopup = 'extraDayPopup',
  extraHourPopup1 = 'extraHourPopup1',
  extraHourPopup3 = 'extraHourPopup3',
  extraHourPopup4 = 'extraHourPopup4',
  extraHourPopup5 = 'extraHourPopup5',
  f_chats_read_receipt = 'f_chats_read_receipt',
  f_checkoutprofile = 'f_checkoutprofile',
  f_fanclubonly = 'f_fanclubonly',
  f_home_chataholic = 'f_home_chataholic',
  f_home_premium = 'f_home_premium',
  f_home_security = 'f_home_security',
  f_instead_flirtcast_chataholic = 'f_instead_flirtcast_chataholic',
  f_instead_flirtcast_premium = 'f_instead_flirtcast_premium',
  f_instead_flirtcast_security = 'f_instead_flirtcast_security',
  f_joinmyfanclub = 'f_joinmyfanclub',
  f_menu_chataholic = 'f_menu_chataholic',
  f_menu_premium = 'f_menu_premium',
  f_menu_security = 'f_menu_security',
  f_model_recordedshow = 'f_model_recordedshow',
  f_notif_chataholic = 'f_notif_chataholic',
  f_notif_premium = 'f_notif_premium',
  f_notif_security = 'f_notif_security',
  f_nudephotos = 'f_nudephotos',
  f_prem_chatpopup = 'f_prem_chatpopup',
  f_recordedshow = 'f_recordedshow',
  failed_notif = 'failed_notif',
  failed_notif_feature = 'failed_notif_feature',
  fanclub = 'fanclub',
  fav_sex_pos = 'fav_sex_pos',
  favorites_add = 'favorites_add',
  featurePopup = 'featurePopup',
  feature_banner_profile = 'feature_banner_profile',
  feature_banner_search = 'feature_banner_search',
  features = 'features',
  features_alert = 'features_alert',
  features_coins_menu = 'features_coins_menu',
  features_coins_msg = 'features_coins_msg',
  features_coins_web = 'features_coins_web',
  features_notif = 'features_notif',
  features_rm_popup = 'features_rm_popup',
  features_search = 'features_search',
  features_search_rs = 'features_search_rs',
  female_features_upgrade = 'female_features_upgrade',
  flirtAlarm = 'flirtAlarm',
  flirtcast_send = 'flirtcast_send',
  fortune_wheel_discount = 'fortune_wheel_discount',
  forum_post = 'forum_post',
  free5_verify = 'free5_verify',
  freeCommunication = 'freeCommunication',
  free_communication = 'free_communication',
  free_communication_activity_tooltip = 'free_communication_activity_tooltip',
  free_communication_banner = 'free_communication_banner',
  free_communication_not_paid = 'free_communication_not_paid',
  free_communication_paid = 'free_communication_paid',
  free_communication_search_activity_banner = 'free_communication_search_activity_banner',
  free_message = 'free_message',
  free_message_profile_banner = 'free_message_profile_banner',
  free_message_search_activity_banner = 'free_message_search_activity_banner',
  friends_imagefeed = 'friends_imagefeed',
  friends_vod = 'friends_vod',
  ftLimitedOffer = 'ftLimitedOffer',
  ftSecondOffer = 'ftSecondOffer',
  ftcard_1st = 'ftcard_1st',
  ftcard_1st_special = 'ftcard_1st_special',
  ftcard_2st = 'ftcard_2st',
  ftcard_2st_lifetime = 'ftcard_2st_lifetime',
  ftcard_pp = 'ftcard_pp',
  ftcard_trial = 'ftcard_trial',
  full_member = 'full_member',
  full_profile_text_chat = 'full_profile_text_chat',
  full_profile_video_chat = 'full_profile_video_chat',
  full_size_vip_photo = 'full_size_vip_photo',
  full_size_vip_video = 'full_size_vip_video',
  funnel = 'funnel',
  funnel_confirm = 'funnel_confirm',
  gallery_blurred = 'gallery_blurred',
  gallery_chat = 'gallery_chat',
  gallery_like = 'gallery_like',
  gallery_paidChat = 'gallery_paidChat',
  generous_gift = 'generous_gift',
  geo_feature = 'geo_feature',
  get_credits = 'get_credits',
  get_me_high = 'get_me_high',
  gift = 'gift',
  go_offline_settings = 'go_offline_settings',
  going_book = 'going_book',
  group_chat_pp = 'group_chat_pp',
  hard_decline = 'hard_decline',
  help_section = 'help_section',
  hideAgeGeo = 'hideAgeGeo',
  higherpack_popup = 'higherpack_popup',
  highlight_profile = 'highlight_profile',
  hint_looking_for = 'hint_looking_for',
  hint_ms = 'hint_ms',
  hint_photobig = 'hint_photobig',
  hint_start_chat = 'hint_start_chat',
  hint_view = 'hint_view',
  holiday_offer = 'holiday_offer',
  holiday_offer_s = 'holiday_offer_s',
  home = 'home',
  home_feature = 'home_feature',
  home_membership = 'home_membership',
  homepage_centerbar = 'homepage_centerbar',
  homepage_leftbar = 'homepage_leftbar',
  homepage_rightrbar = 'homepage_rightrbar',
  hon_big_photo_more = 'hon_big_photo_more',
  hon_fullmatches_message = 'hon_fullmatches_message',
  hon_fullmatches_photo = 'hon_fullmatches_photo',
  hon_likeback_notif = 'hon_likeback_notif',
  hon_likedyou_banner = 'hon_likedyou_banner',
  hon_likedyou_user = 'hon_likedyou_user',
  hon_looking_for = 'hon_looking_for',
  hookupPlan = 'hookupPlan',
  hot_icon_profile = 'hot_icon_profile',
  hot_icon_search = 'hot_icon_search',
  hot_profile_view = 'hot_profile_view',
  hot_user = 'hot_user',
  hot_user_chat_banner = 'hot_user_chat_banner',
  hot_user_favourite = 'hot_user_favourite',
  hot_user_message = 'hot_user_message',
  hot_user_photo = 'hot_user_photo',
  hot_user_profile_banner = 'hot_user_profile_banner',
  hot_user_search_activity_banner = 'hot_user_search_activity_banner',
  hot_user_view = 'hot_user_view',
  hot_user_wink = 'hot_user_wink',
  hustler_access = 'hustler_access',
  hustler_allvideo_banner = 'hustler_allvideo_banner',
  hustler_video_view = 'hustler_video_view',
  icebreaker_assist_limit = 'icebreaker_assist_limit',
  icebreaker_assist_limit_2 = 'icebreaker_assist_limit_2',
  imbread = 'imbread',
  imbupgradebutton = 'imbupgradebutton',
  inb_chat_read = 'inb_chat_read',
  inb_chat_read_2 = 'inb_chat_read_2',
  inb_chat_read_3 = 'inb_chat_read_3',
  inb_chat_read_4 = 'inb_chat_read_4',
  inb_chat_read_5 = 'inb_chat_read_5',
  inb_chat_read_6 = 'inb_chat_read_6',
  inb_chat_read_7 = 'inb_chat_read_7',
  inb_chat_read_8 = 'inb_chat_read_8',
  inb_chat_read_9 = 'inb_chat_read_9',
  inb_chat_read_10 = 'inb_chat_read_10',
  inb_chat_read_11 = 'inb_chat_read_11',
  inb_chat_read_12 = 'inb_chat_read_12',
  inb_chat_read_13 = 'inb_chat_read_13',
  inb_chat_read_14 = 'inb_chat_read_14',
  inb_chat_read_15 = 'inb_chat_read_15',
  inb_chat_read_16 = 'inb_chat_read_16',
  inb_chat_read_17 = 'inb_chat_read_17',
  inb_chat_read_18 = 'inb_chat_read_18',
  inb_chat_read_19 = 'inb_chat_read_19',
  inb_chat_read_20 = 'inb_chat_read_20',
  inb_chat_read_21 = 'inb_chat_read_21',
  inb_chat_read_22 = 'inb_chat_read_22',
  inb_chat_read_23 = 'inb_chat_read_23',
  inb_chat_read_24 = 'inb_chat_read_24',
  inb_chat_read_25 = 'inb_chat_read_25',
  inb_chat_read_26 = 'inb_chat_read_26',
  inb_chat_read_27 = 'inb_chat_read_27',
  inb_chat_read_28 = 'inb_chat_read_28',
  inb_chat_type = 'inb_chat_type',
  inb_chat_type_16 = 'inb_chat_type_16',
  inb_chat_type_n = 'inb_chat_type_n',
  inb_message_read_alerts = 'inb_message_read_alerts',
  inb_notif_browsed = 'inb_notif_browsed',
  inb_notif_chat_read = 'inb_notif_chat_read',
  inb_notif_chat_read_2 = 'inb_notif_chat_read_2',
  inb_notif_chat_read_3 = 'inb_notif_chat_read_3',
  inb_notif_chat_read_4 = 'inb_notif_chat_read_4',
  inb_notif_chat_read_5 = 'inb_notif_chat_read_5',
  inb_notif_chat_read_6 = 'inb_notif_chat_read_6',
  inb_notif_chat_read_7 = 'inb_notif_chat_read_7',
  inb_notif_chat_read_8 = 'inb_notif_chat_read_8',
  inb_notif_chat_read_9 = 'inb_notif_chat_read_9',
  inb_notif_chat_read_10 = 'inb_notif_chat_read_10',
  inb_notif_chat_read_11 = 'inb_notif_chat_read_11',
  inb_notif_chat_read_12 = 'inb_notif_chat_read_12',
  inb_notif_chat_read_13 = 'inb_notif_chat_read_13',
  inb_notif_chat_read_14 = 'inb_notif_chat_read_14',
  inb_notif_chat_read_15 = 'inb_notif_chat_read_15',
  inb_notif_chat_read_16 = 'inb_notif_chat_read_16',
  inb_notif_chat_read_17 = 'inb_notif_chat_read_17',
  inb_notif_chat_read_18 = 'inb_notif_chat_read_18',
  inb_notif_chat_read_19 = 'inb_notif_chat_read_19',
  inb_notif_chat_read_20 = 'inb_notif_chat_read_20',
  inb_notif_chat_read_21 = 'inb_notif_chat_read_21',
  inb_notif_chat_read_22 = 'inb_notif_chat_read_22',
  inb_notif_chat_read_23 = 'inb_notif_chat_read_23',
  inb_notif_chat_read_24 = 'inb_notif_chat_read_24',
  inb_notif_chat_read_25 = 'inb_notif_chat_read_25',
  inb_notif_chat_read_26 = 'inb_notif_chat_read_26',
  inb_notif_chat_read_27 = 'inb_notif_chat_read_27',
  inb_notif_chat_read_28 = 'inb_notif_chat_read_28',
  inb_notif_photo_mail = 'inb_notif_photo_mail',
  inb_notif_video_mail = 'inb_notif_video_mail',
  inb_notif_winked = 'inb_notif_winked',
  inb_photo_send = 'inb_photo_send',
  inb_photo_sent = 'inb_photo_sent',
  inb_privateVideo = 'inb_privateVideo',
  inb_pv_chat_read = 'inb_pv_chat_read',
  inb_pv_chat_read_big = 'inb_pv_chat_read_big',
  inb_pv_chat_read_small = 'inb_pv_chat_read_small',
  inb_upgradebutton = 'inb_upgradebutton',
  inb_video_mail = 'inb_video_mail',
  inb_video_send = 'inb_video_send',
  inb_video_sent = 'inb_video_sent',
  inbox_chat_read = 'inbox_chat_read',
  inchat_banner = 'inchat_banner',
  incognito_mode_activity = 'incognito_mode_activity',
  incognito_mode_settings = 'incognito_mode_settings',
  incognito_view_banner = 'incognito_view_banner',
  incognito_view_profile = 'incognito_view_profile',
  indiaPopupPremium = 'indiaPopupPremium',
  inpage_decline = 'inpage_decline',
  instead_flirtcast = 'instead_flirtcast',
  interactionRooms = 'interactionRooms',
  interactionRooms_international_flirt = 'interactionRooms_international_flirt',
  interactionRooms_international_friends = 'interactionRooms_international_friends',
  interactionRooms_international_general = 'interactionRooms_international_general',
  interactionRooms_international_homosexual = 'interactionRooms_international_homosexual',
  interactionRooms_local_friends = 'interactionRooms_local_friends',
  interactionRooms_local_homosexual = 'interactionRooms_local_homosexual',
  interactionRooms_local_kinky = 'interactionRooms_local_kinky',
  interactionRooms_local_naughty = 'interactionRooms_local_naughty',
  internal_mail_send = 'internal_mail_send',
  internal_mail_view = 'internal_mail_view',
  invisibleMode = 'invisibleMode',
  invisible_activity_tooltip = 'invisible_activity_tooltip',
  invisible_mode = 'invisible_mode',
  invisible_mode_profile_banner = 'invisible_mode_profile_banner',
  invisible_mode_search_activity_banner = 'invisible_mode_search_activity_banner',
  ios_fsb_ltp = 'ios_fsb_ltp',
  ios_notif = 'ios_notif',
  ios_notif_pp = 'ios_notif_pp',
  ios_rad_ltp = 'ios_rad_ltp',
  ios_search_ltp = 'ios_search_ltp',
  join_live_chat = 'join_live_chat',
  landing_video = 'landing_video',
  laststepcard = 'laststepcard',
  leftmenu_imagefeed = 'leftmenu_imagefeed',
  letter_notif_1 = 'letter_notif_1',
  letter_notif_2 = 'letter_notif_2',
  letter_photo = 'letter_photo',
  letter_read = 'letter_read',
  letter_reply = 'letter_reply',
  letter_type = 'letter_type',
  letter_type_inbox = 'letter_type_inbox',
  letter_type_ph = 'letter_type_ph',
  lg_ActionButtons = 'lg_ActionButtons',
  lg_likedyou = 'lg_likedyou',
  lg_limit = 'lg_limit',
  lg_limits_profiles = 'lg_limits_profiles',
  lg_previous_profile = 'lg_previous_profile',
  lg_view_all_photo = 'lg_view_all_photo',
  lg_view_all_photos = 'lg_view_all_photos',
  lifeTime = 'lifeTime',
  lifetimeOffer = 'lifetimeOffer',
  like_read = 'like_read',
  likechat = 'likechat',
  liveCamCredits = 'liveCamCredits',
  liveCamOffer = 'liveCamOffer',
  liveJasmin = 'liveJasmin',
  live_cam = 'live_cam',
  livecam = 'livecam',
  livechat = 'livechat',
  livechat_index = 'livechat_index',
  load_more_feed = 'load_more_feed',
  load_more_users = 'load_more_users',
  login_popup = 'login_popup',
  logout = 'logout',
  looking_for = 'looking_for',
  looking_for_1 = 'looking_for_1',
  looking_for_2 = 'looking_for_2',
  looking_for_3 = 'looking_for_3',
  looking_for_4 = 'looking_for_4',
  looking_for_5 = 'looking_for_5',
  looking_for_6 = 'looking_for_6',
  looking_for_h = 'looking_for_h',
  looking_for_media = 'looking_for_media',
  looking_for_wc = 'looking_for_wc',
  low_balance_popup = 'low_balance_popup',
  loyalty_email1 = 'loyalty_email1',
  loyalty_email2 = 'loyalty_email2',
  loyalty_email3 = 'loyalty_email3',
  loyalty_email4 = 'loyalty_email4',
  loyalty_email5 = 'loyalty_email5',
  loyalty_email6 = 'loyalty_email6',
  loyalty_email7 = 'loyalty_email7',
  loyalty_sms = 'loyalty_sms',
  lp_devents = 'lp_devents',
  m_activity = 'm_activity',
  mailRead = 'mailRead',
  mailWrite = 'mailWrite',
  mail_1d_rm_free = 'mail_1d_rm_free',
  mail_ics = 'mail_ics',
  mail_sent_pmareply_flirtcast = 'mail_sent_pmareply_flirtcast',
  mailtype = 'mailtype',
  mainmenu_allinSearch = 'mainmenu_allinSearch',
  mainmenu_emailSyst = 'mainmenu_emailSyst',
  mainmenu_fullPhotos = 'mainmenu_fullPhotos',
  mainmenu_shareCont = 'mainmenu_shareCont',
  mainmenu_unlimChat = 'mainmenu_unlimChat',
  mainmenu_unlimLG = 'mainmenu_unlimLG',
  match = 'match',
  match_view = 'match_view',
  matches_photobig = 'matches_photobig',
  maxpay_external_pp_close = 'maxpay_external_pp_close',
  megaBoost = 'megaBoost',
  membership = 'membership',
  membershipOfferAfterUnlockProfile = 'membershipOfferAfterUnlockProfile',
  membershipOfferAfterUnlockProfileAdult = 'membershipOfferAfterUnlockProfileAdult',
  membership_advanced_search = 'membership_advanced_search',
  membership_alert = 'membership_alert',
  membership_pp_mini = 'membership_pp_mini',
  membership_search = 'membership_search',
  membership_search_rs = 'membership_search_rs',
  membershipwithwcaccess = 'membershipwithwcaccess',
  menu = 'menu',
  menu_banner = 'menu_banner',
  message = 'message',
  message_read_alerts = 'message_read_alerts',
  messager_5free = 'messager_5free',
  messages_advanced_change = 'messages_advanced_change',
  messages_banner = 'messages_banner',
  messenger = 'messenger',
  messenger_1click_messages = 'messenger_1click_messages',
  messenger_chat_read = 'messenger_chat_read',
  messenger_unlimWeek = 'messenger_unlimWeek',
  metArt = 'metArt',
  microFeatures = 'microFeatures',
  microFeaturesPedal = 'microFeaturesPedal',
  microFeaturesSpecialOffer = 'microFeaturesSpecialOffer',
  microIncognito = 'microIncognito',
  miniMembership = 'miniMembership',
  missed_video_call = 'missed_video_call',
  missed_voice_call = 'missed_voice_call',
  mobSideMenu = 'mobSideMenu',
  moreLikes = 'moreLikes',
  more_communication = 'more_communication',
  motivation_banner = 'motivation_banner',
  motivation_banner_activityfeed = 'motivation_banner_activityfeed',
  motivation_banner_chatrooms = 'motivation_banner_chatrooms',
  motivation_banner_friends = 'motivation_banner_friends',
  motivation_banner_home = 'motivation_banner_home',
  motivation_banner_leftsidebar_1 = 'motivation_banner_leftsidebar_1',
  motivation_banner_myprof = 'motivation_banner_myprof',
  motivation_banner_newsfeed = 'motivation_banner_newsfeed',
  motivation_banner_profile = 'motivation_banner_profile',
  motivation_banner_search = 'motivation_banner_search',
  motivation_banner_search_1var = 'motivation_banner_search_1var',
  motivation_banner_search_2var = 'motivation_banner_search_2var',
  motivation_banner_search_3var = 'motivation_banner_search_3var',
  motivation_banner_settings = 'motivation_banner_settings',
  motivation_banner_sidemenu = 'motivation_banner_sidemenu',
  motivation_banner_someprof = 'motivation_banner_someprof',
  motivation_banner_userprofile = 'motivation_banner_userprofile',
  motivation_banner_views = 'motivation_banner_views',
  motivation_banner_winks = 'motivation_banner_winks',
  motivationgiftpopup_m = 'motivationgiftpopup_m',
  motivationgiftpopup_w = 'motivationgiftpopup_w',
  motivationtipspopup_m = 'motivationtipspopup_m',
  motivationtipspopup_w = 'motivationtipspopup_w',
  msg_photo = 'msg_photo',
  msg_read = 'msg_read',
  msg_type = 'msg_type',
  msg_video = 'msg_video',
  msg_view_MS_redirect = 'msg_view_MS_redirect',
  msgr_gifts_store = 'msgr_gifts_store',
  msngr_1click_Template = 'msngr_1click_Template',
  msngr_1click_changeCard = 'msngr_1click_changeCard',
  msngr_1click_sendMedia = 'msngr_1click_sendMedia',
  msngr_1click_sendtxt = 'msngr_1click_sendtxt',
  msngr_textBanner = 'msngr_textBanner',
  mtv_chat = 'mtv_chat',
  mtv_chat_click = 'mtv_chat_click',
  mtv_chat_show = 'mtv_chat_show',
  mtv_msg = 'mtv_msg',
  mtv_msg_click = 'mtv_msg_click',
  mtv_msg_show = 'mtv_msg_show',
  multiSelectMicroFeatures = 'multiSelectMicroFeatures',
  myProfile_paid_messages = 'myProfile_paid_messages',
  my_liked_users = 'my_liked_users',
  my_profile_imagefeed = 'my_profile_imagefeed',
  my_profile_vod = 'my_profile_vod',
  my_settings_imagefeed = 'my_settings_imagefeed',
  my_settings_vod = 'my_settings_vod',
  mysettings_upstatus = 'mysettings_upstatus',
  navigation_location = 'navigation_location',
  newWayDating = 'newWayDating',
  newWayDatingCredits = 'newWayDatingCredits',
  new_tracker_in_sidebar_upgr = 'new_tracker_in_sidebar_upgr',
  newsfeed_photo_popup = 'newsfeed_photo_popup',
  newsfeed_text_chat = 'newsfeed_text_chat',
  newsfeed_video_chat = 'newsfeed_video_chat',
  notif_FreeMssgEnd = 'notif_FreeMssgEnd',
  notif_PaidMssgEnd = 'notif_PaidMssgEnd',
  notif_addto_friends = 'notif_addto_friends',
  notif_blurred = 'notif_blurred',
  notif_browsed = 'notif_browsed',
  notif_browsed_n = 'notif_browsed_n',
  notif_browsetype = 'notif_browsetype',
  notif_chat_promotion = 'notif_chat_promotion',
  notif_chat_read = 'notif_chat_read',
  notif_chat_read3 = 'notif_chat_read3',
  notif_chat_read_2 = 'notif_chat_read_2',
  notif_chat_read_3 = 'notif_chat_read_3',
  notif_chat_read_4 = 'notif_chat_read_4',
  notif_chat_read_5 = 'notif_chat_read_5',
  notif_chat_read_6 = 'notif_chat_read_6',
  notif_chat_read_7 = 'notif_chat_read_7',
  notif_chat_read_8 = 'notif_chat_read_8',
  notif_chat_read_9 = 'notif_chat_read_9',
  notif_chat_read_10 = 'notif_chat_read_10',
  notif_chat_read_11 = 'notif_chat_read_11',
  notif_chat_read_12 = 'notif_chat_read_12',
  notif_chat_read_13 = 'notif_chat_read_13',
  notif_chat_read_14 = 'notif_chat_read_14',
  notif_chat_read_14_b = 'notif_chat_read_14_b',
  notif_chat_read_15 = 'notif_chat_read_15',
  notif_chat_read_17 = 'notif_chat_read_17',
  notif_chat_read_18 = 'notif_chat_read_18',
  notif_chat_read_19 = 'notif_chat_read_19',
  notif_chat_read_20 = 'notif_chat_read_20',
  notif_chat_read_21 = 'notif_chat_read_21',
  notif_chat_read_22 = 'notif_chat_read_22',
  notif_chat_read_23 = 'notif_chat_read_23',
  notif_chat_read_24 = 'notif_chat_read_24',
  notif_chat_read_25 = 'notif_chat_read_25',
  notif_chat_read_26 = 'notif_chat_read_26',
  notif_chat_read_27 = 'notif_chat_read_27',
  notif_chat_read_28 = 'notif_chat_read_28',
  notif_chat_read_29 = 'notif_chat_read_29',
  notif_chat_read_30 = 'notif_chat_read_30',
  notif_chat_read_31 = 'notif_chat_read_31',
  notif_chat_read_n = 'notif_chat_read_n',
  notif_chat_read_reelsLike = 'notif_chat_read_reelsLike',
  notif_chat_type_1 = 'notif_chat_type_1',
  notif_chat_type_2 = 'notif_chat_type_2',
  notif_chat_type_3 = 'notif_chat_type_3',
  notif_chat_type_4 = 'notif_chat_type_4',
  notif_imbread = 'notif_imbread',
  notif_letter_read = 'notif_letter_read',
  notif_like = 'notif_like',
  notif_not_completed_payment = 'notif_not_completed_payment',
  notif_photo_mail = 'notif_photo_mail',
  notif_pma_photosend = 'notif_pma_photosend',
  notif_pma_reply_like = 'notif_pma_reply_like',
  notif_pma_reply_view = 'notif_pma_reply_view',
  notif_stories = 'notif_stories',
  notif_stories_like = 'notif_stories_like',
  notif_stories_view = 'notif_stories_view',
  notif_text_chat_btn = 'notif_text_chat_btn',
  notif_updateline_photo = 'notif_updateline_photo',
  notif_updateline_video = 'notif_updateline_video',
  notif_userlike = 'notif_userlike',
  notif_video_chat_btn = 'notif_video_chat_btn',
  notif_video_mail = 'notif_video_mail',
  notif_videochatinvite = 'notif_videochatinvite',
  notif_wc = 'notif_wc',
  notif_winked = 'notif_winked',
  notif_winked_n = 'notif_winked_n',
  notification_allinSearch = 'notification_allinSearch',
  notification_emailSyst = 'notification_emailSyst',
  notification_fullPhotos = 'notification_fullPhotos',
  notification_shareCont = 'notification_shareCont',
  notification_unlimChat = 'notification_unlimChat',
  notification_unlimLG = 'notification_unlimLG',
  notifications_features_banner = 'notifications_features_banner',
  notifications_popup_banner = 'notifications_popup_banner',
  notifications_popup_banner_h = 'notifications_popup_banner_h',
  notificator_feature = 'notificator_feature',
  offer = 'offer',
  oneDayTrial = 'oneDayTrial',
  oneLinePP = 'oneLinePP',
  onl_chat_read = 'onl_chat_read',
  onl_chat_type = 'onl_chat_type',
  onl_msg_read = 'onl_msg_read',
  onl_msg_type = 'onl_msg_type',
  openFunnel = 'openFunnel',
  open_model = 'open_model',
  open_pp_crm = 'open_pp_crm',
  open_video = 'open_video',
  open_video_2 = 'open_video_2',
  outgoing_call = 'outgoing_call',
  outgoing_call_profile = 'outgoing_call_profile',
  outgoing_voice_call = 'outgoing_voice_call',
  paid_mssgOffer = 'paid_mssgOffer',
  paid_nm = 'paid_nm',
  paid_nm_no_limits = 'paid_nm_no_limits',
  paid_nm_sexy = 'paid_nm_sexy',
  paid_spin = 'paid_spin',
  paidfunnel_applepay = 'paidfunnel_applepay',
  paidfunnel_f = 'paidfunnel_f',
  paidfunnel_holiday = 'paidfunnel_holiday',
  paidfunnel_m = 'paidfunnel_m',
  paidfunnel_utm_sub_paid = 'paidfunnel_utm_sub_paid',
  paidfunnel_utm_sub_pp = 'paidfunnel_utm_sub_pp',
  paidfunnel_utm_sub_reqpaid = 'paidfunnel_utm_sub_reqpaid',
  paidfunnel_utm_sub_reqpaid3d = 'paidfunnel_utm_sub_reqpaid3d',
  paidfunnel_utm_sub_skpp3d = 'paidfunnel_utm_sub_skpp3d',
  partly_message = 'partly_message',
  pay_dimoco = 'pay_dimoco',
  pay_retryPermission = 'pay_retryPermission',
  pay_tenYearsOffer = 'pay_tenYearsOffer',
  paypal = 'paypal',
  paysafecard = 'paysafecard',
  pedal9_Interactive_like_pop_up = 'pedal9_Interactive_like_pop_up',
  pedal_freeProfile = 'pedal_freeProfile',
  ph_request = 'ph_request',
  phone_call = 'phone_call',
  photo = 'photo',
  photo_badge_discount = 'photo_badge_discount',
  photo_big = 'photo_big',
  photo_big_search = 'photo_big_search',
  photo_big_wc = 'photo_big_wc',
  photo_feature = 'photo_feature',
  photo_mail = 'photo_mail',
  photo_mail_view_n = 'photo_mail_view_n',
  photo_more = 'photo_more',
  photo_more_wc = 'photo_more_wc',
  photo_open = 'photo_open',
  photo_read = 'photo_read',
  photo_request = 'photo_request',
  photo_send = 'photo_send',
  photo_sent = 'photo_sent',
  photo_small = 'photo_small',
  photo_stories_chat = 'photo_stories_chat',
  photo_view = 'photo_view',
  photo_view_update_line = 'photo_view_update_line',
  photofeed = 'photofeed',
  pma_photo_request_age = 'pma_photo_request_age',
  pma_photosend = 'pma_photosend',
  pma_reply_like = 'pma_reply_like',
  pma_reply_view = 'pma_reply_view',
  popupAllVideo = 'popupAllVideo',
  popupCredits = 'popupCredits',
  popupFeatures = 'popupFeatures',
  popupMembership = 'popupMembership',
  popupVideo = 'popupVideo',
  popup_gift = 'popup_gift',
  popup_gifts_store = 'popup_gifts_store',
  popup_notif_chat_read = 'popup_notif_chat_read',
  popup_notif_chat_read_3 = 'popup_notif_chat_read_3',
  popup_notif_chat_read_4 = 'popup_notif_chat_read_4',
  popup_notif_chat_read_5 = 'popup_notif_chat_read_5',
  popup_notif_chat_read_6 = 'popup_notif_chat_read_6',
  popup_tenYearsOffer = 'popup_tenYearsOffer',
  popup_unlimWeek = 'popup_unlimWeek',
  post_init_popup = 'post_init_popup',
  postreg = 'postreg',
  posttrans_crm_promo = 'posttrans_crm_promo',
  posttrans_promo = 'posttrans_promo',
  posttrans_promo_1 = 'posttrans_promo_1',
  posttrans_pup_promo = 'posttrans_pup_promo',
  pp_afterfunnel = 'pp_afterfunnel',
  pp_banner_myprof = 'pp_banner_myprof',
  pp_banner_someprof = 'pp_banner_someprof',
  pp_lifetime = 'pp_lifetime',
  pp_modelfunclub = 'pp_modelfunclub',
  pp_my_profile = 'pp_my_profile',
  pp_profile = 'pp_profile',
  pp_profile_ms = 'pp_profile_ms',
  pp_some_profile = 'pp_some_profile',
  pp_some_profile_ms = 'pp_some_profile_ms',
  premium_sticker = 'premium_sticker',
  priorityMan = 'priorityMan',
  prism = 'prism',
  privateAlbum = 'privateAlbum',
  privatePhoto = 'privatePhoto',
  privateVideo = 'privateVideo',
  privateVideochat = 'privateVideochat',
  private_photo = 'private_photo',
  private_photo_holder = 'private_photo_holder',
  private_photo_notif = 'private_photo_notif',
  private_photos_and_videos = 'private_photos_and_videos',
  privatpopup = 'privatpopup',
  privatpopup_m = 'privatpopup_m',
  prof_detail = 'prof_detail',
  profileVideo = 'profileVideo',
  profileVodShowAll = 'profileVodShowAll',
  profile_chat_type = 'profile_chat_type',
  profile_closed = 'profile_closed',
  profile_feed = 'profile_feed',
  profile_gift = 'profile_gift',
  profile_gifts_store = 'profile_gifts_store',
  profile_letter_type = 'profile_letter_type',
  profile_notif_dir = 'profile_notif_dir',
  profile_video = 'profile_video',
  profiles_limit = 'profiles_limit',
  promo = 'promo',
  promoFeatures = 'promoFeatures',
  promoMembership = 'promoMembership',
  promopage = 'promopage',
  push_crm_notification = 'push_crm_notification',
  push_goto_feature = 'push_goto_feature',
  push_goto_subscription = 'push_goto_subscription',
  pv_chat_read = 'pv_chat_read',
  pv_chat_read_big = 'pv_chat_read_big',
  pv_chat_read_small = 'pv_chat_read_small',
  quizfunnel = 'quizfunnel',
  read_mail_report = 'read_mail_report',
  reg_popup = 'reg_popup',
  registration_redirect = 'registration_redirect',
  remarketingOffer = 'remarketingOffer',
  remarketing_loyalty = 'remarketing_loyalty',
  remarketing_popup = 'remarketing_popup',
  remarketing_show_popup = 'remarketing_show_popup',
  remove_account = 'remove_account',
  repeatCreditPackageLessThanRequested = 'repeatCreditPackageLessThanRequested',
  repeat_inpage_decline = 'repeat_inpage_decline',
  report_member = 'report_member',
  restore_payment = 'restore_payment',
  result = 'result',
  rm_popup = 'rm_popup',
  rm_popup_hd1 = 'rm_popup_hd1',
  rm_popup_hd2 = 'rm_popup_hd2',
  s_1click = 's_1click',
  s_1click_change = 's_1click_change',
  s_1click_free = 's_1click_free',
  s_addfunds = 's_addfunds',
  s_addfunds_chat = 's_addfunds_chat',
  s_afterchat = 's_afterchat',
  s_billinginfo_addfunds = 's_billinginfo_addfunds',
  s_buzz = 's_buzz',
  s_crediticon = 's_crediticon',
  s_flash = 's_flash',
  s_flash_premiere = 's_flash_premiere',
  s_freechat = 's_freechat',
  s_getnaked = 's_getnaked',
  s_givegift = 's_givegift',
  s_givetip = 's_givetip',
  s_homebanner = 's_homebanner',
  s_message = 's_message',
  s_message_mp = 's_message_mp',
  s_more_addfunds = 's_more_addfunds',
  s_motivbaner = 's_motivbaner',
  s_myaccount_addfunds = 's_myaccount_addfunds',
  s_nudechat = 's_nudechat',
  s_nudephotos = 's_nudephotos',
  s_partychat = 's_partychat',
  s_postransdecline = 's_postransdecline',
  s_pps = 's_pps',
  s_privatechat = 's_privatechat',
  s_recordedshow = 's_recordedshow',
  s_regular = 's_regular',
  s_searchbanner = 's_searchbanner',
  s_secondposttrans = 's_secondposttrans',
  s_sneakpeekchat = 's_sneakpeekchat',
  s_stream_credits_bonus = 's_stream_credits_bonus',
  s_tipafternude = 's_tipafternude',
  s_tippingchat = 's_tippingchat',
  s_tipsafterchat = 's_tipsafterchat',
  s_videonotif = 's_videonotif',
  s_vmotivation = 's_vmotivation',
  s_voyeurview = 's_voyeurview',
  safe_mode = 'safe_mode',
  say_hi_promotion = 'say_hi_promotion',
  scope = 'scope',
  search = 'search',
  searchLimitOnlineNew = 'searchLimitOnlineNew',
  searchQueen = 'searchQueen',
  search_advanced = 'search_advanced',
  search_advanced_change = 'search_advanced_change',
  search_allinSearch = 'search_allinSearch',
  search_chat_type = 'search_chat_type',
  search_emailSyst = 'search_emailSyst',
  search_fullPhotos = 'search_fullPhotos',
  search_imagefeed = 'search_imagefeed',
  search_letter_type = 'search_letter_type',
  search_limit = 'search_limit',
  search_limit_h = 'search_limit_h',
  search_limit_nextprofile = 'search_limit_nextprofile',
  search_limit_popup = 'search_limit_popup',
  search_location = 'search_location',
  search_page_activity = 'search_page_activity',
  search_refill_location = 'search_refill_location',
  search_shareCont = 'search_shareCont',
  search_unlimChat = 'search_unlimChat',
  search_unlimLG = 'search_unlimLG',
  search_vod = 'search_vod',
  select_event_invite = 'select_event_invite',
  send_gift_messenger = 'send_gift_messenger',
  send_gift_profile = 'send_gift_profile',
  send_gift_search = 'send_gift_search',
  send_kiss_messenger = 'send_kiss_messenger',
  send_kiss_profile = 'send_kiss_profile',
  send_kiss_search = 'send_kiss_search',
  send_question_game = 'send_question_game',
  send_real_gift = 'send_real_gift',
  send_stickers = 'send_stickers',
  sepa_deu = 'sepa_deu',
  sepa_off = 'sepa_off',
  sexAlarm = 'sexAlarm',
  sex_preferences = 'sex_preferences',
  show_photos = 'show_photos',
  sideMenu = 'sideMenu',
  side_menu_feature_banner = 'side_menu_feature_banner',
  sidebar = 'sidebar',
  sidebar_communbanner = 'sidebar_communbanner',
  sidebar_communbanner11 = 'sidebar_communbanner11',
  sidemenu_features = 'sidemenu_features',
  similar_users = 'similar_users',
  site_autologin = 'site_autologin',
  siteadmin_buy_features = 'siteadmin_buy_features',
  siteadmin_buy_subscription = 'siteadmin_buy_subscription',
  skip_profile = 'skip_profile',
  small_profile_text_chat = 'small_profile_text_chat',
  small_profile_video_chat = 'small_profile_video_chat',
  small_vip_photo = 'small_vip_photo',
  small_vip_video = 'small_vip_video',
  sms_chat_sms_number_0 = 'sms_chat_sms_number_0',
  sms_chat_sms_number_4 = 'sms_chat_sms_number_4',
  smschat = 'smschat',
  sofort = 'sofort',
  spVideos_index = 'spVideos_index',
  special_delivery = 'special_delivery',
  special_delivery_promo = 'special_delivery_promo',
  spin_1day_free = 'spin_1day_free',
  spin_extraDay = 'spin_extraDay',
  spin_extra_discount = 'spin_extra_discount',
  spin_percent_discount = 'spin_percent_discount',
  spin_timer = 'spin_timer',
  ss_newsfeed = 'ss_newsfeed',
  ss_search = 'ss_search',
  start_sms_chat_button = 'start_sms_chat_button',
  status_read_messanger = 'status_read_messanger',
  status_type = 'status_type',
  stick = 'stick',
  stories_view = 'stories_view',
  stories_view_profile = 'stories_view_profile',
  stream_buzz = 'stream_buzz',
  stream_gift = 'stream_gift',
  stream_limit = 'stream_limit',
  stream_limit_model = 'stream_limit_model',
  stream_pip = 'stream_pip',
  stream_private_credits = 'stream_private_credits',
  stream_private_search = 'stream_private_search',
  stream_tips = 'stream_tips',
  streamate = 'streamate',
  streaming_stories_chat = 'streaming_stories_chat',
  subscription_cancel_flow = 'subscription_cancel_flow',
  superBoost = 'superBoost',
  super_dater = 'super_dater',
  super_like_limit = 'super_like_limit',
  super_like_paid_5 = 'super_like_paid_5',
  super_like_paid_15 = 'super_like_paid_15',
  super_like_paid_25 = 'super_like_paid_25',
  super_like_type = 'super_like_type',
  super_like_view = 'super_like_view',
  superspin_resubscribe = 'superspin_resubscribe',
  system_notification_startchat = 'system_notification_startchat',
  talks_checkaccess = 'talks_checkaccess',
  talks_init = 'talks_init',
  teaser_counter = 'teaser_counter',
  teaser_list_banner = 'teaser_list_banner',
  teaser_popup = 'teaser_popup',
  teaser_video_banner = 'teaser_video_banner',
  telegramConversation = 'telegramConversation',
  text_chat_page_video_chat = 'text_chat_page_video_chat',
  textchat_send = 'textchat_send',
  textchat_view = 'textchat_view',
  textchat_write = 'textchat_write',
  third_badge_discount = 'third_badge_discount',
  timerOffer = 'timerOffer',
  tips_freechat = 'tips_freechat',
  to_read_messages = 'to_read_messages',
  toolbar_5free = 'toolbar_5free',
  toolbar_getmore_button = 'toolbar_getmore_button',
  toolbar_offer_button = 'toolbar_offer_button',
  toolbar_tooltip_upgrade_button = 'toolbar_tooltip_upgrade_button',
  toolbar_upgrade_banner = 'toolbar_upgrade_banner',
  toolbar_upgrade_button = 'toolbar_upgrade_button',
  topInChat = 'topInChat',
  topMenu = 'topMenu',
  top_dates = 'top_dates',
  top_dates_feature = 'top_dates_feature',
  top_dates_popup_oneclick = 'top_dates_popup_oneclick',
  top_dates_profileView = 'top_dates_profileView',
  top_in_search = 'top_in_search',
  top_in_search_profile_banner = 'top_in_search_profile_banner',
  top_in_search_search_activity_banner = 'top_in_search_search_activity_banner',
  top_motivation = 'top_motivation',
  top_motivation_feature = 'top_motivation_feature',
  top_motivation_location = 'top_motivation_location',
  top_video_open = 'top_video_open',
  top_videos = 'top_videos',
  top_videos_main = 'top_videos_main',
  tray_notification_startchat = 'tray_notification_startchat',
  trial = 'trial',
  undo_benefit = 'undo_benefit',
  uniq_pp_discount = 'uniq_pp_discount',
  uniq_pp_mf_and_ms = 'uniq_pp_mf_and_ms',
  unknown = 'unknown',
  unlimited_pass = 'unlimited_pass',
  unlockProfile = 'unlockProfile',
  unlockProfileAdult = 'unlockProfileAdult',
  unsend_coin_mms = 'unsend_coin_mms',
  unsend_coin_sms = 'unsend_coin_sms',
  unsend_gift = 'unsend_gift',
  unsend_kiss = 'unsend_kiss',
  upcoming_invite = 'upcoming_invite',
  update_line_limit = 'update_line_limit',
  updateline_photo = 'updateline_photo',
  updateline_photomore = 'updateline_photomore',
  updateline_video = 'updateline_video',
  upgrade_btn = 'upgrade_btn',
  upgrade_notif = 'upgrade_notif',
  upgrade_search = 'upgrade_search',
  upgrade_search_h1 = 'upgrade_search_h1',
  upgrade_search_h2 = 'upgrade_search_h2',
  upgrade_search_h3 = 'upgrade_search_h3',
  upgrade_similar_users = 'upgrade_similar_users',
  upgrade_to_features = 'upgrade_to_features',
  userProfile_allinSearch = 'userProfile_allinSearch',
  userProfile_emailSyst = 'userProfile_emailSyst',
  userProfile_fullPhotos = 'userProfile_fullPhotos',
  userProfile_shareCont = 'userProfile_shareCont',
  userProfile_unlimChat = 'userProfile_unlimChat',
  userProfile_unlimLG = 'userProfile_unlimLG',
  user_comment = 'user_comment',
  user_profile_imagefeed = 'user_profile_imagefeed',
  user_profile_vod = 'user_profile_vod',
  user_remove = 'user_remove',
  user_view = 'user_view',
  userlike = 'userlike',
  userprofile_bigphoto = 'userprofile_bigphoto',
  userprofile_book = 'userprofile_book',
  userprofile_communbanner = 'userprofile_communbanner',
  userprofile_invite = 'userprofile_invite',
  userprofile_lookingfor = 'userprofile_lookingfor',
  userprofile_morephoto = 'userprofile_morephoto',
  userprofile_shortview_book = 'userprofile_shortview_book',
  userprofile_shortview_invite = 'userprofile_shortview_invite',
  userprofile_uptoread = 'userprofile_uptoread',
  userprofile_uptowrite = 'userprofile_uptowrite',
  userprofilemap = 'userprofilemap',
  valentine_card = 'valentine_card',
  verification = 'verification',
  verify = 'verify',
  via_photo = 'via_photo',
  videoChannel = 'videoChannel',
  videoDate = 'videoDate',
  videoMotivation = 'videoMotivation',
  videoUL_wc = 'videoUL_wc',
  video_call_invitation = 'video_call_invitation',
  video_conference = 'video_conference',
  video_mail = 'video_mail',
  video_mail_view_n = 'video_mail_view_n',
  video_popup = 'video_popup',
  video_popup_alexisaspen = 'video_popup_alexisaspen',
  video_popup_monicahotstar = 'video_popup_monicahotstar',
  video_popup_spicysaritass = 'video_popup_spicysaritass',
  video_popup_tiphanie5 = 'video_popup_tiphanie5',
  video_popup_zoepearl = 'video_popup_zoepearl',
  video_read = 'video_read',
  video_search = 'video_search',
  video_send = 'video_send',
  video_sent = 'video_sent',
  video_stories_chat = 'video_stories_chat',
  video_view_update_line = 'video_view_update_line',
  video_wc = 'video_wc',
  videochat_send = 'videochat_send',
  view_profile = 'view_profile',
  view_profile_activity = 'view_profile_activity',
  view_profile_blocked = 'view_profile_blocked',
  view_profile_closed = 'view_profile_closed',
  view_profile_lg = 'view_profile_lg',
  view_profile_messenger = 'view_profile_messenger',
  view_profile_wc = 'view_profile_wc',
  view_stories = 'view_stories',
  view_stream_limit = 'view_stream_limit',
  virtualGift_send = 'virtualGift_send',
  vod = 'vod',
  vodOneClick = 'vodOneClick',
  vod_index = 'vod_index',
  voe3 = 'voe3',
  voice_call_invitation = 'voice_call_invitation',
  wait_or_pay = 'wait_or_pay',
  watchReels = 'watchReels',
  wc_modelprofile = 'wc_modelprofile',
  wcancor = 'wcancor',
  wcchat = 'wcchat',
  webCamCredits = 'webCamCredits',
  webcams_poll = 'webcams_poll',
  wheel_of_fortune_discount = 'wheel_of_fortune_discount',
  who_liked_me = 'who_liked_me',
  widget_active_now = 'widget_active_now',
  widget_interested = 'widget_interested',
  widget_liked_you = 'widget_liked_you',
  widget_most_popular = 'widget_most_popular',
  widget_new_photos = 'widget_new_photos',
  widget_upgrade_to_videocall = 'widget_upgrade_to_videocall',
  wink_dir = 'wink_dir',
  worldWide = 'worldWide',
  wowlike_send = 'wowlike_send',
  wp_30 = 'wp_30',
  wp_30f = 'wp_30f',
  wp_50 = 'wp_50',
  wp_fp = 'wp_fp',
  write_message = 'write_message',
}

export type Video = {
  __typename?: 'Video';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  previewUrl: Scalars['String']['output'];
  sourceName: Scalars['String']['output'];
  uploadAt: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum VideoCallAction {
  busyResponse = 'busyResponse',
  privateVideoAcsessCall = 'privateVideoAcsessCall',
  privateVideoEndCall = 'privateVideoEndCall',
  privateVideoInvintation = 'privateVideoInvintation',
  privateVideoRejectCall = 'privateVideoRejectCall',
  privateVideoStop = 'privateVideoStop',
  privateVideoStopByTimeout = 'privateVideoStopByTimeout',
}

export type VideoCallEvent = {
  __typename?: 'VideoCallEvent';
  action?: Maybe<VideoCallAction>;
  id: Scalars['String']['output'];
  params?: Maybe<VideoCallParams>;
  timestamp: Scalars['Int']['output'];
  userId: Scalars['String']['output'];
};

export type VideoCallParams = {
  __typename?: 'VideoCallParams';
  fromVideoId?: Maybe<Scalars['String']['output']>;
  toVideoId?: Maybe<Scalars['String']['output']>;
};

export type VideoChatType = {
  __typename?: 'VideoChatType';
  allowedToReceive?: Maybe<Scalars['Boolean']['output']>;
  streamId?: Maybe<Scalars['ID']['output']>;
  viewedVideoId?: Maybe<Scalars['ID']['output']>;
};

export type VideoEvent = {
  __typename?: 'VideoEvent';
  duplicateVideoId: Scalars['ID']['output'];
  error: Scalars['String']['output'];
  group: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isConverted: Scalars['Boolean']['output'];
  partOfGroup: Scalars['Boolean']['output'];
  previewUrl: Scalars['String']['output'];
};

export type VideoInterface = {
  id: Scalars['ID']['output'];
  isConverted?: Maybe<Scalars['Boolean']['output']>;
  isPaidForView?: Maybe<Scalars['Boolean']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  videoPreviewUrl?: Maybe<Scalars['String']['output']>;
};

export type VideoMutedEvent = {
  __typename?: 'VideoMutedEvent';
  videoId: Scalars['ID']['output'];
};

export type VideoSend = {
  __typename?: 'VideoSend';
  isEnabled: Scalars['Boolean']['output'];
  upgradeViaToSendVideo: Scalars['String']['output'];
  upgradeViaToViewVideo: Scalars['String']['output'];
};

export type VideoType = {
  __typename?: 'VideoType';
  duration: Scalars['Int']['output'];
  error: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isConverted?: Maybe<Scalars['Boolean']['output']>;
  isPaidForView?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  percentage: Scalars['Int']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  videoPreviewUrl?: Maybe<Scalars['String']['output']>;
};

export type Videos = {
  __typename?: 'Videos';
  allVideos?: Maybe<Array<Maybe<VideoType>>>;
  count?: Maybe<Scalars['Int']['output']>;
  livecamProfileVideoCount?: Maybe<Scalars['Int']['output']>;
  primaryVideo?: Maybe<VideoType>;
};

export type ViewPermissions = {
  __typename?: 'ViewPermissions';
  photo: Scalars['Boolean']['output'];
  profile: Scalars['Boolean']['output'];
  /** @deprecated Use UserData.nearMe instead. Remove after FE-33513 */
  search: Scalars['Boolean']['output'];
  video: Scalars['Boolean']['output'];
};

export type ViewStoryPart = {
  __typename?: 'ViewStoryPart';
  countFreeParts?: Maybe<Scalars['Int']['output']>;
};

export type ViewStoryPartMutationResult = {
  __typename?: 'ViewStoryPartMutationResult';
  errors?: Maybe<MutationPayloadErrors>;
  result?: Maybe<ViewStoryPart>;
};

export type VirtualAssistant = {
  __typename?: 'VirtualAssistant';
  available: Scalars['Boolean']['output'];
  isSessionExist: Scalars['Boolean']['output'];
  user?: Maybe<UserData>;
};

export type VirtualAssistantPayload = {
  __typename?: 'VirtualAssistantPayload';
  sendWelcomeMessage?: Maybe<BooleanMutationResult>;
};

export type VirtualAssistantPayloadSendWelcomeMessageArgs = {
  isPhotoUploaded: Scalars['Boolean']['input'];
};

export type Visitor = {
  __typename?: 'Visitor';
  errors?: Maybe<Array<Maybe<ArgumentError>>>;
  result: Scalars['Boolean']['output'];
  userId: Scalars['ID']['output'];
};

export type VisitorInput = {
  age?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type VisitorsPayload = {
  __typename?: 'VisitorsPayload';
  errors?: Maybe<MutationPayloadErrors>;
  visitors?: Maybe<Array<Maybe<Visitor>>>;
};

export type VoteUser = {
  isLiked: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type WebBillingSepaSettings = {
  __typename?: 'WebBillingSepaSettings';
  formAttributes?: Maybe<FormAttributes>;
  method?: Maybe<Method>;
  sepaAutofillAccess: Scalars['Boolean']['output'];
  sepaFormStyle?: Maybe<Scalars['String']['output']>;
};

export type WebCamPromo = {
  __typename?: 'WebCamPromo';
  chattingOnCamTab?: Maybe<Placement>;
  liveGirlsRoom?: Maybe<Placement>;
  sideBarMenu?: Maybe<Placement>;
  topMenu?: Maybe<Placement>;
};

export enum WebCamPromoNames {
  chatRoomTab = 'chatRoomTab',
  chattingOnCamTab = 'chattingOnCamTab',
  newsFeed = 'newsFeed',
  notice = 'notice',
  profile = 'profile',
  profile_chatroom = 'profile_chatroom',
  profile_search = 'profile_search',
  profile_woc = 'profile_woc',
  sideBarMenu = 'sideBarMenu',
  topMenu = 'topMenu',
}

export type WebPush = {
  __typename?: 'WebPush';
  allowedAcceptedNotificator?: Maybe<Scalars['Boolean']['output']>;
  allowedByRoutes?: Maybe<Scalars['Boolean']['output']>;
  allowedStickyNotification?: Maybe<Scalars['Boolean']['output']>;
  dateInformEnabled?: Maybe<Scalars['Boolean']['output']>;
  dateInformResubscribeLogic?: Maybe<Scalars['String']['output']>;
  defaultBlocked?: Maybe<Scalars['Boolean']['output']>;
  disabledAfterLogin?: Maybe<Scalars['Boolean']['output']>;
  disabledRoutes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  loggerEnabled?: Maybe<Scalars['Boolean']['output']>;
  logicOptions?: Maybe<LogicOptions>;
  oneSignalAllowed?: Maybe<Scalars['Boolean']['output']>;
  safari?: Maybe<SafariWebService>;
  subscribeLogic?: Maybe<Scalars['String']['output']>;
  useNotificator?: Maybe<Scalars['Boolean']['output']>;
  vapidAccountId?: Maybe<Scalars['Int']['output']>;
  vapidPublicKey?: Maybe<Scalars['String']['output']>;
};

export type WebPushType = {
  __typename?: 'WebPushType';
  completed?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  reward?: Maybe<Scalars['Int']['output']>;
};

export type WebcamPlacements = {
  __typename?: 'WebcamPlacements';
  newsFeedNewsFeedNotificationProfile?: Maybe<Placement>;
  noticeActivityProfile?: Maybe<Placement>;
  noticeBoxNotificationProfile?: Maybe<Placement>;
  noticeChattingOnCamNotifProfile?: Maybe<Placement>;
  noticeNotif?: Maybe<Placement>;
  profileCamVideoButton?: Maybe<Placement>;
  profileProfileJoinChat?: Maybe<Placement>;
  profileProfilePhoto?: Maybe<Placement>;
  profile_chatroomChatRoomProfile?: Maybe<Placement>;
  profile_searchSearchJoinChat?: Maybe<Placement>;
  profile_searchSearchProfile?: Maybe<Placement>;
  profile_wocChattingOnCamProfile?: Maybe<Placement>;
};

export enum WeightAttribute {
  NOT_GIVEN = 'NOT_GIVEN',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  ST6_LB6 = 'ST6_LB6',
  ST6_LB8 = 'ST6_LB8',
  ST6_LB10 = 'ST6_LB10',
  ST6_LB12 = 'ST6_LB12',
  ST7_LB1 = 'ST7_LB1',
  ST7_LB3 = 'ST7_LB3',
  ST7_LB5 = 'ST7_LB5',
  ST7_LB7 = 'ST7_LB7',
  ST7_LB9 = 'ST7_LB9',
  ST7_LB12 = 'ST7_LB12',
  ST8_LB0 = 'ST8_LB0',
  ST8_LB2 = 'ST8_LB2',
  ST8_LB4 = 'ST8_LB4',
  ST8_LB6 = 'ST8_LB6',
  ST8_LB9 = 'ST8_LB9',
  ST8_LB11 = 'ST8_LB11',
  ST8_LB13 = 'ST8_LB13',
  ST9_LB1 = 'ST9_LB1',
  ST9_LB3 = 'ST9_LB3',
  ST9_LB6 = 'ST9_LB6',
  ST9_LB8 = 'ST9_LB8',
  ST9_LB10 = 'ST9_LB10',
  ST9_LB12 = 'ST9_LB12',
  ST10_LB0 = 'ST10_LB0',
  ST10_LB3 = 'ST10_LB3',
  ST10_LB5 = 'ST10_LB5',
  ST10_LB7 = 'ST10_LB7',
  ST10_LB9 = 'ST10_LB9',
  ST10_LB11 = 'ST10_LB11',
  ST11_LB0 = 'ST11_LB0',
  ST11_LB2 = 'ST11_LB2',
  ST11_LB4 = 'ST11_LB4',
  ST11_LB6 = 'ST11_LB6',
  ST11_LB8 = 'ST11_LB8',
  ST11_LB11 = 'ST11_LB11',
  ST11_LB13 = 'ST11_LB13',
  ST12_LB1 = 'ST12_LB1',
  ST12_LB3 = 'ST12_LB3',
  ST12_LB5 = 'ST12_LB5',
  ST12_LB8 = 'ST12_LB8',
  ST12_LB10 = 'ST12_LB10',
  ST12_LB12 = 'ST12_LB12',
  ST13_LB0 = 'ST13_LB0',
  ST13_LB2 = 'ST13_LB2',
  ST13_LB5 = 'ST13_LB5',
  ST13_LB7 = 'ST13_LB7',
  ST13_LB9 = 'ST13_LB9',
  ST13_LB11 = 'ST13_LB11',
  ST13_LB13 = 'ST13_LB13',
  ST14_LB2 = 'ST14_LB2',
  ST14_LB4 = 'ST14_LB4',
  ST14_LB6 = 'ST14_LB6',
  ST14_LB8 = 'ST14_LB8',
  ST14_LB10 = 'ST14_LB10',
  ST14_LB13 = 'ST14_LB13',
  ST15_LB1 = 'ST15_LB1',
  ST15_LB3 = 'ST15_LB3',
  ST15_LB5 = 'ST15_LB5',
  ST15_LB7 = 'ST15_LB7',
  ST15_LB10 = 'ST15_LB10',
  ST15_LB12 = 'ST15_LB12',
  ST16_LB0 = 'ST16_LB0',
  ST16_LB2 = 'ST16_LB2',
  ST16_LB4 = 'ST16_LB4',
  ST16_LB7 = 'ST16_LB7',
  ST16_LB9 = 'ST16_LB9',
  ST16_LB11 = 'ST16_LB11',
  ST16_LB13 = 'ST16_LB13',
  ST17_LB1 = 'ST17_LB1',
  ST17_LB4 = 'ST17_LB4',
  ST17_LB6 = 'ST17_LB6',
  ST17_LB8 = 'ST17_LB8',
  ST17_LB10 = 'ST17_LB10',
  ST17_LB12 = 'ST17_LB12',
  ST18_LB1 = 'ST18_LB1',
  ST18_LB3 = 'ST18_LB3',
  ST18_LB5 = 'ST18_LB5',
  ST18_LB7 = 'ST18_LB7',
  ST18_LB9 = 'ST18_LB9',
  ST18_LB12 = 'ST18_LB12',
  ST19_LB0 = 'ST19_LB0',
  ST19_LB2 = 'ST19_LB2',
  ST19_LB4 = 'ST19_LB4',
  ST19_LB6 = 'ST19_LB6',
  ST19_LB9 = 'ST19_LB9',
  ST19_LB11 = 'ST19_LB11',
  ST19_LB13 = 'ST19_LB13',
  ST20_LB1 = 'ST20_LB1',
  ST20_LB3 = 'ST20_LB3',
  ST20_LB6 = 'ST20_LB6',
  ST20_LB8 = 'ST20_LB8',
  ST20_LB10 = 'ST20_LB10',
  ST20_LB12 = 'ST20_LB12',
  ST21_LB0 = 'ST21_LB0',
  ST21_LB3 = 'ST21_LB3',
  ST21_LB5 = 'ST21_LB5',
  ST21_LB7 = 'ST21_LB7',
  ST21_LB9 = 'ST21_LB9',
  ST21_LB11 = 'ST21_LB11',
}

export type WeightDictionary = {
  __typename?: 'WeightDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<WeightAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type WeightUserAttributeDictionary = {
  __typename?: 'WeightUserAttributeDictionary';
  key?: Maybe<WeightAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type Widget = {
  __typename?: 'Widget';
  displayOnlyTop?: Maybe<Scalars['Boolean']['output']>;
  enabled: Scalars['Boolean']['output'];
  imageName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  weight?: Maybe<Scalars['Float']['output']>;
};

export type WithPhotoDictionary = {
  __typename?: 'WithPhotoDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['Boolean']['output']>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type WithPrivateAlbumDictionary = {
  __typename?: 'WithPrivateAlbumDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['Boolean']['output']>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type WithVideoDictionary = {
  __typename?: 'WithVideoDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['Boolean']['output']>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type WorldWideInputParams = {
  age: InputAgeRange;
  gender?: InputMaybe<GenderTypeEnum>;
  photoLevel?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  withPhoto: Scalars['Boolean']['input'];
  withVideo: Scalars['Boolean']['input'];
};

export type WorldWideParams = {
  __typename?: 'WorldWideParams';
  age?: Maybe<AgeRange>;
  gender?: Maybe<GenderTypeEnum>;
  photoLevel?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  withPhoto: Scalars['Boolean']['output'];
  withVideo: Scalars['Boolean']['output'];
};

export type WorldWideParamsDictionary = {
  __typename?: 'WorldWideParamsDictionary';
  age?: Maybe<AgeRange>;
  gender?: Maybe<Array<Maybe<GenderDictionary>>>;
  photoLevel?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  withPhoto?: Maybe<WithPhotoDictionary>;
  withVideo?: Maybe<WithVideoDictionary>;
};

export type WorldWideSearch = {
  __typename?: 'WorldWideSearch';
  defaultParams?: Maybe<WorldWideParams>;
  isActivated: Scalars['Boolean']['output'];
  isAllowed: Scalars['Boolean']['output'];
  params?: Maybe<WorldWideParams>;
  paramsDictionary?: Maybe<WorldWideParamsDictionary>;
  settings?: Maybe<WorldWideSettingsSearch>;
  users?: Maybe<Users>;
};

export type WorldWideSearchUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  usersSearchParams?: InputMaybe<WorldWideInputParams>;
};

export type WorldWideSettingsSearch = {
  __typename?: 'WorldWideSettingsSearch';
  chunkSize?: Maybe<Scalars['Int']['output']>;
  searchLimit?: Maybe<Scalars['Int']['output']>;
};

export type YearOffer = {
  __typename?: 'YearOffer';
  discount?: Maybe<Scalars['Int']['output']>;
  discountedPrice?: Maybe<Price>;
  paymentUrl?: Maybe<Scalars['String']['output']>;
  reasonIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type YearsRange = {
  __typename?: 'YearsRange';
  current: Scalars['Int']['output'];
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export enum ZodiacSignAttribute {
  AQUARIUS = 'AQUARIUS',
  ARIES = 'ARIES',
  CANCER = 'CANCER',
  CAPRICORN = 'CAPRICORN',
  GEMINI = 'GEMINI',
  LEO = 'LEO',
  LIBRA = 'LIBRA',
  NOT_GIVEN = 'NOT_GIVEN',
  PISCES = 'PISCES',
  RATHER_NOT_SAY = 'RATHER_NOT_SAY',
  SAGITTARIUS = 'SAGITTARIUS',
  SCORPIO = 'SCORPIO',
  TAURUS = 'TAURUS',
  VIRGO = 'VIRGO',
}

export type ZodiacSignDictionary = {
  __typename?: 'ZodiacSignDictionary';
  groupTitle?: Maybe<Scalars['String']['output']>;
  key?: Maybe<ZodiacSignAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type ZodiacSignUserAttributeDictionary = {
  __typename?: 'ZodiacSignUserAttributeDictionary';
  key?: Maybe<ZodiacSignAttribute>;
  translation?: Maybe<Scalars['String']['output']>;
};

export type Zone = {
  __typename?: 'Zone';
  placement: ZonePlacement;
  zone: Scalars['String']['output'];
};

export type ZonePlacement = {
  __typename?: 'ZonePlacement';
  name: Scalars['String']['output'];
  nicheRotationNumber?: Maybe<Scalars['Int']['output']>;
  numberPerPage?: Maybe<Scalars['Int']['output']>;
};

export enum AlertBannerEnum {
  declineAlert = 'declineAlert',
  featuresAlert = 'featuresAlert',
  membershipAlert = 'membershipAlert',
}

export type AskFor = {
  __typename?: 'askFor';
  morePhotos: Scalars['Boolean']['output'];
  personalInfo: Scalars['Boolean']['output'];
  photo: Scalars['Boolean']['output'];
};

export type BankMotivationText = {
  __typename?: 'bankMotivationText';
  cardHolderName?: Maybe<Scalars['String']['output']>;
  cardIssuingBank?: Maybe<CardIssuingBank>;
};

export enum BlockUser {
  BLOCK = 'BLOCK',
  UNBLOCK = 'UNBLOCK',
}

export type CardIssuingBank = {
  __typename?: 'cardIssuingBank';
  phone?: Maybe<Scalars['String']['output']>;
  siteUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CommunicationPhotoRestrictionActivityType {
  addUserLike = 'addUserLike',
  chat = 'chat',
  favourite = 'favourite',
  flirtcast = 'flirtcast',
  friendshipAccept = 'friendshipAccept',
  friendshipDecline = 'friendshipDecline',
  friendshipRemove = 'friendshipRemove',
  friendshipRequest = 'friendshipRequest',
  imbImage = 'imbImage',
  imbVideo = 'imbVideo',
  inbox = 'inbox',
  likePhotoStories = 'likePhotoStories',
  likeProfile = 'likeProfile',
  likeVideoStories = 'likeVideoStories',
  multiFlirt = 'multiFlirt',
  privateVideo = 'privateVideo',
  quiz = 'quiz',
  startVideoStreamStories = 'startVideoStreamStories',
  sticker = 'sticker',
  textTemplate = 'textTemplate',
  view = 'view',
  wink = 'wink',
}

export type CrossVisionData = {
  __typename?: 'crossVisionData';
  allowed: Scalars['Boolean']['output'];
  partnerNetwork: Scalars['Boolean']['output'];
  siteName?: Maybe<Scalars['String']['output']>;
};

export type DiscountData = {
  __typename?: 'discountData';
  packages?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  percent?: Maybe<Scalars['Int']['output']>;
};

export enum DistanceUnits {
  kms = 'kms',
  miles = 'miles',
}

export enum FavoriteMutationAction {
  FAVORITE = 'FAVORITE',
  UNFAVORITE = 'UNFAVORITE',
}

export type FreeMessageNotification = {
  __typename?: 'freeMessageNotification';
  bannerType: FreeMessageNotificationBannerEnum;
  isAvailable: Scalars['Boolean']['output'];
  period: Scalars['Int']['output'];
};

export type FreeMessagesBanner = {
  __typename?: 'freeMessagesBanner';
  isAvailable: Scalars['Boolean']['output'];
};

export type OpenProfile = {
  __typename?: 'openProfile';
  fromChat: Scalars['Boolean']['output'];
  fromSearch: Scalars['Boolean']['output'];
};

export type PayUrlParams = {
  __typename?: 'payUrlParams';
  stockId?: Maybe<Scalars['String']['output']>;
  via?: Maybe<Scalars['String']['output']>;
};

export enum ProfileMediaType {
  photo = 'photo',
  video = 'video',
}

export type Quiz = {
  __typename?: 'quiz';
  blocks: Array<Maybe<QuizBlock>>;
};

export type QuizAnswer = {
  __typename?: 'quizAnswer';
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type QuizBlock = {
  __typename?: 'quizBlock';
  questions: Array<Maybe<QuizQuestion>>;
  title: Scalars['String']['output'];
};

export type QuizQuestion = {
  __typename?: 'quizQuestion';
  answers: Array<Maybe<QuizAnswer>>;
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type TelegramSubscription = {
  __typename?: 'telegramSubscription';
  completed?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  reward?: Maybe<Scalars['Int']['output']>;
};

export type UserStatuses = {
  __typename?: 'userStatuses';
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  isDiscreetProfile?: Maybe<Scalars['Boolean']['output']>;
  isExHumanAssistant?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isModerator?: Maybe<Scalars['Boolean']['output']>;
  isNew?: Maybe<Scalars['Boolean']['output']>;
  isOnline?: Maybe<Scalars['Boolean']['output']>;
  isRecentlyOnline?: Maybe<Scalars['Boolean']['output']>;
  isScammer?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use query.user.trusted.isTrusted */
  isTrusted?: Maybe<Scalars['Boolean']['output']>;
  isVirtualAssistant?: Maybe<Scalars['Boolean']['output']>;
  isWebcamProfile?: Maybe<Scalars['Boolean']['output']>;
};
