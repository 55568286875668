import type {EventsInput} from '@core/types/graphql';

type PrepareDataParams = {
  events: Array<EventsInput>;
};

// Convert data to tracking friendly format for backend
const prepareData = (
  dataList: Array<EventsInput>,
): Promise<PrepareDataParams> => {
  return Promise.resolve({
    events: dataList,
  });
};

export default prepareData;
