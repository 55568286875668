import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import logger from '@core/logger';
import ROUTES from '@core/application/constants/routes';
import fetchData from '@core/application/utils/fetchData';

import {DECLINE_VIA} from '../../common/constants/declineVia';
import {setReady} from '../../common/utils/setPaymentProcessingStatus';
import getDeclineScreenTemplate from '../../widgets/failForm/utils/getDeclineScreenTemplate';
import type {
  PaymentAnswer,
  PaymentDataProps,
} from '../types/paymentAnswerProps';
import {PAYMENT_FLOW_ABORT} from './getProcessPaymentFlow';
import getResourceForPayRequest from './getResourceForPayRequest';
import parsePayParamsBeforePayment from './parsePayParamsBeforePayment';
import safeRedirect from './safeRedirect';
import showRedirectUrl from './showRedirectUrl';

const MOVED_TEMPORARILY = 302;

const tryPay = async ({
  action,
  activePackage,
  altMethodsSettings,
  cancelRemarketingSubscription = false,
  method,
  packageId,
  prevVia,
  replaceRedirectToPostMessage = false,
  returnPath,
  scenario,
  stockId,
  urlParams,
  userId,
  withRedirectPayment = false,
  via,
  ...props
}: PaymentDataProps): Promise<PaymentAnswer> => {
  const {url, body} = getResourceForPayRequest({
    action,
    altMethodsSettings,
    method,
    prevVia,
    // Need to return from external payment methods(e.x., from payPal)
    returnPath,
    stockId,
    via,
    ...parsePayParamsBeforePayment({
      method,
      scenario,
      stockId,
      packageId,
      userId,
      ...props,
    }),
  });

  // Props for passing to next method by payment flow
  const commonProps = omitBy(
    {
      action,
      activePackage,
      cancelRemarketingSubscription,
      method,
      prevVia,
      replaceRedirectToPostMessage,
      returnPath,
      stockId,
      urlParams,
      via,
      ...props,
    },
    isNil,
  );

  return fetchData({url, body})
    .then((resp) => {
      if (resp.status === MOVED_TEMPORARILY) {
        setReady();
        showRedirectUrl(resp.headers.get('Location'), false);
      }

      return resp.json();
    })
    .then((resp) => ({
      meta: resp.meta,
      ...(resp.data ?? resp),
    }))
    .then((response) => {
      const {meta} = response;
      // Imitation of native 302 redirect
      if (meta?.code === MOVED_TEMPORARILY && meta?.redirect) {
        safeRedirect(meta.redirect);
        return PAYMENT_FLOW_ABORT;
      }

      const result = {
        ...commonProps,
        ...response,
      };

      if (
        withRedirectPayment &&
        response.orderId &&
        (response.url || response.redirectUrl)
      ) {
        return {
          ...result,
          isAllowedRedirect: true,
          status: true,
        };
      }

      if ((response.acsUrl && response.url) || response['3dsRedirectUrl']) {
        return result;
      }

      if (!response.status) {
        if (response.url) {
          return {
            ...result,
            declineScreenTemplate: getDeclineScreenTemplate(
              response.declineScreenTemplate,
            ),
          };
        }

        const redirectUrl = new URL(
          response.redirectUrl || `/pay?via=${DECLINE_VIA}`,
          window.location.toString(),
        );

        if (!redirectUrl.searchParams.has('returnPath')) {
          redirectUrl.searchParams.set('returnPath', ROUTES.SEARCH);
        }

        return {
          ...result,
          declineScreenTemplate: getDeclineScreenTemplate(
            response.declineScreenTemplate,
          ),
          redirectUrl: redirectUrl.toString(),
        };
      }

      return result;
    })
    .catch((err) => {
      logger.sendError(`[tryPay]: Error is ${err}`);

      return {
        ...commonProps,
        status: false,
      };
    });
};

export default tryPay;
